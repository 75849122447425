/* @flow */
/* eslint-env browser */
import { Component } from 'react';
import classnames from 'classnames'; // eslint-disable-line
import keycode from 'keycode';

type Props = {
  modal: ModalState,
  closeModal: CloseModal,
};

/**
 * @module ModalWrapper
 * @classdesc A view controller for simple modals.
 * The component itself will handle the most general functionality
 * of modals such as transitions and click outside and esc key listener
 * for closing.
 */
class ModalWrapper extends Component<Props> {
  mask: ?HTMLDivElement;

  componentDidMount() {
    if (this.isOpen()) {
      document.addEventListener('keyup', this.listener, false);
    }
  }

  componentDidUpdate() {
    // Background should not move when scrolling, set hidden when there is content
    if (this.isOpen()) {
      document.addEventListener('keyup', this.listener, false);
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }

  componentWillUnmount() {
    // todo: it is possible that the element selector has already been unmounted.
    document.removeEventListener('keyup', this.listener, false);
  }

  listener = (ev: SyntheticKeyboardEvent<>) => {
    if (!this.props.modal.disableClose && keycode(ev.keyCode) === 'esc') {
      this.close();
    }
  };

  close = (event?: SyntheticMouseEvent<>) => {
    if (
      typeof event === 'object' &&
      typeof event.preventDefault === 'function'
    ) {
      event.preventDefault();
    }
    if (this.isOpen()) {
      this.props.closeModal();
    }
  };

  handleMaskClick = (event: SyntheticMouseEvent<>) => {
    if (!this.props.modal.disableClose && event.target === this.mask) {
      this.close(event);
    }
  };

  /**
   * @returns {boolean} true if modal is open
   */
  isOpen = () => !!(this.props.modal && this.props.modal.content);

  render() {
    const Content = this.props.modal.content;

    const wrapperClass = classnames('modal_wrapper', {
      'modal_wrapper-open': this.isOpen(),
    });

    const modalclass = classnames('transition-modal', {
      'transition-modal-enter': this.isOpen(),
    });

    const modalfade = classnames('modalfade', {
      'modalfade-open': this.isOpen(),
    });

    return (
      <div className={modalclass}>
        <div className={modalfade}>
          {Content && (
            <div
              ref={(component) => {
                this.mask = component;
              }}
              onClick={this.handleMaskClick}
              className={wrapperClass}>
              <Content
                {...this.props.modal.contentProps}
                success={!!this.props.modal.success}
                failure={this.props.modal.failure}
                className="modal_main"
                close={this.close}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ModalWrapper;
