/* @flow */
import validator from 'validator';
import differenceInYears from 'date-fns/differenceInYears';
import isValid from 'date-fns/isValid';
import t from 'counterpart';

import { COUNTRIES_WITHOUT_PAYPAL } from '../constants/misc';

export const VALIDATOR_REQUIRED = 'VALIDATOR_REQUIRED';
export const required = (value: mixed) =>
  !!value && value !== '__selectTitle__';

export const VALIDATOR_ZIP = 'VALIDATOR_ZIP';
export const isValidZip = async (value: string | number) => {
  const { default: xregexp } = await import('xregexp');

  return xregexp('[[\\w]{1,}').test(value);
};

export const LINK_MUST_BE_VALID = 'LINK_MUST_BE_VALID';
export const linkMustBeValid = (value: string) => {
  if (value) {
    return value.startsWith('http://') || value.startsWith('https://');
  }
  return true;
};

export const VALIDATOR_ZIP_MAX_LENGTH = 'VALIDATOR_ZIP_MAX_LENGTH';

/* NOTE: Would desperately want to get rid of xregexp dependency, but
         there is no native way to check for unicode property escapes
         like \p and \P
*/
export const VALIDATOR_UNICODE_STRING = 'VALIDATOR_UNICODE_STRING';
export const isUnicodeString = async (value: string | number) => {
  const { default: xregexp } = await import('xregexp');

  return xregexp('[\\p{L}\\.\\-\\ ]{1,}').test(value);
};

export const VALIDATOR_INT = 'VALIDATOR_INT';
export const isInt = (value: string | number) =>
  validator.isInt(String(value), { min: 1 });

export const VALIDATOR_STRING = 'VALIDATOR_STRING';
export const isString = (value: string | number) =>
  validator.matches(String(value), /\S+/i);

export const VALIDATOR_EMAIL = 'VALIDATOR_EMAIL';
export const isEmail = (value: string | number): boolean =>
  validator.isEmail(String(value));

export const VALIDATOR_MAX_LENGTH = 'VALIDATOR_MAX_LENGTH';
export const maxLength = (max: number) => (value: string) =>
  value && value.length <= max;

export const VALIDATOR_MIN_LENGTH = 'VALIDATOR_MIN_LENGTH';
export const minLength = (min: number) => (value: string) =>
  value && value.length >= min;

export const VALIDATOR_ADULT = 'VALIDATOR_ADULT';
export const isAdult = (value: string) =>
  differenceInYears(new Date(), value) >= 18;

export const VALIDATOR_PASSWORD = 'VALIDATOR_PASSWORD';
export const password = (value: string) => minLength(8)(value);

export const VALIDATOR_PASSWORD_LOWER = 'VALIDATOR_PASSWORD_LOWER';
// this validates when the password is empty so we have to return undefined explicitly
// because String(null) = "null" :(
export const passwordLower = (value: string) => {
  return typeof value === 'string'
    ? validator.matches(value, /.*([a-z])+.*/)
    : undefined;
};

export const VALIDATOR_PASSWORD_UPPER = 'VALIDATOR_PASSWORD_UPPER';
export const passwordUpper = (value: string) =>
  validator.matches(String(value), /.*([A-Z])+.*/);

export const VALIDATOR_PASSWORD_NUMBER = 'VALIDATOR_PASSWORD_NUMBER';
export const passwordNumber = (value: string) =>
  validator.matches(String(value), /.*([0-9])+.*/);

export const VALIDATOR_PASSWORD_SPECIAL_CHAR =
  'VALIDATOR_PASSWORD_SPECIAL_CHAR';
export const passwordSpecialChar = (value: string) =>
  validator.matches(String(value), /.*([^A-Za-z0-9])+.*/);

export const VALIDATOR_ENTERPRISE_PASSWORD = 'VALIDATOR_ENTERPRISE_PASSWORD';
export const passwordEnterprise = (value: string) => minLength(8)(value);

export const PASSWORDS_MUST_MATCH = 'PASSWORDS_MUST_MATCH';
export const passwordsMustMatch = (value: string, allValues: any) =>
  allValues.password === allValues.confirmPassword;

export const VALIDATOR_PASSWORD_OR_EMPTY = 'VALIDATOR_PASSWORD_OR_EMPTY';
export const passwordOrEmpty = (value: string) => !value || minLength(8)(value);

export const VALIDATOR_ENTERPRISE_PASSWORD_OR_EMPTY =
  'VALIDATOR_ENTERPRISE_PASSWORD_OR_EMPTY';
export const passwordEnterpriseOrEmpty = (value: string) =>
  !value || minLength(8)(value);

export const VALIDATOR_DATE = 'VALIDATOR_DATE';
export const isDate = (value: string) => isValid(new Date(value));

export const VALIDATOR_TAX_ID = 'VALIDATOR_TAX_ID';
export const isValidTaxId = (value: string) =>
  !value ||
  (value &&
    validator.matches(
      String(value),
      /(?:^[a-zA-Z]{2,2})?[0-9A-Za-z-. ]{3,20}$/i
    ));

export const VALIDATOR_PAYPAL_COUNTRY = 'VALIDATOR_PAYPAL_COUNTRY';
export const isSupportedByPaypal = (value: string) =>
  COUNTRIES_WITHOUT_PAYPAL.indexOf(value) === -1;

export const VALIDATOR_BCG_EMAIL = 'VALIDATOR_BCG_EMAIL';
export const isBCGEmail = (value: string) => value.indexOf('@bcg.com') === -1;

const validCaptionCharsRegex =
  /^[0-9a-zA-Z/\sÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿŸžŽšŠ.,'-]+$/;

export const VALIDATOR_CAPTION_CHARS = 'VALIDATOR_CAPTION_CHARS';
export const captionChars = (value: string) =>
  validator.matches(value, validCaptionCharsRegex);

export const VALIDATOR_CAPTION_TOO_SHORT = 'VALIDATOR_CAPTION_TOO_SHORT';
export const captionTooShort = (value: string) => minLength(10)(value);

export const VALIDATOR_CAPTION_TOO_LONG = 'VALIDATOR_CAPTION_TOO_LONG';
export const captionTooLong = (value: string) => maxLength(100)(value);

export const VALIDATOR_NOT_ENOUGH_KEYWORDS = 'VALIDATOR_NOT_ENOUGH_KEYWORDS';
export const notEnoughKeywords = (value: string) => minLength(5)(value);

export const VALIDATOR_TOO_MANY_KEYWORDS_40 = 'VALIDATOR_TOO_MANY_KEYWORDS_40';
export const overFourtyKeywords = (value: string) => maxLength(40)(value);

export const VALIDATOR_TOO_MANY_KEYWORDS_50 = 'VALIDATOR_TOO_MANY_KEYWORDS_50';
export const overFiftyKeywords = (value: string) => maxLength(50)(value);

export const VALIDATOR_MUST_SELECT_NUMBER_OF_PEOPLE =
  'VALIDATOR_MUST_SELECT_NUMBER_OF_PEOPLE';
export const mustHavePeople = (value: string) => required(value);

export const VALIDATOR_MUST_SELECT_DAYTIME = 'VALIDATOR_MUST_SELECT_DAYTIME';
export const mustSelectDaytime = (value: string) => required(value);

const validators = {
  [VALIDATOR_REQUIRED]: required,
  [VALIDATOR_ZIP]: isValidZip,
  [VALIDATOR_ZIP_MAX_LENGTH]: maxLength(10),
  [VALIDATOR_UNICODE_STRING]: isUnicodeString,
  [VALIDATOR_INT]: isInt,
  [VALIDATOR_STRING]: isString,
  [VALIDATOR_EMAIL]: isEmail,
  [VALIDATOR_PASSWORD]: password,
  [VALIDATOR_PASSWORD_LOWER]: passwordLower,
  [VALIDATOR_PASSWORD_UPPER]: passwordUpper,
  [VALIDATOR_PASSWORD_NUMBER]: passwordNumber,
  [VALIDATOR_PASSWORD_SPECIAL_CHAR]: passwordSpecialChar,
  [VALIDATOR_MIN_LENGTH]: password,
  [VALIDATOR_ENTERPRISE_PASSWORD]: passwordEnterprise,
  [VALIDATOR_PASSWORD_OR_EMPTY]: passwordOrEmpty,
  [VALIDATOR_ENTERPRISE_PASSWORD_OR_EMPTY]: passwordEnterpriseOrEmpty,
  [VALIDATOR_ADULT]: isAdult,
  [VALIDATOR_DATE]: isDate,
  [VALIDATOR_TAX_ID]: isValidTaxId,
  [VALIDATOR_PAYPAL_COUNTRY]: isSupportedByPaypal,
  [VALIDATOR_BCG_EMAIL]: isBCGEmail,
  [PASSWORDS_MUST_MATCH]: passwordsMustMatch,
  [LINK_MUST_BE_VALID]: linkMustBeValid,
  [VALIDATOR_CAPTION_TOO_SHORT]: captionTooShort,
  [VALIDATOR_CAPTION_TOO_LONG]: captionTooLong,
  [VALIDATOR_CAPTION_CHARS]: captionChars,
  [VALIDATOR_NOT_ENOUGH_KEYWORDS]: notEnoughKeywords,
  [VALIDATOR_TOO_MANY_KEYWORDS_40]: overFourtyKeywords,
  [VALIDATOR_TOO_MANY_KEYWORDS_50]: overFiftyKeywords,
  [VALIDATOR_MUST_SELECT_NUMBER_OF_PEOPLE]: mustHavePeople,
  [VALIDATOR_MUST_SELECT_DAYTIME]: mustSelectDaytime,
};

const validate =
  (type: string, errorMessage: ?string) =>
  (value: string, allValues): void | string => {
    if (!validators[type]) {
      return `Missing validator: "${type}"`;
    }
    if (!validators[type](value, allValues)) {
      return errorMessage || t(`forms.error.${type}`);
    }
    return undefined;
  };

export default validate;

export const composeValidators =
  (...validators) =>
  (value, allValues, fieldState) =>
    validators.reduce(
      (error, validator) => error || validator(value, allValues, fieldState),
      undefined
    );
