/* @flow */

// NOTE: helps us keep our quota down on issues we don't care about.
export const SENTRY_IGNORE_LIST = [
  // Errors from JS run by Oppo devices Chrome WebView
  "Cannot read property 'getReadModeConfig' of undefined",
  "Cannot read property 'getReadModeExtract' of undefined",
  "Cannot read property 'getReadModeRender' of undefined",
  // Generic network errors
  "JSON Parse error: Unrecognized token '<'",
  'Failed to fetch',
  'NetworkError when attempting to fetch resource',
  'TypeError: Failed to fetch',
  /Loading chunk.*failed/,
  // Google Analytics gl obal missing
  "'ga' is undefined",
  "Can't find variable: ga",
  'ga is not defined',
  // 404s happening in GraphQL loaders
  /(graphql error|Album|User|Photo).*not found/i,
  'User has no payout level',
  'Paypal account verification failed',
  'Hold your horses, cowboy.',
  'ServerError: Response not successful: Received status code 400',
  'ServerParseError: Unexpected end of JSON input',
  'Paypal account verification failed.',
  // microsoft crawler (?)
  'Non-Error promise rejection captured with value: Object Not Found Matching Id:2',
  // Misc
  'ClipperError',
  'vid_mate_check is not defined',
  'globalThis is not defined',
  '_AutofillCallbackHandler',
];

const SENTRY_URL_DENYLIST = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
];

type SentryInitArguments = {
  sentryInstance: Function,
  config: { server: { hostname: string }, version: string },
  replayConfig: {
    replaysSessionSampleRate: number,
    replaysOnErrorSampleRate: number,
  },
  dsn: string,
  integrations: Array<Function>,
};

export const sentryInit = ({
  sentryInstance,
  config,
  replayConfig,
  dsn,
  integrations,
}: SentryInitArguments) =>
  sentryInstance.init({
    denyUrls: SENTRY_URL_DENYLIST,
    dsn,
    integrations,
    environment: config.server.hostname,
    ignoreErrors: SENTRY_IGNORE_LIST,
    release: config.version,
    replayConfig,
    beforeSend: (event) => {
      const userAgent = event.request.headers['User-Agent'];
      if (
        typeof userAgent === 'string' &&
        (userAgent.indexOf('Opera Mini') > -1 ||
          userAgent.indexOf('Yeti') > -1 ||
          userAgent.indexOf('Linespider') > -1)
      ) {
        return null;
      }
      return event;
    },
  });
