/* @flow */
import { FormRenderProps } from 'react-final-form';
import { RadioButton, Box, Text } from '@eyeem-ui/atoms';

type Props = {
  disabled?: boolean,
  options: Array<{
    value: string,
    text: string,
  }>,
  formRow?: boolean,
  radioColumned?: boolean,
  hideCheckbox: boolean,
} & FormRenderProps;

function RadioGroupWrapper(props: Props) {
  const errorMessage = props.meta.touched && props.meta.error;

  return (
    <>
      {props.options.map((item) => (
        <RadioButton
          {...props.input}
          key={item.value}
          name="gender"
          value={item.value}
          label={item.text}
        />
      ))}
      {errorMessage && (
        <Box mt={2}>
          <Text variant="title6" bold color="red50">
            {errorMessage}
          </Text>
        </Box>
      )}
    </>
  );
}

export default RadioGroupWrapper;
