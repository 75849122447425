/* @flow */
import { connect } from 'react-redux';

import SideNav from './sideNav';
import { toggleSideNavOpenThunk } from '../../../../helpers/actionCreators/redux';

import {
  SESSION_FIELD_HAS_SIDE_NAV,
  SESSION_FIELD_SIDE_NAV_OPEN,
} from '../../../../constants/misc';

import {
  getAuthUser,
  getIsBCG,
  getIsBuyer,
  getIsEnterpriseCustomer,
  getIsMobile,
  getIsPhotographer,
  getIsSeller,
  getSessionField,
} from '../../../../reducers/';

const mapStateToProps = (state) => ({
  hasSideNav: !!getSessionField(state, SESSION_FIELD_HAS_SIDE_NAV),
  isOpen: !!getSessionField(state, SESSION_FIELD_SIDE_NAV_OPEN),
  authUser: getAuthUser(state),
  isMobile: getIsMobile(state),
  isEnterpriseCustomer: getIsEnterpriseCustomer(state),
  isPhotographer: getIsPhotographer(state),
  isBuyer: getIsBuyer(state),
  isBCG: getIsBCG(state),
  isSeller: getIsSeller(state),
});

export default connect(mapStateToProps, {
  toggle: toggleSideNavOpenThunk,
})(SideNav);
