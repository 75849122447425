/* @flow */
import { get, post, patch } from './clientRequest';

export const checkCart = (query: RefreshCheckoutInfoPayload) =>
  get({
    endpoint: '/buyer/checkCart',
    requestParams: query,
  });

export const addOrUpdateCartItem = (item: {
  id: EyeEmAssetId,
  distributionLicenseId: number,
  photo: EyeEmPhoto,
}) =>
  patch({
    endpoint: '/buyer/addOrUpdateCartItem',
    requestParams: { item },
  });

export const saveForLater = (item: EyeEmPhoto) =>
  patch({
    endpoint: '/buyer/saveForLater',
    requestParams: { item },
  });

export const removeFromCart = (payload: RemoveFromCartPayload) =>
  post({
    endpoint: '/buyer/removeCartItems',
    requestParams: {
      photos: payload.photos,
      deals: payload.deals,
      savedForLater: payload.savedForLater,
    },
  });

export const clearCart = () =>
  post({
    endpoint: '/buyer/clearCart',
  });

export const submitSelfServePaymentData = (payload: SelfServePaymentPayload) =>
  post({
    endpoint: '/buyer/payments',
    requestParams: payload,
  });
