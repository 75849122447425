// @flow
import { gql } from '@apollo/client';

export const GET_TASKS = gql`
  query Tasks {
    tasks {
      id
      type
      description {
        headline
        subline
      }
      icon
      action
      badgeCount
      updatedAt
    }
  }
`;
