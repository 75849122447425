/* @flow */
import { css } from 'styled-components';
import { media } from '@eyeem-ui/theme';
import { styleUtils } from 'eyeem-components';

import {
  DROPDOWN_TRIGGER_STYLE_TRANSPARENT_ON_BRIGHT,
  DROPDOWN_TRIGGER_STYLE_GREY,
} from '../constants/misc';

export const TriangleMixin = () => css`
  padding-right: 22px;
  &:after {
    content: '';
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 10px;
    height: 2px;
    border-left: ${(props) => (props.isOpen ? null : '5px solid transparent')};
    border-right: ${(props) =>
      props.isOpen
        ? `0px solid ${styleUtils.fromTheme('colors.whites.white')}`
        : '5px solid transparent'};
    border-top: ${(props) => (props.isOpen ? '2px' : '5px')} solid
      ${(props) =>
        props.triggerStyle === DROPDOWN_TRIGGER_STYLE_GREY ||
        props.triggerStyle === DROPDOWN_TRIGGER_STYLE_TRANSPARENT_ON_BRIGHT
          ? styleUtils.fromTheme('colors.blacks.black1')
          : styleUtils.fromTheme('colors.whites.white')};
    position: absolute;
    transition: all 0.2s ease;
  }
`;

export const ConditionalRevealMixin = css`
  display: ${(props) => (props.hideOn === 'small' ? 'none' : null)};

  @media ${media.largeUp} {
    ${(props) =>
      props.hideOn === 'largeUp' && props.hideOn !== 'small'
        ? 'display:none;'
        : ''}
  }
`;
