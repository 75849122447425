/* @flow */
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';

import SelectLightboxDropdown from './selectLightboxDropdown';

import { getSelectedLightboxId } from '../../../reducers/';
import {
  GET_OWN_LIGHTBOXES,
  GET_LIGHTBOX,
} from '../../../graphql/queries/lightbox';

import {
  openModal,
  setSessionFieldThunk,
} from '../../../helpers/actionCreators/redux';

import { SESSION_FIELD_SELECTED_LIGHTBOX_ID } from '../../../constants/misc';

const mapStateToProps = (state) => {
  return {
    selectedLightboxId: getSelectedLightboxId(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  openModal: (payload) => dispatch(openModal(payload)),
  selectLightbox: (value) =>
    dispatch(
      setSessionFieldThunk({
        name: SESSION_FIELD_SELECTED_LIGHTBOX_ID,
        value,
      })
    ),
  clearSelectedLightbox: () =>
    dispatch(
      setSessionFieldThunk({
        name: SESSION_FIELD_SELECTED_LIGHTBOX_ID,
        value: undefined,
      })
    ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql(GET_OWN_LIGHTBOXES, {
    options: () => ({
      ssr: false,
      fetchPolicy: 'network-only',
    }),
  }),
  graphql(GET_LIGHTBOX, {
    name: 'selectedLightbox',
    skip: ({ selectedLightboxId }) => !selectedLightboxId,
    options: ({ selectedLightboxId }) => ({
      ssr: false,
      variables: {
        lightboxId: selectedLightboxId,
      },
    }),
  })
)(SelectLightboxDropdown);
