/* @flow */
/* eslint-env browser */
import t from 'counterpart';
import { Box, Button, Flex } from '@eyeem-ui/atoms';
import { Field, Form, FormRenderProps } from 'react-final-form';

import { TEST_LIGHTBOX_FORM_NAME } from '../../../constants/pageObjectSelectors';
import { TEST_LIGHTBOX_FORM_CLIENT } from '../../../constants/pageObjectSelectors';
import { TEST_LIGHTBOX_FORM_DESCRIPTION } from '../../../constants/pageObjectSelectors';
import { TEST_SAVE_BUTTON } from '../../../constants/pageObjectSelectors';
import { track } from '../../../helpers/tools';

import { Textarea, Input } from '../../formElements';

import validate, {
  VALIDATOR_UNICODE_STRING,
} from '../../../helpers/validators';

const unicodeString = validate(VALIDATOR_UNICODE_STRING);

type Props = {
  lightbox: EyeEmLightbox,
  className: string,
  close: CloseModal,
  modalMode: string,
} & FormRenderProps;

function LightboxForm(props: Props) {
  const handleCancel = () => {
    track({
      eventType: t('tracking.eventType.inbound'),
      eventAction: t('tracking.eventAction.button'),
      eventName: t(`lightboxes.modals.${props.modalMode}.cancel.eventName`),
      eventLabel: t(`lightboxes.modals.${props.modalMode}.cancel.label`),
      eventPosition: t(`lightboxes.modals.${props.modalMode}.eventPosition`),
      eventOption:
        props.modalMode === 'duplicate' &&
        `copyLightboxId=${props.lightbox.id}`,
    });
    props.close();
  };

  return (
    <Form
      enableReinitialize
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
      destroyOnUnmount={true}>
      {({ handleSubmit }) => (
        <form className={props.className} onSubmit={handleSubmit}>
          <Box p={'24px'}>
            <Field
              component={Input}
              label={t('lightboxes.modals.form.label.name')}
              placeholder={t('lightboxes.modals.form.placeholder.name')}
              name="name"
              type="text"
              validate={unicodeString}
              customProps={{ 'data-test-id': TEST_LIGHTBOX_FORM_NAME }}
            />
            <Field
              component={Input}
              label={t('lightboxes.modals.form.label.clientDescription')}
              placeholder={t(
                'lightboxes.modals.form.placeholder.clientDescription'
              )}
              name="clientDescription"
              type="text"
              validate={unicodeString}
              customProps={{ 'data-test-id': TEST_LIGHTBOX_FORM_CLIENT }}
            />
            <Field
              component={Textarea}
              customProps={{
                'data-test-id': TEST_LIGHTBOX_FORM_DESCRIPTION,
                maxLength: '1024',
              }}
              label={t('lightboxes.modals.form.label.description')}
              name="description"
              placeholder={t('lightboxes.modals.form.placeholder.description')}
              rows={6}
              type="text"
              validate={unicodeString}
            />

            <Flex justifyContent="flex-end" mt={3}>
              <Box mr={2}>
                <Button onClick={handleCancel} variant="transparent">
                  {t(`lightboxes.modals.${props.modalMode}.cancel.label`)}
                </Button>
              </Box>
              <Button
                customProps={{ 'data-test-id': TEST_SAVE_BUTTON }}
                type="submit">
                {t(`lightboxes.modals.${props.modalMode}.confirm.label`)}
              </Button>
            </Flex>
          </Box>
        </form>
      )}
    </Form>
  );
}

export default LightboxForm;
