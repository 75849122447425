/* @flow */

import type { Node } from 'react';
import styled from '@emotion/styled';
import { Collapsible } from '@eyeem-ui/organisms';
import { Box, Text } from '@eyeem-ui/atoms';

import FooterLink from '../footerLink';

import { footerItemPadding } from '../styles';

const StyledTitle = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

function FooterLinkList(props: {
  contentObject: {
    headline: string,
    items: $ReadOnlyArray<{
      custom?: Node,
      title: string,
    }>,
  },
}) {
  return (
    <Collapsible
      name={props.contentObject.headline.split(' ').join('')}
      title={
        <Box p={[footerItemPadding]} pl={[0, 0, 0, footerItemPadding]}>
          <StyledTitle bold key={props.contentObject.headline}>
            {props.contentObject.headline}
          </StyledTitle>
        </Box>
      }
      content={
        <div>
          {props.contentObject.items.map((item) => {
            if (item.custom) {
              return item.custom;
            }
            return <FooterLink key={item.title} item={item} />;
          })}
        </div>
      }
      collapse={[1, 1, 1, 0]}
      maxHeight={400}
    />
  );
}

export default FooterLinkList;
