/* flow */

export const WRONG_CAPTCHA = {
  code: 10000,
  translationString: 'notifications.wrongCaptcha',
};
export const UNEXPECTED_USER = {
  code: 10001,
  translationString: 'notifications.unexpectedUser',
};
