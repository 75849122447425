/* @flow */
// pattern: TEST_PAGEOBJECTNAME_ELEMENTNAME
export const TEST_APP_BANNER = 'TEST_APP_BANNER';
export const TEST_LOGIN_BUTTON = 'TEST_LOGIN_BUTTON';
export const TEST_SELLERSIGNUPPAGE_FORM = 'TEST_SELLERSIGNUPPAGE_FORM';
export const TEST_SELLERSIGNUPPAGE_BUTTON_DISABLED =
  'TEST_SELLERSIGNUPPAGE_BUTTON_DISABLED';
export const TEST_SELLERSIGNUPPAGE_BUTTON = 'TEST_SELLERSIGNUPPAGE_BUTTON';
export const TEST_UPLOADPAGE_POST_BUTTON = 'TEST_UPLOADPAGE_POST_BUTTON';
export const TEST_UPLOADPAGE_PHOTOSPOSTED_UPLOADMORE =
  'TEST_UPLOADPAGE_PHOTOSPOSTED_UPLOADMORE';
export const TEST_UPLOADPAGE_LOWRESICON = 'TEST_UPLOADPAGE_LOWRESICON';
export const TEST_UPLOADPAGE_IMAGECONTAINER = 'TEST_UPLOADPAGE_IMAGECONTAINER';
export const TEST_UPLOADPAGE_DESCRIPTION_INPUT =
  'TEST_UPLOADPAGE_DESCRIPTION_INPUT';
export const TEST_UPLOADPAGE_POST_BUTTON_DISABLED =
  'TEST_UPLOADPAGE_POST_BUTTON_DISABLED';
export const TEST_UPLOADPAGE_ADDALLTAGS_BUTTON =
  'TEST_UPLOADPAGE_ADDALLTAGS_BUTTON';
export const TEST_UPLOADPAGE_ALLTAGSADDED_TEXT =
  'TEST_UPLOADPAGE_ALLTAGSADDED_TEXT';
export const TEST_UPLOADPAGE_TAGSNOTADDED_TEXT =
  'TEST_UPLOADPAGE_TAGSNOTADDED_TEXT';
export const TEST_UPLOADPAGE_AUTOCOMPLETE_TEXT =
  'TEST_UPLOADPAGE_AUTOCOMPLETE_TEXT';
export const TEST_UPLOADPAGE_AUTOCOMPLETE_ITEM =
  'TEST_UPLOADPAGE_AUTOCOMPLETE_ITEM';
export const TEST_EDITPHOTO_ADDALLTAGS_BUTTON =
  'TEST_EDITPHOTO_ADDALLTAGS_BUTTON';
export const TEST_EDITPHOTO_ADDED_TAG = 'TEST_EDITPHOTO_ADDED_TAG';
export const TEST_EDITPHOTO_ALLTAGSADDED_TEXT =
  'TEST_EDITPHOTO_ALLTAGSADDED_TEXT';
export const TEST_EDITPHOTO_TAGSNOTADDED_TEXT =
  'TEST_EDITPHOTO_TAGSNOTADDED_TEXT';
export const TEST_EDITPHOTO_DESCRIPTION_INPUT =
  'TEST_EDITPHOTO_DESCRIPTION_INPUT';
export const TEST_PAYOUTPAGE_HEADERTITLE = 'TEST_PAYOUTPAGE_HEADERTITLE';
export const TEST_PAYOUTPAGE_CONNECT_BUTTON = 'TEST_PAYOUTPAGE_CONNECT_BUTTON';
export const TEST_PAYOUTPAGE_PAYPALNEXT_BUTTON =
  'TEST_PAYOUTPAGE_PAYPALNEXT_BUTTON';
export const TEST_PAYOUTPAGE_PAYPALNEXT_BUTTON_DISABLED =
  'TEST_PAYOUTPAGE_PAYPALNEXT_BUTTON_DISABLED';
export const TEST_PAYOUTPAGE_EMAIL_SENT = 'TEST_PAYOUTPAGE_EMAIL_SENT';
export const TEST_PAYOUTPAGE_FORM = 'TEST_PAYOUTPAGE_FORM';
export const TEST_PAYOUTPAGE_CONNECTEDACCOUNT_TEXT =
  'TEST_PAYOUTPAGE_CONNECTEDACCOUNT_TEXT';
export const TEST_PAYOUTPAGE_CHANGEACCOUNT_BUTTON =
  'TEST_PAYOUTPAGE_CHANGEACCOUNT_BUTTON';
export const TEST_PHOTOVIEW_SUSHIROLL = 'TEST_PHOTOVIEW_SUSHIROLL';
export const TEST_PHOTOVIEW_ADDTOLIGHTBOX_BUTTON =
  'TEST_PHOTOVIEW_ADDTOLIGHTBOX_BUTTON';
export const TEST_PHOTOVIEW_REMOVEFROMLIGHTBOX_BUTTON =
  'TEST_PHOTOVIEW_REMOVEFROMLIGHTBOX_BUTTON';
export const TEST_TOPNAVBAR_PROFILE_ICON = 'TEST_TOPNAVBAR_PROFILE_ICON';
export const TEST_SEARCH_INPUT = 'TEST_SEARCH_INPUT';
export const TEST_SEARCH_FILTERS_BUTTON = 'TEST_SEARCH_FILTERS_BUTTON';
export const TEST_SEARCH_FILTERS_OPEN_BUTTON =
  'TEST_SEARCH_FILTERS_OPEN_BUTTON';
export const TEST_PHOTOINGRID_CONTAINER = 'TEST_PHOTOINGRID_CONTAINER';
export const TEST_PHOTOINCART_CONTAINER = 'TEST_PHOTOINCART_CONTAINER';
export const TEST_ADDTOCART_BUTTON = 'TEST_ADDTOCART_BUTTON';
export const TEST_ADDTOCART_BUTTON_DISABLED = 'TEST_ADDTOCART_BUTTON_DISABLED';
export const TEST_CART_COUNT = 'TEST_CART_COUNT';

export const TEST_USERPHOTO_CONTAINER = 'TEST_USERPHOTO_CONTAINER';
export const TEST_MODAL_CLOSE = 'TEST_MODAL_CLOSE';
export const TEST_PHOTOVIEW_RELEASE_CONTAINER =
  'TEST_PHOTOVIEW_RELEASE_CONTAINER';
export const TEST_MANAGE_RELEASES_BUTTON = 'TEST_MANAGE_RELEASES_BUTTON';
export const TEST_CHECKOUT_TERMS_CHECKBOX = 'TEST_CHECKOUT_TERMS_CHECKBOX';
export const TEST_BIRTHDAY_CHECKBOX = 'TEST_BIRTHDAY_CHECKBOX';
export const TEST_CHECKOUT_DOWNLOAD_CONTAINER =
  'TEST_CHECKOUT_DOWNLOAD_CONTAINER';
export const TEST_BUY_BUTTON = 'TEST_BUY_BUTTON';
export const TEST_SIGNUP_BUTTON = 'TEST_SIGNUP_BUTTON';
export const TEST_CREATE_LIGHTBOX_BUTTON = 'TEST_CREATE_LIGHTBOX_BUTTON';
export const TEST_LIGHTBOX_FORM_NAME = 'TEST_LIGHTBOX_FORM_NAME';
export const TEST_LIGHTBOX_FORM_CLIENT = 'TEST_LIGHTBOX_FORM_CLIENT';
export const TEST_LIGHTBOX_FORM_DESCRIPTION = 'TEST_LIGHTBOX_FORM_DESCRIPTION';
export const TEST_SAVE_BUTTON = 'TEST_SAVE_BUTTON';
export const TEST_LIGHTBOX_DROPDOWN_BUTTON = 'TEST_LIGHTBOX_DROPDOWN_BUTTON';
export const TEST_LIGHTBOX_LINK = 'TEST_LIGHTBOX_LINK';
export const TEST_LIGHTBOX_EDIT_BUTTON = 'TEST_LIGHTBOX_EDIT_BUTTON';
export const TEST_LIGHTBOX_EDIT_DELETE = 'TEST_LIGHTBOX_EDIT_DELETE';
export const TEST_LIGHTBOX_DELETE_CONFIRM_LABEL =
  'TEST_LIGHTBOX_DELETE_CONFIRM_LABEL';
export const TEST_SETTINGS_DELETE_ACCOUNT_BUTTON =
  'TEST_SETTINGS_DELETE_ACCOUNT_BUTTON';
export const TEST_COMMENT_REPLY_LINK = 'TEST_COMMENT_REPLY_LINK';
export const TEST_NEXT_PAGE_BUTTON = 'TEST_NEXT_PAGE_BUTTON';
export const TEST_COMMENT_BUTTON = 'TEST_COMMENT_BUTTON';
export const TEST_LIKE_BUTTON = 'TEST_LIKE_BUTTON';
export const TEST_LIKE_BUTTON_DISABLED = 'TEST_LIKE_BUTTON_DISABLED';
export const TEST_MULTI_RELEASES_LINK = 'TEST_MULTI_RELEASES_LINK';
export const TEST_MULTI_RELEASES_FIRSTNAME_INPUT =
  'TEST_MULTI_RELEASES_FIRSTNAME_INPUT';
export const TEST_MULTI_RELEASES_LASTNAME_INPUT =
  'TEST_MULTI_RELEASES_LASTNAME_INPUT';
export const TEST_SELECTED_LICENSE_LABEL = 'TEST_SELECTED_LICENSE_LABEL';
export const TEST_UNSELECTED_LICENSE_LABEL = 'TEST_UNSELECTED_LICENSE_LABEL';
export const TEST_FOLLOW_BUTTON_FOLLOWED = 'TEST_FOLLOW_BUTTON_FOLLOWED';
export const TEST_FOLLOW_BUTTON = 'TEST_FOLLOW_BUTTON';
export const TEST_PREMIUM_LABEL = 'TEST_PREMIUM_LABEL';
export const TEST_EYEEMLOGO = 'TEST_EYEEMLOGO';
export const TEST_DOWNLOAD_BUTTON = 'TEST_DOWNLOAD_BUTTON';
export const TEST_UPGRADE_BUTTON = 'TEST_UPGRADE_BUTTON';
export const TEST_FLYOUT = 'TEST_FLYOUT';
export const TEST_ENTERPRISE_VIEWER_NO_PERMISSION =
  'TEST_ENTERPRISE_VIEWER_NO_PERMISSION';
export const TEST_NOTIFICATION_BAR = 'TEST_NOTIFICATION_BAR';
export const TEST_CREATOR_HEADER_HOMEPAGE = 'TEST_CREATOR_HEADER_HOMEPAGE';
export const TEST_OPEN_DISCOUNT_CODE_UI = 'TEST_OPEN_DISCOUNT_CODE_UI';
export const TEST_CHECKOUT_SUMMARY_SUBTOTAL = 'TEST_CHECKOUT_SUMMARY_SUBTOTAL';
export const TEST_PRICE_COMPONENT = 'TEST_PRICE_COMPONENT';
export const TEST_UMBO_MODAL_BUTTON = 'TEST_UMBO_MODAL_BUTTON';
export const TEST_BLACKLIST_IMAGE = 'TEST_BLACKLIST_IMAGE';
export const TEST_SHARE_LIGHTBOX_BUTTON = 'TEST_SHARE_LIGHTBOX_BUTTON';
export const TEST_SHARE_LIGHTBOX_MODAL = 'TEST_SHARE_LIGHTBOX_MODAL';
export const TEST_SHARE_LIGHTBOX_MODAL_SUGGESTION_ITEM =
  'TEST_SHARE_LIGHTBOX_MODAL_SUGGESTION_ITEM';
export const TEST_SHARE_LIGHTBOX_MODAL_SHARE_BUTTON =
  'TEST_SHARE_LIGHTBOX_MODAL_SHARE_BUTTON';
export const TEST_DISCOUNT_CODE_SUBMIT_BUTTON =
  'TEST_DISCOUNT_CODE_SUBMIT_BUTTON';
export const TEST_DISCOUNT_CODE_REMOVE_BUTTON =
  'TEST_DISCOUNT_CODE_REMOVE_BUTTON';
export const CUSTOM_LICENSE_MODAL_TRIGGER = 'CUSTOM_LICENSE_MODAL_TRIGGER';
export const CUSTOM_LICENSE_MODAL = 'CUSTOM_LICENSE_MODAL';
export const TEST_ACCOUNT_SAVE_CHANGES = 'TEST_ACCOUNT_SAVE_CHANGES';
export const TEST_NAVBAR_CART = 'TEST_NAVBAR_CART';
export const TEST_CHECKOUT_NOW_BUTTON = 'TEST_CHECKOUT_NOW_BUTTON';
export const DELETE_PHOTO_MODAL_BUTTON = 'DELETE_PHOTO_MODAL_BUTTON';
export const TEST_ILLUSTRATION_UPLOAD_CATEGORIES_WRAPPER =
  'TEST_ILLUSTRATION_UPLOAD_CATEGORIES_WRAPPER';
export const TEST_ILLUSTRATION_UPLOAD_STYLES_WRAPPER =
  'TEST_ILLUSTRATION_UPLOAD_STYLES_WRAPPER';
