/* @flow */

const COOKIE_PRO_KEY = 'OptanonConsent';
const TRACKING_COOKIE_GROUP_ID = 'C0004';

export const getCookieValue = (val: string) => {
  const name = document.cookie.match(`(^|[^;]+)\\s*${val}\\s*=\\s*([^;]+)`);
  return name ? name.pop() : '';
};

export const setCookieFor = (
  name: string,
  value: string,
  age: number,
  domain?: string
) => {
  document.cookie = `${name}=${value}; ${
    domain ? `Domain=${domain}; ` : ''
  }Path=/; Max-Age=${age}`;
};

export const trackingDataAllowed = () => {
  const cookieProValue = getCookieValue(COOKIE_PRO_KEY);
  if (cookieProValue) {
    // User is in EU, where we load the CookiePro script
    return cookieProValue.indexOf(TRACKING_COOKIE_GROUP_ID) >= 0;
  }
  // User has no CookiePro cookie set, therefore not in EU
  return true;
};
