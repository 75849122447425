/* @flow */
import {
  SET_CONTEXT_PAGINATABLE_METADATA,
  UNSET_CONTEXT_PAGINATABLE_METADATA,
} from '../constants/actionTypes';

export default function contextPaginatableMetadata(
  state: ContextPaginatableMetadataState = {},
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case SET_CONTEXT_PAGINATABLE_METADATA:
      return action.payload;
    case UNSET_CONTEXT_PAGINATABLE_METADATA:
      return null;
    default:
      return state;
  }
}
