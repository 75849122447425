/* @flow */
/* eslint-env browser */
/*
 * Modal router provides the router context that is needed
 * to use withRouter HOC in modal components
 */
import { Location, Locations } from 'react-router-component';

import Modal from './modal.jsx';
import LegacyModal from './legacyModal.jsx';

export type ModalRouterProps = {
  hasModal: boolean,
  path: string,
  modal: ModalState,
  closeModal: CloseModal,
};

function ModalRouter(props: ModalRouterProps) {
  const getModal = () => {
    if (props.hasModal) {
      if (props.modal.useEUModal) {
        return <Modal {...props} />;
      }

      return <LegacyModal {...props} />;
    }

    return null;
  };

  return (
    <Locations component={null} path={props.path}>
      <Location
        path={/./}
        modal={props.modal}
        closeModal={props.closeModal}
        handler={() => getModal()}
      />
    </Locations>
  );
}

export default ModalRouter;
