/* @flow */
import { Component } from 'react';
import t from 'counterpart';
import { Box, Flex, Text } from '@eyeem-ui/atoms';

import Link from '../../general/link';

import ChangeMarketEmailModal from '../changeMarketEmail/';

type Props = {
  openModal: OpenModal,
  marketUser?: EyeEmMarketUser,
};

class ResendVerificationEmailModal extends Component<Props> {
  changeEmailModal = () =>
    this.props.openModal({
      content: ChangeMarketEmailModal,
      useEUModal: true,
      close: true,
      contentProps: {
        title: t('modal.changeMarketEmail.title'),
      },
    });

  render() {
    return (
      <Box p={'24px'} pt={2}>
        <Box mb={3}>
          <Text color="grey50">
            {t('modal.resendVerificationEmail.subline')}
          </Text>
        </Box>
        {this.props.marketUser && (
          <Box mb={3}>
            <Text bold>{this.props.marketUser.email}</Text>
          </Box>
        )}
        <Flex>
          <Box mr="1">
            <Text color="grey50">
              {t('modal.resendVerificationEmail.notYourEmail')}
            </Text>
          </Box>
          <Link onClick={this.changeEmailModal}>
            <Text color="aqua50">
              {t('modal.resendVerificationEmail.changeIt')}
            </Text>
          </Link>
        </Flex>
      </Box>
    );
  }
}

export default ResendVerificationEmailModal;
