/* @flow */
import type { Node } from 'react';

import { Component } from 'react';
import t from 'counterpart';

import LightboxModal from '../../modals/lightbox/';

type Props = {
  children: Node,
  openModal: OpenModal,
};

class CreateLightbox extends Component<Props> {
  onClick = () =>
    this.props.openModal({
      content: LightboxModal,
      contentProps: {
        title: t('lightboxes.modals.create.title'),
      },
      useEUModal: true,
    });

  render() {
    return <div onClick={this.onClick}>{this.props.children}</div>;
  }
}

export default CreateLightbox;
