/* @flow */
import { compareAsStrings } from '../compareAsStrings';
import { getAssetSourceUserId } from '../getAssetSourceUserId';

export const ownAsset = (
  asset: EyeEmAsset,
  authUserId: string | number
): boolean => {
  const userId = getAssetSourceUserId(asset);
  return compareAsStrings(userId, authUserId);
};
