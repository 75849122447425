/* @flow */
import { RECEIVE_NORMALIZED_DATA } from '../constants/actionTypes';

export default function checkout(
  state: SalesState = {},
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case RECEIVE_NORMALIZED_DATA:
      return action.payload.sales || state;
    default:
      return state;
  }
}

export const mapInitialDataToInitialState = (initialData: NormalizedData) =>
  initialData.sales;

export const getSales = (state: SalesState) => state;
