/* @flow */
import t from 'counterpart';
import styled from '@emotion/styled';
import { ThemeProvider } from '@eyeem-ui/theme';
import { Helmet } from 'react-helmet-async';

import T from './general/translate';
import Link from './general/link/';
import { Text, Box, Button } from '@eyeem-ui/atoms';

// should be in theme, but isn't?
const navHeight = 64;

const StyledWrapper = styled.div`
  margin-top: -${(props) => (props.resourceType === 'release' ? navHeight * 2 : navHeight)}px;
`;

const StyledHeightWrapper = styled.div`
  height: 100%;
`;

function NotFound(props: { resourceType?: string }) {
  return (
    <StyledHeightWrapper>
      <Helmet>
        <title>{t('meta.title.notFound')}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <ThemeProvider>
        <StyledWrapper resourceType={props.resourceType} className="notFound">
          <div className="g_centered_wrapper">
            <div className="g_centered">
              <Text variant="title3" as="h2">
                {t(`error.notFound.${props.resourceType || 'other'}`)}
              </Text>
              <Box mt={4}>
                <Link href="/">
                  <Button variant="primary">
                    {t('error.notFound.backtoeyeem')}
                  </Button>
                </Link>
              </Box>
            </div>
            <div className="notFound_footer">
              <T
                with={{
                  supportLink: (
                    <T
                      component="a"
                      className="g_link g_link-aqua"
                      href={t('error.notFound.supportHref')}>
                      error.notFound.supportLink
                    </T>
                  ),
                }}
                component="p"
                className="notFound_supportLink">
                error.notFound.contactSupport
              </T>
              <T
                component="p"
                photographer={
                  <Link className="g_link" href="/u/paubuscato">
                    @paubuscato
                  </Link>
                }
                className="notFound_credits">
                error.notFound.credits
              </T>
            </div>
          </div>
        </StyledWrapper>
      </ThemeProvider>
    </StyledHeightWrapper>
  );
}

export default NotFound;
