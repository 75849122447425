// @flow
import type { Node } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Box } from '@eyeem-ui/atoms';

import {
  SESSION_FIELD_HAS_SIDE_NAV,
  SESSION_FIELD_SIDE_NAV_OPEN,
} from '../../../constants/misc';

import { getSessionField } from '../../../reducers';

const StyledMain = styled(Box)`
  flex: 1 1 auto;
  width: calc(
    100% -
      ${(props) =>
        props.isSideNavOpen
          ? props.theme.openSideNavWidth
          : props.theme.sideNavWidth}
  );
`;

type Props = {
  children: Node[],
  hasSideNav: boolean,
  isSideNavOpen: boolean,
};

function BaseWrapper(props: Props) {
  return (
    <StyledMain isSideNavOpen={props.isSideNavOpen}>
      {props.children}
    </StyledMain>
  );
}

export default connect((state) => ({
  hasSideNav: !!getSessionField(state, SESSION_FIELD_HAS_SIDE_NAV),
  isSideNavOpen: !!getSessionField(state, SESSION_FIELD_SIDE_NAV_OPEN),
}))(BaseWrapper);
