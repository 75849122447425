/* @flow */
import { RECEIVE_NORMALIZED_DATA } from '../constants/actionTypes';

export default function licensing(
  state: LicensingState = [],
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case RECEIVE_NORMALIZED_DATA:
      return action.payload.licensing || state;
    default:
      return state;
  }
}

export function mapInitialDataToInitialState(initialData: NormalizedData) {
  return initialData.licensing || [];
}

export const getUserLicensing = (state: LicensingState) => state;
