/* @flow */
import Ago from 'react-ago-component';

import RenderOnMount from './renderOnMount.jsx';

type Props = {
  autoUpdate?: boolean,
  className?: string,
  date: string,
};

function AgoWrap(props: Props) {
  return (
    <RenderOnMount>
      <Ago
        className={props.className}
        autoUpdate={props.autoUpdate}
        date={props.date || Date.now()}
      />
    </RenderOnMount>
  );
}

export default AgoWrap;
