/* @flow */
import styled from 'styled-components';

import T from '../../../general/translate.jsx';
import Link from '../../../general/link/';

const StyledReplyLink = styled(T)`
  line-height: 24px;
`;

function ReplyLink(props: { href: string }) {
  return (
    <StyledReplyLink
      component={Link}
      useStyledText
      color="white"
      display="block"
      size="default"
      href={props.href}>
      news.photo.reply
    </StyledReplyLink>
  );
}

export default ReplyLink;
