/* eslint-disable no-case-declarations */
const introspectionQueryResultData = require('./scripts/fragmentTypes.json');

const memoryCacheConfig = {
  possibleTypes: introspectionQueryResultData,
  typePolicies: {
    Query: {
      fields: {
        // Cache redirects
        // https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
        photo: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'Photo',
              id: args.photoId,
            });
          },
        },
        illustration: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'Illustration',
              id: args.illustrationId,
            });
          },
        },
        lightbox: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'LightBox',
              id: args.lightboxId,
            });
          },
        },
      },
    },
    // this is for the photo.market.licensing.purchased Field.
    // We can not rely on the id here since the purchased object is different depending on the collection
    PurchaseInfo: {
      keyFields: ['id', 'price'],
    },
    // item.purchasedLicense field from GET /enterprise/assets (previous purchases).
    // Do not normalize since the included id references the license
    PurchasedLicenseInfo: {
      keyFields: false,
    },
    // nested type in LicensingOptions. Do not normalize (!) since multiple types with same id can be in the response
    AvailableLicense: {
      keyFields: false,
    },
    // Fields is returned from getBlog request to contentfull and does not have an id
    Fields: {
      keyFields: ['title'],
    },
  },
};

export { memoryCacheConfig };
