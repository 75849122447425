/* @flow */
import type { FieldProps } from 'redux-form';
import { Select } from '@eyeem-ui/atoms';

type Props = {
  placeholder?: string,
  disabled?: boolean,
  formRow?: boolean,
  options: Array<{
    value: string,
    text: string,
  }>,
  'data-test-id'?: string,
} & FieldProps;

function SelectWrapper(props: Props) {
  return (
    <Select
      {...props.input}
      label={props.label}
      disabled={props.disabled}
      customProps={{
        'data-test-id': props['data-test-id'],
      }}
      options={props.options}
      errorMessage={props.meta.touched && props.meta.error}
    />
  );
}

export default SelectWrapper;
