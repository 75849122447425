/* @flow */
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';

import {
  RECEIVE_NORMALIZED_DATA,
  REQUEST_PAGINATABLE,
  TOGGLE_LIKE,
  DELETE_PHOTO,
  CREATE_LIGHTBOX,
  DELETE_LIGHTBOX,
  UPDATE_LIGHTBOX,
  SET_PAGE,
} from '../constants/actionTypes';

import {
  ownLightboxesPID,
  getLikersPID,
  getLikedPhotosPID,
} from '../helpers/paginatableIdentifiers';

import {
  deleteFromArray,
  addToArray,
  getPageFromOffsetAndLimit,
  compareAsStrings,
} from '../helpers/tools';

export const mergePaginatable = (
  existingPaginatable: Paginatable = { items: [], limit: 0, offset: 0 },
  newPaginatable: Paginatable = { items: [], limit: 0, offset: 0 }
) => {
  if (!existingPaginatable || !existingPaginatable.items) {
    let correctedTotal = newPaginatable.total;
    // We adjust the total number if we receive less photos than requested,
    // see below for more details
    if (newPaginatable.items.length < newPaginatable.limit) {
      // if there's a new page to be expected, just deduct the photos
      const expectedAmountOfPhotos = Math.min(
        newPaginatable.limit,
        newPaginatable.total - newPaginatable.offset
      );

      if (newPaginatable.items.length < expectedAmountOfPhotos) {
        correctedTotal =
          newPaginatable.total -
          (newPaginatable.limit - newPaginatable.items.length);
      }
    }

    return {
      ...newPaginatable,
      correctedTotal,
    };
  }

  let correctedTotal = existingPaginatable.total;
  // Since blacklisted photos get removed from results after the page chunk
  // is generated on the backend and after the total number is calculated,
  // we cannot trust the total count anymore and have to adjust it every
  // time we receive less photos than requested. `paginatable.total`
  // will never be an exact number: It's a consistency issue with our
  // distributed systems on backend.
  if (newPaginatable.items.length < newPaginatable.limit) {
    // If we get in here, we got less photos than the requested amount ("limit").
    // This can mean that it's the last page, but it could also mean that
    // the total is incorrect.

    if (newPaginatable.total > newPaginatable.offset + newPaginatable.limit) {
      // In here, we know there's a new page to be expected,
      // so we just deduct the missing photos
      correctedTotal -= newPaginatable.limit - newPaginatable.items.length;
    }

    if (
      newPaginatable.total - newPaginatable.offset < newPaginatable.limit &&
      newPaginatable.items.length < newPaginatable.total - newPaginatable.offset
    ) {
      // If there are photos missing at the end of the paginatable,
      // we just update the correctedTotal to the total amount of items
      correctedTotal =
        existingPaginatable.items.length + newPaginatable.items.length;
    }
  }

  const morePaginatable =
    newPaginatable.offset >= existingPaginatable.items.length
      ? new Array(newPaginatable.offset - existingPaginatable.items.length)
      : [];

  const start = [
    ...existingPaginatable.items.slice(0, newPaginatable.offset),
    ...morePaginatable,
  ];
  const end = [
    ...existingPaginatable.items.slice(
      newPaginatable.offset + newPaginatable.items.length
    ),
  ];

  const items = [...start, ...newPaginatable.items, ...end];
  const offset = Math.max(existingPaginatable.offset, newPaginatable.offset);

  const hasIdenticalSearchTerm =
    existingPaginatable?.resourceId?.toString().toLowerCase() ===
      newPaginatable?.resourceId?.toString().toLowerCase() &&
    existingPaginatable.resourceType === 'search' &&
    newPaginatable.resourceType === 'search';

  const paginatable = hasIdenticalSearchTerm
    ? newPaginatable
    : existingPaginatable;

  return {
    ...paginatable,
    items,
    total: newPaginatable.total,
    correctedTotal,
    limit: newPaginatable.limit,
    offset,
  };
};

const emptyPaginatable = (paginatableIdentifier: PIDObject) => ({
  ...paginatableIdentifier,
  offset: 0,
  total: 0,
  limit: 30,
  items: [],
});

const merge = (
  arr1: $ReadOnlyArray<Paginatable>,
  arr2: $ReadOnlyArray<Paginatable>
) => {
  let toBeMerged = arr2;
  const newArray = arr1.map((element) => {
    const matchingElementIndex = findIndex(
      toBeMerged,
      (item) =>
        item.paginatableName === element.paginatableName &&
        compareAsStrings(item.resourceId, element.resourceId) &&
        item.resourceType === element.resourceType
    );
    const newElement =
      matchingElementIndex >= 0
        ? mergePaginatable(element, toBeMerged[matchingElementIndex])
        : element;

    if (matchingElementIndex >= 0) {
      // if it's already merged, we don't have to concat it anymore later
      toBeMerged = [
        ...toBeMerged.slice(0, matchingElementIndex),
        ...toBeMerged.slice(matchingElementIndex + 1),
      ];
    }

    return newElement;
  });
  return newArray.concat(toBeMerged);
};

const paginatableMatches = (
  paginatable: Paginatable,
  paginatableIdentifier: PIDObject
) =>
  paginatable.resourceType === paginatableIdentifier.resourceType &&
  paginatable.resourceId === paginatableIdentifier.resourceId &&
  paginatable.paginatableName === paginatableIdentifier.paginatableName;

const addToPaginatable = (state, element) => ({
  ...state,
  items: addToArray(state.items, element),
  total: state.total + 1,
  correctedTotal: state.correctedTotal + 1,
});

const removeFromPaginatable = (state, element) => ({
  ...state,
  items: deleteFromArray(state.items, element),
  total: state.total - 1,
  correctedTotal: state.correctedTotal - 1,
});

const removeFromAllPhotoPaginatables = (state, payload) =>
  state.paginatableType === 'photo'
    ? removeFromPaginatable(state, payload.photoId)
    : state;

const moveElementToTop = (state, id) => {
  const index = state.items.indexOf(id);
  return index >= 0
    ? {
        ...state,
        items: [
          state.items[index],
          ...state.items.slice(0, index),
          ...state.items.slice(index + 1),
        ],
      }
    : state;
};

const paginatable = (state: Paginatable, action: ReduxAction<Action>) => {
  switch (action.type) {
    case REQUEST_PAGINATABLE:
      return paginatableMatches(state, action.payload)
        ? { ...state, pending: true }
        : state;
    case DELETE_PHOTO:
      return removeFromAllPhotoPaginatables(state, action.payload);
    case TOGGLE_LIKE: {
      let newState = state;

      const likersPID = getLikersPID(action.payload.photoId);
      if (paginatableMatches(state, likersPID)) {
        newState = action.payload.liked
          ? removeFromPaginatable(state, action.payload.authUserNickname)
          : addToPaginatable(state, action.payload.authUserNickname);
      }

      const likedPhotosPID = getLikedPhotosPID(action.payload.authUserNickname);
      if (paginatableMatches(state, likedPhotosPID)) {
        newState = action.payload.liked
          ? removeFromPaginatable(state, action.payload.photoId)
          : addToPaginatable(state, action.payload.photoId);
      }

      return newState;
    }
    case UPDATE_LIGHTBOX:
      return paginatableMatches(state, ownLightboxesPID)
        ? moveElementToTop(state, action.payload.id)
        : state;
    case CREATE_LIGHTBOX:
      return paginatableMatches(state, ownLightboxesPID)
        ? addToPaginatable(state, action.payload.lightbox.id)
        : state;
    case DELETE_LIGHTBOX:
      return paginatableMatches(state, ownLightboxesPID)
        ? removeFromPaginatable(state, action.payload.lightboxId)
        : state;
    case SET_PAGE:
      if (paginatableMatches(state, action.payload.PIDObject)) {
        return {
          ...state,
          page: action.payload.page,
        };
      }
      return state;
    default:
      return state;
  }
};

export default function paginatables(
  state: PaginatablesState = [],
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case RECEIVE_NORMALIZED_DATA:
      return action.payload.paginatable
        ? merge(state, action.payload.paginatable)
        : state;
    case CREATE_LIGHTBOX: {
      const tempState = !find(state, ownLightboxesPID)
        ? state.concat(emptyPaginatable(ownLightboxesPID))
        : state;
      return tempState.map((paginatableItem) =>
        paginatable(paginatableItem, action)
      );
    }
    case REQUEST_PAGINATABLE: {
      const tempState = !find(state, {
        resourceType: action.payload.resourceType,
        resourceId: action.payload.resourceId,
        paginatableName: action.payload.paginatableName,
      })
        ? state.concat(emptyPaginatable(action.payload))
        : state;
      return tempState.map((paginatableItem) =>
        paginatable(paginatableItem, action)
      );
    }
    default:
      return state.map((paginatableItem) =>
        paginatable(paginatableItem, action)
      );
  }
}

export const getPaginatable = (
  state: PaginatablesState,
  params: PIDObject
): Paginatable => params && find(state, params);

// when necessary fill paginatable.items with empty items
export const mapInitialDataToInitialState = (initialData: NormalizedData) =>
  initialData?.paginatable?.map((paginatableRow) => {
    const items = new Array(paginatableRow.offset);
    paginatableRow.items = items.concat(paginatableRow.items);
    paginatableRow.initialPage = getPageFromOffsetAndLimit(
      paginatableRow.offset,
      paginatableRow.limit
    );
    return paginatableRow;
  });
