/* @flow */
import { theme } from 'eyeem-components';

// eslint-disable-next-line no-unused-vars
const { borderRadius, ...rest } = theme;

// we exclude borderRadius from old theme to not override the theme form eyeem-ui
export default {
  ...rest,
  zIndices: {
    sideNav: 12,
    navBar: 11,
    dropdown: 9,
    suggestionsBox: 8,
    uploadTopbar: 7,
    flyout: 3,
    actionBar: 3,
    releaseActionBar: 3,
    downloadBlock: 2,
  },
  navHeight: '64px',
  // MAGIC NUMBER EXPLANATION: up till size large the max-width of landing page slides
  // is defined by paddings on the side, at the maximum point of size large, the width is 1024-24-24=976px.
  // Sticky headers are positioned using margins (just padding the content doesn't work because of the bottom border)
  // However, to center then on xlarge-up, we need margin:auto. Building this is easiest, when the max-width of the element
  // is just as wide as the element is at the moment margin:auto kicks in: 976px;
  maxWidth: 976,
  opacities: {
    navItemHover: 0.48,
  },
  fontBase: '/node-static/fonts/sailec',
  sideNavWidth: '64px',
  openSideNavWidth: '240px',
};
