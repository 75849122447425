/* @flow */
import { SET_PHOTO_CACHE } from '../constants/actionTypes';
import {
  getRequestedDimensions,
  getActualPhotoDimensions,
} from '../helpers/photoCaching';
import { getThumbFilename } from '../helpers/tools';

export default function cachedThumbnail(
  state: CachedThumbnailState = {},
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case SET_PHOTO_CACHE:
      return {
        ...state,
        [getThumbFilename(action.payload.url)]: {
          ...getActualPhotoDimensions(
            getRequestedDimensions(action.payload.url),
            action.payload.originalDimensions
          ),
          url: action.payload.url,
        },
      };
    default:
      return state;
  }
}

export const getCachedThumbnail = (
  state: CachedThumbnailState,
  requestedUrl: string
) => state[getThumbFilename(requestedUrl)];
