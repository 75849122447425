/* @flow */
import { connect } from 'react-redux';

import { getLanguage } from '../../../../reducers/';

import FooterLink from './footerLink.jsx';

const mapStateToProps = (state) => ({
  language: getLanguage(state),
});

export default connect(mapStateToProps)(FooterLink);
