/* @flow */
/* eslint-env browser */
import { Component } from 'react';

import { Field } from 'redux-form';

import CaptchaComponent from './captchaComponent';

type Props = {
  customError: string,
};
class Captcha extends Component<Props> {
  render() {
    return (
      <Field
        name="recaptchaResponse"
        component={CaptchaComponent}
        props={{ customError: this.props.customError }}
      />
    );
  }
}

export default Captcha;
