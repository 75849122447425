// @flow
import t from 'counterpart';
import styled from '@emotion/styled';
import { theme } from '@eyeem-ui/theme';

import Link from '../../../general/link';
import { getUrlUnlocalized } from '../../../../helpers/localization';

import NotificationsTrigger from './notificationsTrigger';

const StyledLink = styled(Link)`
  font-size: ${theme.fontSizes[2]}px;
`;

const isCurrentPath = (currentPath, navItemPath) =>
  getUrlUnlocalized(navItemPath) === getUrlUnlocalized(currentPath);

export const getMoreMenuContent = (isCreator: boolean) => {
  const eventPosition = isCreator ? 'sidenav_creators' : 'sidenav_enterprise';
  return {
    menuTitle: t('sideNav.more.title'),
    magazine: {
      title: t('sideNav.more.magazine'),
      href: '/magazine',
      extraProps: {
        'data-eventname': 'access_sidebar_nav',
        'data-eventaction': 'More',
        'data-eventlabel': 'Magazine',
        'data-eventposition': eventPosition,
      },
    },
    items: [
      {
        groupTitle: t('sideNav.more.items.company.title'),
        groupItems: [
          {
            title: t('sideNav.more.items.company.about'),
            component: StyledLink,
            href: '/story',
            extraProps: {
              'data-eventname': 'access_sidebar_nav',
              'data-eventaction': 'More',
              'data-eventlabel': 'About_Eyeem',
              'data-eventposition': eventPosition,
            },
          },
          {
            title: t('sideNav.more.items.company.press'),
            component: StyledLink,
            href: '/press',
            extraProps: {
              'data-eventname': 'access_sidebar_nav',
              'data-eventaction': 'More',
              'data-eventlabel': 'Press',
              'data-eventposition': eventPosition,
            },
          },
          {
            title: t('sideNav.more.items.company.careers'),
            component: StyledLink,
            href: '/jobs',
            extraProps: {
              'data-eventname': 'access_sidebar_nav',
              'data-eventaction': 'More',
              'data-eventlabel': 'Jobs',
              'data-eventposition': eventPosition,
            },
          },
        ],
      },
      {
        groupTitle: t('sideNav.more.items.legal.title'),
        groupItems: [
          {
            title: t('sideNav.more.items.legal.terms'),
            component: StyledLink,
            href: '/tos',
            extraProps: {
              'data-eventname': 'access_sidebar_nav',
              'data-eventaction': 'More',
              'data-eventlabel': 'Terms & Privacy',
              'data-eventposition': eventPosition,
            },
          },
          {
            title: t('sideNav.more.items.legal.imprint'),
            component: StyledLink,
            href: '/imprint',
            extraProps: {
              'data-eventname': 'access_sidebar_nav',
              'data-eventaction': 'More',
              'data-eventlabel': 'Imprint',
              'data-eventposition': eventPosition,
            },
          },
        ],
      },
      {
        groupTitle: t('sideNav.more.items.support.title'),
        groupItems: [
          {
            title: t('sideNav.more.items.support.help'),
            component: StyledLink,
            href: 'https://eyeem.zendesk.com/hc/en-us/categories/115000290289-Frequently-Asked-Questions',
            extraProps: {
              'data-eventname': 'access_sidebar_nav',
              'data-eventaction': 'More',
              'data-eventlabel': 'Need Help',
              'data-eventposition': eventPosition,
            },
          },
        ],
      },
    ],
  };
};

const getBuyerNavItems = (isProductionPath, isEnterpriseCustomer, isBCG) => {
  const eventPosition = isEnterpriseCustomer
    ? 'sidenav_enterprise'
    : 'sidenav_buyer';
  const navItems = {
    primary: [
      {
        text: t('sideNav.items.licensing'),
        name: 'licensing',
        component: StyledLink,
        active: !isProductionPath,
        href: '/',
        extraProps: {
          'data-eventname': 'access_sidebar_nav',
          'data-eventaction': 'Image_Licensing',
          'data-eventlabel': 'not_set',
          'data-eventposition': eventPosition,
        },
      },
      {
        text: t('sideNav.items.production'),
        name: 'production',
        active: isProductionPath,
        href: '', // set after component "mounts" client-side,
        extraProps: {
          'data-eventname': 'access_sidebar_nav',
          'data-eventaction': 'Production',
          'data-eventlabel': 'not_set',
          'data-eventposition': eventPosition,
        },
      },
    ],
    secondary: [
      {
        text: t('sideNav.items.account'),
        name: 'account',
        extraProps: {
          'data-eventname': 'access_sidebar_nav',
          'data-eventaction': 'Account',
          'data-eventlabel': 'not_set',
          'data-eventposition': eventPosition,
        },
      },
      {
        text: t('sideNav.items.more'),
        name: 'more',
        extraProps: {
          'data-eventname': 'access_sidebar_nav',
          'data-eventaction': 'More',
          'data-eventlabel': 'not_set',
          'data-eventposition': eventPosition,
        },
      },
    ],
  };

  if (isEnterpriseCustomer && !isBCG) {
    navItems.secondary = [
      {
        text: t('sideNav.items.team'),
        name: 'team',
        href: '/settings/team',
        extraProps: {
          'data-eventname': 'access_sidebar_nav',
          'data-eventaction': 'Team',
          'data-eventlabel': 'not_set',
          'data-eventposition': eventPosition,
        },
      },
      ...navItems.secondary,
    ];
  }
  return navItems;
};

const getPhotographerNavItems = (isProductionPath, authUser, path) => {
  const uploadPath = '/upload';

  const eventPosition = 'sidenav_creators';
  const profilePath = authUser.nickname
    ? `/u/${authUser.nickname.toLowerCase()}`
    : '/';
  return {
    primary: [
      {
        text: t('sideNav.items.profile'),
        name: 'profile',
        component: StyledLink,
        active: isCurrentPath(path, profilePath),
        href: profilePath,
        extraProps: {
          'data-eventname': 'access_sidebar_nav',
          'data-eventaction': 'Profile',
          'data-eventlabel': 'not_set',
          'data-eventposition': eventPosition,
        },
      },
      {
        text: t('sideNav.items.following'),
        name: 'following',
        component: StyledLink,
        active: isCurrentPath(path, '/following'),
        href: '/following',
        extraProps: {
          'data-eventname': 'access_sidebar_nav',
          'data-eventaction': 'Following',
          'data-eventlabel': 'not_set',
          'data-eventposition': eventPosition,
        },
      },
      {
        text: t('sideNav.items.upload'),
        name: 'upload',
        component: StyledLink,
        active: path.startsWith('/upload'),
        href: uploadPath,
        extraProps: {
          'data-eventname': 'access_sidebar_nav',
          'data-eventaction': 'Upload',
          'data-eventlabel': 'not_set',
          'data-eventposition': eventPosition,
        },
      },
      {
        text: t('sideNav.items.missions'),
        name: 'missions',
        component: StyledLink,
        active: isCurrentPath(path, '/missions/photographers'),
        href: '/missions/photographers',
        extraProps: {
          'data-eventname': 'access_sidebar_nav',
          'data-eventaction': 'Missions',
          'data-eventlabel': 'not_set',
          'data-eventposition': eventPosition,
        },
      },
    ],
    secondary: [
      {
        text: 'Notifications',
        name: 'notifications',
        component: NotificationsTrigger,
        extraProps: {
          'data-eventname': 'access_sidebar_nav',
          'data-eventaction': 'Notifications',
          'data-eventlabel': 'not_set',
          'data-eventposition': eventPosition,
        },
      },
      {
        text: t('sideNav.items.account'),
        name: 'account',
        iconName: 'gear',
        extraProps: {
          'data-eventname': 'access_sidebar_nav',
          'data-eventaction': 'Account',
          'data-eventlabel': 'not_set',
          'data-eventposition': eventPosition,
        },
      },
      {
        text: t('sideNav.items.more'),
        name: 'more',
        extraProps: {
          'data-eventname': 'access_sidebar_nav',
          'data-eventaction': 'More',
          'data-eventlabel': 'not_set',
          'data-eventposition': eventPosition,
        },
      },
    ],
  };
};

export const getSideNavItems = ({
  path,
  authUser,
  isPhotographer,
  isEnterpriseCustomer,
  isBCG,
}: {
  path: string,
  authUser: AuthUser,
  isPhotographer: boolean,
  isEnterpriseCustomer: boolean,
  isBCG: boolean,
}) => {
  const isProductionPath = Boolean(path) && path.indexOf('production') > -1;

  if (isPhotographer) {
    return getPhotographerNavItems(isProductionPath, authUser, path);
  }

  return getBuyerNavItems(isProductionPath, isEnterpriseCustomer, isBCG);
};

export const getAccountMenuContent = ({
  isEnterpriseCustomer,
  isPhotographer,
  isBuyer,
  isSeller,
}: {
  isEnterpriseCustomer: boolean,
  isPhotographer: boolean,
  isBuyer: boolean,
  isSeller: boolean,
}) => {
  let eventPosition = isEnterpriseCustomer
    ? 'sidenav_enterprise'
    : 'sidenav_buyer';
  if (isPhotographer) {
    eventPosition = 'sidenav_creator';
  }
  const items = [];
  if (isSeller) {
    items.push({
      title: t('sideNav.account.items.payout'),
      component: StyledLink,
      href: '/market/sell/payout',
    });
  }

  if (isBuyer && !isEnterpriseCustomer) {
    items.push({
      title: t('sideNav.account.items.orderHistory'),
      component: StyledLink,
      href: '/market/transactions',
      extraProps: {
        'data-eventname': 'access_sidebar_nav',
        'data-eventaction': 'Account',
        'data-eventlabel': 'Order_History',
        'data-eventposition': eventPosition,
      },
    });
  }
  items.push({
    title: t('sideNav.account.items.settings'),
    component: StyledLink,
    href: '/settings/account',
    extraProps: {
      'data-eventname': 'access_sidebar_nav',
      'data-eventaction': 'Account',
      'data-eventlabel': 'Setting',
      'data-eventposition': eventPosition,
    },
  });
  items.push({
    title: t('sideNav.account.items.logout'),
    component: StyledLink,
    href: '/logout',
    extraProps: {
      'data-eventname': 'access_sidebar_nav',
      'data-eventaction': 'Account',
      'data-eventlabel': 'Logout',
      'data-eventposition': eventPosition,
    },
  });

  return {
    menuTitle: t('sideNav.account.title'),
    items,
  };
};
