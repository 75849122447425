/* @flow */
/* eslint-disable max-len */
import styled from 'styled-components';
import { styleUtils } from 'eyeem-components';
import { TEST_EYEEMLOGO } from '../../constants/pageObjectSelectors';

const ProLogo = ({ className }) => (
  <svg className={className} width="118" height="24" viewBox="0 0 118 24">
    <path
      d="M23.4 4.2l-3.5 9.55-3.67-9.54h-3.77l5.6 13.5-2.53 6.3h3.68l2.56-6.6L27 4.2h-3.6zM3.53 3.08h7.5V0H0v17.4h11.15v-3.07H3.53V9.78h7.62V6.82H3.53V3.07zM38.4 6.7c-.57-.87-1.32-1.5-2.22-1.92-.9-.4-1.92-.6-3.05-.6-1.9 0-3.44.6-4.63 1.8-1.18 1.2-1.78 2.9-1.78 5.15 0 2.4.66 4.12 1.98 5.18 1.32 1.07 2.83 1.6 4.56 1.6 2.08 0
            3.7-.64 4.87-1.9.74-.8 1.16-1.55 1.25-2.35h-3.45c-.2.45-.44.7-.7.9-.48.4-1.1.6-1.88.6-.73 0-1.35-.16-1.87-.48-.85-.52-1.3-1.48-1.35-2.73h9.43c0-1.13-.02-1.97-.1-2.57-.17-1-.52-1.9-1.04-2.67 0 0 .52.77 0 0zm-8.2 2.97c.14-.8.44-1.5.92-1.98.47-.5 1.15-.74 2.02-.74.8 0 1.46.2 2 .68.54.46.84 1.12.9 2.03h-5.83zM72.9
            7.54c-.13-.66-.37-1.22-.74-1.67-.45-.55-.95-.94-1.5-1.16-.56-.2-1.12-.32-1.7-.32-.85 0-1.62.18-2.32.53-.65.37-1.2.93-1.65 1.7-.25-.65-.62-1.17-1.1-1.57-.62-.43-1.42-.65-2.4-.65-1.06 0-1.92.25-2.57.76-.38.28-.75.76-1.2 1.44v-1.9h-3.3V17.6h3.4V9.8c0-.7.13-1.22.3-1.58.34-.62 1-.93 1.97-.93.83 0 1.4.3 1.7.92.15.35.25.9.25
            1.68v7.73h3.42V9.9c0-.58.07-1.08.25-1.5.35-.76.98-1.15 1.92-1.15.8 0 1.37.3 1.67.9.15.32.23.72.23 1.2v8.28h3.45L73 9.46c0-.84-.04-1.5-.12-1.92 0 0 .08.43 0 0zm-27.8-4.47h7.6V0H41.55v17.4H52.8v-3.07h-7.73V9.78h7.74V6.82h-7.73V3.07zm39.55 5.3V1.4h5.6c1.13 0 2.05.28 2.75.85.7.57 1.05 1.45 1.05 2.63s-.35 2.06-1.05
            2.64c-.7.57-1.62.86-2.75.86h-5.6zM83 0v17.1h1.63V9.75h5.96c.75 0 1.45-.1 2.07-.32.62-.2 1.16-.53 1.6-.96.45-.42.8-.93 1.03-1.54.24-.6.36-1.3.36-2.06s-.12-1.45-.36-2.06c-.24-.6-.58-1.12-1.03-1.54-.44-.42-.98-.74-1.6-.96C92.05.12 91.35 0 90.6 0H83zm15.2 4.74V17.1h1.5v-6.6c0-.65.1-1.25.33-1.8s.54-1.02.95-1.42c.4-.4.9-.7
            1.45-.92.55-.22 1.18-.3 1.86-.28v-1.5c-1.12-.06-2.1.2-2.9.73-.8.56-1.4 1.33-1.78 2.34h-.04v-2.9h-1.4zm13.15.9c.7 0 1.35.16 1.9.46.53.3.98.7 1.34 1.2.35.5.62 1.06.8 1.7.2.62.28 1.26.28 1.9 0 .67-.1 1.3-.28 1.94-.18.63-.45 1.2-.8 1.7-.37.48-.82.88-1.36 1.2-.55.3-1.18.44-1.9.44-.7
            0-1.34-.15-1.9-.45-.53-.3-.98-.7-1.34-1.2-.36-.5-.63-1.06-.8-1.7-.2-.62-.3-1.26-.3-1.9 0-.67.1-1.3.3-1.94.17-.64.44-1.2.8-1.7.36-.5.8-.9 1.35-1.2.54-.3 1.17-.45 1.9-.45zm0-1.26c-.94 0-1.78.18-2.5.53-.73.36-1.34.83-1.84 1.42-.48.6-.86 1.28-1.1 2.07-.27.78-.4 1.62-.4 2.5 0 .9.13 1.75.4 2.54.24.8.62 1.48 1.1 2.07.5.6 1.12
            1.07 1.84 1.4.73.35 1.56.52 2.5.52.95 0 1.78-.17 2.5-.5.73-.36 1.34-.82 1.84-1.4.5-.6.87-1.3 1.12-2.08.26-.8.38-1.63.38-2.53 0-.88-.12-1.72-.38-2.5-.25-.8-.63-1.5-1.12-2.08-.5-.6-1.1-1.07-1.83-1.42-.73-.35-1.56-.52-2.5-.52z"
    />
  </svg>
);

const NormalLogo = ({ className }) => (
  <svg
    className={className}
    width="73"
    height="24"
    viewBox="0 0 73 24"
    data-test-id={TEST_EYEEMLOGO}>
    <path
      d="M23.402 4.21l-3.51 9.54-3.666-9.54h-3.764l5.595 13.49-2.523 6.3h3.674l2.555-6.612L27 4.208h-3.598zM3.528
        3.07h7.51V0H0v17.403h11.153v-3.07H3.528v-4.55h7.625V6.824H3.528V3.07zm34.887
        3.627c-.59-.87-1.334-1.51-2.237-1.913-.903-.404-1.918-.606-3.044-.606-1.897 0-3.44.598-4.63 1.793-1.188
        1.197-1.783 2.916-1.783 5.157 0 2.39.66 4.118 1.977 5.18 1.317 1.06 2.836 1.59 4.56 1.59 2.086 0 3.71-.64
        4.87-1.898.744-.792 1.163-1.555 1.254-2.35h-3.455c-.202.454-.433.687-.696.9-.484.397-1.11.6-1.88.6-.728
        0-1.35-.157-1.866-.482-.85-.522-1.302-1.474-1.354-2.725h9.428c.016-1.137-.02-1.974-.112-2.57-.158-1.013-.502-1.906-1.03-2.676
        0 0 .528.77 0 0zm-8.202 2.97c.123-.795.424-1.488.902-1.98.478-.494 1.15-.73 2.02-.73.8 0 1.467.217
        2.007.682.54.464.84 1.118.903 2.028h-5.832zm42.667-2.13c-.126-.658-.372-1.212-.736-1.663-.45-.554-.954-.942-1.508-1.164-.554-.22-1.116-.332-1.686-.332-.855
        0-1.63.178-2.327.534-.657.356-1.208.914-1.65 1.674-.255-.633-.627-1.152-1.117-1.555-.61-.434-1.41-.652-2.4-.652-1.044
        0-1.904.253-2.56.76-.374.285-.743.764-1.198 1.436v-1.91h-3.3V17.63h3.414V9.814c0-.704.114-1.234.297-1.59.33-.625.99-.938
        1.957-.938.832 0 1.412.313 1.697.938.158.348.26.906.26 1.673v7.733h3.413V9.897c0-.577.072-1.072.254-1.483.358-.776.994-1.163
        1.928-1.163.807 0 1.37.3 1.67.903.16.316.246.72.246 1.21v8.267h3.442L73 9.46c0-.838-.04-1.49-.12-1.924 0 0 .08.435 0
        0zM45.066 3.07h7.625V0H41.54v17.403h11.267v-3.07h-7.74v-4.55h7.74V6.824h-7.74V3.07z"
    />
  </svg>
);

const SquareLogo = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    data-test-id={TEST_EYEEMLOGO}
    xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="22" height="22" stroke="white" strokeWidth="2" />
    <path
      d="M10.4731 7.55325H16.288V5.25H7.7417V18.3023H16.3763V15.9998H10.4731V12.5872H16.3763V10.368H10.4731V7.55325Z"
      fill="white"
    />
  </svg>
);

const StyledProLogo = styled(({ isBlack, ...rest }) => <ProLogo {...rest} />)`
  pointer-events: auto;
  fill: ${(props) =>
    props.isBlack
      ? styleUtils.fromTheme('colors.blacks.black5')
      : styleUtils.fromTheme('colors.whites.white')};
`;

const StyledNormalLogo = styled(({ isBlack, ...rest }) => (
  <NormalLogo {...rest} />
))`
  pointer-events: auto;
  fill: ${(props) =>
    props.isBlack
      ? styleUtils.fromTheme('colors.blacks.black5')
      : styleUtils.fromTheme('colors.whites.white')};
`;

const StyledSquareLogo = styled(({ ...rest }) => <SquareLogo {...rest} />)`
  pointer-events: auto;
`;

function Logo(props: {
  shouldUseProLogo?: boolean,
  isBlack?: boolean,
  square?: boolean,
}) {
  if (props.square) {
    return <StyledSquareLogo />;
  }

  if (props.shouldUseProLogo) {
    return <StyledProLogo isBlack={props.isBlack} />;
  }

  return <StyledNormalLogo isBlack={props.isBlack} />;
}

export default Logo;
