/* @flow */
import type { Node } from 'react';

import { Component } from 'react';

import { Box, Flex } from '@eyeem-ui/atoms';
import { ThemeProvider } from '@eyeem-ui/theme';

import T from '../translate.jsx';
import {
  DEVICE_OS_ANDROID,
  DEVICE_OS_IOS,
  STORELINK_EYEEM,
} from '../../../constants/misc';

type State = {
  isOther: boolean,
};

type Props = {
  // tracking event position
  eventPosition: string,

  // should it respect the operating system
  smart?: boolean,

  // if provided and device isOther then default to this link
  defaultDevice?: DEVICE_OS_ANDROID | DEVICE_OS_IOS,
  children: Node,

  // class to attach to the app store icon(s) wrapper
  imgWrapperClass?: string,
  isIOS?: boolean,
  isAndroid?: boolean,
};

class AppLinks extends Component<Props, State> {
  constructor(props: Props, context: mixed) {
    super(props, context);

    this.state = {
      isOther: !props.isIOS && !props.isAndroid,
    };
  }

  render() {
    const android = (
      <T
        noTranslate
        component="a"
        eventType="click_outbound"
        eventName="download_play_store"
        eventAction="click_button"
        eventLabel="play_store"
        eventPosition={this.props.eventPosition}
        target="_blank"
        href={STORELINK_EYEEM[DEVICE_OS_ANDROID]}>
        {this.props.children ? (
          this.props.children
        ) : (
          <img alt="Google Play Store" src="/node-static/img/playstore.svg" />
        )}
      </T>
    );

    const iOS = (
      <T
        noTranslate
        component="a"
        eventType="click_outbound"
        eventName="download_app_store"
        eventAction="click_button"
        eventLabel="app_store"
        eventPosition={this.props.eventPosition}
        target="_blank"
        href={STORELINK_EYEEM[DEVICE_OS_IOS]}>
        {this.props.children ? (
          this.props.children
        ) : (
          <img alt="Apple App Store" src="/node-static/img/appstore.svg" />
        )}
      </T>
    );

    if (
      (this.state.isOther && this.props.defaultDevice === DEVICE_OS_ANDROID) ||
      (this.props.smart && this.props.isAndroid)
    ) {
      return android;
    }
    if (
      (this.state.isOther && this.props.defaultDevice === DEVICE_OS_IOS) ||
      (this.props.smart && this.props.isIOS)
    ) {
      return iOS;
    }
    return (
      <ThemeProvider>
        <Flex>
          <Box mr={2}>{iOS}</Box>
          <Box>{android}</Box>
        </Flex>
      </ThemeProvider>
    );
  }
}

export default AppLinks;
