/* @flow */

import { gql } from '@apollo/client';

export const SimpleIllustrationFragment = gql`
  fragment SimpleIllustration on Illustration {
    height
    id
    previewUrl
    previewUrlPublic
    relativeUrl
    route
    thumbUrl
    isLicensable
    type
    width
  }
`;

// used for the readFragment call in singleAsset
export const SimpleSearchIllustrationFragment = gql`
  fragment SimpleSearchIllustration on Illustration {
    id
    type
    height
    width
    thumbUrl
    relativeUrl
    previewUrl
    isLicensable
  }
`;
