/* @flow */
import type { FieldProps } from 'react-final-form';
import { Checkbox } from '@eyeem-ui/atoms';

type Props = {
  label: string,
  disabled?: boolean,
  formRow?: boolean,
  'data-test-id'?: string,
} & FieldProps;

function CheckboxWrapper(props: Props) {
  const isValid =
    !props.meta.touched || (props.meta.touched && props.meta.valid);

  return (
    <Checkbox
      {...props.input}
      checked={props.input.checked}
      disabled={props.disabled}
      customProps={{ 'data-test-id': props['data-test-id'] }}
      errorMessage={!isValid ? props.meta.error : null}
      isValid={isValid}
      label={props.label}
      formRow={props.formRow}
    />
  );
}

export default CheckboxWrapper;
