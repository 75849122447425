/* @flow */
import { Component } from 'react';
import t from 'counterpart';
import has from 'lodash/has';
import get from 'lodash/get';

import CreateLightbox from '../../general/createLightbox/';
import T from '../../general/translate.jsx';
import DropdownSelect from '../../general/dropdownSelect.jsx';

import { DROPDOWN_TRIGGER_STYLE_DARK } from '../../../constants/misc';

import LightboxModal from '../../modals/lightbox/';

type Props = {
  selectLightbox: Function,
  clearSelectedLightbox: Function,
  openModal: OpenModal,
  selectedLightboxId: EyeEmLightboxId,
  selectedLightbox?: {
    loading: boolean,
    lightbox?: EyeEmLightbox,
  },
  data?: {
    ownLightboxes?: {
      items: $ReadOnlyArray<EyeEmLightbox>,
    },
  },
};

class SelectLightboxDropdown extends Component<Props> {
  componentDidUpdate = (prevProps: Props) => {
    const wasLightboxLoading =
      prevProps.selectedLightbox?.loading &&
      !get(this.props, 'selectedLightbox.loading');
    if (wasLightboxLoading) {
      // if finished loading check the lightbox exists
      const hasFoundLightbox = has(this.props, 'selectedLightbox.lightbox.id');
      const hasSelectedLightboxInSession = !!this.props.selectedLightboxId;

      // A lightbox that doesn't exist might be selected in the session field
      // in this case, delete it from the session field!
      if (!hasFoundLightbox && hasSelectedLightboxInSession) {
        this.props.clearSelectedLightbox();
      }
    }
  };

  selectLightbox = (lightboxId: EyeEmLightboxId) =>
    this.props.selectLightbox(lightboxId);

  openCreateLightboxModal = () => {
    return this.props.openModal({
      content: LightboxModal,
      contentProps: {
        selectLightboxCallback: this.selectLightbox,
        title: t('lightboxes.modals.create.title'),
      },
      useEUModal: true,
    });
  };

  render() {
    const showCreateButton =
      !this.props.data?.ownLightboxes?.items ||
      this.props.data?.ownLightboxes?.items.length < 1;

    return showCreateButton ? (
      <CreateLightbox>
        <T
          component="button"
          eventType={t('tracking.eventType.inbound')}
          eventAction={t('tracking.eventAction.button')}
          eventLabel={t('lightboxes.strip.select.create.label')}
          eventName={t('lightboxes.strip.select.create.eventName')}
          eventPosition={t('lightboxes.strip.select.create.eventPosition')}
          className="g_btn g_btn-aqua g_btn-small g_btn-fullWidth">
          lightboxes.strip.select.create.label
        </T>
      </CreateLightbox>
    ) : (
      <DropdownSelect
        noTranslate
        triggerStyle={DROPDOWN_TRIGGER_STYLE_DARK}
        unselected={t('lightboxes.strip.select.noLightboxSelected')}
        options={
          (this.props.data?.ownLightboxes?.items &&
            this.props.data?.ownLightboxes?.items.map((lightbox) => ({
              value: lightbox.id,
              text: lightbox.name,
              addTitle: true,
              eventName: t('lightboxes.strip.select.eventName'),
              eventLabel: lightbox.name,
              eventPosition: t('lightboxes.strip.select.eventPosition'),
              eventOption: `lightboxId=${lightbox.id}`,
              hoverLink: {
                label: t('lightboxes.strip.select.view.label'),
                href: `/lightboxes/${lightbox.id}`,
                eventName: t('lightboxes.strip.select.view.eventName'),
                eventLabel: lightbox.name,
                eventPosition: t('lightboxes.strip.select.view.eventPosition'),
                eventOption: `lightboxId=${lightbox.id}`,
              },
            }))) ||
          []
        }
        topOfListLink={{
          label: t('lightboxes.strip.select.create.label'),
          onClick: this.openCreateLightboxModal,
          eventName: t('lightboxes.strip.select.create.eventName'),
          eventPosition: t('lightboxes.strip.select.create.eventPosition'),
        }}
        activeKey={this.props.selectedLightbox?.lightbox?.id}
        onChange={this.selectLightbox}
      />
    );
  }
}

export default SelectLightboxDropdown;
