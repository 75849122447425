/* @flow */
import { cleanObject } from '../tools';

type PostPhotoStatsArg = TrackUploadPostPhotosArg;

type PostIllustrationStatsArg = TrackUploadPostIllustrationsArg;
type PostAssetStatsArg = PostPhotoStatsArg | PostIllustrationStatsArg;

export const postAssetStats = (arg: PostAssetStatsArg) => {
  const result = cleanObject({
    [`${arg.assetType}AddedToMission`]: 0,
    [`${arg.assetType}Uploaded`]: arg.upload.length,
    [`${arg.assetType}AddedToMarket`]: 0,
    [`${arg.assetType}WithCaption`]: 0,
    [`${arg.assetType}WithAlbums`]: 0,
    [`${arg.assetType}WithLocation`]: 0,
    uploadPrefillAlbum:
      arg.assetType === 'photo'
        ? arg.uploadPrefillInfo &&
          arg.uploadPrefillInfo.album &&
          arg.uploadPrefillInfo.album.id
        : false,
  });

  switch (arg.assetType) {
    case 'photo':
    case 'photos':
      arg.upload.forEach((photo) => {
        if (photo.market) {
          result.photosAddedToMarket += 1;
        }
        if (photo.description && photo.description !== '') {
          result.photosWithCaption += 1;
        }
        if (photo.albums && photo.albums.length > 0) {
          result.photosWithAlbums += 1;
        }
        if (arg.uploadPrefillInfo && photo.albums) {
          photo.albums.forEach((album) => {
            if (album === arg.uploadPrefillInfo.album.name) {
              result.photosAddedToMission += 1;
            }
          });
        }
        if (photo.location && photo.location.city) {
          result.photosWithLocation += 1;
        }
      });
      break;
    case 'illustration':
    case 'illustrations':
      arg.upload.forEach(() => {
        result.illustrationsAddedToMarket += 1; // no need to check: illustration uploads are always added to market
        result.illustrationsWithCaption += 1; // no need to check: illustration captions are required
        if (arg.isSubmittedToMission) {
          result.illustrationsAddedToMission += 1;
        }
      });
      break;
    default: // eslint-disable-line no-console
      console.error(`Unsupported asset type: ${arg.assetType}`);
      return {};
  }

  return result;
};
