/* @flow */
import { RECEIVE_NORMALIZED_DATA } from '../constants/actionTypes';

export default function buyerAddress(
  state: BuyerAddressState = {},
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case RECEIVE_NORMALIZED_DATA:
      return action.payload.address || state;
    default:
      return state;
  }
}

export function mapInitialDataToInitialState(initialData: NormalizedData) {
  return initialData.address;
}

export const getBuyerAddress = (state: BuyerAddressState) => state;

// people that already have an address are recurring buyers. This is used to make sure they
// are able to checkout from wherever in the world they are (even non-DE and non-US countries
// or unknown IPs)
export const getIsRecurringBuyer = (state: BuyerAddressState) => !!state.id;
