/* @flow */
/* eslint-env browser */
import { PureComponent } from 'react';
import t from 'counterpart';
import styled from '@emotion/styled';
import { Box, Text } from '@eyeem-ui/atoms';

import ScrollCapture from '../../general/scrollCapture.jsx';

import Task from './task.jsx';
import News from './news.jsx';

const StyledWrapper = styled.div`
  position: relative;
  height: calc(100vh - 50px);
  margin: 20px -20px 0;

  & > div {
    height: 100%;
  }
`;

const StyledScrollContainer = styled(Box)`
  overflow-y: auto;
  max-height: 100%;
`;

const StyledScrollContainerHeadline = styled.div`
  margin-top: ${(props) => (props.isEmpty ? '40px' : '24px')};
  margin-right: ${(props) => (props.isEmpty ? '16px' : null)};
  margin-bottom: ${(props) => (props.isEmpty ? '40px' : '12px')};
  margin-left: 16px;
  padding-bottom: ${(props) => (props.isEmpty ? '16px' : null)};
  white-space: ${(props) => (props.isEmpty ? 'normal' : null)};
`;

type Props = {
  news: {
    news: NewsPayload,
    loading: boolean,
    refetch: Function,
    fetchMore: Function,
  },
  tasks: {
    tasks: {
      total: number,
      items: TaskItem,
    },
    loading: boolean,
    refetch: Function,
    fetchMore: Function,
    keywordingBacklog: { total: number },
  },

  resendVerificationEmail: Function,
  openModal: Function,
};

type State = {
  isLoadingMore: boolean,
};

export default class Notifications extends PureComponent<Props, State> {
  state = {
    isLoadingMore: false,
  };

  onScroll = (event: SyntheticInputEvent<HTMLElement>) => {
    const scrollBottom = event.target.scrollTop + event.target.offsetHeight;

    if (
      event.target.scrollHeight < scrollBottom + 250 &&
      !this.state.isLoadingMore
    ) {
      this.setState({ isLoadingMore: true });
      this.props.news
        .fetchMore({
          variables: {
            oldestId: this.props.news.news.oldestId,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            // BUG: Apollo returns 'undefined' for prev
            if (!fetchMoreResult) return prev;
            return Object.assign({}, prev, {
              news: {
                ...prev.news,
                ...fetchMoreResult.news,
                items: [...prev.news.items, ...fetchMoreResult.news.items],
              },
            });
          },
        })
        .finally(() => this.setState({ isLoadingMore: false }));
    }
  };

  showTasksBlock = () => this.props.tasks?.tasks?.length > 0;

  showActivitiesBlock = () =>
    !this.props.news?.loading && this.props.news?.news?.total > 0;

  showEmptyMessage = () =>
    !this.showTasksBlock() && !this.showActivitiesBlock();

  render() {
    const { news, tasks } = this.props;

    return (
      <StyledWrapper>
        <ScrollCapture>
          <StyledScrollContainer p={2} px={[0, 2]} onScroll={this.onScroll}>
            {this.showTasksBlock() &&
              tasks.tasks.map((task) => (
                <Task
                  key={task.type}
                  resendVerificationEmail={this.props.resendVerificationEmail}
                  openModal={this.props.openModal}
                  task={task}
                />
              ))}

            {(news?.loading || this.showActivitiesBlock()) && (
              <News
                key={4}
                news={news.news}
                loading={news.loading || this.state.isLoadingMore}
              />
            )}
            {!this.props.news?.loading && this.showEmptyMessage() && (
              <StyledScrollContainerHeadline isEmpty key={4}>
                <Text>{t('navbar.notifications.empty')}</Text>
              </StyledScrollContainerHeadline>
            )}
          </StyledScrollContainer>
        </ScrollCapture>
      </StyledWrapper>
    );
  }
}
