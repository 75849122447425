import { gql } from '@apollo/client';
import { SimplePhotoFragment } from '../fragments/photo';
import { SimpleIllustrationFragment } from '../fragments/illustration';

export const GET_LIGHTBOX = gql`
  query getLightbox($lightboxId: ID!) {
    lightbox(lightboxId: $lightboxId) {
      id
      name
      clientDescription
      description
      items {
        items {
          __typename
          ... on Photo {
            ...SimplePhoto
          }
          ... on Illustration {
            ...SimpleIllustration
          }
        }
      }
    }
  }
  ${SimplePhotoFragment}
  ${SimpleIllustrationFragment}
`;

export const GET_LIGHTBOX_NAME = gql`
  query getLightboxName($lightboxId: ID!) {
    lightbox(lightboxId: $lightboxId) {
      id
      name
    }
  }
`;

export const GET_LIGHTBOX_FOR_GRID = gql`
  query getLightboxForGrid(
    $lightboxId: ID!
    $offset: Int
    $limit: Int
    $isPhotographer: Boolean!
  ) {
    lightbox(
      lightboxId: $lightboxId
      offset: $offset
      limit: $limit
      isPhotographer: $isPhotographer
    ) {
      id
      clientDescription
      description
      totalItems
      lastUserUpdatedAt
      name
      userId
      isShared
      items(offset: $offset, limit: $limit) {
        total
        items {
          __typename
          ... on Photo {
            id
            type
            lightboxIds
            updated @include(if: $isPhotographer)
            updatedAt @include(if: $isPhotographer)
            thumbUrl
            previewUrl: photoUrl
            isForSale
            ...SimplePhoto
            eyevision {
              headline
              tags
            }
            user {
              thumbUrl
            }
          }
          __typename
          ... on Illustration {
            lightboxIds
            ...SimpleIllustration
            width
            height
          }
        }
      }
    }
  }
  ${SimplePhotoFragment}
  ${SimpleIllustrationFragment}
`;

export const GET_LIGHTBOX_PHOTOS_FOR_SINGLE_PHOTO = gql`
  query getLightboxPhotosForSinglePhoto(
    $lightboxId: ID!
    $offset: Int
    $limit: Int
  ) {
    lightbox(lightboxId: $lightboxId, offset: $offset, limit: $limit) {
      id
      items(offset: $offset, limit: $limit) {
        total
        offset
        items {
          __typename
          ... on Photo {
            type
            ...SimplePhoto
          }
          __typename
          ... on Illustration {
            ...SimpleIllustration
          }
        }
      }
    }
  }
  ${SimplePhotoFragment}
  ${SimpleIllustrationFragment}
`;

export const GET_MISSION_SAMPLES = gql`
  query getMissionSamples($lightboxId: ID!, $offset: Int) {
    lightbox(lightboxId: $lightboxId) {
      id
      items(offset: $offset) {
        items {
          # Don't use photo fragment here since the photo shape
          # in the response from getLightbox api endpoint
          # does not contain market info
          __typename
          ... on Photo {
            id
            type
            thumbUrl
            previewUrl: photoUrl
            updated
            relativeUrl
            updatedAt
            width
            height
            totalLikes
            totalComments
          }
          __typename
          ... on Illustration {
            ...SimpleIllustration
          }
        }
      }
    }
  }
  ${SimpleIllustrationFragment}
`;

export const GET_OWN_LIGHTBOXES = gql`
  query getOwnLightboxes($offset: Int, $limit: Int) {
    ownLightboxes(offset: $offset, limit: $limit) {
      offset
      limit
      total
      items {
        id
        name
        isShared
        description
        clientDescription
        totalPhotos
        lastUserUpdatedAt
      }
    }
  }
`;

export const GET_LIGHTBOX_MEMBERS = gql`
  query getLightboxMembers($lightboxId: ID!) {
    lightboxMembers(lightboxId: $lightboxId) {
      total
      items {
        userUuid
        role
      }
    }
  }
`;

export const GET_LIGHTBOXES_FOR_PREVIEW_GRID = gql`
  query getLightboxesByIds($lightboxIds: [ID!]!, $offset: Int!, $limit: Int!) {
    lightboxesByIds(lightboxIds: $lightboxIds, offset: $offset, limit: $limit) {
      id
      name
      items(offset: $offset, limit: $limit) {
        total
        offset
        items {
          __typename
          ... on Illustration {
            id
            previewUrl
          }
        }
        items {
          __typename
          ... on Photo {
            id
            previewUrl: photoUrl
          }
        }
      }
    }
  }
`;
