// @flow
import { useEffect, useState } from 'react';
import mitt from 'mitt';
import { useMutation, useQuery } from '@apollo/client';

import styled from '@emotion/styled';
import { SideNavigation } from '@eyeem-ui/organisms';
import { media, ThemeProvider, theme } from '@eyeem-ui/theme';
import { Box } from '@eyeem-ui/atoms';

import withRouter from '../../../../helpers/hoc/withRouter.jsx';

import Notifications from '../../notifications';

import {
  getSideNavItems,
  getMoreMenuContent,
  getAccountMenuContent,
} from './sideNavContent.jsx';
import { GET_NEWS_UNSEEN } from '../../../../graphql/queries/news';
import { MARK_NEWS_READ } from '../../../../graphql/mutations/news';

import {
  PRODUCTIONS_HOSTNAME,
  TOGGLE_SIDENAV_PUBSUB_EVENT,
  WEBFLOW_URLS,
} from '../../../../constants/misc';
import { routeMatchesOneOf, track } from '../../../../helpers/tools';

const StyledSide = styled(Box)`
  height: 100%;
  width: ${(props) =>
    props.isOpen
      ? props.theme.dimensions.openSideNavWidth
      : props.theme.dimensions.sideNavWidth};
  transition: width 0.2s ease-in;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;

  ${(props) =>
    props.isMobile &&
    `
    width: ${props.theme.dimensions.openSideNavWidth};
    transform: ${props.isOpen ? 'translateX(100%)' : 'translateX(-100%)'};
    transition: transform 0.2s ease-in;
  `}

  @media ${media.mediumUp} {
    position: relative;
  }

  /* override webflow styles :( */
  a {
    opacity: initial;
  }
`;

const StyledFixed = styled.div`
  position: fixed;
  height: 100vh;
  width: ${(props) =>
    props.isOpen
      ? props.theme.dimensions.openSideNavWidth
      : props.theme.dimensions.sideNavWidth};
  transition: width 0.2s ease-in;
`;

function SideNav(
  props: {
    isOpen: boolean,
    hasSideNav: boolean,
    toggle: Function,
    isMobile: boolean,
    authUser: AuthUser,
    isEnterpriseCustomer: boolean,
    isBuyer: boolean,
    isPhotographer: boolean,
    path: string,
    isBCG: boolean,
    isSeller: boolean,
  } & WithRouterProps
) {
  const [markNewsAsRead] = useMutation(MARK_NEWS_READ);
  const newsUnseenQuery = useQuery(GET_NEWS_UNSEEN, {
    ssr: false,
    skip: !props.isPhotographer,
  });

  const path = props.path || props.getPath();
  const sideNavItems = getSideNavItems({
    path,
    authUser: props.authUser,
    isBuyer: props.isBuyer,
    isPhotographer: props.isPhotographer,
    isSeller: props.isSeller,
    isEnterpriseCustomer: props.isEnterpriseCustomer,
    isBCG: props.isBCG,
  });

  const [productionLink, setProductionLink] = useState('');

  const user = {
    name: props.authUser?.name || props.authUser?.fullname,
    email: props.authUser?.email,
    thumbUrl: props.authUser?.thumbUrl,
  };

  // TODO: Iterate over all tasks and find the item
  // with the newest "updatedAt" field, maybe backend
  // can already provide us with this information right away?
  const getNewestTaskTimestamp = () => '133333337';
  const { toggle } = props;

  const toggleSideNav = (isOpen) => {
    // emit this event only on webflow pages
    // full React pages use the session fields
    if (routeMatchesOneOf(path, WEBFLOW_URLS)) {
      window.mitt.emit(TOGGLE_SIDENAV_PUBSUB_EVENT, {
        isOpen: !isOpen, // redux thunk transforms this later
      });
    }
    track({
      eventType: 'click_inbound',
      eventAction: isOpen ? 'collapse' : 'expand',
      eventLabel: '',
      eventName: 'access_sidebar_nav',
      eventPosition: path,
    });
    toggle({ isSideNavOpen: isOpen });
  };

  const onClose = () => {
    if (
      props.isPhotographer &&
      newsUnseenQuery.data?.news?.unseen > 0 &&
      Boolean(newsUnseenQuery.data?.news?.newestId)
    ) {
      return markNewsAsRead({
        variables: {
          newestNewsId: newsUnseenQuery.data.news.newestId,
          newestTaskTimestamp: getNewestTaskTimestamp(),
        },
      });
    }
    return false;
  };

  useEffect(() => {
    if (!props.authUser?.hasProductionAccess) return;
    const PRODUCTIONS_URL = `https://${PRODUCTIONS_HOSTNAME}`;
    function handleSetProductionLink() {
      // need to do this client-side since we still have no way of knowing about it server-side...
      setProductionLink(PRODUCTIONS_URL); // Still no way to know about http or https on both client and server...
    }

    function setupPrerender() {
      // Prefetch productions url since it's on a different subdomain.
      // To make transition feel snappier.
      const preloadLink = document.createElement('link');
      preloadLink.href = PRODUCTIONS_URL;
      preloadLink.rel = 'prerender';
      document.head.appendChild(preloadLink);
    }

    setupPrerender();
    handleSetProductionLink();
  }, [props.authUser]);

  useEffect(() => {
    function handleSidenavToggle(payload: { isOpen: boolean }) {
      return toggle({ isSideNavOpen: payload.isOpen });
    }

    function setupPubSub() {
      /* This is so we can dispatch open/close events between the
         separate nav and side-nav React apps on webflow pages.
      */
      window.mitt = mitt();
      window.mitt.on(TOGGLE_SIDENAV_PUBSUB_EVENT, handleSidenavToggle);
    }

    setupPubSub();
    return () => {
      window.mitt.off(TOGGLE_SIDENAV_PUBSUB_EVENT, handleSidenavToggle);
    };
  }, []);

  if (!props.hasSideNav) {
    return <></>;
  }

  if (!props.isPhotographer) {
    sideNavItems.primary.map((item) => {
      if (item.name === 'production') {
        item.href = props.authUser?.hasProductionAccess
          ? productionLink
          : '/production/book';
      }
      return item;
    });
  }

  return (
    <ThemeProvider>
      <StyledSide
        flex="0 0 auto"
        isOpen={props.isOpen}
        isMobile={props.isMobile}
        ml={props.isMobile ? `-${theme.dimensions.openSideNavWidth}` : '0'}>
        <StyledFixed isOpen={props.isOpen}>
          <SideNavigation
            key={path} // re-renders the sidenav when path changes, closes the submenus on navigation
            setIsOpen={() => toggleSideNav(props.isOpen)}
            isOpen={props.isOpen}
            menuContents={sideNavItems}
            moreMenuContent={getMoreMenuContent(props.isPhotographer)}
            accountMenuContent={getAccountMenuContent({
              isEnterpriseCustomer: props.isEnterpriseCustomer,
              isPhotographer: props.isPhotographer,
              isSeller: props.isSeller,
              isBuyer: props.isBuyer,
            })}
            isPhotographer={props.isPhotographer}
            close={() => toggleSideNav(true)}
            notificationsMenuContent={{
              feed: <Notifications />,
              wide: true,
              onClose: () => onClose(),
              menuTitle: 'Notifications',
            }}
            user={user}
            currentPath={path}
            isMobile={props.isMobile}
            isSeller={props.isSeller}
          />
        </StyledFixed>
      </StyledSide>
    </ThemeProvider>
  );
}

export default withRouter(SideNav);
