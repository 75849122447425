/* @flow */
import type { Node } from 'react';
import type { FieldProps } from 'react-final-form';
import { Textarea } from '@eyeem-ui/atoms';

type Props = {
  customProps?: any,
  disabled?: boolean,
  label?: string,
  labelSecondary?: Node,
  placeholder?: string,
  rows: number,
  maxLength?: number,
} & FieldProps;

function TextareaWrapper(props: Props) {
  return (
    <Textarea
      {...props.input}
      customProps={props.customProps}
      disabled={props.disabled}
      errorMessage={
        (props.meta.touched && props.meta.error) ?? props.meta.submitError
      }
      isValid={!props.meta.invalid && !props.meta.pristine}
      label={props.label}
      labelSecondary={props.labelSecondary}
      placeholder={props.placeholder}
      rows={props.rows}
      maxLength={props.maxLength}
    />
  );
}

TextareaWrapper.defaultProps = {
  customProps: null,
  disabled: false,
  label: null,
  placeholder: null,
};

export default TextareaWrapper;
