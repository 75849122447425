/* @flow */
/* eslint-env browser */

import values from 'lodash/values';
import has from 'lodash/has';
import isNull from 'lodash/isNull';
import range from 'lodash/range';
import isUndefined from 'lodash/isUndefined';
import pickBy from 'lodash/pickBy';
import get from 'lodash/get';
import startsWith from 'lodash/startsWith';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import format from 'date-fns/format';
import qs from 'qs';
import pathToRegexp from 'path-to-regexp';
import t from 'counterpart';
import deepMerge from 'deepmerge';

import { isIllustration, trackingTypeFromAssetType } from '../assets';
import sanitize from '../sanitize';
import {
  isEnterpriseCustomer,
  isPhotographer,
  isBuyer,
  isAdmin,
  isBCG,
} from '../accounts';
import { getUrlUnlocalized } from '../localization';

import {
  COLLECTIONS,
  COUNTRIES_WITH_BUYER_ACCESS,
  CURRENCIES,
  DEFAULT_LANGUAGE,
  DEVICE_OS_ANDROID,
  DEVICE_OS_IOS,
  DEVICE_TYPE_PHONE,
  EU_COUNTRIES,
  FROONT_URLS,
  GRID_STEP_SIZE,
  LICENSEID_SOCIAL,
  MARKET_DETAILS_MODE,
  MISSION_STATUS_COMPLETED,
  MISSION_STATUS_IN_PREPARATION,
  MISSION_STATUS_READY_TO_LAUNCH,
  MISSION_STATUS_RUNNING,
  MISSION_STATUS_SELECTING_WINNERS,
  PAGINATION_MAX_PAGE,
  PAGINATION_PARADIGM_AUTO,
  PAGINATION_PARADIGM_MANUAL,
  PHONE_NUMBERS_EYEEM,
  PHOTO_MARKET_STATUS_COMMERCIAL,
  PHOTO_MARKET_STATUS_EDITORIAL,
  PHOTO_MARKET_STATUS_ON_HOLD,
  PHOTO_MARKET_STATUS_REFUSED,
  PHOTO_MARKET_STATUS_UNKNOWN,
  PHOTO_PARTNER_STATUS_ON_SALE,
  PHOTO_PARTNER_STATUS_SELECTED,
  PHOTO_PARTNER_STATUS_SUBMITTED,
  PHOTO_RELEASE_STATUS_CLEARED,
  PHOTO_RELEASE_STATUS_NEEDED,
  PHOTO_RELEASE_STATUS_NOT_NEEDED,
  PHOTO_RELEASE_STATUS_REQUESTED,
  PHOTO_REVIEW_STATUS_REVIEWED,
  PRICEPOINT_TYPE_PACK,
  PRICEPOINT_TYPE_PRICE,
  PRODUCTIONS_DEVELOPMENT_URL,
  PRODUCTIONS_PRODUCTION_URL,
  PRODUCTIONS_STAGING_URL,
  PRODUCTIONS_STAGING_TESTING_URL,
  PRODUCTIONS_PRODUCTION_TEST_URL,
  RELEASE_STATUS_CLEARED,
  RELEASE_STATUS_REQUESTED,
  SEARCH_BASE_URL_CONST,
  STORELINK_EYEEM,
  WEBFLOW_URLS,
} from '../../constants/misc';

import { ASSET_TYPE, ASSET_TYPE_ILLUSTRATION } from '../../constants/assets';

import {
  ALBUM_PHOTOS_QUERY_ALIAS,
  SEARCH_QUERY_ALIAS,
  SINGLE_ILLUSTRATION_QUERY_ALIAS,
  SINGLE_PHOTO_QUERY_ALIAS,
} from '../../graphql/constants';

import type { IllustrationReview } from '../../helpers/illustrations';

import { compareAsStrings } from './compareAsStrings';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';

export * from './compareAsStrings';
export * from './ownAsset';
export * from './getAssetSourceUserId';

const getDevicePixelRatio = () => {
  // default ratio is 1
  let ratio = 1;

  // IE10 doesn't support devicePixelRatio but still could be retina
  if (
    window.screen.systemXDPI !== undefined &&
    window.screen.logicalXDPI !== undefined &&
    window.screen.systemXDPI > window.screen.logicalXDPI
  ) {
    // Only allow for values > 1
    ratio = window.screen.systemXDPI / window.screen.logicalXDPI;
  } else if (window.devicePixelRatio !== undefined) {
    // other browsers support devicePixelRatio
    ratio = window.devicePixelRatio;
  }

  return ratio;
};

export const getBrowserInfo = () => ({
  maxWidth: Math.max(window.screen.width, window.screen.height),
  devicePixelRatio: getDevicePixelRatio(),

  screenDimensions: {
    height: window.screen.height,
    width: window.screen.width,
  },
});

export const updateThumbnailInfo =
  (updatePhotos: Function) =>
  (fileObject: UploadPhoto, dataUrl?: string, additionalFields?: {} = {}) => {
    if (Object.keys(additionalFields).length) {
      updatePhotos({
        photosToUpdate: [fileObject.uuid],
        fields: additionalFields,
      });
    }

    const image = new Image();
    image.src = dataUrl;
    image.onload = () => {
      // We calculate the image ratio with 1 decimal precision
      const ratio = Math.round((1000 * image.width) / image.height) / 1000;
      const fields = {
        ratio,
        orientation: ratio < 1 ? 'portrait' : 'landscape',
        width: fileObject.width || image.width,
        height: fileObject.height || image.height,
      };
      if (dataUrl.indexOf('http') === -1) {
        fields.dataUrl = dataUrl;
      } else {
        fields.url = dataUrl;
      }

      updatePhotos({
        photosToUpdate: [fileObject.uuid],
        fields,
      });
    };
  };

export const handleInputEvent =
  (params: {
    stopPropagation?: boolean,
    preventDefault?: boolean,
    callback?: Function,
    payload?: mixed,
  }) =>
  (event: SyntheticInputEvent<HTMLElement>) => {
    if (params.stopPropagation) {
      event.stopPropagation();
    }
    if (params.preventDefault) {
      event.preventDefault();
    }
    if (params.callback) {
      params.callback(params.payload);
    }
  };

export const preventDefault = (
  params: ?{
    callback?: Function,
    payload?: mixed,
  }
) =>
  handleInputEvent({
    preventDefault: true,
    callback: params && params.callback,
    payload: params && params.payload,
  });

export const preventAndStop = (
  params: ?{
    callback?: Function,
    payload?: mixed,
  }
) =>
  handleInputEvent({
    preventDefault: true,
    stopPropagation: true,
    callback: params && params.callback,
    payload: params && params.payload,
  });

export const logoutAndRedirect = (redirectUrl: string) => {
  window.location = `/logout?redirect=${redirectUrl}`;
};

export const getPageFromOffsetAndLimit = (
  offset?: number | string,
  limit?: number | string
) => {
  if (typeof offset !== 'undefined' && typeof limit !== 'undefined') {
    return Math.floor(parseInt(offset, 10) / parseInt(limit, 10)) + 1;
  }
  return 1;
};

export const getCurrentPageFromUrl = (url) => {
  const urlParams = url.split('?');
  if (urlParams.length > 0) {
    const { page } = qs.parse(urlParams[1]);
    if (page) {
      return page;
    }
  }
  return false;
};

export const extractPIDFromPaginatable = (
  payload: PIDObject | Paginatable
) => ({
  resourceType: payload.resourceType,
  resourceId: payload.resourceId,
  paginatableName: payload.paginatableName,
  paginatableType: payload.paginatableType,
});

export const isPaginatableEmpty = (paginatable: PaginatableMinimal) =>
  paginatable?.total === 0;

export const isPaginatablePending = (paginatable: Paginatable) =>
  paginatable?.pending;

export const reachedMaxPageOffset = (paginatable: PaginatableMinimal) =>
  paginatable.offset + paginatable.limit >=
  PAGINATION_MAX_PAGE * paginatable.limit;

export const isLastPage = (paginatable: Paginatable) =>
  paginatable.total <= paginatable.offset + paginatable.limit ||
  paginatable?.items?.length >=
    (paginatable?.correctedTotal || paginatable?.total) ||
  paginatable?.total < paginatable?.limit;

export const hasNextPageLoaded = (
  paginatable: Paginatable,
  stepSize: number,
  page: number
) => paginatable.offset > stepSize * page;

export const findViaId = (
  array: Array<{ id: string | number }>,
  id: string | number
): Object | void => find(array, (item) => compareAsStrings(item?.id, id));

export const findIndexViaId = (
  array: Array<{ id: string | number }>,
  id: string | number
) => findIndex(array, (item) => compareAsStrings(item?.id, id));

export const isOneOf = (
  value: string | number,
  array: $ReadOnlyArray<string | number>
) => value && array?.indexOf(value) >= 0;

/* takes array of strings and returns true if one starts with the compareValue */
export const startsWithOneOf = (
  string: string,
  compareStrings: $ReadOnlyArray<string>
) =>
  string &&
  compareStrings &&
  compareStrings.some((compareString) => startsWith(string, compareString));

export const removeItemsFromArray = (
  source: $ReadOnlyArray<string>,
  toRemove: $ReadOnlyArray<string>
) =>
  source.filter((item) => {
    const arrayLowercased = toRemove.map((removeItem) =>
      removeItem.toLowerCase()
    );
    return item && !arrayLowercased.includes(item.toLowerCase());
  });

export const routeMatches = (string: string, route: string) =>
  route && pathToRegexp(route).test(getUrlUnlocalized(string.split('?')[0]));

export const routeMatchesOneOf = (
  string: string,
  routes: $ReadOnlyArray<string>
) => string && routes && routes.some((route) => routeMatches(string, route));

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);
export const capitalizeFirstLetterOfEachWord = (str: string) =>
  str.split(' ').map(capitalizeFirstLetter).join(' ');

/**
 * Converts untrustworthy characters into HTML entities
 * to prevent XSS attacks.
 * Example uses: injecting a query string into the DOM.
 * See: https://cheatsheetseries.owasp.org/cheatsheets/Cross_Site_Scripting_Prevention_Cheat_Sheet.html#rule-1---html-escape-before-inserting-untrusted-data-into-html-element-content
 *
 * @param {string} value
 * @returns {string}
 */
export const sanitizeString = (value: string): string => {
  const map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
  };
  const reg = /[&<>"']/gi;
  return value.replace(reg, (match) => map[`${match}`]);
};

export const getQueryFromUrl = (originalUrl: string) => {
  // strip language and client from the url
  let url = getUrlUnlocalized(originalUrl);
  if (url.indexOf('/client') === 0) {
    url = url.replace('/client', '');
  }
  if (url && startsWithOneOf(url, [SEARCH_BASE_URL_CONST, `/search/photos`])) {
    const queryParamsFromUrl = qs.parse(url.split('?')[1]);
    const hasQueryInPath = url.split('/').length === 4;
    const queryfromParams = queryParamsFromUrl?.q;
    if (hasQueryInPath) {
      // remove ? and extract query
      const query = url.split('?')[0].split('/')[3];
      return sanitize(decodeURIComponent(query));
    }
    if (queryfromParams) {
      return sanitize(decodeURIComponent(queryfromParams));
    }
  }

  return false;
};

export const sortObjectKeys = (object: Object) => {
  let sortedObject = {};
  Object.keys(object)
    .sort()
    .forEach((key) => {
      sortedObject = {
        ...sortedObject,
        [key]: object[key],
      };
    });
  return sortedObject;
};

/**
 * Get the path for requesting data for client side rendering
 * @uses window
 * @returns {string}
 */
export const getClientPath = () => {
  let { clientPort, hostname } = get(window, 'eyeconfig.server');
  const clientPath = get(window, 'eyeconfig.clientPath', '/client');
  return `//${hostname}${clientPort}${clientPath}`;
};

export const reverseString = (string: string) =>
  string.split('').reverse().join('');

/**
 * @param {string} path route to split
 * @returns {string} a class safe string
 */
export const getBodyClass = (path: string) => {
  // set body class to tracking name
  const split = path.split('?')[0].replace(/^\//, '').split('/');
  const classes = [];

  for (let i = 0; i < split.length; i += 1) {
    const name = split[i];

    if (i > 0) {
      classes.push(`${split[i - 1]}-${name}`);
    } else {
      classes.push(name);
    }
  }
  return classes.join(' ');
};

/**
 * Abbreviate a number and add a letter for
 * if the number is too great
 * @param {number} num
 * @returns {number|string}
 */
export const abbreviate = (num: number) => {
  if (num >= 1000000) {
    return `${Math.round(num / 100000) / 10}m`;
  }
  if (num >= 1000) {
    return `${Math.round(num / 100) / 10}k`;
  }
  return num;
};

/**
 * Remove bad white space characters
 * @param {object} data
 * @returns {string}
 */
export const stripIllegalTokens = (data: Object | string) => {
  const dataString = typeof data !== 'string' ? JSON.stringify(data) : data;
  return dataString.replace(/\u2028/g, '\\u2028').replace(/\u2029/g, '\\u2029');
};

export const escapeJson = (string: string) => string.replace(/<\//g, '<\\/');

// https://codereview.stackexchange.com/a/153702
export const escapeRegExp = (string: string) =>
  string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

/**
 * Shamelessly stolen from
 * http://jsperf.com/number-format
 * @param {number} number number to convert
 * @returns {string} spaces between thousand (123123 -> 123 123)
 */
export const separateThousands = (number: number) => {
  const separator = ',';
  const string = Math.max(0, number).toFixed(0);
  const { length } = string;
  const end = /^\d{4,}$/.test(string) ? length % 3 : 0;
  return (
    (end ? string.slice(0, end) + separator : '') +
    string.slice(end).replace(/(\d{3})(?=\d)/g, `$1${separator}`)
  );
};

export const keyFor = (type: string) => {
  if (type === 'user') {
    return 'nickname';
  }
  return 'id';
};

export const getResourceInMiddleware = (data) => {
  if (
    data.requestInfo?.type &&
    data.requestInfo?.resource &&
    isOneOf(data.requestInfo.type, [
      'album',
      'blog',
      'collection',
      'illustration',
      'mission',
      'photo',
      'release',
      'search',
      'user',
    ])
  ) {
    // this works with the normalized data structures
    // minimum requirements:
    // { requestInfo: {type: 'album',resource: albumId},
    //   resource: response.data } <-- contains full EyeEmAlbum
    return find(data[data.requestInfo.type], (item) => {
      let resource = item.id;
      // users need special treatment here
      if (data.requestInfo.type === 'user') {
        resource = item.nickname;
      }
      return resource && compareAsStrings(resource, data.requestInfo.resource);
    });
  }

  return false;
};

// Creates an array like ['0','1','2',...]
// of a given length that can be
// used for mapping in JSX.
// Since we cannot iterate over objects
// in our translation files, this is the
// preferred method to iterate over a set
// amount of strings in the translation json.
export const mapArray = (length: number) => {
  const response = [];
  for (let i = 0; i < length; i += 1) {
    response.push(`${i}`);
  }
  return response;
};

export const validateEmail = (email: string) => {
  // eslint-disable-next-line max-len
  const re =
    /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.([a-z][a-z]+)|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
  return re.test(email);
};

// expects IOS or ANDROID, or returns the ios link
export const getStoreLink = (os: MobileOS) => {
  const lowerCaseOS = (os || DEVICE_OS_IOS).toLowerCase();
  let key: 'iOS' | 'Android';

  switch (lowerCaseOS) {
    case 'android':
      key = DEVICE_OS_ANDROID;
      break;
    case 'ios':
    default:
      key = DEVICE_OS_IOS;
  }

  return STORELINK_EYEEM[key];
};

/**
 * Call when attempting to open a link in the ios app.
 * Works by creating a timeout that will only fire if the
 * open in app failed.
 */
export const handleAppOpenFailure = (os: MobileOS) =>
  setTimeout(() => {
    window.location.href = getStoreLink(os);
  }, 1000);

// truncates a string if it exceeds a max length,
// but avoids cutting the string in the middle of a wor
export const truncateBeforeFirstWordThatExceeds = (
  maxLength: number,
  input: string
) => {
  let outputString;
  if (input && input.length > maxLength) {
    outputString = input.substr(0, maxLength);
    const lastSpaceIndex = outputString.lastIndexOf(' ');
    if (lastSpaceIndex > 0) {
      outputString = outputString.substr(0, lastSpaceIndex);
    }
  }
  return outputString || input;
};

/**
 * Creates an object composed of the object properties predicate is not an empty string, undefined or * null.
 */
export const cleanObject = (object: Object) =>
  pickBy(
    object,
    (element: any) =>
      element !== '' && !isUndefined(element) && !isNull(element)
  );

export const getChunkedArray = (arr: $ReadOnlyArray<any>, chunkSize: number) =>
  range(Math.ceil(arr.length / chunkSize)).map((__, i) =>
    arr.slice(i * chunkSize, i * chunkSize + chunkSize)
  );

/**
 * Get the tracking data for a cart item
 * @param asset that pertains to cart item
 * @param cartItem
 * @returns {object}
 */
export const productTracking = ({
  asset,
  cartItem,
}: {
  asset?: EyeEmAsset,
  cartItem: EyeEmCartItemPhoto | EyeEmCartItemDeal,
}): TrackingProduct => {
  const product: TrackingProduct = {
    id: cartItem.id,
    quantity: 1,
  };

  // for packs
  if (cartItem.dealType === 'PACKAGE') {
    product.price = (cartItem.fullPrice / 100).toFixed(2);
    product.variant = cartItem.licenseType.name;
    product.category =
      cartItem.premiumPrice === -1
        ? COLLECTIONS.ESSENTIAL
        : COLLECTIONS.PREMIUM;
    product.brand = 'not_set';
  }

  // for assets
  if (asset) {
    if (cartItem.selectedLicense) {
      product.price =
        cartItem.selectedLicense.pricePoints?.type === PRICEPOINT_TYPE_PACK
          ? '0.00'
          : (cartItem.selectedLicense.pricePoints?.price / 100).toFixed(2);
      product.variant = cartItem.selectedLicense.name;
    } else {
      product.variant = 'not_available';
    }

    product.brand =
      asset.user?.nickname ||
      cartItem.photo?.user?.nickname ||
      cartItem.photo?.user ||
      asset.user;

    product.category = isPremiumPhoto(asset)
      ? COLLECTIONS.PREMIUM
      : COLLECTIONS.ESSENTIAL;

    product.name = trackingTypeFromAssetType(asset.type);
  }

  return product;
};

export const getEcommerceEvent = (payload: {
  cartPhotos: EyeEmCartItemPhoto[],
  cartDeals: EyeEmCartItemDeal[],
  actionFields: {},
}) => {
  let products = payload.cartPhotos.map((cartItem) =>
    productTracking({ cartItem, asset: cartItem.photo })
  );

  // reformat cartDeals object to not have prices in cent
  payload.cartDeals &&
    payload.cartDeals.forEach((deal) =>
      products.push({
        id: deal?.id,
        variant: deal?.licenseType?.name,
        quantity: 1,
        price: (deal?.fullPrice / 100).toFixed(2),
        category: deal.dealType,
      })
    );

  return {
    checkout: {
      actionField: payload.actionFields,
      products,
    },
  };
};

export const hasCartItems = (cart: {
  photos: EyeEmCartItemPhoto[],
  deals: EyeEmCartItemDeal[],
}) =>
  Boolean([...(cart?.photos || [])].length + [...(cart?.deals || [])].length);

export const licenseDimensions = (
  width: number,
  height: number,
  licenseId: number
) => {
  let restrictedWidth;
  let restrictedHeight;
  if (width > height) {
    restrictedWidth = compareAsStrings(licenseId, LICENSEID_SOCIAL)
      ? Math.min(1200, width)
      : width;
    restrictedHeight = Math.round((height * restrictedWidth) / width);
  } else {
    restrictedHeight = compareAsStrings(licenseId, LICENSEID_SOCIAL)
      ? Math.min(1200, height)
      : height;
    restrictedWidth = Math.round((width * restrictedHeight) / height);
  }

  const dpi = licenseId === LICENSEID_SOCIAL ? 72 : 300;

  const inch = 2.54; // in cm
  const cmWidth = (restrictedWidth / dpi) * inch;
  const cmHeight = (restrictedHeight / dpi) * inch;

  return {
    width: restrictedWidth,
    height: restrictedHeight,
    dpi,
    cmWidth: Math.round(cmWidth * 100) / 100,
    cmHeight: Math.round(cmHeight * 100) / 100,
  };
};

export const getPositionOffsetInJustifiedRow = (
  rows: $ReadOnlyArray<$ReadOnlyArray<EyeEmAssetId>>,
  rowIndex: number,
  additionalOffset: number = 0
) => {
  let offset = 0;
  for (let i = 0; i < rowIndex; i += 1) {
    offset += rows[i].length;
  }
  return offset + additionalOffset;
};

// Check whether the page is scrolled far enough to add elements to bottom?
// Function taken from infiniteScroll jQuery plugin (https://github.com/holtonma/infini_scroll)
export const levelReached = () => {
  // TODO: This is weird, is the "Math.max()" needed if we just have an or in there?
  const pageHeight = Math.max(
    document.body.scrollHeight || document.body.offsetHeight
  );

  const viewportHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight ||
    0;

  const scrollHeight =
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    0;

  // true if scroll position within 5 * viewportheight from page bottom
  return pageHeight - viewportHeight - scrollHeight < 5 * viewportHeight;
};

export const findIndexInArray = (
  array: $ReadOnlyArray<any> = [],
  element: any,
  key?: string
): number => {
  let index;
  if (key) {
    const selectObj = {};
    selectObj[key] = element;
    index = findIndex(array, selectObj);
    if (index === -1) {
      selectObj[key] = `${element}`;
      index = findIndex(array, selectObj);
    }
    if (index === -1) {
      selectObj[key] = parseInt(element, 10);
      index = findIndex(array, selectObj);
    }
  } else {
    index = array.indexOf(element);
    if (index === -1) {
      index = array.indexOf(`${element}`);
    }
    if (index === -1) {
      index = array.indexOf(parseInt(element, 10));
    }
  }
  return index;
};

export const deleteFromArray = (
  array: $ReadOnlyArray<any> = [],
  element: any,
  key?: string
): $ReadOnlyArray<any> => {
  const index = findIndexInArray(array, element, key);

  if (index >= 0) {
    return array.slice(0, index).concat(array.slice(index + 1));
  }
  return array;
};

export const addToArray = (
  array: $ReadOnlyArray<any>,
  element: any
): $ReadOnlyArray<any> => [element].concat(array);

export const updateInArray = (
  array: $ReadOnlyArray<any>,
  element: any,
  replaceElement: any,
  key?: string
): $ReadOnlyArray<any> => {
  const index = findIndexInArray(array, element, key);

  return array
    .slice(0, index)
    .concat(replaceElement)
    .concat(array.slice(index + 1));
};

export const getAssetSourceNickname = (asset: EyeEmAsset): ?string =>
  has(asset, 'user.nickname')
    ? get(asset, 'user.nickname')
    : get(asset, 'user');

export const ownPhoto = (
  photo: EyeEmPhoto,
  authUserNickname: string
): boolean => {
  const nickname = getAssetSourceNickname(photo);
  return compareAsStrings(nickname, authUserNickname);
};

export const isAuthUserFollowingUser = (user: EyeEmUser) =>
  get(user, 'following');

const testRoutes = (link, routes): boolean => {
  if (routeMatchesOneOf(link, routes)) {
    return true;
  }
  if (startsWithOneOf(link, ['http://', 'https://'])) {
    return true;
  }
  return false;
};

export const isWebflowUrl = (params: {
  link: string,
  user?: AuthUser,
}): boolean => {
  const routes = [
    ...WEBFLOW_URLS,
    isEnterpriseCustomer(params.user) ? false : '/',
  ];
  return testRoutes(params.link, routes);
};

export const isLinkExternal = (link: string, user?: AuthUser): boolean => {
  const routes = [
    '/logout',
    ...FROONT_URLS,
    ...WEBFLOW_URLS,
    isEnterpriseCustomer(user) ? false : '/',
  ];
  return testRoutes(link, routes);
};

export const getHomeUrl = (params: {
  authUser: AuthUser,
  queryParams?: {},
}) => {
  const url = isPhotographer(params.authUser) ? '/following' : '/';
  return `${url}${qs.stringify(params.queryParams, { addQueryPrefix: true })}`;
};

export const getMissionLandingUrl = (params: { authUser?: AuthUser }) =>
  isPhotographer(params.authUser) ? '/missions/photographers' : '/missions';

const missionStateOrder = [
  MISSION_STATUS_IN_PREPARATION,
  MISSION_STATUS_READY_TO_LAUNCH,
  MISSION_STATUS_RUNNING,
  MISSION_STATUS_SELECTING_WINNERS,
  MISSION_STATUS_COMPLETED,
];

export const isPastMissionStatus = (
  mission: EyeEmMission,
  compareState: MissionStatus
) =>
  missionStateOrder.indexOf(mission.status) >
  missionStateOrder.indexOf(compareState);

export const isMissionRunning = (mission: EyeEmMission) =>
  mission && mission.status === MISSION_STATUS_RUNNING;
export const isMissionSelectingWinners = (mission: EyeEmMission) =>
  mission && mission.status === MISSION_STATUS_SELECTING_WINNERS;
export const isMissionCompleted = (mission: EyeEmMission) =>
  mission && mission.status === MISSION_STATUS_COMPLETED;
export const isMarketMission = (mission: EyeEmMission) =>
  mission && mission.marketOnly === true;

export const isMobile = (deviceType: string) =>
  deviceType === DEVICE_TYPE_PHONE;

export const isReviewed = (asset: EyeEmAsset) =>
  compareAsStrings(
    get(asset, 'market.reviewStatus'),
    PHOTO_REVIEW_STATUS_REVIEWED
  );

export const isEditorial = (asset: EyeEmAsset) =>
  get(asset, 'market.status') === PHOTO_MARKET_STATUS_EDITORIAL;
export const isCommercial = (asset: EyeEmAsset) =>
  get(asset, 'market.status') === PHOTO_MARKET_STATUS_COMMERCIAL;
export const isLicensable = (asset: EyeEmAsset) => {
  switch (asset.type) {
    case ASSET_TYPE.PHOTOS: {
      return get(asset, 'market.isLicensable') === true;
    }
    case ASSET_TYPE.ILLUSTRATIONS: {
      return get(asset, 'isLicensable') === true;
    }
    default: {
      return false;
    }
  }
};

export const isAvailableOnMarket = (asset: EyeEmAsset) =>
  isCommercial(asset) || isEditorial(asset) || isLicensable(asset);

export const isAvailableAndReviewed = (asset: EyeEmAsset) =>
  isAvailableOnMarket(asset) && isReviewed(asset);
export const isForSale = (asset: EyeEmAsset) =>
  asset.market?.collection &&
  isAvailableOnMarket(asset) &&
  isReviewed(asset) &&
  isCommercial(asset);

export const isSelectedForPartner = (asset: EyeEmAsset) =>
  compareAsStrings(
    get(asset, 'partnerStatus.premium'),
    PHOTO_PARTNER_STATUS_SELECTED
  ) ||
  compareAsStrings(
    get(asset, 'partnerStatus.premium'),
    PHOTO_PARTNER_STATUS_SUBMITTED
  );
export const isPartner = (asset: EyeEmAsset) =>
  compareAsStrings(
    get(asset, 'partnerStatus.premium'),
    PHOTO_PARTNER_STATUS_ON_SALE
  );

export const isMarketStatusPending = (asset: EyeEmAsset) =>
  get(asset, 'market.status') === PHOTO_MARKET_STATUS_UNKNOWN;
export const isRefused = (asset: EyeEmAsset) =>
  get(asset, 'market.status') === PHOTO_MARKET_STATUS_REFUSED;
export const isOnHold = (asset: EyeEmAsset) =>
  get(asset, 'market.status') === PHOTO_MARKET_STATUS_ON_HOLD;

export const isAlreadyPurchased = (asset: EyeEmAsset): boolean =>
  !!get(asset, 'market.licensing.purchased') ||
  !!get(asset, 'purchasedLicense');

export const isReleaseRequested = (release: EyeEmRelease) =>
  compareAsStrings(get(release, 'status'), RELEASE_STATUS_REQUESTED);
export const isReleaseCleared = (release: EyeEmRelease) =>
  compareAsStrings(get(release, 'status'), RELEASE_STATUS_CLEARED);

const PARTNER_SOURCE_NICKNAMES = [
  'maskot_agency',
  'cavan_agency',
  'HEX_agency',
  'johner_agency',
  'westend61_agency',
  'addictive_stock',
];
export const isPartnerSourcedAsset = (asset: EyeEmAsset): boolean => {
  const nickname = getAssetSourceNickname(asset);
  return PARTNER_SOURCE_NICKNAMES.includes(String(nickname));
};

export const getReleaseLink = (release: EyeEmRelease) => {
  if (release.multiReleaseId) {
    return `https://www.eyeem.com/multireleases/${release.multiReleaseId}`;
  }
  return release.link;
};

export const allReleasesClear = (
  photo: EyeEmPhoto,
  releaseType: 'MODEL' | 'PROPERTY'
) =>
  compareAsStrings(
    get(photo, `market.${releaseType.toLowerCase()}Status`),
    PHOTO_RELEASE_STATUS_CLEARED
  ) ||
  compareAsStrings(
    get(photo, `market.${releaseType.toLowerCase()}Status`),
    PHOTO_RELEASE_STATUS_NOT_NEEDED
  );

export const getOverallReleaseStatus = (photo: EyeEmPhoto) => {
  const modelStatus = get(photo, 'market.modelStatus');
  const propertyStatus = get(photo, 'market.propertyStatus');

  if (
    compareAsStrings(PHOTO_RELEASE_STATUS_NEEDED, modelStatus) ||
    compareAsStrings(PHOTO_RELEASE_STATUS_NEEDED, propertyStatus)
  ) {
    return PHOTO_RELEASE_STATUS_NEEDED;
  }
  if (
    compareAsStrings(PHOTO_RELEASE_STATUS_REQUESTED, modelStatus) ||
    compareAsStrings(PHOTO_RELEASE_STATUS_REQUESTED, propertyStatus)
  ) {
    return PHOTO_RELEASE_STATUS_REQUESTED;
  }
  if (
    compareAsStrings(PHOTO_RELEASE_STATUS_CLEARED, modelStatus) ||
    compareAsStrings(PHOTO_RELEASE_STATUS_CLEARED, propertyStatus)
  ) {
    return PHOTO_RELEASE_STATUS_CLEARED;
  }
  return PHOTO_RELEASE_STATUS_NOT_NEEDED;
};

export const getMarketDetailsMode = ({
  asset,
  isAuthenticated,
  isSeller,
  ownAsset,
  review,
}: {
  asset: EyeEmAsset,
  isAuthenticated: boolean,
  isSeller: boolean,
  ownAsset: boolean,
  review?: IllustrationReview | null,
}) => {
  if (!isAuthenticated) {
    if (!isAvailableOnMarket(asset) || !isForSale(asset)) {
      return MARKET_DETAILS_MODE.NOT_FOR_SALE;
    }
    return MARKET_DETAILS_MODE.FOREIGN_ASSET_ON_SALE;
  }
  if (isMarketStatusPending(asset)) {
    return MARKET_DETAILS_MODE.PENDING;
  }
  if (ownAsset) {
    if (isIllustration(asset)) {
      if (typeof review === 'undefined') {
        return null; // review query might not be resolved
      } else if (review === null) {
        return MARKET_DETAILS_MODE.ILLUSTRATION.IN_REVIEW; // illustration is not reviewed yet
      } else if (review.status === 'Accepted') {
        return MARKET_DETAILS_MODE.ILLUSTRATION.ON_SALE;
      } else {
        switch (review.value) {
          case 'LOW_COMMERCIAL_VALUE':
            return MARKET_DETAILS_MODE.ILLUSTRATION.NOT_ON_SALE
              .LACK_OF_MARKET_DEMAND;
          case 'SIMILARITY':
            return MARKET_DETAILS_MODE.ILLUSTRATION.NOT_ON_SALE
              .IMAGE_SIMILARITY;
          case 'TERMS_OF_SERVICE_VIOLATION':
            return MARKET_DETAILS_MODE.ILLUSTRATION.NOT_ON_SALE
              .TERMS_OF_SERVICE;
          case 'WATERMARKS':
            return MARKET_DETAILS_MODE.ILLUSTRATION.NOT_ON_SALE.WATERMARKS;
          default:
            // This fallback condition should never be triggered
            console.error('Unknown review status:', JSON.stringify(review));
            return MARKET_DETAILS_MODE.ILLUSTRATION.IN_REVIEW;
        }
      }
    }
    if (!isSeller) {
      return MARKET_DETAILS_MODE.JOIN_AS_SELLER;
    }
    if (isRefused(asset)) {
      return MARKET_DETAILS_MODE.REFUSED;
    }
    if (isOnHold(asset)) {
      return MARKET_DETAILS_MODE.ON_HOLD;
    }
    if (!isAvailableOnMarket(asset)) {
      return MARKET_DETAILS_MODE.ADD_TO_MARKET;
    }
    return MARKET_DETAILS_MODE.OWN_PHOTO_ON_SALE;
  }
  if (isLicensable(asset)) {
    return MARKET_DETAILS_MODE.FOREIGN_ASSET_ON_SALE;
  } else if (isAlreadyPurchased(asset)) {
    return MARKET_DETAILS_MODE.PURCHASED;
  } else {
    return MARKET_DETAILS_MODE.NOT_FOR_SALE;
  }
};

export const getDefaultPaginationParadigm = (user?: AuthUser) =>
  isBuyer(user) ? PAGINATION_PARADIGM_MANUAL : PAGINATION_PARADIGM_AUTO;

export const trackingEventData = ({
  eventName,
  eventAction,
  eventLabel,
  eventPosition,
  eventValue,
  eventPositionIndex,
  eventOption: eventOptionArg,
  nonInteraction,
  eventAssetType,
  eventType,
  ecommerce,
}: FlatTrackingData) => {
  let eventOption;
  if (
    eventOptionArg &&
    (typeof eventOptionArg === 'string' || Array.isArray(eventOptionArg))
  ) {
    eventOption = eventOptionArg;
  }

  if (
    eventOptionArg &&
    typeof eventOptionArg === 'object' &&
    Object.keys(eventOptionArg).length > 0 &&
    !Array.isArray(eventOptionArg)
  ) {
    eventOption = qs.stringify(sortObjectKeys(eventOptionArg));
  }

  // TRACKING
  const eventInfo = {
    ...(typeof eventName !== 'undefined' && { eventName }),
    ...(typeof eventAction !== 'undefined' && { eventAction }),
    ...(typeof eventLabel !== 'undefined' && { eventLabel }),
    ...(typeof eventPosition !== 'undefined' && { eventPosition }),
    ...(typeof eventValue !== 'undefined' && { eventValue }),
    ...(typeof eventPositionIndex !== 'undefined' && { eventPositionIndex }),
    ...(typeof nonInteraction !== 'undefined' && { nonInteraction }),
    ...(typeof eventOption !== 'undefined' && { eventOption }),
    eventAssetType,
  };

  const trackingObject = {
    event: eventType,
    eventInfo,
    ecommerce,
  };

  return cleanObject(trackingObject);
};

export const track = (flatTrackingData: FlatTrackingData) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    if (!Object.keys(flatTrackingData).includes('eventAssetType')) {
      flatTrackingData.eventAssetType = undefined;
    }

    window.dataLayer.push(trackingEventData(flatTrackingData));
  }
};

type HTMLNodeDataset = {
  eventname: string,
  eventposition: string,
};

/* NOTE: Finds components that were imported externally
         (like from @eyeem-ui/organisms), and attaches tracking listeners.
         It gathers data attributes like `data-eventname`, `data-eventposition`, etc,
         and sends their values along as a tracking event to the dataLayer.
*/
export const addExtraTrackingListeners = () => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    // These elements will also contain the other `data-` properties.
    const elements = [...document.querySelectorAll('[data-eventname]')];

    const sendGatheredInfo = (element) => {
      // The subNav items on the sidebar do not get the eventlistener.
      // we have to retriger the function on click
      setTimeout(() => {
        addExtraTrackingListeners();
      }, 100);
      const dataset: HTMLNodeDataset = { ...element.dataset };

      // Map all-lowercase events from data-attributes to names like eventPosition, etc
      const shapedTrackingObject = Object.keys(dataset).reduce((acc, curr) => {
        const eventName = curr.split('event')[1];
        if (eventName) {
          const capitalized =
            eventName.charAt(0).toUpperCase() + eventName.slice(1);
          acc[`event${capitalized}`] = dataset[curr]; // eslint-disable-line
        }

        return acc;
      }, {});

      if (!shapedTrackingObject.eventType) {
        shapedTrackingObject.eventType = t('tracking.eventType.inbound');
      }

      return track(shapedTrackingObject);
    };

    // adding the 'tracking' attribute to avoid setting the eventlistener twice on the same element
    const attachTracking = (element) => {
      element.setAttribute('tracking', 'true');
      element.addEventListener('click', () => sendGatheredInfo(element));
    };

    elements.forEach((element) => {
      if (element.getAttribute('tracking')) {
        return;
      }
      return attachTracking(element);
    });
  }
};

export const merge = (
  oldArray: $ReadOnlyArray<any>,
  newArray: $ReadOnlyArray<any>,
  key: ?string = 'id'
) => {
  const tempObj = oldArray.concat(newArray).reduce((accumulated, element) => {
    // Payload elements come after the elements already found in the state.
    // If a payload element doesn't exist yet, it just gets added
    const currentState = accumulated[element[key]];

    let newElementState;
    if (currentState) {
      newElementState = {
        ...accumulated[element[key]],
        ...element,
      };
    } else {
      newElementState = element;
    }

    return {
      ...accumulated,
      [element[key]]: newElementState,
    };
  }, {});
  // reduce returned an object, so we turn it back
  // into an array before returning it as new state
  return values(tempObj);
};

export const stripUrlParameters = (path: string) => path && path.split('?')[0];

export const getCompDownloadUrl = (asset: EyeEmAsset) =>
  `https://cdn.eyeem.com/thumb/${getThumbFilename(asset.previewUrl)}/comp`;

export const timeLeft = (timeStamp: number) => formatDistanceToNow(timeStamp);

export const userFriendlyDate = (
  timeStamp: number | string,
  dateFormat?: string
) => format(new Date(timeStamp), dateFormat);

export const isFacebookPhoto = (url: string) =>
  url.indexOf('graph.facebook.com') > -1;

/**
 * Pins every given url to https
 * @param {string|*} url
 * @returns {string|*}
 */
export const httpsifyUrl = (url: string) => {
  if (typeof url === 'string') {
    return url.replace(/^(https?):\/\//, 'https://');
  }
  return url;
};

/**
 * Click inside calculations
 * @param event, elementClasses
 * @returns {boolean}
 */
export const isClickInsideElement = (
  event: SyntheticInputEvent<HTMLElement>,
  elementClasses: $ReadOnlyArray<string>
) => {
  if (elementClasses.length) {
    const target = event.target || event.srcElement;
    // console.log('elementClasses', elementClasses, 'target.className', target.className);
    return new RegExp(elementClasses.join('|')).test(target.className);
  }
  return false;
};

export const isStatusOk = (statusCode: number) => statusCode < 400;

export const getSrcSetUrl = (
  url: string,
  breakpoint: string,
  devicePixelRatio: number
) => {
  // add breakpoint suffix to image name, so test.jpg -> test-large.jpg

  // regex to get url's file extension (including dot)
  const search = /(\..*$)/;

  // build replacement by adding breakpoint name to url
  let replacement = `-${breakpoint}`;

  // if a retina device and no svg then add the @2x suffix
  if (url.match(search)[0] !== '.svg' && devicePixelRatio >= 2) {
    replacement += '@2x';
  }

  // add the file extension to the end
  replacement += '$1';

  return url.replace(search, replacement);
};

// only used in viewRouter
export const dispatchReceiveClientDataEvent = () => {
  let clientDataEvent;
  if (typeof Event === 'function') {
    clientDataEvent = new Event('receiveClientData');
  } else {
    // ie polyfill
    clientDataEvent = document.createEvent('Event');
    clientDataEvent.initEvent('receiveClientData', false, false);
  }
  document.dispatchEvent(clientDataEvent);
};

// makes sure the basic conversionEventOption contains photoId and gridIndex
export const enrichConversionEventOption = (
  conversionEventOption: ConversionEventOption,
  asset: EyeEmAsset,
  gridIndex?: number
) => {
  if (asset.type === ASSET_TYPE_ILLUSTRATION) {
    return cleanObject({
      ...conversionEventOption,
      illustrationId: asset?.id,
      positionIndex: gridIndex,
    });
  }
  return cleanObject({
    ...conversionEventOption,
    photoId: asset?.id,
    positionIndex: gridIndex,
  });
};

export const isCheckoutBlocked = (countryIso, isRecurringBuyer) =>
  COUNTRIES_WITH_BUYER_ACCESS.indexOf(countryIso) === -1 && !isRecurringBuyer;

export const getCurrencyByCountryIso = (countryIso: string) => {
  if (countryIso === 'GB') {
    return 'GBP';
  }
  if (EU_COUNTRIES.indexOf(countryIso) !== -1) {
    return 'EUR';
  }
  return 'USD';
};

export const isArrayUnequal = (
  nextArray: $ReadOnlyArray<string | number>,
  array: $ReadOnlyArray<string | number>
) =>
  nextArray.length !== array.length ||
  nextArray.some((value, index) => value !== array[index]);

export const getPhoneNumberByCountryIso = (countryIso: string) => {
  if (EU_COUNTRIES.indexOf(countryIso) !== -1 || countryIso === 'CH') {
    return PHONE_NUMBERS_EYEEM.eu;
  }
  return PHONE_NUMBERS_EYEEM.world;
};

export const isClientUrl = (baseUrl: string) => baseUrl === '/client';

// TODO: this should be refactored to a type predicate (aka type guard) to make sure the type system knows the correct type of the argument when the function returns
// https://stackoverflow.com/questions/49063521/flowjs-howto-type-predicate-functions
// https://www.typescriptlang.org/docs/handbook/advanced-types.html
export const isNumber = (number: string | number) => {
  // eslint-disable-next-line
  const isnan = isNaN(parseFloat(number));
  // eslint-disable-next-line
  const isfinite = isFinite(number);
  return !isnan && isfinite;
};

export const isStyleSquare = (style: string | number) =>
  style === 'square' || style === 'circle' || style === 'sq';

/* Sample URLs:
  * 'http://cdn.eyeem.com/thumb/6a9c5531977fe974b539ce2584bfdfde562ff147-1405498401/640/480'
  * 'http://cdn.eyeem.com/thumb/6a9c5531977fe974b539ce2584bfdfde562ff147-1405498401/w/1200'
  * 'http://cdn.eyeem.com/thumb/6a9c5531977fe974b539ce2584bfdfde562ff147-1405498401/h/1200'
  * 'http://cdn.eyeem.com/thumb/6a9c5531977fe974b539ce2584bfdfde562ff147-1405498401/sq/1200'
  * To get the filename, we remove everything before the first slash and any URL parameters
  * url split ->
  *    ["http:", "", "cdn.eyeem.com", "thumb",
          "6a9c5531977fe974b539ce2584bfdfde562ff147-1405498401", "sq", "1200"]
*/
export const getThumbFilename = (url: string) => url && url.split('/')[4];

export const validateOnBlurOnly = (params: { trigger: string }) =>
  params.trigger === 'blur';

export const toggleSelectionIds = (params: {
  map: $ReadOnlyArray<string>,
  id: string,
}) => {
  if (params.map.indexOf(params.id) !== -1) {
    return params.map.filter((selectionItem) => selectionItem !== params.id);
  }
  return [...params.map, params.id];
};

export const getWatermarkedPhotoUrl = (
  photo: EyeEmPhoto,
  user: AuthUser,
  preventWatermarks?: boolean
) =>
  !preventWatermarks &&
  !isPhotographer(user) &&
  isAvailableOnMarket(photo) &&
  !isAdmin(user)
    ? photo.previewUrlPublic
    : photo.previewUrl;

export const isEuIP = (countryIso: string) =>
  EU_COUNTRIES.indexOf(countryIso) !== -1;

export const getSocialSettingsUrl = (user: AuthUser) =>
  isEnterpriseCustomer(user) && !isBCG(user)
    ? '/settings/integrations'
    : '/settings/services';

export const blogHeaderImageUrl = (header) => {
  if (header && header.fileId) {
    return `https://cdn.eyeem.com/thumb/${header.fileId}/640/480`;
  }
  return header && header.imageUrl;
};

export const isPremiumPhoto = (photo: EyeEmAsset) =>
  photo?.market?.collection === COLLECTIONS.PREMIUM;

/**
 * @returns true if downloads left for selected photo
 */
export const hasDownloadsRemainingForPhoto = (
  licenses?: AvailableLicense[]
) => {
  if (!licenses) return false;

  const isRemainingForPricepoint = (item) => {
    if (!item) return false;
    if (item.type === PRICEPOINT_TYPE_PRICE) return true;
    if (item.type === PRICEPOINT_TYPE_PACK) {
      // Unlimited
      if (item.remaining === -1) return true;
      // Counters remaining
      if (item.remaining > 0) return true;

      return false;
    }

    return false;
  };

  return licenses.some((license) => {
    return isRemainingForPricepoint(license.payment);
  });
};

export const getSelectedLicenseForPhoto = (
  photo: EyeEmPhoto,
  licensingOptions: LicensingOption[],
  licenseId: number
) => {
  const selectedLicense = find(
    licensingOptions,
    (item) => item.id === licenseId
  );
  const selectedPricePoint = isPremiumPhoto(photo)
    ? {
        ...selectedLicense,
        pricePoints: selectedLicense?.pricePoints?.premium,
      }
    : {
        ...selectedLicense,
        pricePoints: selectedLicense?.pricePoints?.regular,
      };
  return selectedPricePoint;
};

// TODO: is this the best was to determine portalMode? just relying on webflow data set values?
export const isInPortalMode =
  typeof document !== 'undefined' &&
  document.querySelector('html') &&
  document.querySelector('html').dataset &&
  document.querySelector('html').dataset.wfPage;

export const sixMonthInSeconds = 6 * 30 * 24 * 60 * 60;
export const sevenDaysInSeconds = 7 * 24 * 60 * 60;

// https://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case
export const camelize = (str: string) =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) =>
      index === 0 ? letter.toLowerCase() : letter.toUpperCase()
    )
    .replace(/\s+/g, '');

export const cleanDescription = (description: string) => {
  const descriptionOnly = /.*?(?=\[a:)/g;
  const match =
    (description.match(descriptionOnly) &&
      description.match(descriptionOnly)[0]) ||
    '';

  if (match.length) {
    return match.trim();
  }

  return match;
};

export const createDescriptionTagListFrom = (tags: Array<string>) =>
  tags.reduce((allTags, tag) => {
    if (allTags && allTags.length) {
      return `${allTags} [a:${tag}]`;
    }
    return `[a:${tag}]`;
  }, '');

export const assembleDescription = (
  description: string,
  tags: $ReadOnlyArray<string>
) => {
  const formattedTagList = createDescriptionTagListFrom(tags);

  if (description.length) {
    return `${description} ${formattedTagList}`;
  }

  return formattedTagList;
};

// TODO: replace this with a modern and standard way
export const deprecatedCreateFileDownload = (imageUrl: string) => {
  const link = document.createElement('a');
  link.href = imageUrl;
  document.body.appendChild(link);
  //TODO: remove once https://github.com/cypress-io/cypress/issues/949 is done
  if (!window.Cypress) {
    link.click();
    setTimeout(() => link.parentNode.removeChild(link), 10);
  }
};

export const parseTotalPricingToInt = (totalPricing) => {
  Object.keys(totalPricing).forEach((key) => {
    totalPricing[key] = parseInt(totalPricing[key], 10);
  });
  return totalPricing;
};

export const isDealTransaction = (transaction: EyeEmTransaction) =>
  transaction?.dealTemplates?.length > 0;

export const stripTrailingSlash = (string: string) =>
  string.endsWith('/') && string !== '/' ? string.slice(0, -1) : string;

export const getProductionsRedirect = (
  environment: LoginRedirectToken,
  tokens: string = ''
) => {
  switch (environment) {
    case 'production-staging-testing':
      return `${PRODUCTIONS_STAGING_TESTING_URL}/${tokens}`;

    case 'production-prod-testing':
      return `${PRODUCTIONS_PRODUCTION_TEST_URL}/${tokens}`;

    case 'production-development':
      return `${PRODUCTIONS_DEVELOPMENT_URL}/${tokens}`;

    case 'production-staging':
      return `${PRODUCTIONS_STAGING_URL}/${tokens}`;

    case 'production':
      return `${PRODUCTIONS_PRODUCTION_URL}/${tokens}`;

    default:
      return null;
  }
};

export const isSearchPath = (path: string | typeof undefined) => {
  if (!path) {
    return false;
  }

  if (path.indexOf('/search/') > -1) {
    return true;
  }

  return false;
};

const findQueries = (manager, name) => {
  const matching = [];
  manager.queries.forEach((q) => {
    if (q.observableQuery && q.observableQuery.queryName === name) {
      matching.push(q);
    }
  });
  return matching;
};

const getQueryNameFromPath = (path) => {
  if (path.indexOf('/search/') !== -1) {
    return SEARCH_QUERY_ALIAS;
  }
  if (path.indexOf('/p/') !== -1) {
    return SINGLE_PHOTO_QUERY_ALIAS;
  }
  if (path.indexOf('/i/') !== -1) {
    return SINGLE_ILLUSTRATION_QUERY_ALIAS;
  }
  if (path.indexOf('/a/') !== -1) {
    return ALBUM_PHOTOS_QUERY_ALIAS;
  }
  return false;
};

/*  This is a helper which helps us refetch a query based on something
    that Apollo did not trigger (direct downloads, redux actions, etc)

    https://github.com/apollographql/react-apollo/issues/562#issuecomment-517585875
*/
export const refetchQueryByName = (name, client) => {
  if (name) {
    // eslint-disable-next-line
    return Promise.all(
      findQueries(client.queryManager, name).map((q) =>
        q.observableQuery.refetch()
      )
    );
  }
  return false;
};

export const refetchQueryByPath = (path, client) => {
  const name = getQueryNameFromPath(path);
  if (name) {
    return refetchQueryByName(name, client);
  }
  return false;
};

export const getAlbumStepSize = (isManualPagination: boolean) => {
  if (isManualPagination) {
    return GRID_STEP_SIZE[PAGINATION_PARADIGM_MANUAL];
  }

  return GRID_STEP_SIZE[PAGINATION_PARADIGM_AUTO];
};

export const getOffset = (user: AuthUser, page: number = 0) => {
  if (page > 0) {
    return (Number(page) - 1) * getAlbumStepSize(isBuyer(user));
  }

  return 0;
};

export const hackColorMode = () => {
  // https://github.com/system-ui/theme-ui/issues/702
  if (
    typeof localStorage === 'object' &&
    typeof localStorage.removeItem === 'function'
  ) {
    localStorage.removeItem('theme-ui-color-mode');
  }
};

export const deepMergePaginatables = (
  currentData: Paginatable,
  newData: Paginatable
) =>
  deepMerge(currentData, newData, {
    arrayMerge: (target, source) => [...target, ...source],
  });

export const shouldShowUmboModal = (authUser: AuthUser) => {
  const today = Date.now();
  const startRange = new Date(2023, 1, 1); // February 1st 2023
  const endRange = new Date(2023, 2, 1); // March 1st 2023

  const isWithinTimeRange =
    isAfter(today, startRange) && isBefore(today, endRange);

  return isWithinTimeRange && isPhotographer(authUser);
};

export const formatPrice = (
  amount: number | string,
  currency: string,
  truncate?: boolean
) => {
  // add decimals if not truncated
  let number = amount;
  if (typeof amount === 'number') {
    number = (parseFloat(amount) / 100).toFixed(truncate ? 0 : 2);
  }
  const spaceCharacter = '\u00A0'; // non-breakable space
  return `${CURRENCIES[`${currency}`]}${spaceCharacter}${number}`;
};

export const getSearchRobotsValue = (paginatable, page, language, path) => {
  const indexFollow = 'index, follow';
  const noIndexFollow = 'noindex, follow';
  const noIndexNoFollow = 'noindex, nofollow';

  const INDEX_MINIMUM = 150;
  const PAGES_TO_INDEX = 10;

  if (
    (paginatable && Number(paginatable.total) === 0) ||
    language !== DEFAULT_LANGUAGE
  ) {
    return noIndexNoFollow;
  }

  if (
    path === '/search/pictures' ||
    (paginatable?.total && paginatable.total < INDEX_MINIMUM)
  ) {
    return noIndexFollow;
  }

  if (paginatable?.offset === 0 || page <= PAGES_TO_INDEX) {
    return indexFollow;
  }
  return noIndexFollow;
};

export const omitDeep = (obj: {}, property: string) =>
  JSON.parse(JSON.stringify(obj), (key, value) =>
    key === property ? undefined : value
  );
export * from './postAssetStats';
