/* @flow */
import { get, post } from './clientRequest';

export const updateFacebookSetting = (payload: UpdateFacebookSettingPayload) =>
  post({
    endpoint: '/authUser/update/facebookSetting',
    requestParams: payload,
  });

export const connectApp = (payload: ConnectAppPayload) =>
  post({
    endpoint: '/authUser/linkedApps',
    requestParams: payload.urlParams,
  });

export const refreshUser = () =>
  get({
    endpoint: '/refresh',
  });
