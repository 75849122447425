/* @flow */

import find from 'lodash/find';

import {
  RECEIVE_NORMALIZED_DATA,
  UPDATE_FACEBOOK_SETTING,
  UPDATE_USER_FLAG,
} from '../constants/actionTypes';

import { findViaId, compareAsStrings, merge } from '../helpers/tools';

const updateUserFlag = (user: AuthUser, name: string, value: any) => ({
  ...user,
  newsSettings: {
    ...user.newsSettings,
    [name]: value,
  },
});

/**
 * Reducer for a single user
 *
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
const user = (state: AuthUser, action: ReduxAction<Action>) => {
  switch (action.type) {
    case UPDATE_FACEBOOK_SETTING:
      if (compareAsStrings(state.nickname, action.payload.authUserNickname)) {
        return {
          ...state,
          services: {
            ...state.services,
            facebook: {
              ...state.services.facebook,
              [action.payload.settingName]: action.payload.settingValue,
            },
          },
        };
      }
      return state;
    case UPDATE_USER_FLAG:
      if (compareAsStrings(state.nickname, action.payload.authUserNickname)) {
        return updateUserFlag(state, action.payload.name, action.payload.value);
      }
      return state;
    default:
      return state;
  }
};

/**
 * Main users reducer
 *
 * @param {array} givenState
 * @param {object} action
 * @returns {array}
 */
export default function users(
  state: UsersState = [],
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case RECEIVE_NORMALIZED_DATA:
      if (action.payload.user) {
        return merge(state, action.payload.user);
      }
      return state;

    default:
      return state.map((userItem) => user(userItem, action));
  }
}

export const mapInitialDataToInitialState = (initialData: NormalizedData) =>
  initialData.user;

export const getUser = (
  state: UsersState,
  query: number | string
): EyeEmUser | AuthUser =>
  find(state, (item) => compareAsStrings(item.nickname, query)) ||
  findViaId(state, query);
