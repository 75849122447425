/** @flow */

import { MARKET_PHOTO_MAX_REQUEST_WIDTH } from '../constants/misc';

import {
  isFacebookPhoto,
  getSrcSetUrl,
  isNumber,
  isStyleSquare,
} from './tools';

/**
 * Modify the cdn url and replace style and thumb url segments
 * url format: http://cdn.eyeem.com/thumb/6a9c5531977fe974b539ce2584bfdfde562ff147-1405498401/640/480
 * deprecated format: http://cdn.eyeem.com/thumb/sq/200/6a9c5531977fe974b539ce2584bfdfde562ff147-1405498401
 *
 * illustrations format: https://cdn-illustrations-staging.eyeem.com/illustrations/561/thumb/11a8f9cfd64b228da75f8c5a6687270d64cd82e9-1656928286681?width=480&height=480
 * illustrations documentation: https://github.com/eyeem/illustrator/blob/main/documentation/architecture/thumbnails.md
 */

const replaceDimensionsInUrl = (
  url: string,
  size1: 'w' | 'h' | number,
  size2: number,
  isIllustration?: boolean,
  isPortrait?: boolean
) => {
  if (isFacebookPhoto(url)) {
    return `${url}&width=${size2}&height=${size2}`;
  }
  const urlSplit = url.split('/');
  // when the 4 entry of the split array is bigger than 10 characters we have an old url
  const filename = urlSplit[4].length > 10 ? urlSplit[4] : urlSplit[6];

  if (isIllustration) {
    const queryStrings = ({
      size1,
      size2,
    }: {
      size1: 'w' | 'h' | number,
      size2: number,
    }) => {
      if (size1 === 'w') {
        return `?width=${size2}`;
      } else if (size1 === 'h') {
        return `?height=${size2}`;
      }
      const width = isNumber(size1) ? `width=${size1}` : '';
      const height = isNumber(size2) ? `height=${size2}` : '';
      if (width.length === 0 && height.length === 0) {
        return '';
      } else if (width.length > 0 && height.length > 0) {
        return isPortrait ? `?${height}` : `?${width}`;
      } else {
        return `?${width || height}`;
      }
    };
    return `${url}${queryStrings({ size1, size2 })}`;
  } else {
    return `https://${urlSplit[2]}/thumb/${filename}/${size1}/${size2}`;
  }
};

/**
 * If width is lower than 100px: use requested width
 * If width is bigger than 100px and lower than 400 match px to a multiple of 50,
 * If width is 400 or more match px to a multiple of 100,
 */
export const getClosestSupportedImageSizeStep = (size: number) => {
  let val = size;
  if (size < 100) {
    return val;
  }
  if (size < 400 && size % 50 !== 0) {
    val = Math.ceil(size / 50) * 50;
  }
  if (size >= 400 && size % 100 !== 0) {
    val = Math.ceil(size / 100) * 100;
  }

  // capping all image with to MARKET_PHOTO_MAX_REQUEST_WIDTH
  if (val >= MARKET_PHOTO_MAX_REQUEST_WIDTH) {
    return MARKET_PHOTO_MAX_REQUEST_WIDTH;
  }
  return val;
};

/**
 * Return a url for an image conditional of the user screen size
 * @param {Object} obj
 * @param {string} obj.url - url
 * @param {string} obj.srcSet - srcSet
 * @param {Breakpoint} obj.breakpointName - breakpointName
 * @param {number} obj.devicePixelRatio - devicePixelRatio
 * @param {boolean} obj.retinafy - retinafy
 * @param {string | number} obj.size1 - size1
 * @param {number} obj.size2 - size2
 * @returns {string | undefined}
 */
export const getSrc = ({
  url,
  srcSet,
  breakpointName,
  devicePixelRatio,
  retinafy,
  size1,
  size2,
  isPortraitIllustration,
}: {
  url: string,
  srcSet?: string,
  breakpointName: Breakpoint,
  devicePixelRatio: number,
  retinafy: boolean,
  size1: string | number,
  size2?: number,
  isPortraitIllustration: boolean,
}): string | typeof undefined => {
  if (!url) {
    return undefined;
  }
  if (/\/node-static\//.test(url)) {
    if (srcSet) {
      return getSrcSetUrl(url, breakpointName, devicePixelRatio);
    }
    if (retinafy && !srcSet) {
      // get the url's file extension including dot
      const search = /(\..*$)/;

      let replacement = devicePixelRatio >= 2 ? '@2x' : '';

      // add the file extension to the end
      replacement += '$1';

      return url.replace(search, replacement);
    }
    return url;
  }

  const isIllustrationUrl = (url) => /cdn-illustrations/.test(url);

  if (
    (/cdn.eyeem.com/.test(url) && !/blog-cdn.eyeem.com/.test(url)) ||
    isIllustrationUrl(url) ||
    isFacebookPhoto(url)
  ) {
    let adjustedSize1 = size1;
    let adjustedSize2 = size2;

    // add support for 'square' to alias 'sq'
    // shiv 'circle' to 'sq' and add a class later
    if (isStyleSquare(adjustedSize1)) {
      if (isIllustrationUrl(url)) {
        adjustedSize1 = adjustedSize2;
      } else {
        adjustedSize1 = 'sq';
      }
    }

    // if we have a device pixel ratio then scale the image
    // to suit the device
    if (devicePixelRatio !== 1) {
      // size1 could be a style string
      if (isNumber(adjustedSize1)) {
        adjustedSize1 = Math.ceil(adjustedSize1 * devicePixelRatio);
      }

      adjustedSize2 = Math.ceil(adjustedSize2 * devicePixelRatio);
    }

    if (isNumber(adjustedSize1)) {
      adjustedSize1 = getClosestSupportedImageSizeStep(adjustedSize1);
    }

    adjustedSize2 = getClosestSupportedImageSizeStep(adjustedSize2);
    return replaceDimensionsInUrl(
      url,
      adjustedSize1,
      adjustedSize2,
      isIllustrationUrl(url),
      isPortraitIllustration
    );
  }

  // everything else (e.g. filepicker.io preview images)
  return url;
};
