/* @flow */
import { connect } from 'react-redux';

import { getIsMobile } from '../../../../reducers/';

import LinkList from './linkList.jsx';

const mapStateToProps = (state) => ({
  isMobile: getIsMobile(state),
});

export default connect(mapStateToProps)(LinkList);
