/** @flow */

import deepMerge from 'deepmerge';
import uniqBy from 'lodash/unionBy';

type FormState = { [key: string]: UploadIllustration };

type FormStateAction = {
  type: 'ADD' | 'UPDATE' | 'REMOVE',
  payload?: {
    caption?: string,
    tags?: string[],
    categories?: string[],
    styles?: string[],
  },
  uuid: string,
};

export const ADD_TO_FORMSTATE = 'ADD';
export const REMOVE_FROM_FORMSTATE = 'REMOVE';
export const REMOVE_TAG = 'REMOVE_TAG';
export const ADD_TAG = 'ADD_TAG';
export const EDIT_FORMSTATE = 'EDIT_FORMSTATE';

export const formStateReducer = (state: FormState, action: FormStateAction) => {
  switch (action.type) {
    case ADD_TO_FORMSTATE:
      return {
        ...state,
        ...{ [action.uuid]: action.payload },
      };
    case REMOVE_FROM_FORMSTATE: {
      delete state[action.uuid];
      return state;
    }
    case EDIT_FORMSTATE: {
      let currentItem = state[action.uuid];
      currentItem = deepMerge(currentItem, action.payload, {
        arrayMerge: (target, source) => {
          if (source[0]?.source && source[0]?.keyword) {
            return uniqBy(
              [...target, ...source].reverse(),
              'keyword'
            ).reverse();
          }
          return source;
        },
      });
      return {
        ...state,
        ...{ [action.uuid]: currentItem },
      };
    }
    case REMOVE_TAG: {
      const currentItem = state[action.uuid];
      const currentTags = currentItem.tags;
      const updatedTags = currentTags.filter(
        (tag) => tag.keyword !== action.payload.tag.keyword
      );
      const updatedItem = { ...currentItem, tags: updatedTags };
      return { ...state, ...{ [action.uuid]: updatedItem } };
    }
    case ADD_TAG: {
      const currentItem = state[action.uuid];
      currentItem.tags.push(action.payload.tag);
      currentItem.tags = uniqBy(currentItem.tags, 'keyword');
      return { ...state, ...{ [action.uuid]: currentItem } };
    }
    default:
      return state;
  }
};
