/* @flow */
import {
  ADD_OR_UPDATE_CART_ITEM,
  ADD_TO_LIGHTBOX,
  CLEAR_FLYOUT,
  REMOVE_FROM_LIGHTBOX,
} from '../constants/actionTypes';

export default function flyout(
  state: FlyoutState = {},
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case ADD_OR_UPDATE_CART_ITEM:
      if (action.payload.added) {
        return {
          ...action.payload,
          flyoutType: 'cart',
        };
      }
      return state;
    case ADD_TO_LIGHTBOX:
      if (!action.payload.isLightboxStripOpen) {
        return {
          ...action.payload,
          flyoutType: 'lightbox',
          added: true,
        };
      }
      return state;
    case REMOVE_FROM_LIGHTBOX:
      if (
        action.payload.openFlyoutOnRemove &&
        !action.payload.isLightboxStripOpen
      ) {
        return {
          ...action.payload,
          flyoutType: 'lightbox',
        };
      }
      return state;
    case CLEAR_FLYOUT:
      return {};
    default:
      return state;
  }
}
