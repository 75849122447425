/* @flow */
import {
  OPEN_MODAL,
  CLOSE_MODAL,
  NOTIFY_MODAL_SUCCESS,
  NOTIFY_MODAL_FAILURE,
} from '../constants/actionTypes';

export default function modal(
  state: ModalState = {},
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case OPEN_MODAL:
      return action.payload;
    case CLOSE_MODAL:
      return {};
    case NOTIFY_MODAL_SUCCESS:
      return {
        ...state,
        success: true,
      };
    case NOTIFY_MODAL_FAILURE:
      return {
        ...state,
        failure: action.payload,
      };
    default:
      return state;
  }
}

export const getModal = (state: ModalState) => state;

export const getHasModal = (state: MissionsState) =>
  Object.keys(state).length > 0;
