/* @flow */
import { ALLOW_NSFW_PHOTO } from '../constants/actionTypes';

export default function nsfwWhitelist(
  state: NsfwWhitelistState = [],
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case ALLOW_NSFW_PHOTO:
      return [...state, action.payload.photoId];
    default:
      return state;
  }
}

export const getIsNsfwPhotoOnWhitelist = (
  state: NsfwWhitelistState,
  photoId: EyeEmAssetId
) => state.indexOf(photoId) >= 0 || state.indexOf(`${photoId}`) >= 0;
