/* @flow */
import type { FieldProps } from 'redux-form';
import { Input } from '@eyeem-ui/atoms';

type Props = {
  customProps?: any,
  disabled?: boolean,
  label?: string,
  placeholder: string,
  type?: 'password' | 'text',
} & FieldProps;

function InputWrapper(props: Props) {
  return (
    <Input
      {...props.input}
      autoComplete={props.autoComplete}
      customProps={props.customProps}
      disabled={props.disabled}
      errorMessage={props.meta.touched && props.meta.error}
      isValid={!props.meta.invalid && !props.meta.pristine}
      label={props.label || props.placeholder}
      placeholder={props.placeholder}
      type={props.type}
    />
  );
}

InputWrapper.defaultProps = {
  customProps: null,
  disabled: false,
  label: null,
  type: 'text',
};

export default InputWrapper;
