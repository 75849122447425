import { connect } from 'react-redux';
import NotificationBar from './notificationBar.jsx';
import { dismissNotification } from '../../helpers/actionCreators/redux';

import {
  getIsAuthenticated,
  getNotifications,
  getIsMobile,
} from '../../reducers/';

const mapStateToProps = (state) => ({
  notifications: getNotifications(state),
  isAuthenticated: getIsAuthenticated(state),
  isMobile: getIsMobile(state),
});

export default connect(mapStateToProps, {
  dismissNotification,
})(NotificationBar);
