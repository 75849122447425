/* @flow */
import { connect } from 'react-redux';
import { compose } from 'redux';
import SearchInputDropdown from './searchInputDropdown.jsx';
import withRouter from '../../../../helpers/hoc/withRouter';
import {
  getSearchResourceId,
  getAuthUser,
  getIsBuyer,
  getStepSize,
} from '../../../../reducers';

import {
  updateSearchFilters,
  unsetContextPaginatableMetadata,
  setSearchTrack,
} from '../../../../helpers/actionCreators/redux';

import { fullUrlParamsToSearchData } from '../../../../helpers/search';

const mapStateToProps = (state, ownProps) => {
  const authUser = getAuthUser(state);
  const path = ownProps.getPath();
  const searchData = fullUrlParamsToSearchData(path, ownProps.query, authUser);

  return {
    searchData,
    authUser,
    resourceId: getSearchResourceId(state),
    isBuyer: getIsBuyer(state),
    stepSize: getStepSize(state),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    updateSearchFilters,
    unsetContextPaginatableMetadata,
    setSearchTrack,
  })
)(SearchInputDropdown);
