/* @flow */

import { combineReducers } from 'redux';
import find from 'lodash/find';
import get from 'lodash/get';

import {
  RECEIVE_NORMALIZED_DATA,
  UPDATE_SEARCH_FILTERS,
  RESET_SEARCH_FILTERS,
  UPDATE_SEARCH_SORT,
  UPDATE_SEARCH_TERM,
  RESET_SEARCH_SORT,
} from '../../constants/actionTypes';

import {
  toggleFilterValue,
  searchDataToResourceId,
  hasNoSearchTerm,
  defaultFilterValues,
  getDefaultSortValue,
} from '../../helpers/search';

import { compareAsStrings, cleanObject } from '../../helpers/tools';

// Reducer
function q(state = '', action) {
  switch (action.type) {
    case RECEIVE_NORMALIZED_DATA:
      if (action.payload.searchData) {
        return action.payload.searchData.q;
      }
      return state;
    case UPDATE_SEARCH_TERM:
      return action.payload;
    default:
      return state;
  }
}

function filters(state = {}, action) {
  switch (action.type) {
    case RECEIVE_NORMALIZED_DATA:
      if (action.payload.searchData) {
        return action.payload.searchData.filters;
      }
      return state;
    case UPDATE_SEARCH_FILTERS:
      return cleanObject({
        ...state,
        [action.payload.filterType]: toggleFilterValue(
          state[action.payload.filterType],
          action.payload.value,
          action.payload.filterType,
          action.payload.authUser
        ),
      });
    case RESET_SEARCH_FILTERS:
      return defaultFilterValues({ authUser: action.payload.authUser });
    default:
      return state;
  }
}

function sort(state = '', action) {
  switch (action.type) {
    case RECEIVE_NORMALIZED_DATA:
      return get(action.payload, 'searchData.sort') || state;
    case UPDATE_SEARCH_SORT:
      return action.payload;
    case RESET_SEARCH_SORT:
      return getDefaultSortValue({ authUser: action.payload.authUser });
    default:
      return state;
  }
}

function tokens(state = [], action) {
  switch (action.type) {
    case RECEIVE_NORMALIZED_DATA:
      return get(action.payload, 'searchData.tokens') || state;
    case UPDATE_SEARCH_TERM:
      return [];
    default:
      return state;
  }
}

function translatedQuery(state = [], action) {
  switch (action.type) {
    case RECEIVE_NORMALIZED_DATA:
      return get(action.payload, 'search.searchData.translatedQuery') || state;
    default:
      return state;
  }
}

// Reducer
export default combineReducers({
  q,
  filters,
  sort,
  tokens,
  translatedQuery,
});

// Initial Data Mapping
export function mapInitialDataToInitialState(initialData: NormalizedData) {
  const authUser: AuthUser = find(initialData.user, (item) =>
    compareAsStrings(item.id, initialData.auth.user)
  );

  return {
    q: get(initialData, 'search.searchData.q') || '',
    filters:
      get(initialData, 'search.searchData.filters') ||
      defaultFilterValues({ authUser }),
    sort:
      get(initialData, 'search.searchData.sort') ||
      getDefaultSortValue({ authUser }),
    tokens: get(initialData, 'search.searchData.tokens') || [],
    translatedQuery:
      get(initialData, 'search.searchData.translatedQuery') || '',
  };
}

// Selector API
export const getSearchResourceId = (state: SearchData) =>
  searchDataToResourceId(state);
export const getHasNoSearchTerm = (state: SearchData) => hasNoSearchTerm(state);
export const getHasSearchFilters = (state: SearchData) =>
  state && Object.keys(state.filters).length > 0;
export const getSearchData = (state: SearchData) => state;
