/* @flow */

import { connect } from 'react-redux';
import ViewRouter from './viewRouter';

import {
  getAuthUser,
  getDevice,
  getIsAuthenticated,
  getIsBCG,
  getIsEnterpriseCustomer,
  getIsLightboxStripOpen,
  getLanguage,
} from '../../reducers/';

import {
  receiveNormalizedData,
  resetSearchFilters,
  setTempField,
  showNotificationAndDismiss,
  updateBrowserInfo,
  updateSearchFilters,
  updateSearchTerm,
} from '../../helpers/actionCreators/redux';

export default connect(
  (state: RootReducerState) => ({
    authUser: getAuthUser(state),
    isAuthenticated: getIsAuthenticated(state),
    isBCG: getIsBCG(state),
    isEnterpriseCustomer: getIsEnterpriseCustomer(state),
    isIOS: getDevice(state).isIOS,
    isLightboxStripOpen: getIsLightboxStripOpen(state),
    locale: getLanguage(state),
  }),
  {
    receiveNormalizedData,
    resetSearchFilters,
    setTempField,
    showNotification: showNotificationAndDismiss,
    updateBrowserInfo,
    updateSearchFilters,
    updateSearchTerm,
  }
)(ViewRouter);
