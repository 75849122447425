/* @flow */
/* eslint-env browser */
import { Component } from 'react';
import t from 'counterpart';

import { Box, Button, Flex } from '@eyeem-ui/atoms';

import Link from '../../general/link/';

type Props = {
  className: string,
  close: CloseModal,
  feature: 'upload' | 'sellerSignup' | 'connect' | 'illustrations',
  linkProps: { href: string },
  resetUpload: Function,
  shouldAskForNavigationConfirmation: Function,
};

export default class ConfirmNavigationModal extends Component<Props> {
  leave = () => {
    if (this.props.feature === 'illustrations') {
      this.props.shouldAskForNavigationConfirmation({
        shouldAskForNavigationConfirmation: false,
        reason: 'IllustrationUploader',
      });
    }
    if (this.props.feature === 'upload') {
      this.props.resetUpload();
      if (window.dropzone) {
        window.dropzone.removeAllPhotos();
      }
    }
    this.props.close();
  };

  render() {
    return (
      <Flex p="24px" justifyContent="flex-end">
        <Box mr="1">
          <Button variant="transparent" onClick={this.props.close}>
            {t('modal.cancel.confirmNavigation')}
          </Button>
        </Box>
        <Link forced href={this.props.linkProps.href} onClick={this.leave}>
          <Button>{t('modal.confirm.confirmNavigation')}</Button>
        </Link>
      </Flex>
    );
  }
}
