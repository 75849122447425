/* @flow */
import { connect } from 'react-redux';
import PageStructure from './pageStructure.jsx';

import {
  getAuthUser,
  getCartRemovals,
  getIsBuyer,
  getIsLoginViaParam,
  getIsMobile,
  getLanguage,
} from '../../reducers/';

import {
  checkCartRemovalsThunk,
  dismissNotifications,
  openModal,
  setSessionFieldThunk,
  showNotificationAndDismiss,
} from '../../helpers/actionCreators/redux';
const mapStateToProps = (state) => ({
  authUser: getAuthUser(state),
  cartRemovals: getCartRemovals(state),
  isBuyer: getIsBuyer(state),
  isLoginViaParam: getIsLoginViaParam(state),
  isMobile: getIsMobile(state),
  language: getLanguage(state),
});

const mapDispatchToProps = {
  checkCartRemovals: checkCartRemovalsThunk,
  dismissNotifications,
  openModal,
  showNotification: showNotificationAndDismiss,
  setSessionField: setSessionFieldThunk,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageStructure);
