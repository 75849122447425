/* @flow */
import { Component } from 'react';
import styled from 'styled-components';
import { StyledText } from 'eyeem-components';
import { media } from '@eyeem-ui/theme';
import T from '../../translate.jsx';
import Link from '../../link';
import { isLinkExternal } from '../../../../helpers/tools';
import { getUrlLocalized } from '../../../../helpers/localization';
import { footerItemPadding } from '../styles';

const StyledLink = styled(({ isMisc, isMobile, ...rest }) => <T {...rest} />)`
  ${(props) =>
    props.isMisc
      ? `margin-right: 16px; font-size: ${footerItemPadding}; margin-bottom: 0;`
      : undefined}
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 ${footerItemPadding} ${footerItemPadding} 0;

  @media ${media.xlargeUp} {
    padding: ${footerItemPadding};
  }
`;

type Props = {
  item: Object,
  isMisc: boolean,
  isMobile: boolean,
  // gets attached to every link
  itemClass?: string,
  isInExternalPage: boolean,
  language: string,
};

class FooterLink extends Component<Props> {
  render() {
    const { item } = this.props;
    return (
      <StyledLink
        useStyledText
        noTranslate
        isMisc={this.props.isMisc}
        isMobile={this.props.isMobile}
        href={item.href && getUrlLocalized(item.href, this.props.language)}
        color="linkWhite"
        size="pStatic"
        renderAs="a"
        m={0}
        component={item.href && isLinkExternal(item.href) ? StyledText : Link}
        onClick={item.onClick}
        eventAction={item.eventAction}
        eventType={item.eventType}
        eventName={item.eventName}
        eventPosition={item.eventPosition}
        title={item.title}
        target={item.newTab && '_blank'}>
        {item.title}
      </StyledLink>
    );
  }
}

export default FooterLink;
