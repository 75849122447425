/* @flow */
import { PureComponent } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import Link from './link/';
import ParseLinks from './parseLinks.jsx';
import { compareAsStrings } from '../../helpers/tools';
import { TEST_COMMENT_REPLY_LINK } from '../../constants/pageObjectSelectors';

const StyledComment = styled.span`
  color: ${(props) => props.theme.colors.whites.white};
  font-size: 14px;
  font-family: 'Sailec Regular', sans-serif;
  letter-spacing: 0;
  line-height: 21px;
  margin-bottom: 0px;
`;

/**
 * Convert [user:1234] into a <Link>
 * @param component {string}
 * @param comment {object}
 * @returns {XML}
 */
const userLink = (component, comment, linkClass, index) => {
  const regId = /\d+/g;

  // can we extract the id from the userNotation
  const match = component.match(regId);

  // our backup will be the component itself
  let html = component;

  if (match.length) {
    const id = match[0];

    // iterate the mentioned users and get the user that matches the found id
    // then create link to that profile
    comment.mentionedUsers.forEach((user) => {
      if (compareAsStrings(id, user.id)) {
        html = (
          <Link
            key={user.id}
            href={`/u/${user.nickname.toLowerCase()}`}
            title={user.nickname}
            data-test-id={TEST_COMMENT_REPLY_LINK}
            className={classnames('g_link ', linkClass)}>
            @{user.nickname}
          </Link>
        );
      }
    });
  }

  return <span key={index}>{html}</span>;
};

type Props = {
  linkClass: string,
  comment: EyeEmComment,
};

/**
 * Comment component. Will wrap user mentions in <Link>s
 * @props
 *  - comment {object}
 *      - extendedMessage {string} with [user:12345] mention notation
 *      - mentionedUsers {array} with user objects with ids
 */
class Comment extends PureComponent<Props> {
  /**
   * Wrap a string in spans
   * @param component {string}
   * @returns {XML}
   */
  textNode(component: string, key: number) {
    // render plain textnode
    return (
      <ParseLinks
        key={key}
        text={component}
        linkClassName={this.props.linkClass}
      />
    );
  }

  render() {
    if (this.props.comment) {
      const { comment } = this.props;

      const regUserNotation = /(\[user:\d*\])/g;

      // split the message into components
      const components = comment.extendedMessage.split(regUserNotation);

      // generate markup by splitting the components of the comment and
      // rendering each component in an appropriate container
      const markup = components.map((component, index) => {
        // is this component a user notation [user:id]?
        if (regUserNotation.test(component)) {
          return userLink(component, comment, this.props.linkClass, index);
        }
        return this.textNode(component, index);
      });

      // render all nodes inside a comment wrapper
      return <StyledComment>{markup}</StyledComment>;
    }
    // if no comment given then return a noscript tag
    return null;
  }
}

export default Comment;
