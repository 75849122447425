/* @flow */
import omit from 'lodash/omit';
import compact from 'lodash/compact';

import {
  RECEIVE_NORMALIZED_DATA,
  CLEAR_CART_REMOVALS,
} from '../constants/actionTypes';

// remove null entries from cart removals, unclear where they came from
const getCleanCartRemovals = (tempState: CartRemovalState) =>
  tempState &&
  Object.keys(tempState).reduce((acc, curr) => {
    acc[curr] = compact(tempState[curr]);
    return acc;
  }, {});

export default function cartRemovals(
  state: CartRemovalsState = {},
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case RECEIVE_NORMALIZED_DATA:
      return (
        (action.payload.cartRemovals &&
          getCleanCartRemovals(action.payload.cartRemovals)) ||
        state
      );
    case CLEAR_CART_REMOVALS:
      return action.payload.type ? omit(state, action.payload.type) : {};
    default:
      return state;
  }
}

export const getCartRemovals = (state: CartRemovalsState) => state;

export function mapInitialDataToInitialState(initialData: NormalizedData) {
  return getCleanCartRemovals(initialData.cartRemovals);
}
