/* @flow */
/* Lightbox strip */
import type { Node } from 'react';
import styled from '@emotion/styled';
import { ThemeProvider } from '@eyeem-ui/theme';

import { cleanObject } from '../../../helpers/tools';

import StripPhoto from './stripPhoto/';
import PlaceholderBoxes from './placeholderBoxes.jsx';

const Strip = styled.div`
  white-space: nowrap;
  overflow: hidden;
`;

function PhotoStrip(props: {
  className?: string,
  paginatable: Paginatable,
  lightboxId: EyeEmLightboxId,
  translatePrefix: string,
  eventOption?: string,
  floatButton?: Node,
  onWhite?: boolean,
  contextPaginatableMetadata?: ContextPaginatableMetadataState,
}) {
  const {
    floatButton = false,
    onWhite = false,
    className = null,
    lightboxId,
    translatePrefix,
    eventOption = null,
    contextPaginatableMetadata,
    paginatable,
  } = props;

  return (
    <ThemeProvider>
      <Strip className={className}>
        {paginatable &&
          paginatable.items.slice(0, 20).map((asset) => (
            <StripPhoto
              conversionEventOption={cleanObject({
                sourceType: paginatable.resourceType,
                sourceId:
                  paginatable.resourceType !== 'search' &&
                  paginatable.resourceId,
                sourceName:
                  paginatable.resourceType !== 'search' &&
                  paginatable.paginatableName,
              })}
              key={'strip-photo-' + asset.id}
              lightboxId={lightboxId}
              translatePrefix={translatePrefix}
              eventOption={eventOption}
              asset={asset}
              contextPaginatableMetadata={contextPaginatableMetadata}
            />
          ))}
        <PlaceholderBoxes onWhite={onWhite} />
        {floatButton}
      </Strip>
    </ThemeProvider>
  );
}

PhotoStrip.displayName = 'LightboxStrip';

export default PhotoStrip;
