/* @flow */
import type { Node } from 'react';

import { createElement, PureComponent } from 'react';
import omit from 'lodash/omit';
import Translate from 'react-translate-component';
import t from 'counterpart';

import { connect } from 'react-redux';

import { getLanguage } from '../../reducers/';

import { track } from '../../helpers/tools';

import { getUrlLocalized } from '../../helpers/localization';

// enable some markupProps, they're used in strings like this:
// { headline: 'EyeEm Missions:%(br)sCreativity on demand.' }
const markupProps = {
  br: <br />,
  br2: <br />,
  brMediumUp: <br className="br-mediumUp" />,
};

type Props = {
  // DOM element or react element that gets rendered
  component?: string | Node,

  // if true the children aren't treated
  // as translation key but rendered as is
  noTranslate?: boolean,

  with?: Object,

  onClick?: Function | false,
  eventAssetType?: 'photo' | 'illustration',
  eventName?: string,
  eventType?: string,
  eventAction?: string,
  eventValue?: string | number,
  eventLabel?: string,
  eventPosition?: string,
  eventPositionIndex?: number,
  eventEcommerce?: Object,
  eventOption?: Object | string,
  children: Node,
  // passes down test id so it can identified by tests
  // eslint-disable-next-line react/no-unused-prop-types
  'data-test-id'?: string,

  language: string,
};

class TranslateWrapper extends PureComponent<Props> {
  static defaultProps = {
    component: 'span',
  };

  onClick = (event: SyntheticInputEvent<HTMLElement>) => {
    if (this.props.eventName) {
      track({
        eventAssetType: this.props.eventAssetType,
        eventType: this.props.eventType || t('tracking.eventType.inbound'),
        eventValue: this.props.eventValue,
        eventName: this.props.eventName,
        eventAction: this.props.eventAction,
        eventLabel: this.props.eventLabel || this.props.children,
        eventPosition: this.props.eventPosition,
        eventPositionIndex: this.props.eventPositionIndex,
        ecommerce: this.props.eventEcommerce,
        eventOption: this.props.eventOption,
      });
    }

    if (this.props.onClick) {
      this.props.onClick(event);
    }
  };

  render() {
    const unconsumedProps = omit(this.props, [
      'noTranslate',
      'with',
      'dispatch',
      'children',
      'component',
      'eventType',
      'eventValue',
      'eventName',
      'eventAction',
      'eventLabel',
      'eventPosition',
      'eventPositionIndex',
      'eventEcommerce',
      'eventOption',
      'onClick',
    ]);

    const nonTranslationProps = omit(unconsumedProps, [
      'data-test-id',
      'album',
      'replyLink',
      'follow',
      'comment',
      'abbreviatedCount',
      'count',
      'scrollToY',
      'bodyLink',
      'eventAction',
      'eventType',
      'eventName',
      'eventPosition',
      'language',
    ]);

    if (nonTranslationProps.href) {
      nonTranslationProps.href = getUrlLocalized(
        nonTranslationProps.href,
        this.props.language
      );
    }

    const translateComponentProps = {
      component: this.props.component,
      with: {
        ...markupProps,
        ...unconsumedProps, // remove when all translate instances moved to "with" syntax
        ...this.props.with,
      },
      content: this.props.children,
      ...nonTranslationProps,
    };

    return this.props.noTranslate ? (
      createElement(
        this.props.component,
        {
          ...nonTranslationProps,
          onClick: this.onClick,
          // eslint-disable-next-line react/prop-types
          'data-test-id': this.props['data-test-id'],
        },
        this.props.children
      )
    ) : (
      <Translate
        {...translateComponentProps}
        // eslint-disable-next-line react/prop-types
        data-test-id={this.props['data-test-id']}
        onClick={this.onClick}
      />
    );
  }
}

export default connect((state) => ({
  language: getLanguage(state),
}))(TranslateWrapper);
