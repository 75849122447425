/* @flow */
import T from '../general/translate.jsx';

function FailureModal(props: {
  className: string,
  close: CloseModal,
  noTranslate: boolean,
  errorMessage: string,
}) {
  return (
    <div className={props.className}>
      <div className="modal_content">
        <T noTranslate={props.noTranslate} component="h2" className="modal_h2">
          {props.errorMessage}
        </T>
      </div>
      <div className="modal_buttonBox modal_buttonBox-seamless">
        <T
          component="button"
          onClick={props.close}
          className="g_btn g_btn-aqua">
          modal.success.default
        </T>
      </div>
    </div>
  );
}

export default FailureModal;
