/* @flow */
import lodashGet from 'lodash/get';
import { post, get } from './clientRequest';

export const getCustomerUserVerification = (payload: { token: string }) =>
  get({
    endpoint: `/enterprise/verifyCustomerUser/${payload.token}`,
  });

export const resetEnterprisePassword = (payload: ResetPasswordPayload) =>
  post({
    endpoint: '/enterprise/resetPassword',
    requestParams: payload,
  });

export const registerCustomerUser = (payload: RegisterUserPayload) =>
  post({
    endpoint: '/enterprise/registerCustomerUser',
    requestParams: payload,
  });

export const chargeCodeValidation = (payload: { chargeCode: string }) => {
  const bcgChargeCode = lodashGet(payload, 'chargeCode', '').trim();

  return get({
    endpoint: `/enterprise/validate`,
    requestParams: { bcgChargeCode },
  });
};

export const getCustomerUser = (id: string) =>
  get({
    endpoint: `/enterprise/me`,
    requestParams: { id },
  });

export const getEnterpriseUserLicensing = (currency: string) =>
  get({
    endpoint: '/enterprise/licensing',
    requestParams: { currency },
  });
