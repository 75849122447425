/* @flow */
import fetch from 'isomorphic-fetch';
import qs from 'qs';
import { cleanObject } from '../helpers/tools';
import { dataPath } from '../constants/misc';

const logger = console;
const LOG_PREFIX = 'CLIENT';

const getHeaders = (contentType: ?string | false) =>
  cleanObject({
    'Content-Type': contentType || 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  });

const getUrl = (endpoint: string, params?: {} = {}) =>
  `${dataPath}${endpoint}${qs.stringify(params, {
    addQueryPrefix: true,
    arrayFormat: 'repeat',
  })}`;

const handleError =
  (method: 'DELETE' | 'GET' | 'PATCH' | 'POST' | 'PUT', path: string) =>
  async (err: Error) =>
    err &&
    logger.info(
      `[${LOG_PREFIX}] Failed to ${method} from ${path} with error: ${JSON.stringify(
        err
      )}`
    );

const handleResponse =
  (method: 'DELETE' | 'GET' | 'PATCH' | 'POST' | 'PUT', path: string) =>
  async (response) => {
    try {
      const body = await response.json();
      return {
        ok: response.ok,
        body,
        statusCode: response.status,
        method,
        path,
      };
    } catch (error) {
      logger.info(
        `[${LOG_PREFIX}] response parsing failed: ${method} ${path}, ${JSON.stringify(
          error
        )} `
      );
      return {
        ok: false,
        body: { message: 'Service unavailable' },
        statusCode: 503,
        method,
        path,
      };
    }
  };

const requestWithoutBody = (
  method: 'GET',
  params: {
    endpoint: string,
    requestParams?: {},
    timeout?: number,
  }
) => {
  const url = getUrl(params.endpoint, params.requestParams);
  const headers = getHeaders();

  return fetch(url, { headers, method, credentials: 'same-origin' })
    .then(handleResponse(method, url))
    .catch(handleError(method, url));
};

const requestWithBody = (
  method: 'DELETE' | 'PATCH' | 'POST' | 'PUT',
  params: {
    endpoint: string,
    requestParams?: {},
    timeout?: number,
    type?: 'form' | 'json',
  }
) => {
  const url = getUrl(params.endpoint);
  const headers = getHeaders(
    params.type === 'form' && 'application/x-www-form-urlencoded'
  );
  const body = JSON.stringify(params.requestParams);

  return fetch(url, {
    headers,
    credentials: 'same-origin',
    method,
    body,
  })
    .then(handleResponse(method, url))
    .catch(handleError(method, url));
};

type ClientApiRequestParams = {
  endpoint: string,
  requestParams?: {},
  type?: 'form' | 'json',
  timeout?: number,
};

export const del = (params: ClientApiRequestParams) =>
  requestWithBody('DELETE', params);
export const get = (params: ClientApiRequestParams) =>
  requestWithoutBody('GET', params);
export const patch = (params: ClientApiRequestParams) =>
  requestWithBody('PATCH', params);
export const post = (params: ClientApiRequestParams) =>
  requestWithBody('POST', params);
export const put = (params: ClientApiRequestParams) =>
  requestWithBody('PUT', params);
