/* @flow */
import { post, get } from './clientRequest';

export const login = (payload: LoginPayload) =>
  post({
    endpoint: '/auth/login',
    requestParams: payload,
  });

export const loginViaFacebook = (payload: LoginViaFacebookPayload) =>
  post({
    endpoint: '/auth/loginViaFacebook',
    requestParams: payload,
  });

export const signup = (payload: SignupPayload) =>
  post({
    endpoint: '/auth/signup',
    requestParams: payload,
  });

export const getOpenIdAuthUrl = (payload: OpenIdAuthUrlPayload) =>
  get({
    endpoint: '/auth/openIdAuthUrl',
    requestParams: payload,
  });
