/* @flow */

import uniq from 'lodash/uniq';
import get from 'lodash/get';

import { findViaId, merge } from '../helpers/tools';

import { RECEIVE_NORMALIZED_DATA } from '../constants/actionTypes';

const nativeEyeemApps = [
  '1', // EyeEm iPhone App
  '2', // EyeEm Android App
  '3', // EyeEm Web Interface
];

const filterNonNativeApps = (allApps = []) =>
  allApps.filter((item) => nativeEyeemApps.indexOf(item) === -1);

export function mapInitialDataToInitialState(initialData: NormalizedData) {
  const linkedApps = get(initialData, 'linkedApps[0].items');
  let items = linkedApps || [];
  if (initialData.app) {
    items = items.concat(initialData.app);
  }

  return {
    items,
    authRequest: get(initialData, 'app[0].id'),
    linked: linkedApps && linkedApps.map((app) => app.id),
  };
}

export default function apps(
  state: AppsState = {},
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case RECEIVE_NORMALIZED_DATA: {
      const newState = mapInitialDataToInitialState(action.payload);
      return {
        items: merge(state.items, newState.items),
        authRequest: newState.authRequest || state.authRequest,
        linked: uniq((state.linked || []).concat(newState.linked || [])),
      };
    }
    default:
      return state;
  }
}

export const getNonNativeLinkedApps = (state: AppsState) =>
  filterNonNativeApps(state.linked);
export const getAuthRequestAppId = (state: AppsState) => state.authRequest;
export const getApp = (state: AppsState, id: number | string) =>
  findViaId(state.items, id);
