import { connect } from 'react-redux';

import LanguageToggle from './languageToggle';

import { getLanguage } from '../../../../reducers/';
import { switchLanguageThunk } from '../../../../helpers/actionCreators/redux';

const mapStateToProps = (state) => ({
  language: getLanguage(state),
});

export default connect(mapStateToProps, {
  switchLanguage: switchLanguageThunk,
})(LanguageToggle);
