/* @flow */
import styled from 'styled-components';

import Photo from '../../../general/photo/';

const StyledPhotos = styled.span`
  display: block;
`;

const StyledPhotosItem = styled(Photo)`
  display: inline-block;
  margin-right: 8px;
  margin-top: 8px;

  &:nth-of-type(5n) {
    margin-right: 0;
  }

  a {
    display: block;
    overflow: hidden;

    padding-top: 8px;
  }

  img {
    height: 48px;
  }
`;

function PhotoList(props: {
  photos: $ReadOnlyArray<EyeEmPhoto>,
  onClick: Function,
}) {
  return (
    <StyledPhotos>
      {props.photos.map((photo) => (
        <StyledPhotosItem
          key={photo.id}
          click={props.onClick}
          url={photo.previewUrl}
          size1="sq"
          size2={48}
          link={`/p/${photo.id}`}
        />
      ))}
    </StyledPhotos>
  );
}

export default PhotoList;
