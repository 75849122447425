/* @flow */
import { connect } from 'react-redux';

import {
  getBreakpointName,
  getDevicePixelRatio,
  getCachedThumbnail,
} from '../../../reducers/';

import { setPhotoCache } from '../../../helpers/actionCreators/redux';
import { getSrc } from '../../../helpers/photoSrc';
import { ASSET_TYPE_ILLUSTRATION } from '../../../constants/assets';

import Photo from './photo.jsx';

const mapStateToProps = (
  state: RootReducerState,
  ownProps: {
    url: string,
    srcSet: boolean,
    retinafy: boolean,
    size1: string | number,
    size2: number,
    forceRetina?: boolean,
    ignoreCache?: boolean,
    asset: EyeEmAsset,
  }
) => {
  const devicePixelRatio = ownProps.forceRetina
    ? 2
    : getDevicePixelRatio(state);

  const breakpointName = getBreakpointName(state);

  let asset = ownProps.asset;

  const imgSrc = getSrc({
    url: ownProps.url,
    srcSet: ownProps.srcSet,
    breakpointName,
    devicePixelRatio,
    retinafy: ownProps.retinafy,
    size1: ownProps.size1,
    size2: ownProps.size2,
    isPortraitIllustration:
      ownProps?.asset?.type === ASSET_TYPE_ILLUSTRATION &&
      ownProps?.asset.height > ownProps?.asset.width,
  });

  return {
    imgSrc,
    cachedThumbnail: !ownProps.ignoreCache && getCachedThumbnail(state, imgSrc),
    asset,
    devicePixelRatio,
    breakpointName,
  };
};

export default connect(mapStateToProps, { setPhotoCache })(Photo);
