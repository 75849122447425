/* @flow */
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';

import StripPhoto from './stripPhoto.jsx';

import { REMOVE_ASSET_FROM_LIGHTBOX } from '../../../../graphql/mutations/lightbox';
import {
  LIGHTBOX_FOR_GRID_QUERY_ALIAS,
  LIGHTBOX_QUERY_ALIAS,
} from '../../../../graphql/constants';

import {
  setContextPaginatableMetadata,
  setTempField,
  storeConversionEventOptionThunk,
} from '../../../../helpers/actionCreators/redux';

const mapDispatchToProps = {
  setContextPaginatableMetadata,
  setTempField,
  storeConversionEventOption: storeConversionEventOptionThunk,
};

export default compose(
  graphql(REMOVE_ASSET_FROM_LIGHTBOX, {
    name: 'removeFromLightbox',
    options: () => ({
      refetchQueries: [LIGHTBOX_FOR_GRID_QUERY_ALIAS, LIGHTBOX_QUERY_ALIAS],
    }),
  }),
  connect(null, mapDispatchToProps)
)(StripPhoto);
