/* @flow */
import {
  RECEIVE_NORMALIZED_DATA,
  UPDATE_MARKET_USER,
} from '../constants/actionTypes';

export default function marketUser(
  state: EyeEmMarketUser = {},
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case RECEIVE_NORMALIZED_DATA:
      return action.payload.marketUser || state;
    case UPDATE_MARKET_USER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export function mapInitialDataToInitialState(initialData: NormalizedData) {
  return initialData.marketUser;
}
