/* @flow */
import get from 'lodash/get';

import { track } from '../helpers/tools';

import {
  RECEIVE_NORMALIZED_DATA,
  AUTH_PENDING,
  SET_AUTH_REF,
} from '../constants/actionTypes';

const defaultState = {
  authRef: '/',
  authenticated: false,
  pending: false,
  loginViaParam: false,
};

export default function auth(
  state: AuthState = defaultState,
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case RECEIVE_NORMALIZED_DATA:
      if (state.authenticated && !get(action.payload, 'auth.authenticated')) {
        // tracking to see if we loose sessions users
        track({
          eventType: 'click_inbound',
          eventAction: 'logout',
          eventLabel: 'logout via server response',
          eventName: 'error',
          nonInteraction: true,
        });
        // eslint-disable-next-line
        location.reload();
      }
      if (action.payload.auth) {
        return {
          ...action.payload.auth,
          authRef: action.payload.authRef || state.authRef,
        };
      }
      return state;
    case AUTH_PENDING:
      return {
        ...state,
        pending: action.payload.pending,
      };
    case SET_AUTH_REF:
      return {
        ...state,
        authRef: action.payload.authRef,
      };
    default:
      return state;
  }
}

export function mapInitialDataToInitialState(initialData: NormalizedData) {
  return {
    ...defaultState,
    ...initialData.auth,
  };
}

export const getAuthUserId = (state: AuthState) => state.user; // this is actually an id
export const getIsAuthenticated = (state: AuthState) => state.authenticated;
export const getIsAuthPending = (state: AuthState) => state.pending;
export const getAuthRef = (state: AuthState) => state.authRef;
export const getIsLoginViaParam = (state: AuthState) => state.loginViaParam;
