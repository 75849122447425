// @flow
import { gql } from '@apollo/client';

import { SearchFragment } from '../fragments/search';
import { SimplePhotoFragment } from '../fragments/photo';

export const GET_SEARCH_RESULTS = gql`
  query search(
    $isAdmin: Boolean!
    $isBuyer: Boolean!
    $limit: Int
    $offset: Int
    $params: SearchInputParams!
  ) {
    search(
      params: $params
      offset: $offset
      limit: $limit
      isAdmin: $isAdmin
      isBuyer: $isBuyer
    ) {
      ...SimpleSearch
    }
  }
  ${SearchFragment}
`;

export const GET_SEARCH_SUGGESTION = gql`
  query searchSuggestionQuery($q: String) {
    suggestions(q: $q) {
      keywords {
        text
      }
    }
  }
`;

export const GET_SIMILAR_PHOTOS = gql`
  query getSimilarPhotos($assetType: String!, $assetId: String!) {
    similarPhotos(assetType: $assetType, assetId: $assetId) {
      items {
        ...SimplePhoto
      }
    }
  }
  ${SimplePhotoFragment}
`;
