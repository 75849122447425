/* @flow */
/* General helper that renders its children ony on componentDidMount */
/* eslint-env browser */
import type { Node } from 'react';

import { Component } from 'react';

type State = {
  render: boolean,
};

type Props = {
  children: Node,
};

class RenderOnMount extends Component<Props, State> {
  state = {
    render: false,
  };

  componentDidMount() {
    this.setState({ render: true });
  }

  render() {
    if (this.state.render) {
      return this.props.children;
    }
    return null;
  }
}

export default RenderOnMount;
