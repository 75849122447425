/* @flow */
/*
  NOTE: Temp Flags, Session Fields and Backend Flags:
  Temp Flags are stored only temporarily on client side and will be wiped out on page reload
  or if a hard link was clicked (e.g EyeEm Logo).
  Current use cases are the animation on/off state of the Previous Downloads button or slider
  positions.
  If you need something with longer TTL you should go for Session Fields and if it needs to be
  accessible by other clients (i.e. Android/ iOS), you need a Backend Flag (aka Auth User News
  Settings).
*/
import {
  RECEIVE_NORMALIZED_DATA,
  STORE_CONVERSION_EVENT_OPTION,
  SET_SESSION_FIELD,
} from '../constants/actionTypes';

import { SESSION_FIELD_CONVERSION_EVENT_OPTIONS } from '../constants/misc';

export default function sessionFields(
  state: SessionFieldsState = {},
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case RECEIVE_NORMALIZED_DATA:
      if (action.payload.sessionFields) {
        return {
          ...state,
          ...action.payload.sessionFields,
          [SESSION_FIELD_CONVERSION_EVENT_OPTIONS]: {
            ...state.conversionEventOptions,
            ...action.payload.conversionEventOptions,
          },
        };
      }
      return state;
    case STORE_CONVERSION_EVENT_OPTION:
      return {
        ...state,
        [SESSION_FIELD_CONVERSION_EVENT_OPTIONS]: {
          ...state.conversionEventOptions,
          [action.payload.photoId]: action.payload.eventOption,
        },
      };
    case SET_SESSION_FIELD:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };

    default:
      return state;
  }
}

export const getSessionField = (state: SessionFieldsState, fieldName: string) =>
  state[fieldName];

export const getConversionEventOption = (
  state: SessionFieldsState,
  photoId: string | number
) => state.conversionEventOptions[photoId];

export const mapInitialDataToInitialState = (initialData: NormalizedData) =>
  initialData.sessionFields;
