/* @flow */
import { connect } from 'react-redux';
import { compose } from 'redux';

import SearchBar from './searchBar.jsx';
import withRouter from '../../../helpers/hoc/withRouter';

import {
  getIsEnterpriseCustomer,
  getStepSize,
  getAuthUser,
  getLanguage,
  getIsMobile,
} from '../../../reducers';

import {
  fullUrlParamsToSearchData,
  searchTermFromUrl,
} from '../../../helpers/search';

import {
  updateSearchTerm,
  unsetContextPaginatableMetadata,
  updateSearchFilters,
  setSearchTrack,
} from '../../../helpers/actionCreators/redux';

export default compose(
  withRouter,
  connect(
    (state: RootReducerState, ownProps) => {
      const authUser = getAuthUser(state);

      const path = ownProps.getPath ? ownProps.getPath() : '';
      const searchTerm = searchTermFromUrl(path);

      return {
        authUser,
        isEnterpriseCustomer: getIsEnterpriseCustomer(state),
        // Rely on URL params rather than search stuff in redux.
        searchData: fullUrlParamsToSearchData(path, ownProps.query, authUser),

        stepSize: getStepSize(state),
        hasNoSearchTerm: !searchTerm,
        language: getLanguage(state),
        isMobile: getIsMobile(state),
        isSearchInputDropdownHidden: false,
      };
    },
    {
      updateSearchTerm,
      updateSearchFilters,
      unsetContextPaginatableMetadata,
      setSearchTrack,
    }
  )
)(SearchBar);
