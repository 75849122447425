/* @flow */
/* eslint-env browser */
import { Component } from 'react';
import find from 'lodash/find';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import t from 'counterpart';
import styled, { css } from 'styled-components';
import { Icon, styleUtils, StyledText } from 'eyeem-components';
import { Plus } from '@eyeem-ui/icons';
import { media, ThemeProvider } from '@eyeem-ui/theme';

import T from './translate.jsx';
import Link from './link/';

import { TriangleMixin } from '../../helpers/styleMixins';

import {
  DROPDOWN_TRIGGER_STYLE_DARK,
  DROPDOWN_TRIGGER_STYLE_TRANSPARENT_ON_BRIGHT,
  DROPDOWN_TRIGGER_STYLE_TRANSPARENT_ON_DARK,
  DROPDOWN_TRIGGER_STYLE_GREY,
} from '../../constants/misc';

import { isLinkExternal } from '../../helpers/tools';

const BackgroundColorForDropdownMixin = (props: {
  triggerStyle:
    | DROPDOWN_TRIGGER_STYLE_DARK
    | DROPDOWN_TRIGGER_STYLE_TRANSPARENT_ON_BRIGHT
    | DROPDOWN_TRIGGER_STYLE_TRANSPARENT_ON_DARK
    | DROPDOWN_TRIGGER_STYLE_GREY,
}) => {
  switch (props.triggerStyle) {
    case DROPDOWN_TRIGGER_STYLE_GREY:
      return css`
        background-color: ${styleUtils.fromTheme('colors.greys.grey6')};

        &:hover {
          background-color: ${styleUtils.fromTheme('colors.greys.grey5')};
        }
      `;
    case DROPDOWN_TRIGGER_STYLE_DARK:
      return css`
        background-color: ${styleUtils.fromTheme('colors.blacks.black5')};

        &:hover {
          background-color: ${styleUtils.fromTheme('colors.blacks.black4')};
        }
      `;

    default:
      return null;
  }
};

const StyledCheckmark = styled(Icon)`
  fill: ${styleUtils.fromTheme('colors.whites.white')};
  position: absolute;
  left: 16px;
  top: 11px;
`;

const StyledPlus = styled(Plus)`
  position: absolute;
  left: 16px;
  top: 16px;
`;

const StyledDropdownSelectTrigger = styled.div`
  position: relative;
  height: 100%;
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 2px;
  transition: background-color 0.2s ease-out, background 0.2s ease-out;

  ${BackgroundColorForDropdownMixin};
`;

const StyledDropdownSelectTriggerWrapper = styled.span`
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
  justify-content: ${(props) =>
    props.triggerStyle === DROPDOWN_TRIGGER_STYLE_TRANSPARENT_ON_DARK ||
    props.triggerStyle === DROPDOWN_TRIGGER_STYLE_TRANSPARENT_ON_BRIGHT
      ? 'flex-end'
      : 'flex-start'};
  transition: background-color 0.2s ease-out;

  ${BackgroundColorForDropdownMixin};
  ${TriangleMixin};

  > p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const StyledDropdownSelectItems = styled.ul`
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: ${(props) => props.theme.zIndices.dropdown};
  margin: 8px 0 0 0;
  max-height: 242px;
  overflow: auto;

  box-shadow: 0 2px 4px 2px
    ${styleUtils.fromTheme('colors.uncategorized.unnamed15')};
  padding: 8px 0 8px 0;
  border: 1px solid ${styleUtils.fromTheme('colors.blacks.black5')};
  border-radius: 2px;
  background-color: ${styleUtils.fromTheme('colors.blacks.black5')};
  list-style: none;
`;

const StyledDropdownSelectItem = styled(T)`
  cursor: pointer;
  display: block;
  position: relative;
  padding: 2px 0;
  background-color: ${styleUtils.fromTheme('colors.blacks.black5')};
  text-decoration: none;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: ${styleUtils.fromTheme('colors.blacks.black2')};
  }
`;

const StyledDropdownSelectItemLabel = styled(T)`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 40px;
  padding-right: 12px;
  line-height: 2rem;
  margin: 0;
`;

const StyledDropdownSelectWrapper = styled.div`
  position: relative;
  user-select: none;
  display: block;
  margin: 0;
  height: 32px;
  z-index: ${(props) => props.theme.zIndices.dropdown};
  width: 160px;

  @media ${media.largeUp} {
    width: 192px;
  }
`;

type Props = {
  unselected: string,
  noTranslate?: boolean,
  activeKey?: string,
  options: $ReadOnlyArray<{
    value: string,
    text: string,
    href?: string,
    labelProps: Object,
    bcgIcon?: boolean,
    eventName?: string,
    eventLabel?: string,
    eventPosition?: string,
    eventOption?: string,
    hoverLink?: {
      href: string,
      label: string,
      eventName: string,
      eventLabel: string,
      eventPosition: string,
      eventOption: string,
    },
  }>,
  onChange?: Function,
  className?: string,
  topOfListLink?: {
    onClick: Function,
    label: string,
    eventName: string,
    eventPosition: string,
  },
  triggerStyle:
    | DROPDOWN_TRIGGER_STYLE_DARK
    | DROPDOWN_TRIGGER_STYLE_TRANSPARENT_ON_BRIGHT
    | DROPDOWN_TRIGGER_STYLE_TRANSPARENT_ON_DARK
    | DROPDOWN_TRIGGER_STYLE_GREY,
  inPage?: boolean,
};

type State = {
  open: boolean,
};

class DropdownSelect extends Component<Props, State> {
  static defaultProps = {
    options: [],
    triggerStyle: DROPDOWN_TRIGGER_STYLE_GREY,
  };

  dropdownSelect = null;

  state = {
    open: false,
  };

  handleClickOutside = (event: SyntheticInputEvent<HTMLElement>) => {
    // inside click
    if (this.dropdownSelect && !this.dropdownSelect.contains(event.target)) {
      this.setState({ open: false });
    }
  };

  /**
   * Triggers when a dropdown changes. notify parent
   * @param {string} value The value of the selected checkbox
   * @param {boolean} prevent If the default of the event should be prevented
   */
  handleChange =
    (value: string, prevent: boolean) =>
    (event: SyntheticInputEvent<HTMLElement>) => {
      if (prevent) {
        event.preventDefault();
      }
      this.setState({ open: false });
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    };

  toggleOpen = (event: SyntheticInputEvent<HTMLElement>) => {
    event.nativeEvent.stopImmediatePropagation();
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };

  preventDefaultAndStopPropagation = (
    event: SyntheticInputEvent<HTMLElement>
  ) => {
    event.stopPropagation();
    event.preventDefault();
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  render() {
    const { options } = this.props;
    const activeOption = find(options, { value: this.props.activeKey });

    return (
      <StyledDropdownSelectWrapper
        ref={(node) => {
          if (this.state.open) {
            this.dropdownSelect = node;
          }
        }}
        className={this.props.className}>
        <StyledDropdownSelectTrigger
          tabIndex={0}
          role="button"
          triggerStyle={this.props.triggerStyle}
          aria-pressed={this.state.open}
          onClick={this.toggleOpen}>
          <StyledDropdownSelectTriggerWrapper
            isOpen={this.state.open}
            triggerStyle={this.props.triggerStyle}>
            <T
              component={StyledText}
              size={this.props.inPage ? 'default' : 'pStatic'}
              color={
                this.props.triggerStyle === DROPDOWN_TRIGGER_STYLE_GREY ||
                this.props.triggerStyle ===
                  DROPDOWN_TRIGGER_STYLE_TRANSPARENT_ON_BRIGHT
                  ? 'black'
                  : 'linkWhite'
              }
              noTranslate={this.props.noTranslate}
              with={activeOption && activeOption.labelProps}>
              {(activeOption && activeOption.text) || this.props.unselected}
            </T>
          </StyledDropdownSelectTriggerWrapper>
        </StyledDropdownSelectTrigger>
        <TransitionGroup>
          {this.state.open && (
            <CSSTransition
              classNames="transition_dropdown"
              timeout={{ enter: 700, exit: 400 }}>
              <StyledDropdownSelectItems
                key="list"
                triggerStyle={this.props.triggerStyle}>
                {this.props.topOfListLink && (
                  <li key="topOfListLink">
                    <span>
                      <ThemeProvider>
                        <StyledPlus size={16} color="aqua50" />
                      </ThemeProvider>
                      <StyledDropdownSelectItemLabel
                        noTranslate={this.props.noTranslate}
                        color="white"
                        component={StyledText}
                        size="pStatic"
                        eventType={t('tracking.eventType.inbound')}
                        eventAction={t('tracking.eventAction.link')}
                        eventName={this.props.topOfListLink.eventName}
                        eventLabel={this.props.topOfListLink.label}
                        eventPosition={this.props.topOfListLink.eventPosition}
                        onClick={this.props.topOfListLink.onClick}
                        className="dropdownSelect_item_label g_link g_link-aqua">
                        {this.props.topOfListLink.label}
                      </StyledDropdownSelectItemLabel>
                    </span>
                  </li>
                )}
                {options.map((option) => (
                  <li key={option.value}>
                    <StyledDropdownSelectItem
                      noTranslate
                      onClick={this.handleChange(option.value, !option.href)}
                      component={
                        option.href && !isLinkExternal(option.href)
                          ? Link
                          : 'span'
                      }
                      rel={
                        option.href && isLinkExternal(option.href)
                          ? 'noopener'
                          : null
                      }
                      eventType={t('tracking.eventType.inbound')}
                      eventAction={t('tracking.eventAction.link')}
                      eventName={option.eventName}
                      eventLabel={option.eventLabel}
                      eventPosition={option.eventPosition}
                      eventOption={option.eventOption}
                      href={option.href || '#'}
                      className="dropdownSelect_item">
                      {activeOption && activeOption.value === option.value && (
                        <StyledCheckmark size={13} type="checkmark" />
                      )}
                      <StyledDropdownSelectItemLabel
                        noTranslate={this.props.noTranslate}
                        component={StyledText}
                        size="pStatic"
                        color="white"
                        with={option.labelProps}
                        title={option.addTitle && option.text}
                        className="dropdownSelect_item_label">
                        {option.text}
                      </StyledDropdownSelectItemLabel>
                      {option.hoverLink && (
                        <div className="dropdownSelect_item_label_hoverLink">
                          <T
                            noTranslate={this.props.noTranslate}
                            eventType={t('tracking.eventType.inbound')}
                            eventAction={t('tracking.eventAction.link')}
                            eventName={option.hoverLink.eventName}
                            eventLabel={option.hoverLink.eventLabel}
                            eventPosition={option.hoverLink.eventPosition}
                            eventOption={option.hoverLink.eventOption}
                            component={Link}
                            onClick={this.preventDefaultAndStopPropagation}
                            href={option.hoverLink.href}
                            className="g_link g_link-aqua typo_pMin">
                            {option.hoverLink.label}
                          </T>
                        </div>
                      )}
                    </StyledDropdownSelectItem>
                  </li>
                ))}
              </StyledDropdownSelectItems>
            </CSSTransition>
          )}
        </TransitionGroup>
      </StyledDropdownSelectWrapper>
    );
  }
}

export default DropdownSelect;
