/* @flow */
import { useQuery } from '@apollo/client';

import { GET_NEWS_UNSEEN } from '../../../../graphql/queries/news';

import t from 'counterpart';
import qs from 'qs';
import styled from '@emotion/styled';
import { Buttonizer } from '@eyeem-ui/atoms';

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledNavItemIconCount = styled.span`
  font-size: 9px;
  line-height: 16px;

  opacity: ${(props) => (props.itemCount > 0 ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 8px;
  left: 38px;
  display: block;
  min-width: 16px;
  height: 16px;
  border-radius: 8px;
  text-align: center;
  padding: 0 4px;
  background: ${(props) => props.theme.colors.red50};
  color: ${(props) => props.theme.colors.grey100};

  &:after {
    content: '${(props) => props.itemCount}';
  }
`;

function NotificationsTrigger(props) {
  const { children, ...rest } = props;
  const newsUnseenQuery = useQuery(GET_NEWS_UNSEEN, {
    ssr: false,
  });

  const badgeCount = newsUnseenQuery.data?.news?.unseen || 0;

  return (
    <StyledWrapper>
      <Buttonizer
        data-eventname={t('navbar.notifications.eventName')}
        data-eventtype={t('tracking.eventType.inbound')}
        data-eventposition={t('navbar.eventPosition')}
        data-eventaction={t('tracking.eventAction.icon')}
        data-eventlabel={`icon_news:${qs.stringify(badgeCount)}`}
        {...rest}>
        {children}
        <StyledNavItemIconCount itemCount={badgeCount} />
      </Buttonizer>
    </StyledWrapper>
  );
}

export default NotificationsTrigger;
