/* eslint-disable no-return-assign */
/* @flow */
/**
 * Standard component for display of all non svg images.
 * <b>Do not use <img/> for anything other than svg format</b>.
 *
 * Accepts cdn and local images as [url] and for cdn images will optimise for retina and size.
 * Allows background to be used instead of <img/> tag
 * by passing children or setting `background=true`.
 *
 * `srcSet=true` allows you to use different assets at different
 * break-points by using asset name suffixes.
 *
 * So <Photo srcSet url="/node-static/img/slide1.jpg"/> will map to
 *
 * /slide1-small.jpg
 * /slide1-small@2x.jpg
 * /slide1-medium.jpg
 * /slide1-medium@2x.jpg
 * /slide1-large.jpg
 * /slide1-large@2x.jpg
 * /slide1-xlarge.jpg
 * /slide1-xlarge@2x.jpg
 *
 * Be aware <Photo/> does <b>not</b> check for the files presence. Test on retina devices
 * to ensure all images are available
 */
import type { Node } from 'react';

import { PureComponent } from 'react';
import merge from 'lodash/merge';
import get from 'lodash/get';
import classnames from 'classnames';
import styled from 'styled-components';
import createComponentFromTagProp from 'react-create-component-from-tag-prop';
import { Box } from '@eyeem-ui/atoms';

import Link from '../link/';
import { DUMMY_PIXEL } from '../../../constants/misc';
import { isCachedPhotoSmallerThanRequested } from '../../../helpers/photoCaching';

import { httpsifyUrl, isStyleSquare } from '../../../helpers/tools';

const blockDownloadCss = `&:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // TODO: make me a constant
  z-index: 2;

  content: '';
  opacity: 1;
}`;

const ComponentFromTagProp = createComponentFromTagProp({
  tag: Link,
  prop: 'renderAs',
  propsToOmit: ['renderAs', 'shouldPreventDownload'],
});

// overlays image to prevent right clicks on photo
const PreventDownloadComponent = styled(ComponentFromTagProp)`
  ${(props) => (!props.shouldPreventDownload ? '' : blockDownloadCss)};
`;

type Props = {
  breakpointName: string,
  devicePixelRatio: number,

  // a string or number. this can be either a style such as "square"
  // or the width
  size1: string | number,

  // a string or number for optional width or height
  // depending on what type of size1 was supplied
  size2: string | number,

  // img[alt] attr
  alt: string,

  // url to the image in cdn, such as photoUrl property
  url: string,

  // images from dropzone have a generated dataurl
  dataUrl: string,

  // if a classname should be appended to img tag
  className: string,

  // if given photo is wrapped in <Link> with this href
  link: boolean | string,

  // a[target] target
  target: string,

  // img[title] attr
  title: string,

  // onclick handler
  click: boolean | Function,

  // takes care for retina versions of the filerequests
  // by adding @2x when the device is a retina device
  retinafy?: boolean,

  // force the image to load the retina version even if
  // devicepixelratio is smaller or unknown (only works in
  // combination with retinafy)
  forceRetina: boolean,

  // if true then render as if it had children
  background: boolean,

  // any background position other than 'center center'
  backgroundPosition: string | boolean,

  backgroundSize: string,

  // styles to be applied to photo object
  style: mixed,

  // any children that might be rendered if photo
  // is a background image component
  children: Node,

  'data-test-id': string,

  imgSrc: string,

  cachedThumbnail: CachedThumbnail,
  setPhotoCache: Function,
  asset?: EyeEmAsset,
  isAdmin?: boolean,
  shouldPreventDownload?: boolean,
  loading: string,
};

export default class Photo extends PureComponent<Props> {
  static defaultProps = {
    backgroundPosition: 'center center',
    retinafy: false,
  };

  componentDidMount() {
    if (
      !isStyleSquare(this.props.size1) &&
      !this.props.background &&
      !this.props.children &&
      !this.props.dataUrl &&
      this.props.imgSrc &&
      this.props.asset
    ) {
      const width = this.props.asset && this.props.asset.width;
      const height = this.props.asset && this.props.asset.height;

      if (this.imgElement && this.props.cachedThumbnail) {
        // cached other version of the image or base64 encoded black pixel, to reduce requests
        this.imgElement.src = this.props.cachedThumbnail.url;

        if (
          isCachedPhotoSmallerThanRequested(
            this.props.imgSrc,
            { width, height },
            this.props.cachedThumbnail
          )
        ) {
          // we use the timeout to then switch to the incoming image
          this.imgElement.src = this.props.imgSrc;
        }
      } else if (this.props.asset) {
        this.props.setPhotoCache({
          url: this.props.imgSrc,
          originalDimensions: { width, height },
        });
      }
    }
  }

  componentDidUpdate(prevProps: Props) {
    // warning: hack
    // we have an issue with browser not updating the image until next image is loaded
    // so, we load a quick-loading dummy pixel just before loading of new image so that
    // the image turns black in the meantime
    if (
      prevProps.url !== this.props.url &&
      !isStyleSquare(this.props.size1) &&
      !this.props.background &&
      !this.props.children
    ) {
      // cached other version of the image or base64 encoded black pixel, to reduce requests
      if (this.imgElement) {
        this.imgElement.src =
          (this.props.cachedThumbnail && this.props.cachedThumbnail.url) ||
          DUMMY_PIXEL;
      }

      if (
        !this.props.cachedThumbnail ||
        isCachedPhotoSmallerThanRequested(
          this.props.imgSrc,
          {
            width: get(this.props, 'asset.width'),
            height: get(this.props, 'asset.height'),
          },
          this.props.cachedThumbnail
        )
      ) {
        // we use the timeout to then switch to the incoming image
        if (this.imgElement) {
          this.imgElement.src = this.props.imgSrc;
        }

        if (this.props.asset) {
          this.props.setPhotoCache({
            url: this.props.imgSrc,
            originalDimensions: {
              width: this.props.asset.width,
              height: this.props.asset.height,
            },
          });
        }
      }
    }
  }

  /**
   * Handle image click
   */
  handleClick = (event: SyntheticInputEvent<HTMLElement>) => {
    if (this.props.click) {
      this.props.click(event);
    }
  };

  // TODO: Refactor or document, this is way too hard to understand
  render() {
    const className = classnames(
      'photo',
      {
        'photo-round': this.props.size1 === 'circle',
      },
      this.props.className
    );

    // for children or background we will not use an <img> tag
    if (this.props.children || this.props.background) {
      let style =
        this.props.imgSrc &&
        merge(
          {
            backgroundImage: classnames(
              `url("${
                (this.props.cachedThumbnail &&
                  this.props.cachedThumbnail.url) ||
                httpsifyUrl(this.props.imgSrc)
              }")` // regular background
            ),
            backgroundPosition: this.props.backgroundPosition,
            backgroundRepeat: 'no-repeat',
            willChange: 'background-image',
          },
          this.props.style
        );

      if (this.props.backgroundSize) {
        style = {
          ...style,
          backgroundSize: this.props.backgroundSize,
        };
      }

      // figuring out what element we need here...
      const Component = this.props.link ? PreventDownloadComponent : Box;

      let parentComponentChildren = this.props.children;

      if (
        !isStyleSquare(this.props.size1) &&
        this.props.cachedThumbnail &&
        this.props.asset &&
        isCachedPhotoSmallerThanRequested(
          this.props.imgSrc,
          { width: this.props.asset.width, height: this.props.asset.height },
          this.props.cachedThumbnail
        )
      ) {
        let childStyle = this.props.imgSrc && {
          backgroundImage: `url(${httpsifyUrl(this.props.imgSrc)})`,
          backgroundPosition: this.props.backgroundPosition,
          backgroundRepeat: 'no-repeat',
          willChange: 'background-image',
        };

        if (this.props.backgroundSize) {
          childStyle = {
            ...childStyle,
            backgroundSize: this.props.backgroundSize,
          };
        }

        const childProps = {
          key: `${this.props.imgSrc}-${this.props.size1 || this.props.size2}`,
          href: this.props.link,
          onClick: this.handleClick,
          target: this.props.target,
          className,
          title: this.props.title,
          style: childStyle,
          'data-test-id': this.props['data-test-id'],
        };

        const ChildComponent = this.props.link ? Link : Box;
        const childHtml = (
          <ChildComponent {...childProps}>{this.props.children}</ChildComponent>
        );

        parentComponentChildren = [childHtml];
      }

      const childProps2 = {
        key: `${this.props.size1 || this.props.size2}-${this.props.imgSrc}`,
        href: this.props.link,
        onClick: this.handleClick,
        target: this.props.target,
        className,
        title: this.props.title,
        style,
      };

      return <Component {...childProps2}>{parentComponentChildren}</Component>;
    }

    const img = (
      <img
        itemProp="contentUrl"
        alt={this.props.alt}
        src={
          (this.props.imgSrc && httpsifyUrl(this.props.imgSrc)) ||
          this.props.dataUrl
        }
        title={this.props.title}
        className={className}
        style={this.props.style}
        width={
          (this.props.size1 &&
            isStyleSquare(this.props.size1) &&
            this.props.size2) ||
          undefined
        }
        height={
          (this.props.size1 &&
            isStyleSquare(this.props.size1) &&
            this.props.size2) ||
          undefined
        }
        ref={(element) => (this.imgElement = element)}
        loading={this.props.loading ? this.props.loading : 'lazy'}
      />
    );

    // if link property specified then wrap in a <Link>
    return this.props.link ? (
      <PreventDownloadComponent
        key="child1"
        href={this.props.link}
        onClick={this.handleClick}
        target={this.props.target}
        shouldPreventDownload={this.props.shouldPreventDownload}>
        {img}
      </PreventDownloadComponent>
    ) : (
      <PreventDownloadComponent
        renderAs="span"
        shouldPreventDownload={this.props.shouldPreventDownload}>
        {img}
      </PreventDownloadComponent>
    );
  }
}
