/* @flow */
import { RECEIVE_NORMALIZED_DATA } from '../constants/actionTypes';

import { findViaId, merge } from '../helpers/tools';

export default function missions(
  state: MissionsState = [],
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case RECEIVE_NORMALIZED_DATA:
      if (action.payload.mission) {
        return merge(state, action.payload.mission);
      }
      return state;
    default:
      return state;
  }
}

export const mapInitialDataToInitialState = (initialData: NormalizedData) =>
  initialData.mission;

export const getMission = (state: MissionsState, id: EyeEmMissionId) =>
  findViaId(state, id);
