/* @flow */
const eventDispatcher = (eventName, eventDetail, response) => {
  const customEvent = new CustomEvent(eventName, {
    detail: {
      [eventDetail]: true,
      data: response,
    },
  });
  window.document.dispatchEvent(customEvent);
};

export const addOrUpdateCartItemEvent = () =>
  eventDispatcher('oncartupdated', 'isCartUpdated');
export const addToMarketEvent = () =>
  eventDispatcher('onaddtomarket', 'isAddedToMarket');
export const loginEvent = (data) => {
  eventDispatcher('onlogin', 'isLoggedIn', data.authUser.id);
  return data;
};
export const signupEvent = (data) => {
  eventDispatcher('onsignup', 'isSignedUp', data.authUser.id);
  return data;
};
export const updateAccountEvent = (data) => {
  eventDispatcher('onAccountUpdate', 'isUpdated', data.user);
  return data;
};
export const addToLightboxEvent = (data) => {
  eventDispatcher('onaddtolightbox', 'isAddedToLightbox');
  return data;
};
export const removeFromLightboxEvent = (data) => {
  eventDispatcher('onremovefromlightbox', 'isRemovedFromLightbox');
  return data;
};
export const createSellerAccountEvent = (data) => {
  eventDispatcher('onsellercreation', 'isCreated');
  return data;
};
export const downloadEvent = (data) => {
  eventDispatcher('ondownload', 'isDownloaded');
  return data;
};
export const resetEnterprisePasswordEvent = (data) => {
  eventDispatcher('onPasswordReset', 'isReset');
  return data;
};
export const customerUserRoleChangedEvent = (data) => {
  eventDispatcher('onRoleChanged', 'changed');
  return data;
};
