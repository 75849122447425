// @flow
import { keyframes, createGlobalStyle } from 'styled-components';
import { globalStyles, styleUtils } from 'eyeem-components';

// NOTE: keep this file short, we should have as few global styles as possible

const bounceInDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-45px);
  }
  25% {
    opacity: 1;
    transform: translateY(3px);
  }
  80% {
    transform: translateY(0);
  }
`;

const GlobalStyle = createGlobalStyle`
    ${globalStyles}
    *:focus {
      outline: none;
    }
    * {
      -webkit-tap-highlight-color: transparent;
    }

    html {
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    body {
      min-width: 320px;
      background: ${styleUtils.fromInternalTheme('colors.blacks.black')};

      // "momentum" scrolling for everything that scrolls
      -webkit-overflow-scrolling: touch;
    }

    // This is for font weight issues when the stylesheet applies to Webflow pages
    html:not([data-wf-domain]) body,
    #stage nav {
      // prevent fonts from becomming thinner during animation
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    // hide image icon while image is loading in firefox
    img:-moz-loading {
      visibility: hidden;
    }

    // Forces white "background" on form inputs which make use of autofill
    // (Webkit sets a yellow background which can not be overwritten via background-color)
    input:-webkit-autofill {
      -webkit-box-shadow: none;
    }

    ul {
      margin: 0;

      padding: 0;
      list-style-type: none;
    }

    // CSSTransitionGroup transition names

    .transition_dropdown-enter {
      animation: ${bounceInDown} 0.5s;
      animation-fill-mode: both;
    }
    .transition_dropdown-exit {
      opacity: 1;
      transition: opacity 0.1s ease, transform 0.15s ease;
      transform: translateY(0);
    }
    .transition_dropdown-exit.transition_dropdown-exit-active {
      opacity: 0;
      transform: translateY(-44px);
    }

    .transition_slideUp-enter {
      opacity: 0;
      transform: translateY(100%);
      
      &.transition_slideUp-enter-active {
        opacity: 1;
        transform: translateY(0%);
        transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
      }
    }
    .transition_slideUp-exit {
      opacity: 1;
      transform: translateY(0%);
      
      &.transition_slideUp-exit-active {
        transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
        transform: translateY(100%);
        opacity: 0;
      }
    }

    /* This is only for Webflow pages. Need to set fixed nav since 
       sticky won't work within the nav-stage container
    */
    #nav-stage {
      height: 0;
      nav {
        position: fixed;
        top: 0;
        right: 0;
      }
    }
    `;

export default GlobalStyle;
