/* @flow */

import { PureComponent } from 'react';
import t from 'counterpart';
import styled from '@emotion/styled';
import { media, ThemeProvider } from '@eyeem-ui/theme';
import { Box } from '@eyeem-ui/atoms';

import Logo from '../eyeemlogo';
import AppLinks from '../appLinks/';
import FooterLinkList from './linkList/';
import FooterMisc from './footerMisc.jsx';
import LanguageToggle from './languageToggle';
import { CenteredFlexWrapper, StyledFooter, footerItemPadding } from './styles';

import { NO_FOOTER_PATHS } from '../../../constants/misc';
import { routeMatchesOneOf } from '../../../helpers/tools';
import withRouter from '../../../helpers/hoc/withRouter.jsx';

const StyledColumn = styled(Box)`
  width: 100%;

  @media ${media.xlargeUp} {
    width: 20%;
  }
`;

type Props = {
  eventPosition?: string,
  isBuyer: boolean,
  isMobile: boolean,
  isLoginViaParam: boolean,
  isAuthenticated: boolean,
  getPath: Function,
  path?: string,
};

class Footer extends PureComponent<Props, State> {
  eventPosition = () => this.props.eventPosition || t('footer.eventPosition');

  render() {
    const path =
      this.props.path || (this.props.getPath && this.props.getPath());
    const isInfoPage = !routeMatchesOneOf(path, NO_FOOTER_PATHS);

    if (
      (this.props.isLoginViaParam || this.props.isAuthenticated) &&
      !isInfoPage
    ) {
      return null;
    }

    // Find the config in locales/en.js and locales/de.js
    const { columns, misc } = t('footer');

    return (
      <ThemeProvider>
        <StyledFooter id="footer">
          <Box pt={[5, 5, 5, '48px']} pb={[5]} mx={[3, 0]} px={[2, 3]}>
            <CenteredFlexWrapper
              mx={'auto'}
              flexDirection="row"
              justifyContent="space-between"
              flexWrap="wrap"
              width={[1]}
              pb={[3, 3, 3, 4]}>
              <StyledColumn p={[0, 0, 0, footerItemPadding]} pb={[3, 3, 3, 0]}>
                <Logo />
              </StyledColumn>
              {Object.keys(columns).map((columnName) => (
                <StyledColumn key={columnName}>
                  <FooterLinkList contentObject={columns[columnName]} />
                </StyledColumn>
              ))}
            </CenteredFlexWrapper>

            <CenteredFlexWrapper
              mx={'auto'}
              flexDirection={['column', 'column', 'column', 'row']}
              alignItems={['start', 'start', 'start', 'center']}
              mt={[0, 0, 0, 3]}>
              <Box pr={4}>
                <LanguageToggle />
              </Box>
              {this.props.isMobile && !this.props.isBuyer && (
                <Box width={1 / 4} py={3}>
                  <AppLinks smart eventPosition={this.eventPosition()} />
                </Box>
              )}
              <Box width={1} pt={[1, 1, 1, 0]}>
                <FooterMisc isMobile={this.props.isMobile} items={misc.items} />
              </Box>
            </CenteredFlexWrapper>
          </Box>
        </StyledFooter>
      </ThemeProvider>
    );
  }
}

export default withRouter(Footer);
