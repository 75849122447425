import styled from 'styled-components';
import { styleUtils } from 'eyeem-components';

import Photo from '../../general/photo/';

export const StyledNotification = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledNotificationLeft = styled.span`
  display: flex;
  max-width: 100%;

  color: ${styleUtils.fromTheme('colors.greys.grey3')};
`;

export const StyledNotificationWrapper = styled.div`
  padding: 16px;
  position: relative;
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid
    ${styleUtils.fromTheme('colors.uncategorized.unnamed13')};
  background-color: ${(props) =>
    props.isUnseen
      ? styleUtils.fromTheme('colors.uncategorized.unnamed13')
      : null};

  &:hover {
    background-color: ${styleUtils.fromTheme('colors.uncategorized.unnamed13')};
  }

  transition: background-color 0.2s ease;

  // SHAME
  a {
    color: ${styleUtils.fromTheme('colors.whites.white')};
    text-decoration: none;
  }
`;

export const StyledNotificationIcon = styled(Photo)`
  margin-right: 16px;
  border-radius: 24px;
  background-color: ${styleUtils.fromTheme('colors.whites.white')};
`;
