/* @flow */
/* eslint-env browser */
/* eslint-disable react/jsx-props-no-spreading */
/**
 * Wraps react-router-component's Link component so that we
 * can add more features like cmd + click to open a new window.
 */
import { Component } from 'react';
import type { Node } from 'react';
import omit from 'lodash/omit';
import t from 'counterpart';
import { StyledButton, StyledText } from 'eyeem-components';

import withRouter from '../../../helpers/hoc/withRouter.jsx';
import {
  stripUrlParameters,
  track,
  stripTrailingSlash,
} from '../../../helpers/tools';

import { getUrlLocalized } from '../../../helpers/localization';

import ConfirmNavigationModal from '../../modals/confirmNavigation/';

const omitableProps = [
  'query',
  'array',
  'change',
  'clearSubmit',
  'destroy',
  'forced',
  'getPath',
  'hasUnpostedPhotos',
  'isLightboxStripOpen',
  'isSellerSignupFormDirty',
  'navigate',
  'noTimestamp',
  'openModal',
  'renderAs',
  'toggleLightboxStrip',
  'warning',
  'authUser',
  'isEnterpriseCustomer',
  'isPhotographer',
  'hasSideNav',
  'isActive',
  'expanded',
  'noHover',
  'isAuthenticated',
  'isBuyer',
  'isCurrentPage',
  'language',
  'shouldAskForNavigationConfirmation',
  'shouldAskForNavigationConfirmationAction',
  'backgroundImageUrl',
];

type Props = {
  'data-test-id': string,
  active: boolean,
  children: Node,
  forced: boolean,
  hasUnpostedPhotos: boolean,
  href: string,
  isLightboxStripOpen: boolean,
  isSellerSignupFormDirty: boolean,
  itemProp: string,
  language: string,
  onClick: Function,
  openModal: OpenModal,
  query: {},
  scrollToY: number,
  target: string,
  toggleLightboxStrip: Function,
  useStyledButton: boolean,
  useStyledText: boolean,
  shouldAskForNavigationConfirmation: boolean,
  shouldAskForNavigationConfirmationAction: Function,
} & WithRouterProps;

// Keep these query params in the URL after navigation
// This functionality was added to enable multiple chatbot flows,
// which can only be triggered by query string param
// https://developers.hubspot.com/docs/api/conversations/open-to-chatflow
const preservedQueryParams = [];

class Link extends Component<Props> {
  getHref = () => {
    if (!this.props.href) return undefined;

    // This block enables keeping query params after navigation
    let queryString = '';
    // Only keep specified query params when navigating to relative (local) URLs
    if (typeof window != 'undefined' && this.props.href[0] === '/') {
      const oldParams = new URLSearchParams(window.location.search);
      const newParams = new URLSearchParams();

      preservedQueryParams.reduce((params, value) => {
        const oldValue = oldParams.get(value);
        if (oldValue && params) {
          params.set(value, oldValue);
        }
      }, newParams);

      const newParamsString = newParams.toString();
      if (newParamsString.length > 0) {
        queryString = `?${newParamsString}`;
      }
    }

    return getUrlLocalized(
      `${stripTrailingSlash(this.props.href)}${queryString}`,
      this.props.language
    );
  };

  onClick = (
    event: SyntheticInputEvent<HTMLElement> | SyntheticKeyboardEvent<Document>
  ) => {
    if (this.props.onClick) {
      this.props.onClick(event);
    }
    if (!this.props.href) {
      event.preventDefault();
    } else if (!event.metaKey && !event.ctrlKey) {
      event.preventDefault();
      if (this.shouldInterruptNavigation() && !this.props.forced) {
        const currentFeature = this.getCurrentFeature();
        track({
          eventType: t('tracking.eventType.inbound'),
          eventName: 'access_modal_confirmNavigation',
          eventAction: t('tracking.eventAction.link'),
          eventLabel: 'link',
          eventPosition: currentFeature,
        });
        this.props.openModal({
          content: ConfirmNavigationModal,
          contentProps: {
            linkProps: this.props,
            feature: currentFeature,
            title: t('modal.question.confirmNavigation'),
            description: t('modal.info.confirmNavigation'),
          },
          useEUModal: true,
        });
      } else {
        this.props.navigate(this.getHref(), this.props, (err) => {
          if (err) {
            throw err;
          }
        });

        if (this.props.scrollToY && window.scrollY >= this.props.scrollToY) {
          window.scrollTo(0, this.props.scrollToY);
        } else {
          window.scrollTo(0, 0);
        }
      }
    }
  };

  currentPath = () =>
    this.props.getPath() && stripUrlParameters(this.props.getPath());

  isOnUpload = () => this.currentPath().startsWith('/upload');

  isOnIllustrationUpload = () => this.currentPath() === '/upload/illustrations';

  isOnSellerSignup = () => this.currentPath() === '/market/sell/start';

  getCurrentFeature = () => {
    if (this.isOnIllustrationUpload()) {
      return 'illustrations';
    }
    if (this.isOnUpload()) {
      return 'upload';
    }
    if (this.isOnSellerSignup()) {
      return 'sellerSignup';
    }
    return '';
  };

  shouldInterruptNavigation = () => {
    const shouldInterrupt = this.props.shouldAskForNavigationConfirmation;
    if (shouldInterrupt) {
      return shouldInterrupt;
    } else {
      if (this.isOnUpload()) {
        // set true when there are unposted photos, or illustrations
        return this.props.hasUnpostedPhotos;
      }
      if (this.isOnSellerSignup()) {
        // set true when sellerSignup form has been touched
        return this.props.isSellerSignupFormDirty;
      }
    }
    return false;
  };

  patchBlankTarget() {
    // https://developers.google.com/web/tools/lighthouse/audits/noopener
    if (this.props.target === '_blank') {
      return {
        rel: 'noopener',
      };
    }
    return {};
  }

  render() {
    const href = this.getHref();
    const active = this.props.active ? this.props.active.toString() : null;

    const props = {
      ...omit(this.props, omitableProps),
      onClick: this.onClick,
      href,
      active,
      ...this.patchBlankTarget(),
    };

    const tag = href ? 'a' : 'span';

    let Component = tag;
    if (this.props.useStyledButton) {
      Component = StyledButton;
    }
    if (this.props.useStyledText) {
      Component = StyledText;
    }
    if (this.props.useStyledButton || this.props.useStyledText) {
      return (
        <Component renderAs={tag} {...props}>
          {this.props.children}
        </Component>
      );
    }
    return <Component {...props}>{this.props.children}</Component>;
  }
}

export default withRouter(Link);
