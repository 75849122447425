import { createContext, useContext, useReducer } from 'react';
import { formStateReducer } from './reducers/formState';

export const FormStateContext = createContext(null);
export const FormStateDispatchContext = createContext(null);

export const FormStateProvider = ({ children }) => {
  const [formState, dispatchFormState] = useReducer(formStateReducer, {});

  return (
    <FormStateContext.Provider value={formState}>
      <FormStateDispatchContext.Provider value={dispatchFormState}>
        {children}
      </FormStateDispatchContext.Provider>
    </FormStateContext.Provider>
  );
};

export const useFormState = () => useContext(FormStateContext);
export const useFormStateDispatch = () => useContext(FormStateDispatchContext);
