/* @flow */
/**
 * Item in news list
 */
import { PureComponent } from 'react';
import { StyledText } from 'eyeem-components';
import styled, { css } from 'styled-components';
import Comment from '../../../general/comment.jsx';
import Link from '../../../general/link/';
import T from '../../../general/translate.jsx';
import Photo from '../../../general/photo/';
import AgoWrap from '../../../general/agoWrap.jsx';

import ReplyLink from './replyLink.jsx';
import UserList from './userList.jsx';
import PhotoList from './photoList.jsx';

import { DUMMY_PIXEL } from '../../../../constants/misc';

import {
  StyledNotification,
  StyledNotificationWrapper,
  StyledNotificationLeft,
} from '../styles';

const newsItemStyles = `
  font-size: 14px;
  font-family: 'Sailec Regular', sans-serif;
  letter-spacing: 0;
  line-height: 21px;
  margin-bottom: 0px;
`;

const StyledNewsItem = styled(StyledNotificationWrapper)`
  min-height: 80px;

  &:last-child {
    border-bottom: none;
  }
`;

const StyledNewsItemInner = styled(StyledNotification)`
  white-space: initial;
  line-height: 1.25rem;
  ${newsItemStyles}
`;

const FullSizeMixin = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
`;

const StyledFullsizeReactLink = styled(Link)`
  ${FullSizeMixin};
`;

const StyledPrimaryPhoto = styled.span`
  width: 48px;
  display: block;
  margin-right: 16px;

  img {
    width: 48px;
    height: 48px;
    border-radius: 24px;
  }
`;

const StyledNotificationContent = styled.div`
  display: flex;
  align-items: flex-start;

  a {
    position: relative;
    z-index: 2;
    ${newsItemStyles}
  }
`;

const StyledSecondaryPhotoWrapper = styled.div`
  /* 1. Because for some reason, the photo component sets a
        manual width="X" height="X" property. 😭
  */

  margin-left: 12px;

  img {
    max-width: initial;
    width: 48px !important; /* 1 */
    height: 48px !important; /* 1 */
  }
`;

const StyledStyledText = styled(StyledText)`
  ${newsItemStyles}
`;

const getBackgroundForType = (type) => {
  switch (type) {
    case 'like':
      return '/node-static/img/notifi-like.svg';
    case 'follow':
      return '/node-static/img/notifi-follower.svg';
    case 'albumContributor':
      return '/node-static/img/notifi-photo.svg';
    case 'comment':
    case 'commentMention':
      return '/node-static/img/notifi-comment.svg';
    case 'blogPost':
    case 'page':
      return '/node-static/img/notifi-system.svg';
    case 'taggedPerson':
    case 'fbFriend':
    case 'twFriend':
      return '/node-static/img/notifi-tagged.svg';
    case 'photoSold':
      return '/node-static/img/notifi-sale.svg';
    case 'gettyApproval':
      return '/node-static/img/notifi-getty.svg';
    default:
      return undefined;
  }
};

const StyledNewsMeta = styled.div`
  margin-top: 4px;
  font-size: 12px;

  padding-left: 16px;
  background-position: left center;
  background-repeat: no-repeat;

  background-image: url('${(props) => getBackgroundForType(props.newsType)}');
`;

const removeAccessTokenFromUrl = (url: string) => {
  // it could happen that newsitems have access tokens,
  // we don't need them in the web, they cause errors, we're stripping them off
  if (url.indexOf('?a=') === -1) {
    return url;
  }

  return url.split('?a=')[0];
};

const newsLink = (item: EyeEmNewsItem) => {
  switch (item.newsType) {
    case 'blogPost':
      return removeAccessTokenFromUrl(item.url);
    case 'follow':
    case 'fbFriend':
    case 'twFriend':
      return `/u/${item.user.nickname.toLowerCase()}`;
    case 'albumContributor':
    case 'albumInvite':
      return `/a/${item.album.id}`;
    case 'photoSoldGetty':
      return '/market/sell/payout';
    case 'photoSold':
      return '/market/sell/payout';
    case 'page':
      return '/upload/photos';
    default:
      return `/p/${item.photo.id}`;
  }
};

type Props = {
  item: EyeEmNewsItem,
  unsetContextPaginatableMetadata: Function,
};

class NewsItem extends PureComponent<Props> {
  primaryThumb(item: EyeEmNewsItem) {
    if (item.newsType === 'photoSold' || item.newsType === 'photoSoldGetty') {
      return {
        href: '/market/sell/payout',
        src: '/node-static/img/news-market.svg',
      };
    }
    if (item.itemType === 'camera') {
      return {
        href: '/upload/photos',
        src: '/node-static/img/news-upload.svg',
      };
    }
    if (item.user) {
      const { user } = item;
      return {
        href: user ? `/u/${user.nickname.toLowerCase()}` : '',
        src: user ? user.thumbUrl : DUMMY_PIXEL,
      };
    }
    if (item.newsType === 'mission') {
      return {
        href: `/m/${item.mission.id}`,
        src: '/node-static/img/news-missions.svg',
      };
    }
    if (item.newsType === 'blogPost') {
      return {
        href: removeAccessTokenFromUrl(item.url),
        src: '/node-static/img/news-blog.svg',
      };
    }
    return {
      href: item.url,
      src: '/node-static/img/gridplaceholder.svg',
    };
  }

  secondaryThumb(item: EyeEmNewsItem) {
    let link;
    let url;
    if (
      item.photo &&
      !(item.aggregation && item.aggregation.type === 'photo')
    ) {
      const { photo } = this.props.item;

      link = photo ? `/p/${photo.id}` : '';
      url = photo ? photo.previewUrl : DUMMY_PIXEL;

      return (
        <Photo
          // click={this.props.unsetContextPaginatableMetadata}
          url={url}
          size1="square"
          size2={96}
          // link={link}
        />
      );
    }

    if (item.itemType === 'album') {
      const { album } = this.props.item;

      link = album ? `/a/${album.id}` : '';
      url = album?.coverPhoto?.thumbUrl || DUMMY_PIXEL;

      return (
        <Photo
          // click={this.props.unsetContextPaginatableMetadata}
          url={url}
          size1="sq"
          size2={96}
          link={link}
        />
      );
    }
    return false;
  }

  render() {
    const { item } = this.props;
    const secondaryThumb = this.secondaryThumb(item);

    const itemUser = this.props.item.user;

    const itemUsername =
      itemUser && (itemUser.fullname || `@${itemUser.nickname}`);

    const itemUserNickname = itemUser?.nickname.toLowerCase();

    return (
      <li>
        <StyledNewsItem isUnseen={!item.seen}>
          <StyledNewsItemInner>
            <StyledNotificationLeft>
              {item.itemType !== 'url' && item.itemType !== 'mission' && (
                <StyledFullsizeReactLink
                  onClick={this.props.unsetContextPaginatableMetadata}
                  href={newsLink(item)}
                />
              )}
              {item.itemType === 'url' && item.newsType !== 'gettyApproval' && (
                <StyledFullsizeReactLink href={newsLink(item)} />
              )}
              {/* its a getty aproval link */}
              {item.itemType === 'url' && item.newsType === 'gettyApproval' && (
                <StyledFullsizeReactLink href="/market/sell/getty" />
              )}
              {item.itemType === 'mission' && (
                <StyledFullsizeReactLink href={`/m/${item.mission.id}`} />
              )}
              {(item.newsType === 'photoSold' ||
                item.newsType === 'photoSoldGetty') && (
                <Link href={this.primaryThumb(item).href}>
                  <StyledPrimaryPhoto>
                    <Photo
                      size1="sq"
                      size2={48}
                      url={this.primaryThumb(item).src}
                    />
                  </StyledPrimaryPhoto>
                </Link>
              )}
              <StyledNotificationContent>
                {![
                  'blogPost',
                  'photoSold',
                  'photoSoldGetty',
                  'gettyApproval',
                ].includes(item.newsType) && (
                  <Link href={this.primaryThumb(item).href}>
                    <StyledPrimaryPhoto>
                      <Photo
                        size1="sq"
                        size2={48}
                        url={this.primaryThumb(item).src}
                      />
                    </StyledPrimaryPhoto>
                  </Link>
                )}
                {item.newsType !== 'blogPost' &&
                  item.newsType === 'gettyApproval' && (
                    <Link href="/market/sell/getty">
                      <StyledPrimaryPhoto>
                        <img alt="" src={this.primaryThumb(item).src} />
                      </StyledPrimaryPhoto>
                    </Link>
                  )}
                {item.newsType === 'blogPost' && (
                  <Link
                    href={removeAccessTokenFromUrl(
                      this.primaryThumb(item).href
                    )}>
                    <StyledPrimaryPhoto>
                      <Photo
                        size1="sq"
                        size2={48}
                        className={item.newsType}
                        url={this.primaryThumb(item).src}
                      />
                    </StyledPrimaryPhoto>
                  </Link>
                )}

                <div>
                  {/* default (excluding commentMention) */}
                  {item &&
                    item.itemType !== 'url' &&
                    !item.aggregation &&
                    item.newsType !== 'page' &&
                    item.newsType !== 'commentMention' &&
                    item.newsType !== 'photoSoldGetty' &&
                    item.newsType !== 'page' && (
                      <T
                        component={StyledStyledText}
                        color="grey3"
                        size="default"
                        display="inline"
                        album={
                          this.props.album ? (
                            <Link href={`/a/${item.album}`}>
                              {this.props.album.name}
                            </Link>
                          ) : (
                            ''
                          )
                        }
                        user={
                          itemUsername ? (
                            <T
                              noTranslate
                              useStyledText
                              size="default"
                              display="inline"
                              color="white"
                              component={Link}
                              href={`/u/${itemUserNickname}`}>
                              {itemUsername}
                            </T>
                          ) : (
                            ''
                          )
                        }
                        replyLink={
                          <ReplyLink
                            href={`/p/${item.photo?.id}/comment/${itemUserNickname}`}
                          />
                        }
                        follow={
                          itemUser && !itemUser.following ? (
                            <T
                              useStyledText
                              size="default"
                              display="inline"
                              color="white"
                              component={Link}
                              href={`/u/${itemUserNickname}/follow`}>
                              news.user.followThem
                            </T>
                          ) : null
                        }
                        comment={
                          <Comment
                            comment={item.comment}
                            linkClass="g_link-white"
                          />
                        }>
                        news.{item.itemType}.{item.newsType}
                      </T>
                    )}

                  {/* comment mention render */}
                  {item.itemType !== 'url' &&
                    item.newsType === 'commentMention' && (
                      <T
                        component={StyledStyledText}
                        color="grey3"
                        size="default"
                        display="inline"
                        user={
                          itemUser ? (
                            <T
                              noTranslate
                              useStyledText
                              size="default"
                              display="inline"
                              color="white"
                              component={Link}
                              href={`/u/${itemUserNickname}`}>
                              {itemUsername}
                            </T>
                          ) : (
                            ''
                          )
                        }
                        comment={
                          <Comment
                            comment={item.comment}
                            linkClass="g_link-white"
                          />
                        }
                        replyLink={
                          <ReplyLink
                            href={`/p/${item.photo?.id}/comment/${itemUserNickname}`}
                          />
                        }
                        onClick={this.props.unsetContextPaginatableMetadata}>
                        news.{item.itemType}.{item.newsType}
                      </T>
                    )}

                  {item.itemType === 'photo' &&
                    item.newsType === 'like' &&
                    item.aggregation?.type === 'photo' && (
                      <T
                        component={StyledStyledText}
                        color="grey3"
                        size="default"
                        display="inline"
                        user={
                          itemUser ? (
                            <T
                              noTranslate
                              useStyledText
                              size="default"
                              display="inline"
                              color="white"
                              component={Link}
                              href={`/u/${itemUserNickname}`}>
                              {itemUsername}
                            </T>
                          ) : (
                            ''
                          )
                        }
                        photoList={
                          <PhotoList
                            onClick={this.props.unsetContextPaginatableMetadata}
                            photos={item.aggregation.photos}
                          />
                        }
                        photoCount={item.aggregation.total}>
                        news.photo.aggregatedLikeMultiplePhotos
                      </T>
                    )}

                  {item.itemType === 'photo' &&
                    item.newsType === 'photoSold' &&
                    item.aggregation &&
                    item.aggregation.type === 'photo' && (
                      <T
                        component={StyledText}
                        color="grey3"
                        size="default"
                        display="inline"
                        photoList={
                          <PhotoList
                            onClick={this.props.unsetContextPaginatableMetadata}
                            photos={item.aggregation.photos}
                          />
                        }
                        photoCount={item.aggregation.total}>
                        news.photo.aggregatedSoldMultiplePhotos
                      </T>
                    )}

                  {item.itemType === 'photo' &&
                    item.newsType === 'photoSoldGetty' &&
                    item.aggregation &&
                    item.aggregation.type === 'photo' && (
                      <span>
                        <T component={Link} href="/market/sell/payout">
                          news.photo.photoSoldGetty.title
                        </T>
                        <T count={item.aggregation.total}>
                          news.photo.photoSoldGetty.body
                        </T>
                        <br />
                        <T component={Link} href="/market/sell/payout">
                          news.photo.photoSoldGetty.link
                        </T>
                        <PhotoList
                          onClick={this.props.unsetContextPaginatableMetadata}
                          photos={item.aggregation.photos}
                        />
                      </span>
                    )}

                  {item.itemType === 'photo' &&
                    item.newsType === 'comment' &&
                    item.aggregation?.type === 'photo' && (
                      <T
                        component={StyledStyledText}
                        color="grey3"
                        size="default"
                        display="inline"
                        user={
                          itemUser ? (
                            <T
                              noTranslate
                              useStyledText
                              size="default"
                              display="inline"
                              color="white"
                              component={Link}
                              href={`/u/${itemUserNickname}`}>
                              {itemUsername}
                            </T>
                          ) : (
                            ''
                          )
                        }
                        photoList={
                          <PhotoList
                            onClick={this.props.unsetContextPaginatableMetadata}
                            photos={item.aggregation.photos}
                          />
                        }
                        photoCount={item.aggregation.total}>
                        news.photo.aggregatedComment
                      </T>
                    )}

                  {item.itemType === 'photo' &&
                    item.newsType === 'like' &&
                    item.aggregation &&
                    item.aggregation.type === 'user' && (
                      <UserList
                        newsType={item.newsType}
                        users={item.aggregation.users}
                        total={item.aggregation.total}
                      />
                    )}

                  {item.itemType === 'photo' &&
                    item.newsType === 'comment' &&
                    item.aggregation &&
                    item.aggregation.type === 'user' && (
                      <UserList
                        newsType={item.newsType}
                        users={item.aggregation.users}
                        total={item.aggregation.total}
                      />
                    )}

                  {item.itemType === 'camera' && item.newsType === 'page' && (
                    <span>
                      <T>news.page.camera.text</T>
                      <br />
                      <T component={Link} href="/upload/photos">
                        news.page.camera.link
                      </T>
                    </span>
                  )}

                  {item.itemType === 'url' &&
                    item.newsType !== 'gettyApproval' && (
                      <span>
                        <Link href={removeAccessTokenFromUrl(item.url)}>
                          {item.title}
                        </Link>
                        <br />
                        {item.body}
                      </span>
                    )}
                  {item.itemType === 'photo' &&
                    item.newsType === 'photoSoldGetty' &&
                    !item.aggregation && (
                      <span>
                        <T component={Link} href="/market/sell/payout">
                          news.photo.photoSoldGetty.title
                        </T>
                        <T count={1}>news.photo.photoSoldGetty.body</T>
                        <br />
                        <T component={Link} href="/market/sell/payout">
                          news.photo.photoSoldGetty.link
                        </T>
                      </span>
                    )}
                  {item.itemType === 'url' &&
                    item.newsType === 'gettyApproval' && (
                      <span>
                        <T>news.market.getty.pending</T>
                        <br />
                        <T component="a" href="/market/sell/getty">
                          news.market.getty.approveForSale
                        </T>
                      </span>
                    )}

                  {item.itemType === 'mission' && (
                    <span>
                      <Link href={`/m/${item.mission.id}`}>{item.title}</Link>
                      <br />
                      {item.body}
                      <br />
                      <Link href={`/m/${item.mission.id}`}>
                        <T>news.mission.link</T>
                      </Link>
                    </span>
                  )}

                  <StyledNewsMeta newsType={item.newsType}>
                    <AgoWrap date={item.updated} />
                  </StyledNewsMeta>
                </div>
              </StyledNotificationContent>
            </StyledNotificationLeft>

            <StyledSecondaryPhotoWrapper>
              {secondaryThumb}
            </StyledSecondaryPhotoWrapper>
          </StyledNewsItemInner>
        </StyledNewsItem>
      </li>
    );
  }
}

export default NewsItem;
