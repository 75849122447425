/* @flow */

export const ownLightboxesPID = {
  resourceType: 'standalone',
  resourceId: 'own-lightboxes',
  paginatableName: 'lightboxes',
  paginatableType: 'lightbox',
};

export const getLightboxPhotosPID = (resourceId: string) => ({
  resourceType: 'lightbox',
  resourceId,
  paginatableName: 'photos',
  paginatableType: 'photo',
});

export const getReleasesNeededPID = (resourceId: string) => ({
  resourceType: 'user',
  resourceId: resourceId?.toLowerCase(),
  paginatableName: 'releasesNeededPhotos',
  paginatableType: 'photo',
});

export const getPotentiallyMultiReleasePhotosPID = (resourceId: string) => ({
  resourceType: 'user',
  resourceId: resourceId?.toLowerCase(),
  paginatableName: 'potentiallyMultiReleasesPhotos',
  paginatableType: 'photo',
});

export const getUserPhotosPID = (resourceId: string) => ({
  resourceType: 'user',
  resourceId: resourceId?.toLowerCase(),
  paginatableName: 'photos',
  paginatableType: 'photo',
});

export const getLikersPID = (resourceId: string) => ({
  resourceType: 'photo',
  resourceId: resourceId.toString(),
  paginatableName: 'likers',
  paginatableType: 'user',
});

export const getLikedPhotosPID = (resourceId: string) => ({
  resourceType: 'user',
  resourceId: resourceId?.toLowerCase(),
  paginatableName: 'likedPhotos',
  paginatableType: 'photo',
});

export const getFollowersPID = (resourceId: string) => ({
  resourceType: 'user',
  resourceId: resourceId?.toLowerCase(),
  paginatableName: 'followers',
  paginatableType: 'user',
});

export const getFollowingsPID = (resourceId: string) => ({
  resourceType: 'user',
  resourceId: resourceId?.toLowerCase(),
  paginatableName: 'followings',
  paginatableType: 'user',
});

export const getFollowingFeedPID = (resourceId: string) => ({
  resourceType: 'user',
  resourceId: resourceId?.toLowerCase(),
  paginatableName: 'friendsPhotos',
  paginatableType: 'photo',
});

export const getMarketPhotosPID = (resourceId: string) => ({
  resourceType: 'user',
  resourceId: resourceId?.toLowerCase(),
  paginatableName: 'marketPhotos',
  paginatableType: 'photo',
});

export const getPartnerPhotosPID = (resourceId: string) => ({
  resourceType: 'user',
  resourceId: resourceId?.toLowerCase(),
  paginatableName: 'partnerPhotos',
  paginatableType: 'photo',
});
