/* @flow */
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import Notifications from './notifications.jsx';

import { GET_NEWS } from '../../../graphql/queries/news';
import { GET_TASKS } from '../../../graphql/queries/tasks';
import { RESEND_VERIFICATION_EMAIL } from '../../../graphql/mutations/seller';

import { openModal } from '../../../helpers/actionCreators/redux';

export default compose(
  graphql(GET_NEWS, {
    name: 'news',
    options: () => ({
      ssr: false,
    }),
  }),
  graphql(GET_TASKS, {
    name: 'tasks',
    options: () => ({
      ssr: false,
      fetchPolicy: 'network-only',
    }),
  }),
  graphql(RESEND_VERIFICATION_EMAIL, { name: 'resendVerificationEmail' }),
  connect(null, {
    openModal,
  })
)(Notifications);
