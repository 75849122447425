/* @flow */

import { Component } from 'react';
import shortid from 'shortid';
import styled from '@emotion/styled';
import t from 'counterpart';
import { Box, Flex, Text } from '@eyeem-ui/atoms';
import { media } from '@eyeem-ui/theme';

import FooterLink from './footerLink';
import { StyledSocialLink, footerItemPadding } from './styles';

const StyledTitle = styled(Text)`
  margin-bottom: 0;

  @media ${media.xlargeUp} {
    margin-right: 16px;
  }
`;

type Props = {
  items: [],
  isMobile: boolean,
};

class FooterMisc extends Component<Props> {
  render() {
    return (
      <Flex
        justifyContent={['unset', 'unset', 'unset', 'space-between']}
        flexDirection={['column', 'column', 'column', 'row']}
        flexWrap="wrap"
        alignItems={['start', 'start', 'start', 'center']}>
        <Box>
          <Flex
            flexDirection={['column', 'column', 'column', 'row']}
            alignItems={['start', 'start', 'start', 'center']}>
            <StyledTitle color="grey50" variant="title6">
              {t('footer.misc.headline', { year: new Date().getFullYear() })}
            </StyledTitle>
            <Box>
              <Flex flexDirection="row" flexWrap="wrap">
                {this.props.items.map((item) => {
                  return (
                    <FooterLink
                      key={item.text + shortid.generate()}
                      isMisc
                      isMobile={this.props.isMobile}
                      item={item}
                    />
                  );
                })}
              </Flex>
            </Box>
          </Flex>
        </Box>

        <Box px={[0, 0, 0, footerItemPadding]}>
          <Flex>
            <StyledSocialLink href="https://www.facebook.com/EyeEm/">
              <svg
                width="18"
                height="18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.005 0H.995A.995.995 0 000 .995v16.01c0 .55.445.995.995.995h8.62v-6.947H7.264V8.315h2.34v-2c0-2.327 1.418-3.593 3.493-3.593.705.002 1.409.042 2.11.12v2.429h-1.434c-1.128 0-1.346.533-1.346 1.32v1.736h2.7l-.35 2.726h-2.35V18h4.58a.994.994 0 00.994-.995V.995A.995.995 0 0017.005 0z"
                  fill="#fff"
                />
              </svg>
            </StyledSocialLink>
            <StyledSocialLink href="https://www.instagram.com/eyeemphoto/">
              <svg
                width="30"
                height="30"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.682 18.708H9.318C6.938 18.708 5 16.763 5 14.373V9.627c0-2.39 1.937-4.335 4.318-4.335h5.364c2.38 0 4.318 1.944 4.318 4.335v4.746c0 2.39-1.937 4.335-4.318 4.335zM9.318 6.255A3.369 3.369 0 005.96 9.627v4.746a3.369 3.369 0 003.358 3.372h5.364a3.369 3.369 0 003.358-3.372V9.627a3.369 3.369 0 00-3.358-3.372H9.318zm2.732 9.603c-2.12 0-3.843-1.732-3.843-3.86S9.93 8.14 12.05 8.14s3.843 1.73 3.843 3.859c0 2.127-1.724 3.859-3.843 3.859zm0-6.755a2.893 2.893 0 00-2.884 2.896 2.893 2.893 0 002.884 2.895 2.893 2.893 0 002.884-2.895 2.893 2.893 0 00-2.884-2.896zm4.535-.906a.799.799 0 01-.798.801.8.8 0 01-.797-.8.8.8 0 01.797-.801.8.8 0 01.798.8z"
                  fill="#FFF"
                  fillRule="evenodd"
                />
              </svg>
            </StyledSocialLink>
            <StyledSocialLink href="https://twitter.com/EyeEm">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.0224 8.10405C15.0272 8.2074 15.0292 8.3113 15.0292 8.4157C15.0292 11.6012 12.6044 15.2747 8.1703 15.2747C6.80865 15.2747 5.5419 14.8756 4.475 14.1919C4.66365 14.2141 4.85555 14.2253 5.05005 14.2253C6.17955 14.2253 7.2189 13.84 8.04415 13.1933C6.98885 13.1737 6.09895 12.4767 5.7921 11.519C5.9389 11.5471 6.08995 11.5625 6.24525 11.5625C6.4652 11.5625 6.6783 11.5328 6.88075 11.4776C5.7778 11.2566 4.9467 10.2819 4.9467 9.11425C4.9467 9.1037 4.9467 9.0936 4.94725 9.08355C5.27215 9.26425 5.6437 9.37295 6.03905 9.3851C5.3919 8.95315 4.9663 8.21485 4.9663 7.37845C4.9663 6.93695 5.08555 6.523 5.2928 6.16685C6.48165 7.62545 8.25825 8.5848 10.2618 8.6855C10.2204 8.509 10.1992 8.32505 10.1992 8.13585C10.1992 6.80495 11.2789 5.72535 12.6103 5.72535C13.3035 5.72535 13.93 6.0184 14.3699 6.487C14.919 6.37885 15.4348 6.17795 15.9006 5.90185C15.7204 6.4647 15.3382 6.93695 14.8406 7.23535C15.3282 7.17705 15.793 7.04775 16.225 6.85585C15.9022 7.33925 15.4931 7.7638 15.0224 8.10405ZM10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0Z"
                  fill="white"
                />
              </svg>
            </StyledSocialLink>
            <StyledSocialLink href="https://vimeo.com/eyeem">
              <svg
                width="18"
                height="18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 17.991V0h18v17.991H0zM15.251 5.903c.078-1.695-.553-2.561-1.89-2.6-1.802-.059-3.023.958-3.663 3.052.33-.142.652-.214.963-.214.662 0 .954.372.876 1.114-.04.45-.332 1.105-.876 1.964-.545.86-.954 1.29-1.226 1.29-.351 0-.672-.662-.964-1.988-.098-.388-.274-1.382-.526-2.98-.234-1.481-.857-2.173-1.87-2.076-.428.04-1.07.429-1.927 1.168-.623.565-1.256 1.129-1.898 1.693l.612.788c.583-.408.923-.613 1.02-.613.447 0 .865.7 1.254 2.1l1.05 3.85c.524 1.4 1.165 2.099 1.924 2.099 1.224 0 2.72-1.15 4.489-3.447 1.71-2.201 2.595-3.934 2.652-5.2z"
                  fill="#fff"
                />
              </svg>
            </StyledSocialLink>
            <StyledSocialLink href="https://www.youtube.com/channel/UCx8sMqfYEyo2y-PItosCQrA">
              <svg
                width="30"
                height="30"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.568 14.11V9.89l3.66 2.11-3.66 2.11zm8.14-5.548a1.772 1.772 0 00-1.238-1.263C16.378 7 12 7 12 7s-4.378 0-5.47.299a1.772 1.772 0 00-1.237 1.263C5 9.676 5 12 5 12s0 2.324.293 3.438a1.772 1.772 0 001.237 1.264C7.622 17 12 17 12 17s4.378 0 5.47-.298a1.772 1.772 0 001.237-1.264C19 14.324 19 12 19 12s0-2.324-.293-3.438z"
                  fill="#FFF"
                  fillRule="evenodd"
                />
              </svg>
            </StyledSocialLink>
          </Flex>
        </Box>
      </Flex>
    );
  }
}

export default FooterMisc;
