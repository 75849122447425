/* @flow */
import qs from 'qs';

export const getRequestedDimensions = (url: string) => {
  // split url to get to size1 and size2 values
  const urlSplit = url.split('/');

  if (url.includes('/illustrations') || url.includes('/i/')) {
    const requestParams = url.split('?')[1];
    const requestParamsObj = qs.parse(requestParams);
    if (requestParamsObj.width) {
      return {
        size1: 'w',
        size2: requestParamsObj.width,
      };
    } else {
      return {
        size1: 'h',
        size2: requestParamsObj.height,
      };
    }
  }

  return {
    size1: urlSplit[5],
    size2: urlSplit[6],
  };
};

export const getActualPhotoDimensions = (
  requestedDimensions: { size1: string, size2: string },
  originalDimensions: { width: number, height: number }
) => {
  let width;
  let height;
  switch (requestedDimensions.size1) {
    case 'h':
      height = parseInt(requestedDimensions.size2, 10);
      width = Math.floor(
        (originalDimensions.height / originalDimensions.width) * height
      );
      break;

    case 'w':
      width = parseInt(requestedDimensions.size2, 10);
      height = Math.floor(
        (originalDimensions.height / originalDimensions.width) * width
      );
      break;

    case 'sq':
      width = parseInt(requestedDimensions.size2, 10);
      height = parseInt(requestedDimensions.size2, 10);
      break;

    default: {
      const requestedWidth = parseInt(requestedDimensions.size1, 10);
      const requestedHeight = parseInt(requestedDimensions.size2, 10);

      const imageRatio = originalDimensions.width / originalDimensions.height;
      const requestedRatio = requestedWidth / requestedHeight;
      if (requestedRatio > imageRatio) {
        width = Math.floor(
          (originalDimensions.width * requestedHeight) /
            originalDimensions.height
        );
        height = requestedHeight;
      } else {
        width = requestedWidth;
        height = Math.floor(
          (originalDimensions.height * requestedWidth) /
            originalDimensions.width
        );
      }
    }
  }

  return {
    width,
    height,
  };
};

export const isCachedPhotoSmallerThanRequested = (
  url: string,
  originalDimensions: { width: number, height: number },
  cachedDimensions: { width: number, height: number }
) => {
  const requestedDimensions = getRequestedDimensions(url);
  const actualDimensions = getActualPhotoDimensions(
    requestedDimensions,
    originalDimensions
  );

  return (
    cachedDimensions.width * cachedDimensions.height <
    actualDimensions.width * actualDimensions.height
  );
};
