/* @flow */

import { ThemeProvider } from '@eyeem-ui/theme';
import { Spinner } from '@eyeem-ui/atoms';
import NewsItem from './newsItem/';

function News(props: { news: NewsState, loading: boolean }) {
  return (
    <ul>
      {props.news?.items.slice(0, props.news.limit).map((item) => (
        <NewsItem key={item.id} item={item} />
      ))}
      {props.loading && (
        <li>
          <ThemeProvider>
            <Spinner />
          </ThemeProvider>
        </li>
      )}
    </ul>
  );
}

export default News;
