/* @flow */
/* eslint-env browser */
/**
 * Component for displaying notification.
 *
 * Pass a notification to the interaction store to trigger display.
 * Notifications are objects with the keys:
 * { notification: 'a translate key inside notification.' }
 */
/** @jsxRuntime classic */
/** @jsx jsx */
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { jsx } from 'theme-ui'; // eslint-disable-line
import t from 'counterpart';
import styled from '@emotion/styled';
import { Flex, Banner } from '@eyeem-ui/atoms';
import { ThemeProvider } from '@eyeem-ui/theme';

import T from '../general/translate.jsx';

import { TEST_NOTIFICATION_BAR } from '../../constants/pageObjectSelectors';
import { track } from '../../helpers/tools';

const Wrapper = styled.div`
  position: fixed;
  z-index: 15;
  bottom: 0;
  margin-left: ${({ theme }) => theme.space[2]}px;
  margin-right: ${({ theme }) => theme.space[2]}px;
  margin-bottom: ${({ theme }) => theme.space[2]}px;
`;

const StyledBanner = styled(Banner)`
  margin-top: ${({ theme }) => theme.space[2]}px;

  a {
    color: ${({ theme }) => theme.colors.aqua50};
  }
`;

type Props = {
  dismissNotification: Function,
  notifications: $ReadOnlyArray<Notification>,
};

const NotificationBar = (props: Props) => {
  const getBannerVariant = (type) => {
    const variants = {
      default: 'default',
      warning: 'default',
      success: 'positive',
      error: 'alert',
    };

    return variants[`${type}`];
  };

  return (
    <ThemeProvider mode={props.notifications[0]?.mode || 'dark'}>
      <Wrapper data-test-id={TEST_NOTIFICATION_BAR}>
        <TransitionGroup>
          {props.notifications.length > 0 &&
            props.notifications.reverse().map((item: Notification) => (
              <CSSTransition
                classNames="transition_slideUp"
                timeout={{ enter: 700, exit: 400 }}
                key={item.notification}>
                <Flex>
                  <StyledBanner
                    icon={item.icon}
                    variant={getBannerVariant(item.type || 'default')}
                    onClose={() => {
                      props.dismissNotification(item);
                      return track({
                        eventType: 'click_inbound',
                        eventName: 'cta_dismiss_notification',
                        eventAction: 'click_icon',
                        eventLabel: 'close',
                        eventPosition: 'ad_banner',
                      });
                    }}>
                    <T
                      noTranslate={!!item.customCopy}
                      component="span"
                      as="div"
                      with={{
                        support: (
                          <a
                            sx={{
                              textDecoration: 'underline',
                            }}
                            href="mailto:support@eyeem.com">
                            {t('notifications.supportEmail')}
                          </a>
                        ),
                        supportDeleteAccount: (
                          <a
                            sx={{
                              textDecoration: 'underline',
                            }}
                            target="_blank"
                            rel="noreferrer"
                            href="mailto:support@eyeem.com?subject=Delete my account">
                            {t('notifications.supportEmail')}
                          </a>
                        ),
                        getInTouch: (
                          /* We are currently unable to use styled components from @emotion inside a T, it returns a internalError: too much recursion. For now this is a working solution. */
                          <a
                            sx={{
                              textDecoration: 'underline',
                            }}
                            href="mailto:support@eyeem.com">
                            {t('notifications.cart.getInTouch')}
                          </a>
                        ),
                        marketSupport: (
                          <T
                            component="a"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={t(
                              'payout.actions.requestPassword.marketSupport.href'
                            )}
                            className="g_link g_link-white g_link-underlined">
                            payout.actions.requestPassword.marketSupport.label
                          </T>
                        ),
                      }}
                      amount={item.amount}
                      count={item.count}
                      key={item.notification}>
                      {item.customCopy || `notifications.${item.notification}`}
                    </T>
                  </StyledBanner>
                </Flex>
              </CSSTransition>
            ))}
        </TransitionGroup>
      </Wrapper>
    </ThemeProvider>
  );
};

export default NotificationBar;
