/* @flow */
import { SET_CURRENT_DISCOUNT_CODE } from '../constants/actionTypes';

export default function discountCode(
  state: DiscountCodeState = {},
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case SET_CURRENT_DISCOUNT_CODE:
      return {
        ...state,
        currentDiscountCode: action.payload,
      };

    default:
      return state;
  }
}

export const getCurrentDiscountCode = (state: discountCodeState) =>
  state.currentDiscountCode;
