/* @flow */
import t from 'counterpart';
import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Box, Button, Text } from '@eyeem-ui/atoms';
import type { FormProps } from 'redux-form';
import { Field, reduxForm } from 'redux-form';

import Input from '../../formElements/input.jsx';

import validate, { VALIDATOR_EMAIL } from '../../../helpers/validators';
import { UPDATE_MARKET_USER } from '../../../graphql/mutations/seller';

const email = validate(VALIDATOR_EMAIL);

type Props = {
  showNotification: Function,
  close: Function,
} & FormProps;

const ChangeMarketEmail = (props: Props) => {
  const [updateMarketUser, { data }] = useMutation(UPDATE_MARKET_USER);

  useEffect(() => {
    if (data?.updateMarketUser?.email) {
      props.close();
      props.showNotification({
        type: 'success',
        notification: 'changeMarketEmail',
      });
    }
  }, [data, props]);

  const submit = (values: { email: string }) =>
    updateMarketUser({
      variables: {
        payload: {
          email: values.email,
        },
      },
    });

  return (
    <Box p={'24px'} pt={2}>
      <Box mb={3}>
        <Text color="grey50">{t('modal.changeMarketEmail.subline')}</Text>
      </Box>
      <form onSubmit={props.handleSubmit(submit)}>
        <Field
          component={Input}
          placeholder={t('modal.changeMarketEmail.placeholderEmail')}
          name="email"
          type="email"
          validate={email}
        />
        <Box mt="2">
          <Button variant="subtle" onClick={props.close}>
            {t('modal.cancel.default')}
          </Button>
          <Button type="submit" className="float-right">
            {t('modal.changeMarketEmail.confirm')}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default reduxForm({
  form: 'changeMarketEmail',
})(ChangeMarketEmail);
