/* @flow */

import { gql } from '@apollo/client';

export const SimpleUserFragment = gql`
  fragment SimpleUser on User {
    id
    nickname
    fullname
    thumbUrl
    coverPhoto {
      id
      previewUrl: photoUrl
    }
  }
`;
