import { gql } from '@apollo/client';

export const CREATE_ACCOUNT = gql`
  mutation createAccount($payload: SellerData) {
    createAccount(payload: $payload) {
      id
      firstName
    }
  }
`;

export const SUBMIT_KEYWORDING_BACKLOG_PHOTO = gql`
  mutation submitKeywordingBacklogPhoto($payload: KeywordingBacklogItemInput!) {
    submitKeywordingBacklogPhoto(payload: $payload) {
      eyeemPhotoId
    }
  }
`;

export const REMOVE_PAYPAL_ACCOUNT = gql`
  mutation removePaypalAccount {
    removePaypalAccount {
      statusCode
    }
  }
`;

export const SET_PAYPAL_ACCOUNT = gql`
  mutation setPaypalAccount($payload: SetPaypalAccountInput!) {
    setPaypalAccount(payload: $payload) {
      id
      address
    }
  }
`;

export const REQUEST_PAYPAL_CHANGE_TOKEN = gql`
  mutation requestPaypalChangeToken {
    requestPaypalChangeToken {
      statusCode
    }
  }
`;

export const REQUEST_PAYOUT = gql`
  mutation requestPayout($payload: RequestPayoutInput!) {
    requestPayout(payload: $payload) {
      statusCode
    }
  }
`;

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation resendVerificationEmail {
    resendVerificationEmail {
      email
    }
  }
`;

export const UPDATE_MARKET_USER = gql`
  mutation updateMarketUser($payload: SellerData) {
    updateMarketUser(payload: $payload) {
      email
    }
  }
`;
