/* eslint-disable react/no-multi-comp, react/prefer-stateless-function */
import { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';

import SearchBar from './general/searchBar/';
import Footer from './general/footer/';

import { DOM_IDS } from '../constants/misc';

const isBrowser = typeof document !== 'undefined';

const searchRoot =
  isBrowser &&
  document.getElementById(DOM_IDS.REACT_STAGE_PORTAL_SEARCH_RENDER);

const footerRoot =
  isBrowser && document.getElementById(DOM_IDS.REACT_STAGE_FOOTER);

class SearchPortal extends Component {
  constructor(props) {
    super(props);

    this.searchEl = isBrowser && document.createElement('div');
  }

  componentDidMount() {
    if (searchRoot) {
      searchRoot.innerHTML = '';
      searchRoot.appendChild(this.searchEl);
    }
  }

  componentWillUnmount() {
    if (searchRoot) {
      searchRoot.removeChild(this.searchEl);
    }
  }

  render() {
    if (searchRoot) {
      return ReactDOM.createPortal(
        <SearchBar inPage focusOnMount activeCheck={false} currentPath="/" />,
        this.searchEl
      );
    }
    return null;
  }
}

class FooterPortal extends Component {
  constructor(props) {
    super(props);

    this.footerEl = isBrowser && document.createElement('div');
  }

  componentDidMount() {
    if (footerRoot) {
      footerRoot.innerHTML = '';
      footerRoot.appendChild(this.footerEl);
    }
  }

  componentWillUnmount() {
    if (footerRoot) {
      footerRoot.removeChild(this.footerEl);
    }
  }

  render() {
    if (footerRoot) {
      return ReactDOM.createPortal(<Footer />, this.footerEl);
    }
    return null;
  }
}

class PortalWrapper extends Component {
  render() {
    return (
      <Fragment>
        <SearchPortal key="searchportal" />
        <FooterPortal key="footerportal" />
      </Fragment>
    );
  }
}

export default PortalWrapper;
