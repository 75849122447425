/* @flow */
import { useState, useEffect } from 'react';
import get from 'lodash/get';
import t from 'counterpart';
import type { FieldProps } from 'redux-form';
import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import styled from '@emotion/styled';

import { InputPassword, Text, Flex } from '@eyeem-ui/atoms';

const PASSWORD_MIN_LENGTH = 8;

const StyledWrapper = styled.div`
  position: relative;
`;

const PasswordCheckQuery = gql`
  query passwordCheck($password: String!) {
    passwordCheck(password: $password) {
      valid
      score
    }
  }
`;

type Props = {
  placeholder: string,
  disabled?: boolean,
  'data-test-id'?: string,
  isModal: Boolean,
  setPasswordValidation: Function,
  isOptionalField: boolean,
} & FieldProps;

const InputPasswordWrapper = (props: Props) => {
  const [password, setPassword] = useState('');
  const [currentScore, setCurrentScore] = useState('');
  const [checkPassword, { data }] = useLazyQuery(PasswordCheckQuery);
  const { meta } = props;
  const score = get(data, 'passwordCheck.score');

  useEffect(() => {
    if (password.length >= PASSWORD_MIN_LENGTH) {
      checkPassword({
        variables: {
          password,
        },
      });
    }
  }, [password, checkPassword]);

  useEffect(() => {
    if (score) setCurrentScore(score);
  }, [score]);

  const handleChange = (event) => {
    if (props.setPasswordValidation) {
      props.setPasswordValidation(event.target.value);
    }
    setPassword(event.target.value);
  };

  const passwordProps = props.setPasswordValidation
    ? {
        onChange: handleChange,
        value: password,
      }
    : {};

  const getIsValid = () => {
    if (meta.touched && !password) {
      return false;
    }
    if (props.setPasswordValidation) {
      return password && password?.length >= PASSWORD_MIN_LENGTH;
    }
    if (
      !meta.invalid &&
      !meta.pristine &&
      !meta.error &&
      password?.length >= PASSWORD_MIN_LENGTH
    ) {
      return true;
    }
    if (meta.error) {
      return false;
    }
    return false;
  };

  const getErrorMessage = () => {
    if (!meta.dirty && props.isOptionalField) {
      return undefined;
    }
    if (meta.touched && !password && !meta.dirty) {
      return t('forms.error.VALIDATOR_REQUIRED');
    }
    if (meta.touched && meta.error && !meta.active) {
      return meta.error;
    }
    if (props.setPasswordValidation) {
      return (
        password &&
        password?.length < PASSWORD_MIN_LENGTH &&
        t('forms.label.password.VALIDATOR_MIN_LENGTH')
      );
    }
  };

  return (
    <StyledWrapper>
      <InputPassword
        {...props.input}
        {...passwordProps}
        type="password"
        disabled={props.disabled}
        placeholder={props.placeholder}
        data-test-id={props['data-test-id']}
        isValid={getIsValid()}
        pending={meta.asyncValidating}
        errorMessage={getErrorMessage()}
      />
      {currentScore &&
        password.length >= PASSWORD_MIN_LENGTH &&
        props.setPasswordValidation && (
          <Flex>
            <Text
              color={t(`authForms.signup.passwordScores.${currentScore}.color`)}
              variant="title6"
              bold>
              {t(`authForms.signup.passwordScores.${currentScore}.strength`)}
            </Text>
            {currentScore !== 3 && (
              <Text variant="title6" bold>
                &nbsp;-&nbsp;
                {t(`authForms.signup.passwordScores.${currentScore}.text`)}
              </Text>
            )}
          </Flex>
        )}
    </StyledWrapper>
  );
};

export default InputPasswordWrapper;
