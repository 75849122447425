// @flow
import { gql } from '@apollo/client';

export const GET_NEWS = gql`
  query NewsQuery($oldestId: ID) {
    news(oldestId: $oldestId) {
      id
      total
      unseen
      oldestId
      newestId
      items {
        id
        itemType
        newsType
        updated
        seen
        title
        body
        url
        photo {
          id
          previewUrl: photoUrl
        }
        user {
          id
          nickname
          fullname
          thumbUrl
        }
        comment {
          extendedMessage
          mentionedUsers {
            id
            nickname
            fullname
            thumbUrl
          }
        }
        aggregation {
          type
          total
          photos {
            id
            previewUrl: photoUrl
          }
          users {
            id
            nickname
            fullname
            thumbUrl
          }
        }
      }
    }
  }
`;

export const GET_NEWS_UNSEEN = gql`
  query NewsUnseenQuery($oldestId: ID) {
    news(oldestId: $oldestId) {
      id
      unseen
      newestId
    }
  }
`;
