import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';

import LightboxModal from './lightbox.jsx';
import { getAuthUser } from '../../../reducers/';
import { notifyModalFailure } from '../../../helpers/actionCreators/redux';
import { getOffset } from '../../../helpers/tools';
import {
  GRID_STEP_SIZE,
  PAGINATION_PARADIGM_MANUAL,
} from '../../../constants/misc';
import {
  CREATE_LIGHTBOX,
  UPDATE_LIGHTBOX,
} from '../../../graphql/mutations/lightbox';
import {
  GET_LIGHTBOX,
  GET_OWN_LIGHTBOXES,
} from '../../../graphql/queries/lightbox';
import { LIGHTBOX_FOR_GRID_QUERY_ALIAS } from '../../../graphql/constants';

const STEP_SIZE = GRID_STEP_SIZE[PAGINATION_PARADIGM_MANUAL];

const mapDispatchToProps = {
  notifyModalFailure,
};
const mapStateToProps = (state) => {
  return {
    authUser: getAuthUser(state),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql(GET_LIGHTBOX, {
    name: 'getLightbox',
    skip: ({ lightboxId }) => !lightboxId,
    options: ({ lightboxId }) => ({
      variables: {
        lightboxId,
      },
    }),
  }),
  graphql(CREATE_LIGHTBOX, {
    name: 'createLightbox',
    options: ({ authUser, page }) => {
      return {
        refetchQueries: [
          {
            query: GET_OWN_LIGHTBOXES,
            variables: {
              offset: getOffset(authUser, page),
              limit: STEP_SIZE,
            },
          },
        ],
      };
    },
  }),
  graphql(UPDATE_LIGHTBOX, {
    name: 'updateLightbox',
    options: ({ authUser, page }) => {
      return {
        refetchQueries: [
          LIGHTBOX_FOR_GRID_QUERY_ALIAS,
          {
            query: GET_OWN_LIGHTBOXES,
            variables: {
              offset: getOffset(authUser, page),
              limit: STEP_SIZE,
            },
          },
        ],
      };
    },
  })
)(LightboxModal);
