import { connect } from 'react-redux';
import { getAuthUser, getIsBuyer } from '../../../reducers/';

import HubspotChatBot from './hubspotChatBot';

const mapStateToProps = (state) => ({
  authUser: getAuthUser(state),
  isBuyer: getIsBuyer(state),
});

export default connect(mapStateToProps, {})(HubspotChatBot);
