// @flow
import {
  ApolloClient,
  InMemoryCache,
  from,
  split,
  HttpLink,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { onError } from '@apollo/client/link/error';
import { sha256 } from 'crypto-hash';
import unfetch from 'unfetch';
import * as Sentry from '@sentry/browser';

import { memoryCacheConfig } from './cacheConfig';

const graphQLEndpoint = () => {
  const { clientPort, clientProtocol, hostname } = window.eyeconfig?.server;
  return `${clientProtocol}://${hostname}${clientPort}/graphql`;
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      const fullMessage = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;

      if (process.env.NODE_ENV === 'production') {
        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          Sentry.captureException(fullMessage);
        });
      }
    });
  }
  if (networkError) {
    const message = `[Network error]: ${networkError}`;

    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope((scope) => {
        scope.setLevel('warning');
        Sentry.captureException(message);
      });
    }
  }
});

const persistedQueryLink = createPersistedQueryLink({
  sha256,
  /* Make sure GET requests are used for hashed queries since the default POST
     ones from graphql are not CDN cacheable.
     https://www.apollographql.com/docs/apollo-server/performance/apq/#using-get-requests-with-apq-on-a-cdn
  */
  useGETForHashedQueries: true,
});

const uploadLink = new createUploadLink({
  uri: graphQLEndpoint,
});

const httpLink = new HttpLink({
  credentials: 'same-origin',
  uri: graphQLEndpoint,
  // IE11 needs this explicitly. Earlier polyfills are not good enough
  fetch: typeof window.fetch === 'function' ? window.fetch : unfetch,
}); // must come last

/**
 * apollo-upload-client needs to be terminal, httpLink too.
 * This looks for { context: { hasUpload: true }} in the mutation call
 * (see context option: https://www.apollographql.com/docs/react/data/mutations/#options)
 * Solution baked here: https://github.com/jaydenseric/apollo-upload-client/issues/200#issuecomment-692462146
 */
const terminalLinks = split(
  ({ getContext }) => getContext().hasUpload,
  uploadLink,
  httpLink
);

// Some of our Cypress tests are failing because of the persisted queries
// We only activate those on prod
const link =
  process.env.NODE_ENV === 'development'
    ? from([errorLink, terminalLinks])
    : from([errorLink, persistedQueryLink, terminalLinks]);

export default new ApolloClient({
  cache: new InMemoryCache(memoryCacheConfig).restore(window.__APOLLO_STATE__), // eslint-disable-line
  uri: graphQLEndpoint(),
  link,
});
