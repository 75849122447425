/* @flow */
import { RECEIVE_NORMALIZED_DATA } from '../constants/actionTypes';

import { merge, findViaId } from '../helpers/tools';

export default function albums(
  state: AlbumsState = [],
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case RECEIVE_NORMALIZED_DATA:
      return action.payload.album ? merge(state, action.payload.album) : state;
    default:
      return state;
  }
}

export const mapInitialDataToInitialState = (initialData: NormalizedData) =>
  initialData.album;
export const getAlbum = (state: AlbumState, id: EyeEmAlbumId) =>
  findViaId(state, id);
