// ACTION STRING CONSTANTS //

// This is necessary mainly for two reasons:
// 1 - we can see at a single glance which actions exist (and it's more clear in PRs
//   which ones were added or removed)
// 2 - It helps finding typos: If a constant is undefined it'll throw an error (rather
//   than just doing nothing, as no store is listening to the mistyped action string)

// Expects normalized data as formatted by our Normalizer on the Node server:
export const RECEIVE_NORMALIZED_DATA = 'RECEIVE_NORMALIZED_DATA';

export const SHOULD_ASK_FOR_NAVIGATION_CONFIRMATION =
  'SHOULD_ASK_FOR_NAVIGATION_CONFIRMATION';
export const TOGGLE_LIKE = 'TOGGLE_LIKE';
export const TOGGLE_USER_FOLLOW = 'TOGGLE_USER_FOLLOW';
export const ADD_COMMENT = 'ADD_COMMENT';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const ADD_OR_UPDATE_CART_ITEM = 'ADD_OR_UPDATE_CART_ITEM';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const SAVE_FOR_LATER = 'SAVE_FOR_LATER';
export const ADD_TO_MARKET = 'ADD_TO_MARKET';
export const DELETE_PHOTO = 'DELETE_PHOTO';
export const EDIT_PHOTO = 'EDIT_PHOTO';
export const REQUEST_PAGINATABLE = 'REQUEST_PAGINATABLE';
export const SET_CONTEXT_PAGINATABLE_METADATA =
  'SET_CONTEXT_PAGINATABLE_METADATA';
export const UNSET_CONTEXT_PAGINATABLE_METADATA =
  'UNSET_CONTEXT_PAGINATABLE_METADATA';
export const ENTERPRISE_DOWNLOAD = 'ENTERPRISE_DOWNLOAD';
export const AUTH_PENDING = 'AUTH_PENDING';
export const REMOVE_FROM_MARKET = 'REMOVE_FROM_MARKET';
export const CLEAR_FLYOUT = 'CLEAR_FLYOUT';
export const UPDATE_MARKET_USER = 'UPDATE_MARKET_USER';
export const SET_SESSION_FIELD = 'SET_SESSION_FIELD';
export const SET_SEARCH_INPUT_FIELD = 'SET_SEARCH_INPUT_FIELD';
export const UPDATE_SEARCH_FILTERS = 'UPDATE_SEARCH_FILTERS';
export const RESET_SEARCH_FILTERS = 'RESET_SEARCH_FILTERS';
export const RESET_SEARCH_SORT = 'RESET_SEARCH_SORT';
export const UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM';
export const UPDATE_SEARCH_SORT = 'UPDATE_SEARCH_SORT';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION';
export const DISMISS_NOTIFICATIONS = 'DISMISS_NOTIFICATIONS';
export const DISMISS_NOTIFICATIONS_ON_CLICK = 'DISMISS_NOTIFICATIONS_ON_CLICK';
export const ALLOW_NSFW_PHOTO = 'ALLOW_NSFW_PHOTO';
export const UPDATE_FACEBOOK_SETTING = 'UPDATE_FACEBOOK_SETTING';
export const UPDATE_USER_FLAG = 'UPDATE_USER_FLAG';
export const CLEAR_CART_REMOVALS = 'CLEAR_CART_REMOVALS';
export const SET_AUTH_REF = 'SET_AUTH_REF';
export const STORE_CONVERSION_EVENT_OPTION = 'STORE_CONVERSION_EVENT_OPTION';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const NOTIFY_MODAL_SUCCESS = 'NOTIFY_MODAL_SUCCESS';
export const NOTIFY_MODAL_FAILURE = 'NOTIFY_MODAL_FAILURE';
export const TOGGLE_LIGHTBOX_STRIP = 'TOGGLE_LIGHTBOX_STRIP';
export const CREATE_LIGHTBOX = 'CREATE_LIGHTBOX';
export const DELETE_LIGHTBOX = 'DELETE_LIGHTBOX';
export const UPDATE_LIGHTBOX = 'UPDATE_LIGHTBOX';
export const ADD_TO_LIGHTBOX = 'ADD_TO_LIGHTBOX';
export const REMOVE_FROM_LIGHTBOX = 'REMOVE_FROM_LIGHTBOX';
export const SET_KEYBOARD_SCORING_INDEX = 'SET_KEYBOARD_SCORING_INDEX';
export const UPDATE_BROWSER_INFO = 'UPDATE_BROWSER_INFO';
export const UPDATE_PAYMENT_STATUS = 'UPDATE_PAYMENT_STATUS';
export const CLEAR_CART = 'CLEAR_CART';
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';
export const SET_TEMP_FIELD = 'SET_TEMP_FIELD';
export const SET_PAGE = 'SET_PAGE';
export const ADD_UPLOAD_PHOTO = 'ADD_UPLOAD_PHOTO';
export const UPDATE_UPLOAD_PHOTOS = 'UPDATE_UPLOAD_PHOTOS';
export const ADD_UPLOAD_FILE_ERROR = 'ADD_UPLOAD_FILE_ERROR';
export const REMOVE_UPLOAD_PHOTOS = 'REMOVE_UPLOAD_PHOTOS';
export const SELECT_UPLOAD_PHOTOS = 'SELECT_UPLOAD_PHOTOS';
export const CLEAR_UPLOAD_SELECTION = 'CLEAR_UPLOAD_SELECTION';
export const TOGGLE_UPLOAD_PHOTO_SELECTION = 'TOGGLE_UPLOAD_PHOTO_SELECTION';
export const SET_UPLOAD_POSTING_STATUS = 'SET_UPLOAD_POSTING_STATUS';
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';
export const SET_UPLOAD_PREFILL_INFO = 'SET_UPLOAD_PREFILL_INFO';
export const RESET_UPLOAD = 'RESET_UPLOAD';
export const RESET_UPLOAD_ERRORS = 'RESET_UPLOAD_ERRORS';
export const SET_PHOTO_CACHE = 'SET_PHOTO_CACHE';
export const SET_SEARCH_TRACK = 'SET_SEARCH_TRACK';
export const RESET_SEARCH_TRACK = 'RESET_SEARCH_TRACK';
export const SET_SELECTED_LICENSE = 'SET_SELECTED_LICENSE';
export const SET_CURRENT_DISCOUNT_CODE = 'SET_CURRENT_DISCOUNT_CODE';

// action used for unit tests; this action mustn't be implemented by any of the reducers
export const UNKNOWN_ACTION = 'UNKNOWN_ACTION';
