/* @flow */
/* eslint-env browser */
/*
 * Lightbox Strip Router provides the router context that is needed
 * to use withRouter HOC in the lightbox strip
 */
import { Location, Locations } from 'react-router-component';

import LightboxStrip from './lightboxStrip/';

function LightboxStripRouter(props: { path: string }) {
  return (
    <Locations component={null} path={props.path}>
      <Location path={/./} handler={<LightboxStrip />} />
    </Locations>
  );
}

export default LightboxStripRouter;
