import styled from '@emotion/styled';
import { media } from '@eyeem-ui/theme';
import baseTheme from '../../../helpers/theme';

const searchIconWidth = '38px';

const StyledSearchForm = styled.form`
  width: calc(100% - ${searchIconWidth});
  display: inline-flex;
  align-items: flex-end;
  justify-content: space-between;
  white-space: nowrap;
  position: relative;
  line-height: 1.25rem;
  @media ${media.mediumUp} {
    min-width: calc(320px - ${searchIconWidth});

    & div:first-child {
      width: 100%;
    }
  }
`;

const StyledSearchInput = styled.input`
  font-size: ${(props) => (props.inPage ? '1rem' : '0.875rem')};
  line-height: ${(props) => (props.inPage ? '1.5rem' : '1.25rem;')};
  width: 100%;
  min-width: 0;
  border: 0px;
  padding-top: ${(props) => (props.inPage ? '14px' : '7px')};
  padding-bottom: ${(props) => (props.inPage ? '12px' : '7px')};
  display: inline;
  vertical-align: top;
  background: transparent;
  height: ${(props) => (props.inPage ? '48px' : '100%')};
  color: ${({ theme }) => theme.colors.grey0};

  &::placeholder {
    color: ${({ isActive, theme }) =>
      isActive
        ? baseTheme.colors.uncategorized.unnamed17
        : theme.colors.grey100} !important;
  }
`;

const StyledSearchButton = styled.span`
  display: flex;
  align-items: center;
  padding-right: ${(props) => (props.inPage ? '12px' : '8px')};
  padding-left: ${(props) => (props.inPage ? '20px' : '16px')};
  max-height: inherit;
  border-color: transparent;
  cursor: auto;

  @media ${media.largeUp} {
    font-size: ${(props) => (props.inPage ? '1rem' : '0.875rem')};
    height: ${(props) => (props.inPage ? '56px' : '40px')};
  }
`;

const StyledSearchContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;
  transition: background-color 0.2s ease-in-out;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.grey100 : baseTheme.colors.uncategorized.unnamed3};
`;

export {
  StyledSearchForm,
  StyledSearchButton,
  StyledSearchContainer,
  StyledSearchInput,
};
