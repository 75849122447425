/* @flow */
/* eslint-env browser */
import { Component } from 'react';
import t from 'counterpart';
import styled from 'styled-components';
import { getFilter } from '../../../../helpers/filterTypes';

import { onFilterChange } from '../../../../helpers/search';
import { track } from '../../../../helpers/tools';

import {
  DROPDOWN_TRIGGER_STYLE_TRANSPARENT_ON_BRIGHT,
  DROPDOWN_TRIGGER_STYLE_TRANSPARENT_ON_DARK,
} from '../../../../constants/misc';

import DropdownSelect from '../../dropdownSelect.jsx';

type Props = {
  updateSearchFilters: Function,
  unsetContextPaginatableMetadata: () => void,
  setSearchTrack: Function,
  navigate: Function,
  inPage: boolean,
  isSearchInactive: boolean,
  isBuyer: boolean,
  stepSize: number,
  searchData: SearchData,
  authUser: AuthUser,
  searchTerm?: string,
  selectedFilter?: string,
  setSelectedFilter: Function,
};

const StyledSearchInputDropdownSelect = styled(DropdownSelect)`
  position: absolute;
  flex-shrink: 0;
  text-align: right;
  width: 190px;
  padding-right: 10px;
  right: 0;
  top: 0;
  height: 100%;
`;

export default class SearchInputDropdown extends Component<Props> {
  handleFilterChange = (filterValue: string) => {
    if (!this.props.searchData.q) {
      // On search landing
      this.props.setSelectedFilter({
        filterType: 'proDownloads',
        filterValue,
      });

      track({
        eventType: t('tracking.eventType.inbound'),
        eventAction: 'preselect',
        eventName: 'filter_proDownloads',
        eventLabel: filterValue,
        eventPosition: 'buyerMlpSearch1',
      });

      if (!this.props.searchTerm) {
        return;
      }
    }

    onFilterChange({
      filterType: 'proDownloads',
      filterValue,
      updateSearchFilters: this.props.updateSearchFilters,
      searchData: this.props.searchData,
      unsetContextPaginatableMetadata:
        this.props.unsetContextPaginatableMetadata,
      authUser: this.props.authUser,
      setSearchTrack: this.props.setSearchTrack,
      navigate: this.props.navigate,
    });
  };

  getTriggerStyle = () =>
    this.props.isSearchInactive
      ? DROPDOWN_TRIGGER_STYLE_TRANSPARENT_ON_DARK
      : DROPDOWN_TRIGGER_STYLE_TRANSPARENT_ON_BRIGHT;

  render() {
    const proDownloadsFilter = getFilter({
      authUser: this.props.authUser,
      filterType: 'proDownloads',
    });

    return (
      <StyledSearchInputDropdownSelect
        inPage={this.props.inPage}
        triggerStyle={this.getTriggerStyle()}
        unselected="search.proDownloadsFilter.allPhotos"
        options={proDownloadsFilter?.options}
        activeKey={
          this.props.selectedFilter?.filterValue ||
          this.props.searchData.filters.proDownloads ||
          '0'
        }
        onChange={this.handleFilterChange}
      />
    );
  }
}
