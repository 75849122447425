// @flow
import { gql } from '@apollo/client';
import { SimpleIllustrationFragment } from '../fragments/illustration';

export const ADD_ASSET_TO_LIGHTBOX = gql`
  mutation addAssetToLightbox(
    $lightboxId: ID!
    $assetType: AssetType!
    $assetId: ID!
  ) {
    addAsset(
      lightboxId: $lightboxId
      assetType: $assetType
      assetId: $assetId
    ) {
      id
      type
      thumbUrl
      lightboxIds
      __typename
      ... on Photo {
        previewUrl: photoUrl
      }
      ... on Illustration {
        previewUrl
      }
    }
  }
`;

export const REMOVE_ASSET_FROM_LIGHTBOX = gql`
  mutation removeAssetFromLightbox(
    $lightboxId: ID!
    $assetType: AssetType!
    $assetId: ID!
  ) {
    removeAsset(
      lightboxId: $lightboxId
      assetType: $assetType
      assetId: $assetId
    ) {
      id
      type
      lightboxIds
      __typename
      ... on Photo {
        id
        type
        previewUrl: photoUrl
        lightboxIds
      }
      ... on Illustration {
        previewUrl
      }
    }
  }
`;

export const CREATE_LIGHTBOX = gql`
  mutation createLightbox(
    $name: String!
    $description: String
    $clientDescription: String
    $copyLightboxId: String
  ) {
    createLightbox(
      name: $name
      description: $description
      clientDescription: $clientDescription
      copyLightboxId: $copyLightboxId
    ) {
      id
      clientDescription
      description
      items {
        items {
          __typename
          ... on Photo {
            type
            id
            thumbUrl
            previewUrl: photoUrl
          }
          __typename
          ... on Illustration {
            ...SimpleIllustration
          }
        }
      }
      totalPhotos
      lastUserUpdatedAt
      name
    }
  }
  ${SimpleIllustrationFragment}
`;

export const UPDATE_LIGHTBOX = gql`
  mutation updateLightbox(
    $lightboxId: ID!
    $name: String
    $description: String
    $clientDescription: String
  ) {
    updateLightbox(
      lightboxId: $lightboxId
      name: $name
      description: $description
      clientDescription: $clientDescription
    ) {
      id
      clientDescription
      totalPhotos
      lastUserUpdatedAt
      name
    }
  }
`;

export const DELETE_LIGHTBOX = gql`
  mutation deleteLightbox($lightboxId: ID!) {
    deleteLightbox(lightboxId: $lightboxId) {
      id
    }
  }
`;

export const ADD_LIGHTBOX_MEMBER = gql`
  mutation addLightboxMembers($lightboxId: ID!, $members: MembersInput!) {
    addLightboxMembers(lightboxId: $lightboxId, members: $members) {
      ok
      statusCode
    }
  }
`;
