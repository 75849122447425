/* @flow */
import styled from '@emotion/styled';
import { mapArray } from '../../../helpers/tools';
import { LIGHTBOX_PHOTO_HEIGHT } from '../../../constants/misc';

const WIDTHS = [175, 103, 136, 204];
const NUMBER_OF_BOXES = 20;

const Wrapper = styled.div`
  display: inline-block;
`;

const Box = styled.div`
  display: inline-block;
  height: ${LIGHTBOX_PHOTO_HEIGHT}px;
  width: ${({ width }) => `${width}px`};
  margin-right: 12px;
  background-color: ${({ whiteBackground, theme }) =>
    whiteBackground ? theme.colors.ghost60 : theme.colors.ghost50};
`;

function PlaceholderBoxes(props: { onWhite?: boolean }) {
  return (
    <Wrapper>
      {mapArray(NUMBER_OF_BOXES).map((key: number) => (
        <Box
          key={key}
          width={WIDTHS[key % WIDTHS.length]}
          whiteBackground={props.onWhite}
        />
      ))}
    </Wrapper>
  );
}

PlaceholderBoxes.defaultProps = {
  onWhite: false,
};

export default PlaceholderBoxes;
