import { connect } from 'react-redux';
import { isDirty } from 'redux-form';

import {
  openModal,
  toggleLightboxStripThunk,
  shouldAskForNavigationConfirmation,
} from '../../../helpers/actionCreators/redux';

import {
  getIsLightboxStripOpen,
  getHasUnpostedPhotos,
  getLanguage,
} from '../../../reducers/';
import { getShouldAskForNavigationConfirmation } from '../../../reducers/upload';

import Link from './link.jsx';

export default connect(
  (state) => ({
    shouldAskForNavigationConfirmation:
      getShouldAskForNavigationConfirmation(state)
        .shouldAskForNavigationConfirmation,
    isLightboxStripOpen: getIsLightboxStripOpen(state),
    hasUnpostedPhotos: getHasUnpostedPhotos(state),
    isSellerSignupFormDirty: isDirty('sellerSignup')(state),
    language: getLanguage(state),
  }),
  {
    openModal,
    toggleLightboxStrip: toggleLightboxStripThunk,
    shouldAskForNavigationConfirmationAction:
      shouldAskForNavigationConfirmation,
  }
)(Link);
