/* @flow */

import { gql } from '@apollo/client';

export const SimplePhotoFragment = gql`
  fragment SimplePhoto on Photo {
    id
    type
    thumbUrl
    width
    height
    previewUrl: photoUrl
    previewUrlPublic: photoUrlPublic
    relativeUrl
    route
    isForSale
    market {
      status
      reviewStatus
      modelStatus
      propertyStatus
      score
      modelAmount
      isLicensable
      licensing {
        purchased {
          id
          type
          name
          price
        }
      }
      collection
    }
    user {
      id
      nickname
    }
  }
`;
