/* @flow */
import type { AbstractComponent } from 'react';
import { connect } from 'react-redux';
import Modal from './modalRouter.jsx';
import type { ModalRouterProps } from './modalRouter.jsx';

import { getHasModal, getModal } from '../../reducers/';
import { closeModal } from '../../helpers/actionCreators/redux';

export default (connect(
  (state: RootReducerState) => ({
    hasModal: getHasModal(state),
    modal: getModal(state),
  }),
  { closeModal }
)(Modal): AbstractComponent<ModalRouterProps>);
