/* @flow */
/* eslint-env browser */
/* eslint-disable react/jsx-filename-extension */
import 'regenerator-runtime/runtime';

import * as Sentry from '@sentry/react';
import { Replay } from '@sentry/replay';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { loadableReady } from '@loadable/component';
import { HelmetProvider } from 'react-helmet-async';

import apolloClientBrowser from './graphql/apolloClientBrowser';
import configureStore from './helpers/configureStore';
import App from './views/app';
import { activateLanguage } from './helpers/localization';
import { addExtraTrackingListeners } from './helpers/tools';
import { sentryInit } from './helpers/sentry';
import { DOM_IDS } from './constants/misc';

import { createTranslator, TranslatorProvider } from './helpers/translator';

const translator = createTranslator(window.preloadData.requestInfo.language);

const config = window?.eyeconfig;
const preloadData = (window && window.preloadData) || {};

activateLanguage(preloadData.requestInfo?.language);

const startApp = async () => {
  if (typeof window.IntersectionObserver === 'undefined') {
    await import(
      /* webpackChunkName: "intersection-observer" */ 'intersection-observer'
    );
  }

  loadableReady(() => {
    if (config.sentryEnabled) {
      let integrations = [], replayConfig = {};
      if (config.sentryReplayEnabled) {
        integrations = [new Replay()];
        replayConfig = {
          replaysSessionSampleRate: 0.1,
          replaysOnErrorSampleRate: 1.0,
        };
      }

      sentryInit({
        replayConfig,
        sentryInstance: Sentry,
        config,
        dsn: 'https://5628bd5867fe478787c259993243d515@sentry.io/1268342',
        integrations,
      });
    }

    const stage = document.getElementById(DOM_IDS.REACT_STAGE);
    const searchPortal = document.getElementById(
      DOM_IDS.REACT_STAGE_PORTAL_SEARCH
    );
    const navStage = document.getElementById(DOM_IDS.REACT_STAGE_NAVBAR);
    const sideNavStage = document.getElementById(
      DOM_IDS.REACT_STAGE_SIDENAVBAR
    );
    const footerPortal = document.getElementById(DOM_IDS.REACT_STAGE_FOOTER);

    // render main app (thats our regular page)
    if (stage && !window.mainApp) {
      /**
       * window.mainApp is also used in app.js to trigger the performance metrics
       */
      window.mainApp = true;
      ReactDOM.hydrate(
        <TranslatorProvider value={translator}>
          <HelmetProvider>
            <ApolloProvider client={apolloClientBrowser}>
              <App
                path={window.location.pathname}
                reduxStore={configureStore(preloadData)}
              />
            </ApolloProvider>
          </HelmetProvider>
        </TranslatorProvider>,
        stage
      );
    }

    // render navbar (probably a webflow)
    if (navStage && !window.topNav) {
      window.topNav = true;
      ReactDOM.hydrate(
        <TranslatorProvider value={translator}>
          <ApolloProvider client={apolloClientBrowser}>
            <App
              path={window.location.pathname}
              shouldRenderInjectableNavbar
              reduxStore={configureStore(preloadData)}
            />
          </ApolloProvider>
        </TranslatorProvider>,
        navStage
      );
    }

    // render sidenav  (probably a webflow)
    if (sideNavStage && !window.sideNav) {
      window.sideNav = true;
      ReactDOM.hydrate(
        <TranslatorProvider value={translator}>
          <ApolloProvider client={apolloClientBrowser}>
            <App
              shouldRenderInjectableSideNavbar
              reduxStore={configureStore(preloadData)}
              path={window.location.pathname}
            />
          </ApolloProvider>
        </TranslatorProvider>,
        sideNavStage
      );
    }

    // render searchbar  (probably a webflow)
    if (searchPortal && !window.searchPortal) {
      window.searchPortal = true;
      ReactDOM.hydrate(
        <TranslatorProvider value={translator}>
          <ApolloProvider client={apolloClientBrowser}>
            <App
              path={window.location.pathname}
              isPortal
              reduxStore={configureStore(preloadData)}
            />
          </ApolloProvider>
        </TranslatorProvider>,
        searchPortal
      );
    }

    addExtraTrackingListeners();

    // render footer  (probably a webflow)
    if (footerPortal && !window.footerPortal) {
      window.footerPortal = true;
      ReactDOM.hydrate(
        <TranslatorProvider value={translator}>
          <ApolloProvider client={apolloClientBrowser}>
            <App
              shouldRenderInjectableFooter
              path={window.location.pathname}
              isPortal
              reduxStore={configureStore(preloadData)}
            />
          </ApolloProvider>
        </TranslatorProvider>,
        footerPortal
      );
    }
  });
};

window.startApp = startApp;
window.startApp();

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept();
}
