/* @flow */

import { Component } from 'react';

import T from '../../../general/translate.jsx';
import Link from '../../../general/link/';

/**
 * Wrap a string in react span
 * @param text {string}
 * @returns {XML}
 */
const getTextNode = (text, i) => <span key={`text-${i}`}>{text}</span>;

type Props = {
  newsType: string,
  users: $ReadOnlyArray<EyeEmUser>,
  total: number,
};

class UserList extends Component<Props> {
  getUserLink = (user: EyeEmUser, key: string) =>
    user ? (
      <Link key={key} href={`/u/${user.nickname.toLowerCase()}`}>
        {user.fullname}
      </Link>
    ) : null;

  /**
   * Get the suffix such as "others liked your photos"
   * appropriate for the length of users
   * @param length {number} of users
   * @returns {XML}
   */
  getSuffix() {
    const { total } = this.props;
    if (total <= 3) {
      return (
        <T key="suffix">
          news.photoAggregated.{this.props.newsType}.multipleUsers
        </T>
      );
    }

    return (
      <span key="suffix">
        {` & ${total === 4 ? '1' : total - 3} `}
        <T>news.photoAggregated.{this.props.newsType}.muchMore</T>
      </span>
    );
  }

  /**
   * Given a first and last user create
   * a list of comma separated <Link>s
   * @param first {number} index of first user
   * @param last {number} index of last user
   * @returns {Array} of react components
   */
  joinUsers(first: number, last: number) {
    const components = [];

    for (let i = first; i <= last; i += 1) {
      components.push(this.getUserLink(this.props.users[`${i}`], i));
      if (i !== last) {
        components.push(getTextNode(', ', i));
      }
    }

    return components;
  }

  getComponents = () => {
    const { users } = this.props;
    if (users.length === 1) {
      // 1 person: Peter liked your photo
      return [this.getUserLink(users[0], '_0')];
    }
    if (users.length === 2) {
      // 2 people: Peter & Tobi liked your photo
      return [
        this.getUserLink(users[0], '_0'),
        getTextNode(' & ', '_0'),
        this.getUserLink(users[1], '_1'),
      ];
    }
    if (users.length === 3) {
      // 3 people: Peter, Tobi & Alex liked your photo
      const components = this.joinUsers(0, 1);
      components.push(getTextNode(' & ', '_2'));
      components.push(this.getUserLink(users[2], '_2'));
      return components;
    }
    if (users.length > 3) {
      // 4 people: Peter, Tobi, Alex & 1 other liked your photo
      // 5 or more people: Peter, Tobi, Alex & 2 others liked your photo
      return this.joinUsers(0, 2);
    }
  };

  /**
   *
   Render a list of users that have liked a photo
   */
  render() {
    let components = this.getComponents();

    // add the appropriate suffix for each length
    components.push(this.getSuffix());

    return <div>{components}</div>;
  }
}

export default UserList;
