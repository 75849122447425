/* @flow */
import differenceInYears from 'date-fns/differenceInYears';
import get from 'lodash/get';
import {
  ACCOUNT_TYPE_SELLER,
  CUSTOMER_ADMIN,
  CUSTOMER_BUYER,
  CUSTOMER_VIEWER,
  ENTERPRISE_BCG,
  ENTERPRISE_EYEEM_ROBOT_CORP,
  ENTERPRISE_ING,
  ENTERPRISE_PICSART,
  USER_TYPE_ENTERPRISE,
  USER_TYPE_SELFSERVE,
} from '../constants/misc';
import { compareAsStrings } from './tools';

// Some customers want to disallow licensing of partner-sourced images
const customersWithoutPartnerImages = [
  ENTERPRISE_EYEEM_ROBOT_CORP,
  ENTERPRISE_PICSART,
];
export const contractDisallowsPartnerSources = (user?: AuthUser): boolean =>
  customersWithoutPartnerImages.includes(get(user, 'customer.id'));

export const canSearchIllustrations = (user: AuthUser): boolean =>
  isEnterpriseCustomer(user) || isPhotographer(user);

export const getPARanks = (user?: AuthUser): $ReadOnlyArray<{}> | void =>
  user && get(user, 'customer.personalizedAestheticModels');

// admin functionalities are only available for creators (set in DDD) or
// enterprise accounts (role team.admin set in enterprise internal tool)
export const isAdmin = (user?: AuthUser): boolean =>
  user?.admin || (!!user?.roles && user.roles.includes('team.admin'));

export const isAuthUser = (user: EyeEmUser, authUser?: AuthUser) =>
  compareAsStrings(get(user, 'nickname'), get(authUser, 'nickname'));

export const isAuthenticatedBuyer = (user?: AuthUser) => {
  if (!user) {
    return false;
  }
  return isBuyer(user);
};

// isBCG is a subset of isEnterpriseCustomer
export const isBCG = (user?: AuthUser): boolean =>
  compareAsStrings(get(user, 'customer.id'), ENTERPRISE_BCG);

export const isBuyer = (user?: AuthUser) =>
  !user || user.userType === USER_TYPE_SELFSERVE || isEnterpriseCustomer(user);

// isEnterpriseCustomer is a subset of isBuyer
export const isEnterpriseCustomer = (user?: AuthUser) =>
  isBCG(user) || get(user, 'userType') === USER_TYPE_ENTERPRISE;

export const isING = (user?: AuthUser): boolean =>
  compareAsStrings(get(user, 'customer.id'), ENTERPRISE_ING);

export const isMinor = (birthDate: string) =>
  differenceInYears(new Date(), new Date(birthDate)) < 18;

export const isPhotographer = (user?: AuthUser) => !isBuyer(user);

export const isPicsart = (user?: AuthUser): boolean =>
  compareAsStrings(get(user, 'customer.id'), ENTERPRISE_PICSART);

export const isSelfService = (user?: AuthUser) =>
  isAuthenticatedBuyer(user) && !isEnterpriseCustomer(user);

// isSeller is a subset of isPhotographer
export const isSeller = (user?: AuthUser) =>
  isPhotographer(user) &&
  (get(user, 'newsSettings.account_type') === ACCOUNT_TYPE_SELLER ||
    !!get(user, 'newsSettings.account_market_seller'));

export const isViewer = (user?: AuthUser) => {
  const roles = get(user, 'roles', []);
  return (
    roles.includes(CUSTOMER_VIEWER) &&
    !roles.includes(CUSTOMER_ADMIN) &&
    !roles.includes(CUSTOMER_BUYER)
  );
};

export const showNSFWContent = (authUser?: AuthUser) =>
  get(authUser, 'newsSettings.show_nsfw_content');
