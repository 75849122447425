/* @flow */
/* eslint-env browser */
/* eslint-disable max-classes-per-file */
/* this is the router, it matches paths with controller views  it runs on client and server */
import superagent from 'superagent';
import { Component } from 'react';
import classnames from 'classnames'; // eslint-disable-line
import loadable from '@loadable/component';
import { NotFound, Location, Locations } from 'react-router-component';

import NotFoundView from '../notFound';

import { isBuyer } from '../../helpers/accounts';
import {
  dispatchReceiveClientDataEvent,
  stripUrlParameters,
  getClientPath,
  getBodyClass,
  routeMatches,
  routeMatchesOneOf,
  getBrowserInfo,
  getQueryFromUrl,
} from '../../helpers/tools';
import { getUrlUnlocalized } from '../../helpers/localization';
import { dataLayerPageView } from '../../helpers/dataLayerUpdate';

import {
  ASSET_TYPE_ILLUSTRATION,
  ASSET_TYPE_PHOTO,
} from '../../constants/assets';
import {
  SEARCH_FILTER_PRODOWNLOADS_ONLY_OLD,
  UPLOAD_LOWRESOLUTION,
  SEARCH_BASE_URL_CONST,
  SEARCH_ILLUSTRATION_BASE_URL_CONST,
} from '../../constants/misc';

import VerifyEmail from '../verifyEmail';
import { FormStateProvider } from '../upload/illustrations/context';

const Album = loadable(() => import('../album'));
const AuthForms = loadable(() => import('../authForms'));
const BlogPost = loadable(() => import('../blogPost'));
const BuyerMlp = loadable(() => import('../buyerMLP'));
const Cart = loadable(() => import('../cart'));
const Checkout = loadable(() => import('../checkout'));
const Download = loadable(() => import('../download'));
const FriendsFeed = loadable(() => import('../friendsFeed/index'));
const IllustrationsUploader = loadable(() => import('../upload/illustrations'));
const Lightbox = loadable(() => import('../lightbox'));
const LightboxOverview = loadable(() => import('../lightboxOverview'));
const MarketAuth = loadable(() => import('../marketAuth'));
const Mission = loadable(() => import('../mission'));
const MissionLanding = loadable(() => import('../missionLanding'));
const OpenIdLogin = loadable(() => import('../openIdLogin'));
const OrderHistory = loadable(() => import('../orderHistory'));
const PayoutPage = loadable(() => import('../payout/index'));
const PhotosUploader = loadable(() => import('../upload/photos'));
const PreviousDownloads = loadable(() => import('../previousDownloads'));
const Profile = loadable(() => import('../profile/index'));
const ReleasePage = loadable(() => import('../releasePage'));
const Search = loadable(() => import('../search'));
const SellerSignup = loadable(() => import('../sellerSignup'));
const Settings = loadable(() => import('../settings'));
const SignupSwitch = loadable(() => import('../signupSwitch'));
const SingleAsset = loadable(() => import('../singleAsset'));
const StaticCompany = loadable(() => import('../staticCompany/index'));
const Team = loadable(() => import('../settings/team'));
const UploadFork = loadable(() => import('../upload'));
const UserKeywording = loadable(() => import('../userKeywording'));

const RETRY_TIMEOUT = 4000;

type Props = {
  authUser: AuthUser,
  isAuthenticated: boolean,
  isBCG: boolean,
  isEnterpriseCustomer: boolean,
  isLightboxStripOpen: boolean,
  isNavbarPresent: boolean,
  locale: string,
  path: string,
  receiveNormalizedData: Function,
  resetSearchFilters: Function,
  setTempField: Function,
  showNotification: Function,
  updateBrowserInfo: Function,
  updateSearchFilters: Function,
  updateSearchTerm: Function,
};

class ViewRouter extends Component<Props> {
  render() {
    return (
      <Locations
        contextual
        component="main"
        className={classnames('content', {
          'content-noPadding': !this.props.isNavbarPresent,
          'content-lightboxStripOpen': this.props.isLightboxStripOpen,
        })}
        path={this.props.path}>
        <Location path="/verify/email" handler={<VerifyEmail />} />
        <Location path="/login" handler={<AuthForms form="login" />} />
        <Location path="/signup" handler={<SignupSwitch />} />
        <Location
          path="/enterprise/signup"
          handler={<AuthForms form="enterpriseBuyerSignup" />}
        />
        <Location
          path="/signup/buyer"
          handler={<AuthForms form="buyerSignup" />}
        />
        <Location
          path="/signup/creator"
          handler={<AuthForms form="creatorSignup" />}
        />
        <Location path="/signup/openid/:provider" handler={<OpenIdLogin />} />
        <Location
          path="/password"
          handler={<AuthForms form="requestPasswordReset" />}
        />
        <Location
          path="/confirm/:resetPasswordToken"
          handler={<AuthForms form="resetPassword" />}
        />
        <Location
          path="/enterprise/passwordReset"
          handler={<AuthForms form="resetEnterprisePassword" />}
        />
        <Location
          reloadAfterAuth
          path="/oauth/authorize"
          form={this.props.isAuthenticated ? 'connectApp' : 'login'}
          handler={<AuthForms />}
        />
        <Location path="/upload" handler={<UploadFork />} />
        <Location
          path="/upload/illustrations"
          handler={
            <FormStateProvider>
              <IllustrationsUploader />
            </FormStateProvider>
          }
        />
        <Location
          path="/upload/photos"
          lowResolution={UPLOAD_LOWRESOLUTION}
          handler={<PhotosUploader />}
        />
        <Location path="/market/sell/keywording" handler={<UserKeywording />} />
        <Location path="/missions/photographers" handler={<MissionLanding />} />
        <Location path="/contact" page="contact" handler={<StaticCompany />} />
        <Location path="/settings/team" handler={<Team />} />
        <Location path="/settings/:page" handler={<Settings />} />
        <Location
          path={`${SEARCH_ILLUSTRATION_BASE_URL_CONST}*`}
          handler={<Search />}
        />
        <Location path={`${SEARCH_BASE_URL_CONST}*`} handler={<Search />} />
        <Location
          path="/i/:assetId"
          handler={<SingleAsset assetType={ASSET_TYPE_ILLUSTRATION} />}
        />
        <Location
          path="/p/:assetId"
          handler={<SingleAsset assetType={ASSET_TYPE_PHOTO} />}
        />
        <Location path="/rm/:assetId" handler={<SingleAsset />} />
        <Location
          comment
          path="/p/:assetId/comment"
          handler={<SingleAsset />}
        />
        <Location
          comment
          path="/p/:assetId/comment/:atMention"
          handler={<SingleAsset />}
        />

        <Location
          path="/collection/:assetType"
          handler={<PreviousDownloads />}
        />
        <Location
          path="/u/:nickname"
          paginatableName={
            isBuyer(this.props.authUser) ? 'marketPhotos' : 'photos'
          }
          handler={<Profile />}
        />
        <Location path="/u/:nickname/:paginatableName" handler={<Profile />} />
        <Location
          path="/a/:albumId"
          paginatableName="photos"
          handler={<Album />}
        />
        <Location
          path="/lightboxes"
          paginatableName="photos"
          handler={<LightboxOverview />}
        />
        <Location
          path="/lightboxes/:lightboxId"
          paginatableName="photos"
          handler={<Lightbox />}
        />
        <Location path="/a/:albumId/:paginatableName" handler={<Album />} />
        <Location path="/following" handler={<FriendsFeed />} />
        <Location path="/" handler={<BuyerMlp />} />
        <Location path="/market/cart" handler={<Cart />} />
        <Location path="/market/authenticate" handler={<MarketAuth />} />
        <Location path="/market/checkout" handler={<Checkout />} />
        <Location
          path="/market/checkout/:transactionId"
          handler={<Checkout />}
        />
        <Location
          path="/market/download/:transactionId"
          handler={<Download />}
        />
        <Location path="/market/transactions" handler={<OrderHistory />} />
        <Location path="/market/sell/start" handler={<SellerSignup />} />
        <Location path="/market/sell/payout" handler={<PayoutPage />} />
        <Location
          path="/release/:type/:id"
          resourceType="legacyReleaseForm"
          handler={<NotFoundView />}
        />
        <Location path="/releases/:releaseId" handler={<ReleasePage />} />
        <Location
          path="/multireleases/:releaseId"
          handler={<ReleasePage isMultiRelease />}
        />
        <Location path="/m/:missionId" handler={<Mission />} />
        <Location
          path="/m/:missionId/photos"
          handler={<Mission submissionsTab />}
        />
        <Location
          path="/m/:missionId/illustrations"
          handler={<Mission illustrationsSubmissionsTab />}
        />
        <Location path="/blog/:slug" handler={<BlogPost />} />
        <NotFound handler={<NotFoundView />} />
      </Locations>
    );
  }
}

// eslint-disable-next-line
class ParentViewRouter extends Component<Props> {
  lastRequest = null;

  onBeforeNavigation = (url: string) => {
    const path = getUrlUnlocalized(url);
    if (this.lastRequest) {
      this.lastRequest.abort();
    }

    this.setBodyClass(path);

    const prevPath = this.props.path && getUrlUnlocalized(this.props.path);

    let isRetried = false;
    this.props.updateBrowserInfo(getBrowserInfo());

    const isNotPhotoView =
      !routeMatches(path, '/p/:assetId') && !routeMatches(path, '/i/:assetId');

    if (getQueryFromUrl(path)) {
      this.props.updateSearchTerm(getQueryFromUrl(path));
    }
    if (
      !routeMatches(
        stripUrlParameters(path),
        `${SEARCH_BASE_URL_CONST}/:query?`
      ) &&
      isNotPhotoView
    ) {
      this.props.resetSearchFilters({
        authUser: this.props.authUser,
      });
    }

    if (this.props.isBCG && path === '/collection') {
      this.props.updateSearchFilters({
        filterType: 'proDownloads',
        value: SEARCH_FILTER_PRODOWNLOADS_ONLY_OLD,
        authUser: this.props.authUser,
      });
    }
    function request(context, errorNotificationField) {
      const clientPath = getClientPath();
      const route = `${clientPath}${path}`;
      const graphqlRoutes = [
        `${clientPath}${SEARCH_BASE_URL_CONST}*`,
        `${clientPath}/a/:albumid/:paginatable`,
        `${clientPath}/a/:albumid`,
        `${clientPath}/following`,
        `${clientPath}/i/:assetId`,
        `${clientPath}/lightboxes/:lightboxId`,
        `${clientPath}/lightboxes`,
        `${clientPath}/m/:missionId/photos`,
        `${clientPath}/m/:missionId`,
        `${clientPath}/multireleases/:releaseId`,
        `${clientPath}/p/:assetId`,
        `${clientPath}/releases/:releaseId`,
        `${clientPath}/rm/:assetId`,
        `${clientPath}/u/:userId/:paginatable*`,
        `${clientPath}/u/:userId`,
      ];

      if (!routeMatchesOneOf(route, graphqlRoutes)) {
        context.lastRequest = superagent
          .get(route)
          .set('X-Requested-With', 'XMLHttpRequest')
          .end((err, res) => {
            if (!err && res.body?.requestInfo) {
              dataLayerPageView({
                body: res.body,
                path,
                prevPath,
              });
              context.props.receiveNormalizedData(res.body);
              dispatchReceiveClientDataEvent();
            } else {
              if (!isRetried) {
                isRetried = true;
                setTimeout(() => {
                  request(context, 'serverError');
                }, RETRY_TIMEOUT);
              }
              if (errorNotificationField) {
                context.props.showNotification({
                  notification: errorNotificationField,
                  type: 'warning',
                });
              }
            }
          });
      }
    }

    request(this);
  };

  setBodyClass = (path: string) => {
    document.body.className = classnames(
      getBodyClass(getUrlUnlocalized(path)),
      { 'is-authenticated': this.props.isAuthenticated }
    );
  };

  render() {
    return (
      <Locations
        path={this.props.path}
        component={null}
        onBeforeNavigation={this.onBeforeNavigation}>
        <Location path="/de(/*)" handler={<ViewRouter {...this.props} />} />
        <Location path="/(*)" handler={<ViewRouter {...this.props} />} />
      </Locations>
    );
  }
}
export default ParentViewRouter;
