/* @flow */
import DataLayer from '../middleware/dataLayer';

export const dataLayerPageView = (data: {
  body: NormalizedData,
  hostname?: string,
  path?: string,
  prevPath?: string,
  userLocale?: string,
}) => {
  try {
    // accessing window :/
    const body = {
      ...data.body,
      auth: { ...window.preloadData.auth, ...data.body.auth },
      user: { ...window.preloadData.user, ...data.body.user },
      sessionFields: window.preloadData.sessionFields,
      requestInfo: {
        ...window.preloadData.requestInfo,
        ...data.body.requestInfo,
      },
    };

    const hostname = data.hostname || window?.eyeconfig?.server?.hostname;
    const path =
      data.path || window?.location?.pathname + window?.location?.search;
    const prevPath = data.prevPath || window?.prevPath;
    const userLocale = window.preloadData?.requestInfo?.language || 'en';

    let referrer = prevPath !== path ? prevPath : undefined;
    if (!referrer && window.dataLayer[0]?.page?.destinationURL) {
      referrer = window.dataLayer[0].page.destinationURL;
    }

    const digitalData = new DataLayer().fill(
      body,
      hostname,
      path,
      referrer,
      userLocale
    );

    digitalData.event = 'initialDataLayer';

    if (prevPath || window.dataLayer[0]?.page) {
      digitalData.event = 'virtualPageView';
    }

    if (prevPath !== path) {
      digitalData.virtualUrl = path;
    }

    window.dataLayer.push(digitalData);

    window.prevPath = path;
  } catch (err) {
    console.log('datalayer pageview update', err); // eslint-disable-line
  }
};
