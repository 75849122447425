/* @flow */
import { Component } from 'react';
import { FormRenderProps } from 'react-final-form';
import styled from '@emotion/styled';

import range from 'lodash/range';
import t from 'counterpart';
import { Select, Flex, Box, Text } from '@eyeem-ui/atoms';

const StyledRow = styled.div`
  position: relative;
`;

type Props = {
  label: string,
  formRow?: boolean,
  errorMessage: string,
  defaultDate: string,
} & FormRenderProps;

const currentYear = new Date().getFullYear();

class BirthdaySelect extends Component<Props> {
  pad = (value: number) =>
    value.toString().length === 1 ? `${0}${value}` : value;

  getSelectItemObject = (value: string | number) => ({
    label: value,
    value,
  });

  days = () =>
    range(1, 31 + 1).map((value) => this.getSelectItemObject(this.pad(value)));

  months = () =>
    range(1, 12 + 1).map((value) => this.getSelectItemObject(this.pad(value)));

  years = () =>
    range(1900, currentYear + 1)
      .reverse()
      .map(this.getSelectItemObject);

  getSplit = (date: string) => {
    const aDate = date || this.props.defaultDate || 'YYYY-MM-DD';
    return aDate.split('-');
  };

  getValueFromDate = (date: string, key: string) => {
    // console.log('gettin value', date, key);
    const split = this.getSplit(date);
    switch (key) {
      case 'year':
        return split[0];
      case 'month':
        return split[1];
      default:
        return split[2];
    }
  };

  mergeValueIntoDate = (date: string, value: string, key: string) => {
    // console.log('mergin value', date, key);
    const split = this.getSplit(date);
    let resultArray = split;
    switch (key) {
      case 'year':
        resultArray = [value, split[1], split[2]];
        break;
      case 'month':
        resultArray = [split[0], value, split[2]];
        break;
      default:
        resultArray = [split[0], split[1], value];
    }
    return resultArray.join('-');
  };

  onChange = (event: SyntheticInputEvent<HTMLSelectElement>, key: string) => {
    const inputValue =
      this.props.input.value || this.props.defaultDate || 'YYYY-MM-DD';

    return this.props.input.onChange(
      this.mergeValueIntoDate(inputValue, event.target.value, key)
    );
  };

  onChangeYear = (event: SyntheticInputEvent<HTMLSelectElement>) =>
    this.onChange(event, 'year');

  onChangeMonth = (event: SyntheticInputEvent<HTMLSelectElement>) =>
    this.onChange(event, 'month');

  onChangeDay = (event: SyntheticInputEvent<HTMLSelectElement>) =>
    this.onChange(event, 'day');

  handleBlur = (event: SyntheticInputEvent<HTMLSelectElement>) =>
    event.preventDefault();

  render() {
    const errorMessage = this.props.meta.touched && this.props.meta.error;
    return (
      <StyledRow>
        <Flex justifyContent="space-between" pt={1}>
          <Box flex="1">
            <Select
              {...this.props.input}
              id={`${this.props.input.name}-year`}
              onChange={this.onChangeYear}
              onBlur={this.handleBlur}
              value={this.getValueFromDate(this.props.input.value, 'year')}
              error={!!errorMessage}
              options={[
                { label: t('forms.placeholder.date.year'), value: 'YYYY' },
                ...this.years(),
              ]}
            />
          </Box>
          <Box flex="1" px={2}>
            <Select
              {...this.props.input}
              id={`${this.props.input.name}-month`}
              onChange={this.onChangeMonth}
              onBlur={this.handleBlur}
              value={this.getValueFromDate(this.props.input.value, 'month')}
              error={!!errorMessage}
              options={[
                { label: t('forms.placeholder.date.month'), value: 'MM' },
                ...this.months(),
              ]}
            />
          </Box>
          <Box flex="1">
            <Select
              {...this.props.input}
              id={`${this.props.input.name}-day`}
              onChange={this.onChangeDay}
              onBlur={this.handleBlur}
              value={this.getValueFromDate(this.props.input.value, 'day')}
              error={!!errorMessage}
              options={[
                { label: t('forms.placeholder.date.day'), value: 'DD' },
                ...this.days(),
              ]}
            />
          </Box>
        </Flex>
        {errorMessage && (
          <Text variant="title6" bold color="red50">
            {errorMessage}
          </Text>
        )}
      </StyledRow>
    );
  }
}

export default BirthdaySelect;
