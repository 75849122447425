/* @flow */
import { Component } from 'react';
import get from 'lodash/get';
import t from 'counterpart';
import LightboxForm from './lightboxForm.jsx';
import FailureModal from '../failureModal.jsx';

import { track, cleanObject } from '../../../helpers/tools';
import withRouter from '../../../helpers/hoc/withRouter.jsx';

type Props = {
  close: CloseModal,
  lightboxId: EyeEmLightboxId,
  createLightbox: Function,
  updateLightbox: Function,
  notifyModalFailure: Function,
  getLightbox?: {
    lightbox?: EyeEmLightbox,
  },
  className: string,
  duplicate?: boolean,
  selectLightboxCallback?: Function,
  navigateToLightbox?: boolean,
} & WithRouterProps;

type State = {
  didFail: boolean,
  failureMessage: ?string,
};

class LightboxModal extends Component<Props, State> {
  state = {
    didFail: false,
    failureMessage: undefined,
  };

  modalMode() {
    if (!this.props.lightboxId) {
      return 'create';
    }
    if (this.props.getLightbox?.lightbox?.id && this.props.duplicate) {
      return 'duplicate';
    }
    return 'update';
  }

  submit = (values: {}) => {
    track({
      eventType: t('tracking.eventType.inbound'),
      eventAction: t('tracking.eventAction.button'),
      eventName: t(`lightboxes.modals.${this.modalMode()}.confirm.eventName`),
      eventLabel: t(`lightboxes.modals.${this.modalMode()}.confirm.label`),
      eventPosition: t(`lightboxes.modals.${this.modalMode()}.eventPosition`),
      eventOption:
        this.modalMode() === 'duplicate'
          ? `copyLightboxId=${this.props.getLightbox?.lightbox.id}`
          : '',
    });

    // Migrating this piece-by-piece to use GraphQL...
    switch (this.modalMode()) {
      case 'create':
        return this.props
          .createLightbox({
            variables: {
              ...values,
            },
          })
          .then(({ data }) => {
            if (this.props.selectLightboxCallback) {
              this.props.selectLightboxCallback(data.createLightbox.id);
            }

            if (this.props.navigateToLightbox) {
              this.props.navigate(`/lightboxes/${data.createLightbox.id}`);
            }

            this.props.close();
          });
      case 'update':
        return this.props
          .updateLightbox({
            variables: {
              lightboxId: this.props.getLightbox?.lightbox.id,
              ...values,
            },
          })
          .then(({ errors }) => {
            if (errors) {
              throw new Error(errors[0]);
            }

            this.props.close();
          })
          .catch((err) => {
            this.setState({
              didFail: true,
              failureMessage: err.message,
            });
          });

      case 'duplicate':
        return this.props
          .createLightbox({
            variables: {
              ...values,
              copyLightboxId: this.props.getLightbox?.lightbox.id,
            },
          })
          .then(({ data }) => {
            if (this.props.selectLightboxCallback) {
              this.props.selectLightboxCallback(data.createLightbox.id);
            }

            if (this.props.navigateToLightbox) {
              this.props.navigate(`/lightboxes/${data.createLightbox.id}`);
            }

            this.props.close();
          });

      default:
        break;
    }
  };

  callback = (newLightbox: EyeEmLightbox) => {
    if (this.props.navigateToLightbox) {
      this.props.navigate(`/lightboxes/${newLightbox.id}`);
    }
    return this.props.close();
  };

  render() {
    const lightbox = get(this.props, 'getLightbox.lightbox');

    if (!lightbox && this.modalMode() !== 'create') return null;

    const lightboxName =
      lightbox &&
      (this.modalMode() === 'duplicate'
        ? t('lightboxes.modals.duplicate.name', {
            name: lightbox.name,
          })
        : lightbox.name);

    if (this.state.didFail) {
      return (
        <FailureModal
          {...this.props}
          noTranslate
          errorMessage={this.state.failureMessage}
        />
      );
    }

    return (
      <LightboxForm
        initialValues={cleanObject({
          name: lightboxName,
          clientDescription: lightbox && lightbox.clientDescription,
          description: lightbox && lightbox.description,
        })}
        className={this.props.className}
        modalMode={this.modalMode()}
        lightbox={lightbox}
        close={this.props.close}
        onSubmit={this.submit}
      />
    );
  }
}

export default withRouter(LightboxModal);
