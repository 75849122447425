/* @flow */

/**
 * as we can never be sure whether backend has set
 * id's as strings or as integers, we have these clumsy
 * helper functions, this function is also used to compare usernames
 * in lower case
 **/
export const compareAsStrings = (
  val1?: number | string | null,
  val2?: number | string | null
) =>
  typeof val1 !== 'undefined' &&
  typeof val2 !== 'undefined' &&
  val1 !== null &&
  val2 !== null &&
  `${val1}`.toLowerCase() === `${val2}`.toLowerCase();
