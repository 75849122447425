/* @flow */
/* Navbar router. */
/* eslint-env browser */
/* eslint-disable react/jsx-props-no-spreading */

import loadable from '@loadable/component';

import { Location, Locations } from 'react-router-component';

const NavbarNaked = loadable(() => import('../navbar/navbarNaked'));
const Navbar = loadable(() => import('../navbar'));

// 1. map -> remove first occurance of slash from the paths
// 2. reduce replacing '/' with '\/'
// 3. join to one string separated by |
const pathesArrayToRegex = (array) =>
  array
    .map((item) => (item[0] === '/' ? item.substr(1) : item))
    .map((item) => item.replace(/\//g, '\\/'))
    .join('|');

const NAKED_NAV_PATHES = [
  'login',
  'password',
  'signup',
  'releases/',
  'personal/',
  'multireleases/',
  'market/checkout',
  'market/cart',
];

// create pathes string for regex match of react router component
const pathesRegex = (paths) => new RegExp(`\\/(${pathesArrayToRegex(paths)})`);

function NavbarRouter(props: {
  path: string | typeof undefined,
  isInExternalPage?: boolean,
}) {
  return (
    <Locations component={null} path={props.path} contextual>
      <Location
        path={pathesRegex(NAKED_NAV_PATHES)}
        serverRoute={props.path}
        handler={<NavbarNaked isInExternalPage={props.isInExternalPage} />}
      />
      <Location
        path={/./}
        serverRoute={props.path}
        handler={<Navbar isInExternalPage={props.isInExternalPage} />}
      />
    </Locations>
  );
}

function ParentNavbarRouter(props: {
  path: string,
  isInExternalPage: boolean, // eslint-disable-line
}) {
  return (
    <Locations path={props.path} component={null}>
      <Location path="/de(/*)" handler={<NavbarRouter {...props} />} />
      <Location path="/(*)" handler={<NavbarRouter {...props} />} />
    </Locations>
  );
}

export default ParentNavbarRouter;
