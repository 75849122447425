/* @flow */

import get from 'lodash/get';
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import albums, * as fromAlbums from './albums';
import apps, * as fromApps from './apps';
import auth, * as fromAuth from './auth';
import buyerAddress, * as fromBuyerAddress from './buyerAddress';
import cachedThumbnails, * as fromCachedThumbnails from './cachedThumbnails';
import cart, * as fromCart from './cart';
import cartRemovals, * as fromCartRemovals from './cartRemovals';
import checkout, * as fromCheckout from './checkout';
import paymentMethods, * as fromPaymentMethods from './paymentMethods';
import contextPaginatableMetadata from './contextPaginatableMetadata';
import flyout from './flyout';
import marketUser, * as fromMarketUser from './marketUser';
import missions, * as fromMissions from './missions';
import modal, * as fromModal from './modal';
import notifications, * as fromNotifications from './notifications';
import nsfwWhitelist, * as fromNsfwWhitelist from './nsfwWhitelist';
import paginatables, * as fromPaginatables from './paginatables';
import request, * as fromRequest from './request';
import sales, * as fromSales from './sales';
import search, * as fromSearch from './search/';
import sessionFields, * as fromSessionFields from './sessionFields';
import tempFields, * as fromTempFields from './tempFields';
import upload, * as fromUpload from './upload';
import users, * as fromUsers from './users';
import licensing, * as fromLicensing from './licensing';
import licensingv2, * as fromLicensingv2 from './licensingv2';
import discountCode, * as fromDiscountCode from './discountCode';

import {
  isAdmin,
  isAuthenticatedBuyer,
  isBCG,
  isBuyer,
  isEnterpriseCustomer,
  isPhotographer,
  isSelfService,
  isSeller,
  showNSFWContent,
} from '../helpers/accounts';
import {
  getDefaultPaginationParadigm,
  isMissionCompleted,
  isMissionSelectingWinners,
} from '../helpers/tools';

import {
  getFollowingFeedPID,
  getLightboxPhotosPID,
} from '../helpers/paginatableIdentifiers';

import {
  SESSION_FIELD_LIGHTBOX_STRIP_OPEN,
  SESSION_FIELD_SELECTED_LIGHTBOX_ID,
  GRID_STEP_SIZE,
} from '../constants/misc';

export default combineReducers({
  albums,
  apps,
  auth,
  buyerAddress,
  cachedThumbnails,
  cart,
  cartRemovals,
  checkout,
  contextPaginatableMetadata,
  flyout,
  form,
  marketUser,
  missions,
  modal,
  notifications,
  nsfwWhitelist,
  paginatables,
  request,
  sales,
  search,
  sessionFields,
  tempFields,
  upload,
  users,
  paymentMethods,
  licensing,
  licensingv2,
  discountCode,
});

export function mapInitialDataToInitialState(initialData: NormalizedData) {
  return {
    albums: fromAlbums.mapInitialDataToInitialState(initialData),
    apps: fromApps.mapInitialDataToInitialState(initialData),
    auth: fromAuth.mapInitialDataToInitialState(initialData),
    buyerAddress: fromBuyerAddress.mapInitialDataToInitialState(initialData),
    cart: fromCart.mapInitialDataToInitialState(initialData),
    cartRemovals: fromCartRemovals.mapInitialDataToInitialState(initialData),
    checkout: fromCheckout.mapInitialDataToInitialState(initialData),
    paymentMethods:
      fromPaymentMethods.mapInitialDataToInitialState(initialData),
    marketUser: fromMarketUser.mapInitialDataToInitialState(initialData),
    missions: fromMissions.mapInitialDataToInitialState(initialData),
    notifications: fromNotifications.mapInitialDataToInitialState(initialData),
    paginatables: fromPaginatables.mapInitialDataToInitialState(initialData),
    request: fromRequest.mapInitialDataToInitialState(initialData),
    sales: fromSales.mapInitialDataToInitialState(initialData),
    search: fromSearch.mapInitialDataToInitialState(initialData),
    sessionFields: fromSessionFields.mapInitialDataToInitialState(initialData),
    upload: fromUpload.mapInitialDataToInitialState(initialData),
    users: fromUsers.mapInitialDataToInitialState(initialData),
    licensing: fromLicensing.mapInitialDataToInitialState(initialData),
  };
}

export const getCachedThumbnail = (state: RootReducerState, url: string) =>
  fromCachedThumbnails.getCachedThumbnail(state.cachedThumbnails, url);

export const getAlbum = (state: RootReducerState, albumId: EyeEmAlbumId) =>
  fromAlbums.getAlbum(state.albums, albumId);

export const getSales = (state: RootReducerState) =>
  fromSales.getSales(state.sales);

export const getPaymentMethods = (state: RootReducerState) =>
  fromPaymentMethods.getPaymentMethods(state.paymentMethods);

export const getFilesWithoutKeywords = (state: RootReducerState) =>
  fromUpload.getFilesWithoutKeywords(state.upload);
export const getFilesWithoutClosestCity = (state: RootReducerState) =>
  fromUpload.getFilesWithoutClosestCity(state.upload);
export const getFilesWithoutIptc = (state: RootReducerState) =>
  fromUpload.getFilesWithoutIptc(state.upload);
export const getUploadPhotos = (state: RootReducerState) =>
  fromUpload.getUploadPhotos(state.upload);
export const getSelectedUploadPhotoIds = (state: RootReducerState) =>
  fromUpload.getSelectedUploadPhotoIds(state.upload);
export const getSelectedUploadPhotos = (state: RootReducerState) =>
  fromUpload.getSelectedUploadPhotos(state.upload);
export const getIsUploadPosting = (state: RootReducerState) =>
  fromUpload.getIsUploadPosting(state.upload);
export const getUploadPrefillInfo = (state: RootReducerState) =>
  fromUpload.getUploadPrefillInfo(state.upload);
export const getIsUploadQueueComplete = (state: RootReducerState) =>
  fromUpload.getIsUploadQueueComplete(state.upload);
export const getUploadProgress = (state: RootReducerState) =>
  fromUpload.getUploadProgress(state.upload);
export const getHasUnpostedPhotos = (state: RootReducerState) =>
  fromUpload.getHasUnpostedPhotos(state.upload);
export const getUploadErrors = (state: RootReducerState) =>
  fromUpload.getUploadErrors(state.upload);

export const getTempField = (state: RootReducerState, name: string) =>
  fromTempFields.getTempField(state.tempFields, name);

export const getCartPhotos = (state: RootReducerState) =>
  fromCart.getCartPhotos(state.cart);
export const getCartPhotoItem = (state: RootReducerState, id: EyeEmAssetId) =>
  fromCart.getCartPhotoItem(state.cart, id);
export const getIsPhotoInCart = (state: RootReducerState, id: EyeEmAssetId) =>
  fromCart.getIsPhotoInCart(state.cart, id);

export const getCartDeals = (state: RootReducerState) =>
  fromCart.getCartDeals(state.cart);
export const getCartDealItem = (state: RootReducerState, id: EyeEmAssetId) =>
  fromCart.getCartDealItem(state.cart, id);

export const getSavedForLater = (state: RootReducerState) =>
  fromCart.getSavedForLater(state.cart);

export const getBuyerAddress = (state: RootReducerState) =>
  fromBuyerAddress.getBuyerAddress(state.buyerAddress);
export const getIsRecurringBuyer = (state: RootReducerState) =>
  fromBuyerAddress.getIsRecurringBuyer(state.buyerAddress);

export const getUserLicensing = (state) =>
  fromLicensing.getUserLicensing(state.licensing);

export const getUser = (
  state: RootReducerState,
  query: string | number
): EyeEmUser | void => fromUsers.getUser(state.users, query);
export const getAuthUser = (state: RootReducerState): AuthUser | void =>
  fromUsers.getUser(state.users, fromAuth.getAuthUserId(state.auth));
export const getAuthUserId = (state: RootReducerState): string =>
  fromAuth.getAuthUserId(state.auth);
export const getAuthUserCustomerId = (state: RootReducerState): string => {
  const authUser = fromUsers.getUser(
    state.users,
    fromAuth.getAuthUserId(state.auth)
  );

  return (authUser?.customer && authUser?.customer?.id) || undefined;
};

export const getDownloadRequirementsForAuthUser = (
  state: RootReducerState
): AuthUser | void => {
  const authUser = fromUsers.getUser(
    state.users,
    fromAuth.getAuthUserId(state.auth)
  );
  return get(authUser, 'customer.downloadRequirements', []);
};

export const getIsSelfService = (state: RootReducerState): boolean =>
  isSelfService(getAuthUser(state));
export const getIsSeller = (state: RootReducerState) =>
  isSeller(getAuthUser(state));
export const getIsPhotographer = (state: RootReducerState) =>
  isPhotographer(getAuthUser(state));
export const getIsEnterpriseCustomer = (state: RootReducerState) =>
  isEnterpriseCustomer(getAuthUser(state));
export const getIsBuyer = (state: RootReducerState) =>
  isBuyer(getAuthUser(state));
export const getIsAuthenticatedBuyer = (state: RootReducerState) =>
  isAuthenticatedBuyer(getAuthUser(state));
export const getIsBCG = (state: RootReducerState) => isBCG(getAuthUser(state));
export const getIsAdmin = (state: RootReducerState) =>
  isAdmin(getAuthUser(state));
export const getShowNSFWContent = (state: RootReducerState) =>
  showNSFWContent(getAuthUser(state));

export const getAuthUserNickname = (state: RootReducerState) =>
  getAuthUser(state) && getAuthUser(state).nickname;
export const getIsAuthenticated = (state: RootReducerState) =>
  fromAuth.getIsAuthenticated(state.auth);
export const getIsAuthPending = (state: RootReducerState) =>
  fromAuth.getIsAuthPending(state.auth);
export const getAuthRef = (state: RootReducerState) =>
  fromAuth.getAuthRef(state.auth);
export const getIsLoginViaParam = (state: RootReducerState) =>
  fromAuth.getIsLoginViaParam(state.auth);

export const getContextPaginatableMetadata = (state: RootReducerState) =>
  state.contextPaginatableMetadata;

export const getPaginatable = (state: RootReducerState, params: PIDObject) =>
  fromPaginatables.getPaginatable(state.paginatables, params);

export const getCheckoutTotals = (state: RootReducerState) =>
  fromCheckout.getCheckoutTotals(state.checkout);
export const getIsCheckoutPending = (state: RootReducerState) =>
  fromCheckout.getIsCheckoutPending(state.checkout);
export const getEditorialCountInCheckout = (state: RootReducerState) =>
  fromCheckout.getEditorialCountInCheckout(state.checkout);
export const getIsPaymentPending = (state: RootReducerState) =>
  fromCheckout.getIsPaymentPending(state.checkout);
export const getPaymentError = (state: RootReducerState) =>
  fromCheckout.getPaymentError(state.checkout);
export const getCheckoutDiscountStatus = (state: RootReducerState) =>
  fromCheckout.getCheckoutDiscountStatus(state.checkout);

export const getFollowingFeed = (state: RootReducerState) =>
  getPaginatable(state, getFollowingFeedPID(getAuthUserNickname(state)));

export const getCartRemovals = (state: RootReducerState) =>
  fromCartRemovals.getCartRemovals(state.cartRemovals);

export const getFeatureToggle = (
  state: RootReducerState,
  featureToggle: string
) => {
  const toggleObject = fromSessionFields.getSessionField(
    state.sessionFields,
    featureToggle
  );

  return toggleObject?.visible || toggleObject?.overwritten;
};

export const getSessionField = (state: RootReducerState, fieldName: string) =>
  fromSessionFields.getSessionField(state.sessionFields, fieldName);

export const getConversionEventOption = (
  state: RootReducerState,
  photoId: EyeEmAssetId
) => fromSessionFields.getConversionEventOption(state.sessionFields, photoId);

export const getIsPhotoInSelectedLightbox = (
  state: RootReducerState,
  photoId: EyeEmAssetId
) => {
  const selectedLightboxId = getSelectedLightboxId(state);
  if (selectedLightboxId && photoId) {
    const PID = getLightboxPhotosPID(selectedLightboxId);
    const paginatable = getPaginatable(state, PID);
    if (paginatable?.items.length > 0) {
      return paginatable.items.includes(photoId);
    }
  }
  return false;
};

export const getMission = (state: RootReducerState, id: EyeEmMissionId) =>
  fromMissions.getMission(state.missions, id);

export const getIsMissionSelectingWinners = (
  state: RootReducerState,
  id: EyeEmMissionId
) => isMissionSelectingWinners(getMission(state, id));
export const getIsMissionCompleted = (
  state: RootReducerState,
  id: EyeEmMissionId
) => isMissionCompleted(getMission(state, id));

export const getSelectedLightboxId = (state: RootReducerState) =>
  getSessionField(state, SESSION_FIELD_SELECTED_LIGHTBOX_ID);

export const getIsLightboxStripOpen = (state: RootReducerState) =>
  !!getSessionField(state, SESSION_FIELD_LIGHTBOX_STRIP_OPEN);

export const getModal = (state: RootReducerState) =>
  fromModal.getModal(state.modal);

export const getHasModal = (state: RootReducerState) =>
  fromModal.getHasModal(state.modal);

export const getNonNativeLinkedApps = (state: RootReducerState) =>
  fromApps.getNonNativeLinkedApps(state.apps);
export const getAuthRequestAppId = (state: RootReducerState) =>
  fromApps.getAuthRequestAppId(state.apps);
export const getApp = (state: RootReducerState, id: string | number) =>
  fromApps.getApp(state.apps, id);

export const getCountryIso = (state: RootReducerState) =>
  fromRequest.getCountryIso(state.request);
export const getLanguage = (state: RootReducerState) =>
  fromRequest.getLanguage(state.request);
export const getDeviceType = (state: RootReducerState) =>
  fromRequest.getDeviceType(state.request);
export const getIsMobile = (state: RootReducerState) =>
  fromRequest.getIsMobile(state.request);
export const getIsDesktop = (state: RootReducerState) =>
  fromRequest.getIsDesktop(state.request);
export const getIsTablet = (state: RootReducerState) =>
  fromRequest.getIsTablet(state.request);
export const getIsMac = (state: RootReducerState) =>
  fromRequest.getIsMac(state.request);
export const getBreakpointName = (state: RootReducerState) =>
  fromRequest.getBreakpointName(state.request);
export const getMaxDeviceWidth = (state: RootReducerState) =>
  fromRequest.getMaxDeviceWidth(state.request);
export const getScreenDimensions = (state: RootReducerState) =>
  fromRequest.getScreenDimensions(state.request);
export const getDevicePixelRatio = (state: RootReducerState) =>
  fromRequest.getDevicePixelRatio(state.request);
export const getCheckoutCurrency = (state: RootReducerState) =>
  fromRequest.getCheckoutCurrency(state.request);

// deprecated:
export const getDevice = (state: RootReducerState) =>
  fromRequest.getDevice(state.request);

export const getFlyout = (state: RootReducerState) => state.flyout;

export const getMarketUser = (state: RootReducerState) => state.marketUser;

export const getIsNsfwPhotoOnWhitelist = (
  state: RootReducerState,
  photoId: string | number
) => fromNsfwWhitelist.getIsNsfwPhotoOnWhitelist(state.nsfwWhitelist, photoId);

export const getUTM = (state: RootReducerState) =>
  fromRequest.getUTM(state.request);

export const getSearchResourceId = (state: RootReducerState) =>
  fromSearch.getSearchResourceId(state.search);
export const getSearchData = (state: RootReducerState) =>
  fromSearch.getSearchData(state.search);
export const getSearchTrack = (state: RootReducerState) =>
  fromSearch.getSearchTrack(state.search);
export const getHasSearchFilters = (state: RootReducerState) =>
  fromSearch.getHasSearchFilters(state.search);
export const getSpellcheck = (state: RootReducerState, resourceId: string) =>
  fromSearch.getSpellcheck(state.search, resourceId);

export const getNotifications = (state: RootReducerState) =>
  state.notifications;

export const getStepSize = (state: RootReducerState) =>
  GRID_STEP_SIZE[getDefaultPaginationParadigm(getAuthUser(state))];

export const getSelectedLicenseId = (state: RootReducerState) =>
  fromLicensingv2.getSelectedLicenseId(state.licensingv2);
export const getCurrentDiscountCode = (state: RootReducerState) =>
  fromDiscountCode.getCurrentDiscountCode(state.discountCode);
