/* @flow */
import uniqBy from 'lodash/uniqBy';

import {
  SHOW_NOTIFICATION,
  DISMISS_NOTIFICATION,
  DISMISS_NOTIFICATIONS,
  DISMISS_NOTIFICATIONS_ON_CLICK,
} from '../constants/actionTypes';

export default function notifications(
  state: NotificationsState = [],
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case SHOW_NOTIFICATION: {
      const payload = action.payload.notification
        ? [action.payload]
        : action.payload;

      if (action.payload.override) {
        return payload;
      }

      const mergedState = [...state, ...payload];

      // return array with unique entries
      return uniqBy(mergedState, 'notification');
    }
    case DISMISS_NOTIFICATION: {
      const newState = state.filter(
        (item) => item.notification !== action.payload.notification
      );
      return newState;
    }
    case DISMISS_NOTIFICATIONS:
      if (action?.payload?.notification?.autoHide === false) {
        return { ...state };
      }
      return [];
    case DISMISS_NOTIFICATIONS_ON_CLICK:
      return [];
    default:
      return state;
  }
}

export const mapInitialDataToInitialState = (initialData: NormalizedData) =>
  initialData.notifications;
