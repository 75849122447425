/* @flow */

import { gql } from '@apollo/client';
import { SimplePhotoFragment } from './photo';
import { SimpleUserFragment } from './user';

export const SearchFragment = gql`
  fragment SimpleSearch on SearchResult {
    facets @include(if: $isAdmin) {
      locations {
        elements {
          key
        }
      }
    }
    images: photos {
      offset
      limit
      total
      items {
        ...SimplePhoto
        simpleDescription
        updated
        updatedAt
        liked @skip(if: $isBuyer)
        totalLikes @skip(if: $isBuyer)
        totalComments @skip(if: $isBuyer)
        lightboxIds @include(if: $isBuyer)
        getty {
          headline
          tags
        }
        eyevision {
          headline
          tags
        }
        user @skip(if: $isBuyer) {
          ...SimpleUser
        }
      }
    }
    relatedSearches
  }
  ${SimplePhotoFragment}
  ${SimpleUserFragment}
`;
