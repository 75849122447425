/** @flow */
import { Modal } from '@eyeem-ui/organisms';
import { ThemeProvider } from '@eyeem-ui/theme';

type Props = {
  modal: ModalState,
  closeModal: CloseModal,
  disableDismiss?: boolean,
};

const ModalWrapper = (props: Props) => {
  const Content = props.modal.content;

  const isOpen = () => !!(props.modal && props.modal.content);

  const close = (event?: SyntheticMouseEvent<>) => {
    if (
      typeof event === 'object' &&
      typeof event.preventDefault === 'function'
    ) {
      event.preventDefault();
    }
    if (isOpen()) {
      props.closeModal();
    }
  };
  return (
    <ThemeProvider>
      <Modal
        isShown
        close={props.modal.close ? close : null}
        onDismiss={!props.modal.disableDismiss ? close : () => null}
        title={props.modal.contentProps.title}
        description={props.modal.contentProps.description}>
        <Content {...props.modal.contentProps} close={close} />
      </Modal>
    </ThemeProvider>
  );
};

ModalWrapper.defaultProps = {
  disableDismiss: false,
};

export default ModalWrapper;
