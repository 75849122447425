/* @flow */
import { Component } from 'react';
import t from 'counterpart';
import get from 'lodash/get';
import noop from 'lodash/noop';
import styled from '@emotion/styled';
import { withApollo } from '@apollo/client/react/hoc';
import { ThemeProvider } from '@eyeem-ui/theme';

// Direct Child Components
import Autocomplete from 'react-autocomplete';
import SearchInputDropdown from './searchInputDropdown/';
import SearchIcon from './searchIcon.jsx';
import Suggestion from './suggestion.jsx';

import { track, preventAndStop, isInPortalMode } from '../../../helpers/tools';
import { canSearchIllustrations } from '../../../helpers/accounts';
import {
  getSearchUrl,
  defaultFilterValues,
  SEARCH_BASE_URL,
} from '../../../helpers/search';
import { GET_SEARCH_SUGGESTION } from '../../../graphql/queries/search';

import { getUrlLocalized } from '../../../helpers/localization';
import baseTheme from '../../../helpers/theme';

import { TEST_SEARCH_INPUT } from '../../../constants/pageObjectSelectors';
import {
  SEARCH_FILTER_REPLACE_QUERY_TRUE,
  DEFAULT_LANGUAGE,
  DEBOUNCE_WAIT,
  SEARCH_ILLUSTRATION_BASE_URL_CONST,
} from '../../../constants/misc';

import {
  StyledSearchForm,
  StyledSearchButton,
  StyledSearchContainer,
  StyledSearchInput,
} from './styles';

const SuggestionsBox = styled.div`
  position: absolute;
  display: ${({ display }) => display};
  z-index: ${baseTheme.zIndices.suggestionsBox};
  left: -${(props) => (props.inPage ? '48' : '42')}px;
  top: calc(100% + 8px);
  width: calc(100% + ${(props) => (props.inPage ? '48' : '42')}px);
  border-radius: ${({ theme }) => theme.borderRadius[3]};
  box-shadow: 0 2px 4px 2px ${baseTheme.colors.uncategorized.unnamed15};
  text-align: left;
  background-color: ${({ theme }) => theme.colors.grey100};
  padding: 8px;
`;

const getSuggestionText = (item: KeywordSuggestion) => item.text;

type Props = {
  className: string,
  eventPosition: string,

  // focus after mounting if theres not already stuff written
  focusOnMount?: boolean,
  // disable checks for search button, ie false -> always active,green
  activeCheck?: boolean,
  // should be set when the bar is not rendered in the navbar
  inPage?: boolean,

  currentPath: string,

  stepSize: number,
  searchData: SearchData,
  isEnterpriseCustomer: boolean,
  authUser: AuthUser,
  unsetContextPaginatableMetadata: Function,
  updateSearchTerm: Function,
  updateSearchFilters: Function,
  setSearchTrack: Function,
  hasNoSearchTerm: boolean,
  language: string,
  isMobile: boolean,
  isSearchInputDropdownHidden: boolean,
} & WithRouterProps;

type State = {
  focused: boolean,
  inputFieldValue: string,
  selectedFilter?: SearchFilterState,
  suggestions: { keywords: [] },
};

class SearchBar extends Component<Props, State> {
  state = {
    focused: false,
    inputFieldValue: this.props.searchData.q || '',
    selectedFilter: undefined,
    suggestions: { keywords: [] },
  };

  input: {
    handleInputFocus: Function,
    handleInputBlur: Function,
    focus: Function,
  };

  timeoutId: TimeoutID;

  isMounted = false;

  static defaultProps = {
    focusOnMount: false,
    activeCheck: true,
  };

  componentDidMount() {
    this.isMounted = true;

    const originalHandleInputFocus = this.input.handleInputFocus;
    const originalHandleInputBlur = this.input.handleInputBlur;

    this.input.handleInputFocus = () => {
      originalHandleInputFocus();
      this.handleInputFocus();
    };
    this.input.handleInputBlur = () => {
      originalHandleInputBlur();
      this.handleInputBlur();
    };
    if (this.props.focusOnMount) {
      this.input.focus();
    }
    if (this.inputHasText()) {
      this.fetchSuggestions(this.state.inputFieldValue);
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.currentPath !== this.props.currentPath &&
      this.props.currentPath.indexOf(SEARCH_BASE_URL) !== 0 &&
      this.props.currentPath.indexOf('/p/') !== 0 &&
      this.props.currentPath.indexOf('/i/') !== 0
    ) {
      this.setState({
        inputFieldValue: '',
      });
    }

    if (prevProps.currentPath !== this.props.currentPath) {
      // selected a related search
      this.setState({
        inputFieldValue: this.props.searchData.q,
      });
    }

    if (
      prevProps.searchData.q !== this.props.searchData.q &&
      prevProps.searchData.q !== this.state.inputFieldValue
    ) {
      this.setState({
        inputFieldValue: this.props.searchData.q,
      });
      this.fetchSuggestions(this.props.searchData.q);
    }
  }

  hasIllustrationSearchAccess = () =>
    canSearchIllustrations(this.props.authUser) &&
    this.props.currentPath.includes(SEARCH_ILLUSTRATION_BASE_URL_CONST);

  getPlaceholder = () => {
    if (this.hasIllustrationSearchAccess()) {
      return t('search.placeholder.illustration');
    }
    return t('search.placeholder.photo');
  };

  setSelectedFilter = (value: SearchFilterState) => {
    this.setState({ selectedFilter: value });
  };

  handleInputFocus = () =>
    this.setState({
      focused: true,
    });

  handleInputBlur = () =>
    this.setState({
      focused: false,
    });

  cleanSearchInputPayload = (value: string) => value.replace('#', '');

  triggerSearch = (params: { text: string, eventAction: string }) => {
    // if there is no search term set it to magic space, this will trigger a
    const searchTerm = params.text || ' ';

    // handle illustration search
    if (this.hasIllustrationSearchAccess()) {
      this.props.updateSearchTerm(searchTerm);
      return this.props.navigate(
        `${SEARCH_ILLUSTRATION_BASE_URL_CONST}/${searchTerm}`
      );
    }

    let { filters } = this.props.searchData;

    if (
      this.state.selectedFilter?.filterType &&
      this.state.selectedFilter?.filterValue
    ) {
      filters[this.state.selectedFilter.filterType] =
        this.state.selectedFilter.filterValue;
    }

    const newSearchData = {
      ...this.props.searchData,
      q: searchTerm,
      filters: {
        ...defaultFilterValues({
          authUser: this.props.authUser,
        }),
        ...filters,
      },
      tokens: [],
      relatedSearches: true,
    };

    const newUrl = getUrlLocalized(
      getSearchUrl(newSearchData),
      this.props.language
    );

    // If we did the search from somewhere else,
    // then it's time to navigate to /search.
    if (
      this.props.currentPath.indexOf(SEARCH_BASE_URL) === -1 ||
      this.props.hasNoSearchTerm
    ) {
      track({
        eventType: t('tracking.eventType.inbound'),
        eventAction: params.eventAction,
        eventLabel: searchTerm,
        eventName: 'access_search',
        eventPosition: this.props.eventPosition,
      });

      if (isInPortalMode) {
        window.location.href = newUrl;
        return noop();
      }

      this.props.navigate(newUrl);
    }

    window.scrollTo(0, 0);
    this.props.unsetContextPaginatableMetadata();

    this.setState({
      inputFieldValue: searchTerm,
    });
    this.props.updateSearchTerm(searchTerm);
    this.props.updateSearchFilters({
      filterType: 'replaceQuery',
      value: SEARCH_FILTER_REPLACE_QUERY_TRUE,
      authUser: this.props.authUser,
    });

    /* Temporarily sets search tracking context in redux.
       Gets cleared out after the GraphQL query executes.
       This happens in redux, since searchBar can be rendered from:
       1. Nav bar
       2. Search main
       3. MLP
    */
    this.props.setSearchTrack({
      searchType: params.eventAction,
    });

    return this.props.navigate(newUrl);
  };

  getSuggestions = () => get(this.state, 'suggestions.keywords', []);

  hasSuggestions = () => {
    const suggestions = this.getSuggestions();
    return Boolean(suggestions.length);
  };

  onChangeAutocomplete = (
    event: SyntheticInputEvent<HTMLElement>,
    value: string
  ) => {
    this.setState({
      inputFieldValue: value,
    });
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(
      () => this.fetchSuggestions(value.trim()),
      DEBOUNCE_WAIT
    );
  };

  fetchSuggestions = async (query: string) => {
    const { data } = await this.props.client.query({
      query: GET_SEARCH_SUGGESTION,
      variables: {
        q: query,
      },
    });
    this.setState({
      suggestions: data.suggestions,
    });
  };

  inputHasText = () =>
    this.state.inputFieldValue && this.state.inputFieldValue.length > 0;

  shouldShowDropdown = () => {
    if (this.hasIllustrationSearchAccess()) {
      return false;
    }
    return (
      !this.props.isSearchInputDropdownHidden &&
      this.props.isEnterpriseCustomer &&
      !this.props.isMobile
    );
  };

  onSelectAutocomplete = (value: string) =>
    this.triggerSearch({
      text: value,
      eventAction: 'search_suggestion_select',
    });

  renderSuggestionsBox = (items: $ReadOnlyArray<KeywordSuggestion>) =>
    this.props.language === DEFAULT_LANGUAGE ? (
      <SuggestionsBox
        inPage={this.props.inPage}
        display={items.length > 0 ? 'block' : 'none'}>
        {items}
      </SuggestionsBox>
    ) : (
      <div />
    );

  renderSuggestion = (item: KeywordSuggestion, isHighlighted: boolean) => (
    <div key={`suggestion-${item.text}`}>
      <Suggestion isActive={isHighlighted} suggestion={item} />
    </div>
  );

  render() {
    const isSearchActive =
      this.state.focused || this.inputHasText() || !this.props.activeCheck;

    return (
      <div className={this.props.className}>
        <ThemeProvider>
          <StyledSearchContainer isActive={isSearchActive}>
            <StyledSearchButton
              isActive={isSearchActive}
              inPage={this.props.inPage}>
              <SearchIcon
                isActive={isSearchActive}
                size={this.props.inPage ? 14 : 16}
              />
            </StyledSearchButton>
            <StyledSearchForm
              isActive={isSearchActive}
              onSubmit={preventAndStop({
                callback: this.triggerSearch,
                payload: {
                  text: this.cleanSearchInputPayload(
                    this.inputHasText() ? this.state.inputFieldValue.trim() : ''
                  ),
                  eventAction: 'search_query_enter',
                },
              })}>
              <Autocomplete
                ref={(el) => {
                  this.input = el;
                }}
                autoHighlight={false}
                value={this.state.inputFieldValue}
                items={this.getSuggestions()}
                getItemValue={getSuggestionText}
                onSelect={this.onSelectAutocomplete}
                shouldItemRender={(item) => !item.hidden}
                onChange={this.onChangeAutocomplete}
                renderMenu={this.renderSuggestionsBox}
                renderItem={this.renderSuggestion}
                inputProps={{
                  placeholder: this.getPlaceholder(),
                  'data-test-id': TEST_SEARCH_INPUT,
                  inPage: this.props.inPage,
                  isActive: isSearchActive,
                }}
                renderInput={(props) => {
                  const { ref, ...rest } = props;
                  return (
                    <StyledSearchInput
                      {...rest}
                      aria-label={this.getPlaceholder()}
                      ref={ref}
                    />
                  );
                }}
              />
              {this.shouldShowDropdown() && (
                <SearchInputDropdown
                  setSelectedFilter={this.setSelectedFilter}
                  selectedFilter={this.state.selectedFilter}
                  searchTerm={this.state.inputFieldValue}
                  isSearchInactive={!isSearchActive && !this.inputHasText()}
                  inPage={this.props.inPage}
                />
              )}
            </StyledSearchForm>
          </StyledSearchContainer>
        </ThemeProvider>
      </div>
    );
  }
}

export default withApollo(SearchBar);
