import styled from 'styled-components';
import { styleUtils } from 'eyeem-components';
import { Flex, Box } from '@eyeem-ui/atoms';

export const footerItemPadding = '12px';

export const CenteredFlexWrapper = styled(Flex)`
  max-width: 1200px;
`;

export const StyledFooter = styled.footer`
  background-color: ${styleUtils.fromTheme('colors.blacks.black1')};
  width: 100%;
`;

export const StyledFooterMisc = styled(Box)`
  padding-top: 24px;
`;

export const StyledSocialLink = styled.a`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${footerItemPadding};
`;
