// @flow
import t from 'counterpart';
import { Helmet } from 'react-helmet-async';
import { APPLE_APP_ID } from '../../../constants/misc';
import { PRODUCTION_BASEURL, DEFAULT_LANGUAGE } from '../../../constants/misc';
import { getPageTranslationKey } from '../../../helpers/localization';
Helmet.defaultProps.encodeSpecialCharacters = false;

const DefaultMeta = ({
  path,
  language,
}: {
  path: string,
  language: string,
}) => {
  const translationKey = getPageTranslationKey(path);
  let description = t(`meta.description.${translationKey}`, {
    locale: language,
  });
  let title = t(`meta.title.${translationKey}`, {
    locale: language,
  });

  if (description.includes('missing translation:')) {
    description = t('meta.description.default');
  }
  if (title.includes('missing translation:')) {
    title = t('meta.title.default');
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="twitter:title" content={title} />
      <meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content={`${PRODUCTION_BASEURL}/node-static/img/ogimages/default.jpg`}
      />
      <meta property="al:android:package" content="com.baseapp.eyeem" />
      <meta property="al:android:app_name" content="EyeEm" />
      <meta property="al:ios:app_store_id" content={APPLE_APP_ID} />
      <meta property="al:ios:app_name" content="EyeEm" />
      <meta property="og:site_name" content="EyeEm" />
      <meta property="og:url" content={`${PRODUCTION_BASEURL}${path}`} />
      <meta property="og:ttl" content="2419200" />
      <meta property="fb:app_id" content="138146182878222" />
      <meta property="twitter:site" content="@eyeem" />
      <meta property="twitter:app:name:iphone" content="EyeEm" />
      <meta property="twitter:app:id:iphone" content={APPLE_APP_ID} />
      <meta property="twitter:app:name:ipad" content="EyeEm" />
      <meta property="twitter:app:id:ipad" content={APPLE_APP_ID} />
      <meta property="twitter:app:name:googleplay" content="EyeEm" />
      <meta property="twitter:app:id:googleplay" content="com.baseapp.eyeem" />
      <meta property="msapplication-TileColor" content="#111111" />
      <meta
        property="msapplication-TileImage"
        content="/node-static/img/appBanner/android@2x.png"
      />
      <meta
        name="google-site-verification"
        content="mNC3Covva-oRPz5lcPsuoGPDZEFqdNrHq4X5qPo3Jio"
      />
      {/* We do not index German pages */}
      {language !== DEFAULT_LANGUAGE && (
        <meta name="robots" content="noindex" />
      )}
      {/* Generic links for all pages */}
      <link
        rel="preload"
        href="/node-static/fonts/sailec/SailecBold.woff"
        as="font"
        type="font/woff"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="/node-static/fonts/sailec/SailecRegular.woff"
        as="font"
        type="font/woff"
        crossOrigin=""
      />
    </Helmet>
  );
};

export default DefaultMeta;
