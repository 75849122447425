import { connect } from 'react-redux';
import { getDevice } from '../../../reducers/';

import AppLinks from './appLinks.jsx';

const mapStateToProps = (state) => {
  const { isIOS, isAndroid } = getDevice(state);

  return {
    isIOS,
    isAndroid,
  };
};

export default connect(mapStateToProps)(AppLinks);
