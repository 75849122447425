/* @flow */
/* eslint-env browser */
import { createRef, Component } from 'react';

import Recaptcha from 'react-google-recaptcha';
import type { FormProps } from 'redux-form';

type Props = { customError: Error } & FormProps;

const recaptchaRef = createRef();

class CaptchaComponent extends Component<Props> {
  componentDidMount() {
    if (recaptchaRef.current) {
      recaptchaRef.current.execute();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      recaptchaRef.current &&
      this.props.customError &&
      !prevProps.customError
    ) {
      recaptchaRef.current.reset();
      recaptchaRef.current.execute();
    }
  }

  render() {
    return (
      <Recaptcha
        ref={recaptchaRef}
        sitekey="6Ld-esEUAAAAAAB4MnNWlhMzqOgBbU3uaVbij-2c"
        size="invisible"
        onChange={this.props?.input?.onChange}
      />
    );
  }
}

export default CaptchaComponent;
