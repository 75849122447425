/** @flow */

import t from 'counterpart';
import { Button } from '@eyeem-ui/atoms';
import { ThemeProvider } from '@eyeem-ui/theme';
import { Box, Flex } from '@eyeem-ui/atoms';
import { track } from '../../../../helpers/tools';
import { AVAILABLE_LANGUAGES } from '../../../../constants/misc';
import { footerItemPadding } from '../styles';

function LanguageToggle(props) {
  const toggle = (language: string) => {
    track({
      eventType: 'click_inbound',
      eventName: `access_languageSwitch_${language}`,
      eventAction: 'click_link',
      eventLabel: language,
      eventPosition: props.eventPosition,
    });

    return props.switchLanguage({ language });
  };

  const languages = t('languages');

  return (
    <ThemeProvider>
      <Flex px={[0, 0, 0, footerItemPadding]} py={[footerItemPadding]}>
        {AVAILABLE_LANGUAGES.sort((lang) => (lang === 'en' ? -1 : 1)).map(
          (language) => (
            <Box key={language} mr={[2]}>
              <Button
                text={languages[language]}
                onClick={() => toggle(language)}
                variant={props.language === language ? 'secondary' : 'subtle'}
              />
            </Box>
          )
        )}
      </Flex>
    </ThemeProvider>
  );
}

export default LanguageToggle;
