/* @flow */
import t from 'counterpart';
import styled from '@emotion/styled';
import { ThemeProvider } from '@eyeem-ui/theme';
import { Flex, Text, Box, Button } from '@eyeem-ui/atoms';
import { Helmet } from 'react-helmet-async';

import Link from '../general/link/';
import { NAVBAR_HEIGHT } from '../../constants/misc';

const StyledFlex = styled(Flex)`
  height: 100%;
`;

const StyledHeightWrapper = styled.div`
  height: calc(100vh - ${NAVBAR_HEIGHT}px) !important;
`;

const VerifyEmail = () => {
  return (
    <StyledHeightWrapper>
      <Helmet>
        <title>{t('meta.title.verifyEmail')}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <ThemeProvider>
        <StyledFlex
          alignItems="center"
          justifyContent="center"
          flexDirection="column">
          <Text variant="title3" as="h2">
            {t(`verifyEmail.success.title`)}
          </Text>
          <Box mt={4}>
            <Link href="/login">
              <Button variant="primary">{t('verifyEmail.success.link')}</Button>
            </Link>
          </Box>
        </StyledFlex>
      </ThemeProvider>
    </StyledHeightWrapper>
  );
};

export default VerifyEmail;
