/** @flow */
import type { Node } from 'react';

import { useRef, useState } from 'react';
import type { FieldProps } from 'react-final-form';
import t from 'counterpart';
import styled from '@emotion/styled';
import { Button, Text, Flex, Box, Spinner } from '@eyeem-ui/atoms';

import { preventAndStop } from '../../helpers/tools';
import Photo from '../general/photo/';

type Props = {
  onFileAdded: Function,
  label: string,
  disabled?: boolean,
  placeholder?: Node,
  size1?: string,
  size2?: number,
  formats?: string,
  resolution?: string,
  accept: string,
} & FieldProps;

const StyledInput = styled.input`
  display: none;
`;

const Wrapper = styled(Flex)`
  border: ${({ theme, error }) =>
    `2px solid ${error ? theme.colors.red40 : 'transparent'}`};
  border-radius: ${({ theme }) => theme.radii[2]};
  background-color: ${({ theme }) => theme.colors.ghost50};
`;

const FileUploadAndPreview = (props: Props) => {
  const { value, ...inputProps } = props.input;
  const errorMessage = props.meta.touched && props.meta.error;

  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const clickFileInput = () => fileInputRef?.current?.click();

  const onChange = (event: SyntheticInputEvent<HTMLElement>) => {
    const file = event.target.files[0];
    if (file) {
      setLoading(true);
      props.onFileAdded({
        file,
        callback: () => setLoading(false),
      });
    }
  };

  const renderPhotoOrStringOrSpinnerOrPlaceholder = () => {
    if (loading) {
      return (
        <Box p={3}>
          <Spinner inline size="24" />
        </Box>
      );
    }

    if (value && value.indexOf('.pdf') === -1) {
      return <Photo size1={props.size1} size2={props.size2} url={value} />;
    }

    if (props.placeholder) {
      return props.placeholder;
    }
    return <Text>{value}</Text>;
  };

  return (
    <>
      {props.label && (
        <Box mb="1">
          <Text variant="title6" color="grey50" bold>
            {t(props.label)}
          </Text>
        </Box>
      )}
      <Wrapper error={!!errorMessage} p={3}>
        <Box>{renderPhotoOrStringOrSpinnerOrPlaceholder()}</Box>
        <Box ml="3">
          {props.resolution && (
            <Box>
              <Text variant="title6" color="grey50">
                {t('forms.fileInput.resolution', {
                  resolution: props.resolution,
                })}
              </Text>
            </Box>
          )}
          {props.formats && (
            <Text variant="title6" color="grey50">
              {t('forms.fileInput.formats', {
                formats: props.formats,
              })}
            </Text>
          )}
          <Box pt={2}>
            <Button
              variant="secondary"
              onClick={preventAndStop({
                callback: !props.disabled && clickFileInput,
              })}>
              {t(value ? 'forms.fileInput.change' : 'forms.fileInput.upload')}
            </Button>
          </Box>

          <StyledInput
            {...inputProps}
            ref={fileInputRef}
            onChange={onChange}
            onBlur={onChange}
            accept={props.accept}
            type="file"
          />
        </Box>
        {errorMessage && (
          <div className="column small-12">
            <Text variant="title6" color="orange50">
              {errorMessage}
            </Text>
          </div>
        )}
      </Wrapper>
    </>
  );
};

FileUploadAndPreview.defaultProps = {
  size1: undefined,
  size2: 80,
  accept: '.pdf,.jpeg,.jpg,.png',
};

export default FileUploadAndPreview;
