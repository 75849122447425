/* @flow */
import { styleUtils } from 'eyeem-components';

import styled from 'styled-components';

const StyledSearchIcon = styled.svg`
  transition: fill 0.2s ease-in-out;
  fill: ${(props) =>
    props.isActive
      ? styleUtils.fromTheme('colors.uncategorized.unnamed17')
      : styleUtils.fromTheme('colors.whites.white')};
  width: ${(props) => (props.size ? `${props.size}px` : '14px')};
  height: ${(props) => (props.size ? `${props.size}px` : '14px')};
`;

type Props = {
  isActive: boolean,
  size?: number,
};

function SearchIcon(props: Props) {
  return (
    <StyledSearchIcon viewBox="0 0 14 14" {...props}>
      <path
        d="M10.89 9.477c.032.024.062.05.092.08l2.732 2.733c.388.387.386 1.018-.01
        1.414-.394.393-1.023.4-1.414.01l-2.733-2.732c-.03-.03-.056-.06-.08-.09C8.497
        11.588 7.297 12 6 12c-3.314 0-6-2.686-6-6s2.686-6 6-6 6 2.686 6 6c0 1.296-.41
        2.496-1.11 3.477zm-4.876.537c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4
        4 4z"
      />
    </StyledSearchIcon>
  );
}

export default SearchIcon;
