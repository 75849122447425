// @flow
import { ASSET_TYPE_ILLUSTRATION, ASSET_TYPE_PHOTO } from '../constants/assets';

export const getRouteFromAssetType = (assetType: AssetType) => {
  switch (assetType) {
    case ASSET_TYPE_ILLUSTRATION:
      return '/i/';
    case ASSET_TYPE_PHOTO:
      return '/p/';
    default:
      return '/p/';
  }
};

export const isIllustration = (asset?: EyeEmAsset) =>
  asset?.type === ASSET_TYPE_ILLUSTRATION;

export const trackingTypeFromAssetType = (
  type: AssetType | 'illustration' | 'photo'
): TrackingAssetType => {
  switch (type) {
    case ASSET_TYPE_ILLUSTRATION:
    case 'illustration':
      return 'illustration';
    case ASSET_TYPE_PHOTO:
    case 'photo':
      return 'photo';
    default:
      return 'photo';
  }
};

export const trackingTypeFromRelativeUrl = (url: string): TrackingAssetType => {
  if (url?.indexOf('/i/') > -1) return 'illustration';
  return 'photo';
};
