/* @flow */
import { RECEIVE_NORMALIZED_DATA } from '../../constants/actionTypes';

// Reducer
export default function spellcheck(state: SpellcheckObject = {}, action) {
  switch (action.type) {
    case RECEIVE_NORMALIZED_DATA: {
      return action.payload.spellcheck
        ? { ...state, ...action.payload.spellcheck }
        : state;
    }
    default:
      return state;
  }
}

// Initial Data Mapping
export const mapInitialDataToInitialState = (initialData: NormalizedData) =>
  initialData.spellcheck || {};

// Selector API
export const getSpellcheck = (state: SpellcheckObject, resourceId: string) =>
  state[resourceId] || false;
