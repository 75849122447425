/* @flow */
import {
  SET_SEARCH_TRACK,
  RESET_SEARCH_TRACK,
} from '../../constants/actionTypes';

// Reducer
export default function searchTrack(state: SearchTrackEvent = {}, action) {
  switch (action.type) {
    case SET_SEARCH_TRACK: {
      return { ...action.payload };
    }
    case RESET_SEARCH_TRACK: {
      return {};
    }
    default:
      return state;
  }
}

// Selector API
export const getSearchTrack = (state: SearchTrackEvent) => state;
