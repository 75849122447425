/* @flow */
import counterpart from 'counterpart';
import damalsGermanLocale from 'damals/locales/de';
import counterpartGermanLocale from 'counterpart/locales/de';

import { isOneOf, startsWithOneOf } from '../helpers/tools';
import { DEFAULT_LANGUAGE } from '../constants/misc.js';

import en from '../locales/en';
import de from '../locales/de';

// Load all languages already in counterpart
counterpart.registerTranslations('en', en);
counterpart.registerTranslations('de', de);
counterpart.registerTranslations('de', counterpartGermanLocale);
// language include for Ago component
counterpart.registerTranslations('de', damalsGermanLocale);

const pageTranslationKeys = {
  '/': 'mlp',
  '/contact': 'contact',
  '/en/pictures': 'collectionIndex',
  '/jobs': 'jobs',
  '/market/licensing': 'licensing',
  '/market/sell/payout': 'payout',
  '/market/sell/start': 'sellerForm',
  '/missions': 'missions',
  '/press': 'press',
  '/upload': 'upload',
  '/upload/illustrations': 'upload',
  '/upload/photos': 'upload',
};

// eslint-disable-next-line
export const activateLanguage = (language: string) => {
  // this is still in used but only when you switch the lang by clicking a button client side
  const setToLanguage = !isOneOf(language, AVAILABLE_LOCALES)
    ? DEFAULT_LANGUAGE
    : language;

  // Activating language
  counterpart.setLocale(setToLanguage);
};

export const AVAILABLE_LOCALES = ['en', 'de'];

export const getHyphenedLanguageCode = (languageCode: string) =>
  hyphenedLanguageCoded[languageCode] || 'en-US';

export const getLanguageFromPath = (baseUrl: string) => {
  return baseUrl.split('/')[1] || DEFAULT_LANGUAGE;
};

export const getNoindexContentByLanguage = () => {
  return getUserLocale() === DEFAULT_LANGUAGE
    ? 'noindex, follow'
    : 'noindex, nofollow';
};

export const getPageTranslationKey = (url: string) =>
  pageTranslationKeys[getUrlUnlocalized(url)]
    ? pageTranslationKeys[getUrlUnlocalized(url)]
    : 'default';

export const getPathLanguage = (url: string, req?: ExtendedExpressRequest) => {
  const slicedLanguage = url.split('/')[1];
  return (
    req?.session?.chosenLanguage ||
    req?.session?.language ||
    supportedLanguages.find((language) => language === slicedLanguage) ||
    DEFAULT_LANGUAGE
  );
};

export const getUrlUnlocalized = (url: string) => {
  const languageFromUrl = url.split('/')[1];
  if (isOneOf(languageFromUrl, supportedLanguages)) {
    const finalUrl = url.substr(3);
    return finalUrl === '' ? '/' : finalUrl;
  }
  return url;
};

export const getUserLocale = () => counterpart.getLocale();

export const getUrlLocalized = (url: string, locale?: string) => {
  const languageFromUrl = url.split('/')[1];
  const activeLocale = locale || getUserLocale();

  // default doesn't get reflected in url
  if (activeLocale === DEFAULT_LANGUAGE) {
    return getUrlUnlocalized(url);
  }
  // dont recreate url if its default or full url
  if (
    languageFromUrl === activeLocale ||
    startsWithOneOf(url, ['http://', 'https://'])
  ) {
    return url;
  }
  // replace wrong locales
  if (isOneOf(languageFromUrl, supportedLanguages)) {
    return `/${activeLocale}${url.substr(3)}`;
  }
  return `/${activeLocale}${url}`;
};

export const hyphenedLanguageCoded = {
  en: 'en-US',
  de: 'de-DE',
};

export const supportedLanguages = AVAILABLE_LOCALES;
