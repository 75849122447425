/* @flow */
import { RECEIVE_NORMALIZED_DATA } from '../constants/actionTypes';

export default function paymentMethods(
  state: PaymentState = { paymentMethods: [] },
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case RECEIVE_NORMALIZED_DATA:
      return {
        ...state,
        ...action.payload.paymentMethods,
      };
    default:
      return state;
  }
}

export function mapInitialDataToInitialState(initialData: NormalizedData) {
  return initialData.paymentMethods ? initialData.paymentMethods : [];
}

export const getPaymentMethods = (state: PaymentState) => state;
