/* @flow */
import { combineReducers } from 'redux';

import searchData, * as fromSearchData from './searchData';
import spellcheck, * as fromSpellcheck from './spellcheck';
import searchTrack, * as fromSearchTrack from './searchTrack';

// Reducer
export default combineReducers({
  searchData,
  spellcheck,
  searchTrack,
});

// Initial Data Mapping
export const mapInitialDataToInitialState = (initialData: NormalizedData) => ({
  searchData: fromSearchData.mapInitialDataToInitialState(initialData),
  spellcheck: fromSpellcheck.mapInitialDataToInitialState(initialData),
  searchTrack: {},
});

// Selector API
export const getSearchData = (state: SearchState) => state.searchData;
export const getSearchResourceId = (state: SearchState) =>
  fromSearchData.getSearchResourceId(state.searchData);
export const getHasSearchFilters = (state: SearchState) =>
  fromSearchData.getHasSearchFilters(state.searchData);

export const getSpellcheck = (state: SearchState, resourceId: string) =>
  fromSpellcheck.getSpellcheck(state.spellcheck, resourceId);
export const getSearchTrack = (state: SearchState) =>
  fromSearchTrack.getSearchTrack(state.searchTrack);
