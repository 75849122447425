/* @flow */
/* Function that enables tracking according to google tag manager requirements */
import get from 'lodash/get';
import find from 'lodash/find';
import merge from 'lodash/merge';
import experiments from '../config/experiments';

import {
  isAuthenticatedBuyer,
  isEnterpriseCustomer,
  isPhotographer,
  isSeller,
} from '../helpers/accounts';
import {
  isAvailableOnMarket,
  getOverallReleaseStatus,
  getResourceInMiddleware,
  productTracking,
  isForSale,
} from '../helpers/tools';

import {
  PHOTO_RELEASE_STATUS_NOT_NEEDED,
  PHOTO_RELEASE_STATUS_NEEDED,
  PHOTO_RELEASE_STATUS_REQUESTED,
  PHOTO_RELEASE_STATUS_CLEARED,
  DEFAULT_LANGUAGE,
  LICENSEID_DEFAULT,
} from '../constants/misc';

function DataLayer() {
  this.dl = {
    pageInstanceID: '', // digitalData.page.sysEnv - document.title - env (Staging/Production)
    page: {
      pageStatus: '',
      pageName: '', // document.title
      destinationURL: '', // document.location
      referringURL: '', // document.referrer
      sysEnv: '', // (Desktop/Mobile)
      // variant: '', // a/b testing
      version: '1.0', // relates to used w3c datalayer spec version
      // breadCrumbs: '', // document.referer
      language: DEFAULT_LANGUAGE, // default, will be overridden in DataLayer.prototype.fill
      geoRegion: '',
      category: {
        primaryCategory: '', // market/community
        subCategory1: '', // album / asset / feed / news / user
      },
      searchTermApi: '',
    },
    user: {
      // segment: '', // hasPhotosOnEyeem? -> creator:consumer
      profile: {
        profileInfo: {
          profileId: '', // user.id
          userName: '', // user.nickname
          marketContributor: 'unknown',
        },
      },
    },
  };
}

const contentOwner = (authData, trackingName, requestType, resource) => {
  if (!authData?.authenticated) {
    return 'unknown';
  }
  if (trackingName === 'following') {
    return 'foreign';
  }
  if (requestType === 'user' && resource?.nickname === authData.user) {
    return 'own';
  }
  if (
    ['illustration', 'photo'].includes(requestType) &&
    resource?.user === authData.user
  ) {
    if (!isAvailableOnMarket(resource)) {
      return 'own_community';
    }
    const releaseStatusMap = {
      [PHOTO_RELEASE_STATUS_NOT_NEEDED]: 'not_needed',
      [PHOTO_RELEASE_STATUS_NEEDED]: 'needed',
      [PHOTO_RELEASE_STATUS_REQUESTED]: 'requested',
      [PHOTO_RELEASE_STATUS_CLEARED]: 'cleared',
    };
    const releaseStatus = getOverallReleaseStatus(resource);
    return `own_market_${releaseStatusMap[releaseStatus]}`;
  }
  return 'foreign';
};

const getSubcategory1 = (
  trackingResourceName: TrackingResourceName
): TrackingSubcategory1 => {
  switch (trackingResourceName) {
    case 'following':
      return 'feed';
    case 'illustration':
    case 'photo':
      return 'asset';
    default:
      return trackingResourceName;
  }
};

DataLayer.prototype.fill = function (
  data: NormalizedData,
  hostname: string,
  pathname: string,
  referer: string,
  language: string
) {
  const resource = getResourceInMiddleware(data);

  this.dl.page.sysEnv =
    data.requestInfo?.deviceType === 'desktop'
      ? data.requestInfo?.deviceType
      : `mobile_${data.requestInfo?.deviceType}`;

  this.dl.page.geoRegion = data.requestInfo?.countryIso || 'unknown';

  this.dl.page.pageOption = data.requestInfo?.tracking?.pageOption;

  // Track language
  this.dl.page.language = language;

  const trackingCategory = data.requestInfo?.tracking?.category;
  const trackingNameSplit = data.requestInfo?.tracking?.name.split('_') || [];

  // SSR pages have an accurate referer already
  let referringURL = referer;

  // For client side pages
  if (!referer || !referer.startsWith('http')) {
    const protocol = process.env.NODE_ENV === 'production' ? 'https' : 'http';
    referringURL = `${protocol}://${hostname}${
      hostname === 'localhost' ? `:3001` : ''
    }${referer || ''}`;
  }

  this.dl.page = merge(this.dl.page, {
    pageStatus: data.auth?.authenticated ? 'loggedIn' : 'loggedOut',
    contentOwner: contentOwner(
      data.auth,
      data.requestInfo?.tracking?.name,
      data.requestInfo?.type,
      resource
    ),
    destinationURL: pathname,
    pageName: data.requestInfo?.tracking?.name,
    // TODO: there's a bug here, this is always the initial URL, not actually
    // the referring URL during virtual page loads
    referringURL,
    category: {
      primaryCategory: trackingCategory || 'community',
      subCategory1: getSubcategory1(trackingNameSplit[0]),
    },
    searchTermApi: data.search?.searchdata?.translatedQuery?.join(',') || '',
  });

  const env =
    (hostname === 'www.eyeem.com' && 'production') ||
    (hostname === 'staging.eyeem.com' && 'staging') ||
    (hostname === 'nightly.eyeem.com' && 'nightly-staging') ||
    (hostname === 'frontend.eyeem' && 'local') ||
    hostname;

  this.dl.pageInstanceID = `${this.dl.page?.sysEnv}.${this.dl.page?.pageName}.${env}`;

  const currentUser: AuthUser = find(
    data.user,
    (user) => user?.id === data.auth?.user
  );

  if (currentUser) {
    this.dl.user.profile.profileInfo.profileId =
      currentUser?.userId || currentUser?.id;

    this.dl.user.profile.profileInfo.userName = currentUser?.nickname;
  }

  if (currentUser?.customer) {
    this.dl.user.profile.profileInfo.customerId = currentUser?.customer?.id;
    this.dl.user.profile.profileInfo.hasDownloadRequirements =
      currentUser.customer.downloadRequirements.length > 0;
  }
  if (currentUser?.roles) {
    this.dl.user.profile.profileInfo.userRoles = currentUser?.roles.filter(
      (role) => role.indexOf('customer.') !== -1
    );
  }

  const marketContributorStatus = function () {
    if (isEnterpriseCustomer(currentUser)) {
      return 'enterprise';
    }
    if (isSeller(currentUser)) {
      return 'seller';
    }
    if (isAuthenticatedBuyer(currentUser)) {
      return 'buyer';
    }
    if (isPhotographer(currentUser)) {
      return 'photographer';
    }
    return 'unknown';
  };

  this.dl.user.profile.profileInfo.marketContributor =
    marketContributorStatus();

  if (
    data.requestInfo?.type === 'album' ||
    data.requestInfo?.type === 'illustration' ||
    data.requestInfo?.type === 'photo' ||
    data.requestInfo?.type === 'user'
  ) {
    this.updateProduct({ ...resource, ...data.requestInfo });
  }

  const fieldNames = Object.keys(data?.sessionFields || {}) || [];
  const userExperiments = fieldNames.filter(
    (fieldLabel) => fieldLabel.indexOf('exp_') === 0
  );
  if (experiments && userExperiments.length > 0) {
    // get active experiments and add values to pageviewtracking
    this.dl.user.experiments = userExperiments.reduce((acc, curr) => {
      const activeExperiment = experiments[`${curr}`];
      if (
        activeExperiment?.trackingSlot &&
        !activeExperiment.skip({ authUser: currentUser }) &&
        activeExperiment.shouldTrack({ data })
      ) {
        return (acc = {
          ...acc,
          [activeExperiment.trackingSlot]: activeExperiment.trackingValue(
            data.sessionFields[`${curr}`]
          ),
        });
      }
      return acc;
    }, {});
  }

  if (data.requestInfo?.type === 'search') {
    this.dl.search = {};
    this.dl.search.results = get(data, 'search[0].photos.total');
  }

  return this.dl;
};

DataLayer.prototype.updateProduct = function (resource: EyeEmResource) {
  /* resource is sometimes undefined, we try to capture this case here */
  if (resource && this.dl.page.category.subCategory1 === 'asset') {
    this.dl.product = [
      {
        productID: resource.id,
        description: resource.description,
        productURL: resource.webUrl,
        productImage: resource.previewUrl,
        productThumbnail: resource.thumbUrl,
        size: `${resource.width}x${resource.height}`,
      },
    ];

    // grab first license from available licenses for photoviewtracking

    const defaultLicense = isForSale(resource) && LICENSEID_DEFAULT;

    this.dl.ecommerce = {
      detail: {
        actionField: {
          list: `${resource.type}_detail_af`,
        },
        products: [
          productTracking({
            asset: resource,
            cartItem: {
              id: resource.id,
              distributionLicenseId: defaultLicense,
            },
          }),
        ],
      },
    };
  }

  return this.dl;
};

export default DataLayer;
