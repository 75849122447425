/* @flow */
/* eslint-env browser */
import find from 'lodash/find';
import each from 'lodash/each';
import t from 'counterpart';

import { createAction } from 'redux-actions';
import { SubmissionError } from 'redux-form';

import {
  getOpenIdAuthUrl as getOpenIdAuthUrlClientApi,
  login as loginClientApi,
  loginViaFacebook as loginViaFacebookClientApi,
  signup as signupClientApi,
} from '../../clientAPI/AuthAPI';

import {
  connectApp as connectAppClientApi,
  updateFacebookSetting as updateFacebookSettingClientApi,
  refreshUser as refreshUserClientApi,
} from '../../clientAPI/AuthUserAPI';

import {
  addOrUpdateCartItem as addOrUpdateCartItemClientApi,
  checkCart as checkCartClientApi,
  clearCart as clearCartClientApi,
  removeFromCart as removeFromCartClientApi,
  saveForLater as saveForLaterClientApi,
  submitSelfServePaymentData as submitSelfServePaymentDataClientApi,
} from '../../clientAPI/BuyerAPI';

import {
  getCustomerUser as getCustomerUserClientApi,
  getCustomerUserVerification as getCustomerUserVerificationClientApi,
  getEnterpriseUserLicensing as getEnterpriseUserLicensingClientApi,
  registerCustomerUser as registerCustomerUserClientApi,
  resetEnterprisePassword as resetEnterprisePasswordClientApi,
} from '../../clientAPI/EnterpriseAPI';

import {
  switchLanguage as switchLanguageClientApi,
  setSessionField as setSessionFieldClientApi,
  storeConversionEventOption as storeConversionEventOptionClientApi,
} from '../../clientAPI/MiscAPI';

import { isBuyer, isPhotographer } from '../accounts';
import {
  compareAsStrings,
  getDefaultPaginationParadigm,
  sixMonthInSeconds,
  track,
} from '../tools';
import { setCookieFor } from '../cookies';

import { activateLanguage } from '../localization';

import {
  SESSION_FIELD_LIGHTBOX_STRIP_OPEN,
  SESSION_FIELD_SELECTED_LIGHTBOX_ID,
  SESSION_FIELD_SEARCH_SIDEBAR_OPEN,
  SESSION_FIELD_SEARCH_FILTERS_OPEN,
  SESSION_FIELD_HAS_SIDE_NAV,
  SESSION_FIELD_SIDE_NAV_OPEN,
  TEMP_FIELD_LIGHTBOX_ANIM_DISABLED,
  GRID_STEP_SIZE,
  JWT_COOKIE_NAME,
  ADYEN_STATUS_SUCCESS,
  PRODUCTIONS_COOKIE_DOMAIN,
  NOTIFICATION_LIFETIME,
} from '../../constants/misc';

import { WRONG_CAPTCHA, UNEXPECTED_USER } from '../../constants/errorCodes';

import {
  RECEIVE_NORMALIZED_DATA,
  TOGGLE_LIKE,
  ADD_COMMENT,
  UPDATE_COMMENT,
  DELETE_COMMENT,
  ADD_OR_UPDATE_CART_ITEM,
  REMOVE_FROM_CART,
  ADD_TO_MARKET,
  EDIT_PHOTO,
  REQUEST_PAGINATABLE,
  SET_CONTEXT_PAGINATABLE_METADATA,
  UNSET_CONTEXT_PAGINATABLE_METADATA,
  AUTH_PENDING,
  CLEAR_FLYOUT,
  UPDATE_MARKET_USER,
  SET_SESSION_FIELD,
  UPDATE_SEARCH_FILTERS,
  RESET_SEARCH_FILTERS,
  RESET_SEARCH_SORT,
  UPDATE_SEARCH_TERM,
  UPDATE_SEARCH_SORT,
  SET_SEARCH_TRACK,
  RESET_SEARCH_TRACK,
  SHOW_NOTIFICATION,
  DISMISS_NOTIFICATION,
  DISMISS_NOTIFICATIONS,
  DISMISS_NOTIFICATIONS_ON_CLICK,
  ALLOW_NSFW_PHOTO,
  UPDATE_FACEBOOK_SETTING,
  UPDATE_USER_FLAG,
  CLEAR_CART_REMOVALS,
  SET_AUTH_REF,
  STORE_CONVERSION_EVENT_OPTION,
  OPEN_MODAL,
  CLOSE_MODAL,
  NOTIFY_MODAL_SUCCESS,
  NOTIFY_MODAL_FAILURE,
  TOGGLE_LIGHTBOX_STRIP,
  CREATE_LIGHTBOX,
  DELETE_LIGHTBOX,
  UPDATE_LIGHTBOX,
  ADD_TO_LIGHTBOX,
  REMOVE_FROM_LIGHTBOX,
  UPDATE_BROWSER_INFO,
  UPDATE_PAYMENT_STATUS,
  CLEAR_CART,
  SWITCH_LANGUAGE,
  SET_TEMP_FIELD,
  SET_PAGE,
  ADD_UPLOAD_PHOTO,
  ADD_UPLOAD_FILE_ERROR,
  REMOVE_UPLOAD_PHOTOS,
  SELECT_UPLOAD_PHOTOS,
  CLEAR_UPLOAD_SELECTION,
  TOGGLE_UPLOAD_PHOTO_SELECTION,
  SET_UPLOAD_POSTING_STATUS,
  SET_UPLOAD_PROGRESS,
  UPDATE_UPLOAD_PHOTOS,
  SET_UPLOAD_PREFILL_INFO,
  RESET_UPLOAD,
  RESET_UPLOAD_ERRORS,
  SET_PHOTO_CACHE,
  SET_SELECTED_LICENSE,
  SET_CURRENT_DISCOUNT_CODE,
  SAVE_FOR_LATER,
  SHOULD_ASK_FOR_NAVIGATION_CONFIRMATION,
} from '../../constants/actionTypes';

import * as testEvents from '../../helpers/customTestingEvents';

const createActionWithAnalyticsMeta = (type, metaType) =>
  createAction(
    type,
    undefined, // === don't alter the payload
    (payload) => ({
      analytics: createAction(metaType || type)(payload),
    })
  );

// actions without analytics
export const shouldAskForNavigationConfirmation = createAction(
  SHOULD_ASK_FOR_NAVIGATION_CONFIRMATION
);
export const receiveNormalizedData = createAction(RECEIVE_NORMALIZED_DATA);
export const updateComment = createAction(UPDATE_COMMENT);
export const addToMarket = createAction(ADD_TO_MARKET);
export const editPhoto = createAction(EDIT_PHOTO);
export const updateMarketUser = createAction(UPDATE_MARKET_USER);
export const requestPaginatable = createAction(REQUEST_PAGINATABLE);
export const setContextPaginatableMetadata = createAction(
  SET_CONTEXT_PAGINATABLE_METADATA
);
export const unsetContextPaginatableMetadata = createAction(
  UNSET_CONTEXT_PAGINATABLE_METADATA
);
export const setAuthPending = createAction(AUTH_PENDING);
export const clearFlyout = createAction(CLEAR_FLYOUT);
export const setSessionField = createAction(SET_SESSION_FIELD);
export const updateSearchFilters = createAction(UPDATE_SEARCH_FILTERS);
export const resetSearchFilters = createAction(RESET_SEARCH_FILTERS);
export const resetSearchSort = createAction(RESET_SEARCH_SORT);
export const setSearchTrack = createAction(SET_SEARCH_TRACK);
export const resetSearchTrack = createAction(RESET_SEARCH_TRACK);
export const updateSearchTerm = createAction(UPDATE_SEARCH_TERM);
export const updateSearchSort = createAction(UPDATE_SEARCH_SORT);
export const showNotification = createAction(SHOW_NOTIFICATION);
export const dismissNotification = createAction(DISMISS_NOTIFICATION);
export const dismissNotifications = createAction(DISMISS_NOTIFICATIONS);
export const dismissNotificationsOnClick = createAction(
  DISMISS_NOTIFICATIONS_ON_CLICK
);
export const allowNsfwPhoto = createAction(ALLOW_NSFW_PHOTO);
export const updateFacebookSetting = createAction(UPDATE_FACEBOOK_SETTING);
export const updateUserFlag = createAction(UPDATE_USER_FLAG);
export const clearCartRemovals = createAction(CLEAR_CART_REMOVALS);
export const setAuthRef = createAction(SET_AUTH_REF);
export const storeConversionEventOption = createAction(
  STORE_CONVERSION_EVENT_OPTION
);
export const toggleLightboxStrip = createAction(TOGGLE_LIGHTBOX_STRIP);
export const closeModal = createAction(CLOSE_MODAL);
export const notifyModalSuccess = createAction(NOTIFY_MODAL_SUCCESS);
export const notifyModalFailure = createAction(NOTIFY_MODAL_FAILURE);
export const createLightbox = createAction(CREATE_LIGHTBOX);
export const deleteLightbox = createAction(DELETE_LIGHTBOX);
export const updateLightbox = createAction(UPDATE_LIGHTBOX);
export const addToLightbox = createAction(ADD_TO_LIGHTBOX);
export const removeFromLightbox = createAction(REMOVE_FROM_LIGHTBOX);
export const updateBrowserInfo = createAction(UPDATE_BROWSER_INFO);
export const updatePaymentStatus = createAction(UPDATE_PAYMENT_STATUS);
export const clearCart = createAction(CLEAR_CART);
export const switchLanguage = createAction(SWITCH_LANGUAGE);
export const setTempField = createAction(SET_TEMP_FIELD);
export const setPage = createAction(SET_PAGE);
export const addUploadPhoto = createAction(ADD_UPLOAD_PHOTO);
export const addUploadFileError = createAction(ADD_UPLOAD_FILE_ERROR);
export const removeUploadPhotos = createAction(REMOVE_UPLOAD_PHOTOS);
export const selectUploadPhotos = createAction(SELECT_UPLOAD_PHOTOS);
export const clearUploadSelection = createAction(CLEAR_UPLOAD_SELECTION);
export const toggleUploadPhotoSelection = createAction(
  TOGGLE_UPLOAD_PHOTO_SELECTION
);
export const setUploadPostingStatus = createAction(SET_UPLOAD_POSTING_STATUS);
export const setUploadProgress = createAction(SET_UPLOAD_PROGRESS);
export const updateUploadPhotos = createAction(UPDATE_UPLOAD_PHOTOS);
export const setUploadPrefillInfo = createAction(SET_UPLOAD_PREFILL_INFO);
export const resetUpload = createAction(RESET_UPLOAD);
export const resetUploadErrors = createAction(RESET_UPLOAD_ERRORS);
export const setPhotoCache = createAction(SET_PHOTO_CACHE);

// actions with analytics
export const addComment = createActionWithAnalyticsMeta(ADD_COMMENT);
export const deleteComment = createActionWithAnalyticsMeta(DELETE_COMMENT);
export const addOrUpdateCartItem = createActionWithAnalyticsMeta(
  ADD_OR_UPDATE_CART_ITEM
);
export const removeFromCart = createActionWithAnalyticsMeta(REMOVE_FROM_CART);
export const saveForLater = createActionWithAnalyticsMeta(SAVE_FOR_LATER);
export const toggleLike = createAction(TOGGLE_LIKE);

export const openModal = createActionWithAnalyticsMeta(OPEN_MODAL);
export const setSelectedLicense = createAction(SET_SELECTED_LICENSE);
export const setCurrentDiscountCode = createAction(SET_CURRENT_DISCOUNT_CODE);

export const switchLanguageThunk =
  (payload: SwitchLanguagePayload) => (dispatch: Dispatch<Action>) => {
    dispatch(switchLanguage(payload));
    activateLanguage(payload.language);
    return switchLanguageClientApi(payload).then(
      setTimeout(() => window.location.reload(), 500)
    );
  };

export const storeConversionEventOptionThunk =
  (payload: StoreConversionEventOptionPayload) =>
  (dispatch: Dispatch<Action>) => {
    dispatch(storeConversionEventOption(payload));
    return storeConversionEventOptionClientApi(payload);
  };

export const showNotificationAndDismiss =
  (payload: ShowNotificationPayload) => (dispatch: Dispatch<Action>) => {
    dispatch(showNotification(payload));

    // TODO: this payload.lifetime thing doesn't make sense with
    // multiple notifications at once.
    // Maybe we shouldn't except single notifications anymore
    // and just have an items object in the payload that always needs
    // to be an array?
    const timeout = payload.lifetime || NOTIFICATION_LIFETIME;
    return setTimeout(() => dispatch(dismissNotification(payload)), timeout);
  };

export const connectAppThunk = (payload: ConnectAppPayload) => () =>
  connectAppClientApi(payload)
    .then((response) => response.body)
    .then((body) => payload.callback(body));

export const updateUserFlagThunk =
  (payload: UpdateUserFlagPayload) => (dispatch: Dispatch<Action>) => {
    dispatch(updateUserFlag(payload));
  };

export const clearCartThunk = () => (dispatch: Dispatch<Action>) => {
  dispatch(clearCart());
  return clearCartClientApi()
    .then((res) => res.body)
    .then((body) => dispatch(receiveNormalizedData(body)));
};

export const addOrUpdateCartItemThunk =
  (payload: AddOrUpdateCartItemPayload) => (dispatch: Dispatch<Action>) => {
    dispatch(addOrUpdateCartItem(payload));
    return addOrUpdateCartItemClientApi(payload.item)
      .then((res) => res.body)
      .then((body) => dispatch(receiveNormalizedData(body)))
      .then(() => testEvents.addOrUpdateCartItemEvent()); // Custom event for selenium tests
  };

export const removeFromCartThunk =
  (payload: RemoveFromCartPayload) => (dispatch: Dispatch<Action>) => {
    dispatch(removeFromCart(payload));
    return removeFromCartClientApi(payload)
      .then((res) => res.body)
      .then((body) => dispatch(receiveNormalizedData(body)));
  };

export const saveForLaterThunk =
  (payload: AddOrUpdateCartItemPayload) => (dispatch: Dispatch<Action>) => {
    dispatch(saveForLater(payload));
    return saveForLaterClientApi(payload)
      .then((res) => res.body)
      .then((body) => dispatch(receiveNormalizedData(body)));
  };

export const checkCartRemovalsThunk =
  (payload: CheckCartRemovalsPayload) => (dispatch: Dispatch<Action>) => {
    if (payload?.cartRemovals) {
      if (payload.cartRemovals.merged) {
        dispatch(showNotificationAndDismiss({ notification: 'cart.merged' }));
        dispatch(clearCartRemovals({ type: 'merged' }));
      }

      if (payload.isCartPage || payload.isCheckoutPage) {
        const notifications = [];
        each(payload.cartRemovals, (item, key) => {
          if (item.length > 0 && key !== 'merged') {
            notifications.push({
              notification: `cart.${key}`,
              count: item.length,
            });
          }
        });

        if (notifications.length > 0) {
          // if we are not on the cart page, we just navigate there
          // (after which this function will get triggered again anyways)
          if (payload.isCheckoutPage) {
            window.location = '/market/cart';
          } else {
            const photos = [
              ...(payload?.cartRemovals?.ownPhotos || []),
              ...(payload?.cartRemovals?.invalidLicense || []),
              ...(payload?.cartRemovals?.notFoundPhotos || []),
              ...(payload?.cartRemovals?.alreadyBoughtPhotos || []),
            ];
            const deals = [
              ...(payload?.cartRemovals?.notFoundPacks || []),
              ...(payload?.cartRemovals?.alreadyBoughtpacks || []),
            ];
            dispatch(showNotificationAndDismiss(notifications));
            dispatch(removeFromCartThunk({ photos, deals }));
            dispatch(clearCartRemovals({}));
          }
        }
      }
    }
  };

export const updateFacebookSettingThunk =
  (payload: UpdateFacebookSettingPayload) => (dispatch: Dispatch<Action>) => {
    dispatch(updateFacebookSetting(payload));
    updateFacebookSettingClientApi(payload).catch(() => {
      // TODO: handle error
      // console.log('error', err);
    });
  };

export const setSessionFieldThunk =
  (payload: SetSessionFieldPayload, cb?: Function) =>
  (dispatch: Dispatch<Action>) => {
    dispatch(setSessionField(payload));
    return setSessionFieldClientApi(payload)
      .then((response) => {
        if (cb) {
          cb(response);
        }
      })
      .catch((err) =>
        console.log(
          `setting session flag ${payload.name} to ${String(
            payload.value
          )} failed with:`,
          err
        )
      );
  };

export const changeSearchFilterOpenStatesThunk =
  (payload: ToggleSearchFilterOpenStatePayload) =>
  (dispatch: Dispatch<Action>) =>
    dispatch(
      setSessionFieldThunk({
        name: SESSION_FIELD_SEARCH_FILTERS_OPEN,
        value: payload.filterOpenStates,
      })
    );

export const toggleSearchSidebarThunk =
  (payload: ToggleSearchSidebarPayload) => (dispatch: Dispatch<Action>) =>
    dispatch(
      setSessionFieldThunk({
        name: SESSION_FIELD_SEARCH_SIDEBAR_OPEN,
        value: !payload.isOpen,
      })
    );

export function toggleHasSideNavThunk(payload: ToggleSideNavRenderedPayload) {
  return (dispatch: Dispatch<Action>) =>
    dispatch(
      setSessionFieldThunk({
        name: SESSION_FIELD_HAS_SIDE_NAV,
        value: payload.hasSideNav,
      })
    );
}

export const toggleSideNavOpenThunk =
  (payload: ToggleSideNavExpandedPayload) => (dispatch: Dispatch<Action>) =>
    dispatch(
      setSessionFieldThunk({
        name: SESSION_FIELD_SIDE_NAV_OPEN,
        value: !payload.isSideNavOpen,
      })
    );

export const resetSearchDefaults =
  (payload: ResetSearchDefaultsPayload) => (dispatch: Dispatch<Action>) => {
    dispatch(resetSearchSort({ authUser: payload.authUser }));
    dispatch(resetSearchFilters({ authUser: payload.authUser }));
  };

const handleAuthResponse =
  (payload: LoginPayload, dispatch: Dispatch<Action>) => (response) => {
    // handle error codes
    if (!response.ok) {
      if (response.statusCode === 417) {
        // custom frontend owned 417, we take the key and rerun the login
        if (response.body?.key) {
          return loginClientApi({ ...payload, key: response.body.key }).then(
            handleAuthResponse(payload, dispatch)
          );
        }
        // this is a 417 coming from the api
        if (response.body?.messagesByField?.password) {
          throw new SubmissionError({
            _error: t('authForms.login.errorConflict'),
          });
        }
      }
      dispatch(
        setAuthPending({
          pending: false,
        })
      );
      throw response;
    }

    // response ok
    dispatch(
      setAuthPending({
        pending: false,
      })
    );

    const authUser = find(response.body.user, (item) =>
      compareAsStrings(item.id, response.body.auth.user)
    );

    dispatch(
      resetSearchDefaults({
        authUser,
        stepSize: GRID_STEP_SIZE[getDefaultPaginationParadigm(authUser)],
        searchData: payload.searchData,
      })
    );
    dispatch(receiveNormalizedData(response.body));

    if (authUser && (isBuyer(authUser) || isPhotographer(authUser))) {
      dispatch(toggleHasSideNavThunk({ hasSideNav: true }));
    }

    return {
      authUser,
    };
  };

const handleAuthResponseError = (dispatch: Dispatch<Action>) => (response) => {
  dispatch(
    setAuthPending({
      pending: false,
    })
  );
  // errors thrown in handleAuthResponse()
  if (response instanceof Error) {
    throw response;
  }

  if (response?.statusCode === 403) {
    if (response?.body?.errorCode === WRONG_CAPTCHA.code) {
      throw new SubmissionError({
        _error: t(WRONG_CAPTCHA.translationString),
      });
    }
    if (response?.body?.errorCode === UNEXPECTED_USER.code) {
      throw new SubmissionError({
        _error: t(UNEXPECTED_USER.translationString),
      });
    }
  }

  // signup errors
  if (response?.path?.includes('/auth/signup')) {
    // creator signup - email in use
    if (
      response.statusCode === 400 &&
      response.body?.message?.includes('Cannot sign up with this email')
    ) {
      throw new SubmissionError({
        email: t('authForms.signup.errorConflict'),
      });
    }
    // seller signup - email in use
    if (response.statusCode === 409) {
      throw new SubmissionError({
        email: t('authForms.signup.errorConflict'),
      });
    }
  }

  // default error message
  throw new SubmissionError({
    _error: t('authForms.login.errorConflict'),
  });
};

export const loginViaFacebookThunk =
  (payload: LoginViaFacebookPayload) => (dispatch: Dispatch<Action>) => {
    dispatch(
      setAuthPending({
        pending: true,
      })
    );
    return loginViaFacebookClientApi(payload)
      .then(handleAuthResponse(payload, dispatch))
      .catch(handleAuthResponseError(dispatch));
  };

export const loginViaOauthThunk = (payload) => (dispatch: Dispatch<Action>) => {
  return getOpenIdAuthUrlClientApi(payload)
    .then(({ body }) => body)
    .catch(() => {
      return dispatch(
        showNotification({
          notification: 'signupTokenError',
          type: 'warning',
        })
      );
    });
};

export const loginThunk =
  (payload: LoginPayload) => (dispatch: Dispatch<Action>) => {
    dispatch(
      setAuthPending({
        pending: true,
      })
    );
    return loginClientApi(payload)
      .then(handleAuthResponse(payload, dispatch))
      .then(testEvents.loginEvent) // Custom event for selenium tests
      .catch(handleAuthResponseError(dispatch));
  };

export function signupThunk(payload: SignupPayload) {
  return function (dispatch: Dispatch<Action>) {
    dispatch(
      setAuthPending({
        pending: true,
      })
    );

    return signupClientApi(payload)
      .then(handleAuthResponse(payload, dispatch))
      .then((data) => testEvents.signupEvent(data))
      .catch(handleAuthResponseError(dispatch)); // Custom event for selenium tests
  };
}

export const enterpriseCustomerRequestThunk =
  (payload: SignupPayload) => (dispatch: Dispatch<Action>) =>
    getCustomerUserVerificationClientApi(payload)
      .then((res) => {
        if (!res.ok || !res.statusCode === 200) {
          throw res;
        }

        return res;
      })
      .catch((err) => {
        const notificationBody = {
          notification: 'enterpriseInviteExpiredToken',
          type: 'warning',
        };
        if (err.statusCode === 401) {
          notificationBody.notification = 'invalidToken';
        }
        if (err.statusCode !== 417 && err.statusCode !== 401) {
          notificationBody.notification = 'signupTokenError';
        }
        return dispatch(showNotification(notificationBody));
      });

export const enterpriseRegisterCustomerUserThunk =
  (payload: RegisterUserPayload) => (dispatch: Dispatch<Action>) =>
    registerCustomerUserClientApi(payload)
      .then((res) => {
        if (res.statusCode >= 400) {
          throw res;
        }

        return res;
      })
      .then((res) => {
        setCookieFor(
          JWT_COOKIE_NAME,
          res.body.token,
          sixMonthInSeconds,
          PRODUCTIONS_COOKIE_DOMAIN
        );

        dispatch(
          showNotification({ notification: 'enterpriseUserRegistered' })
        );
      })
      .catch((err) => {
        throw err;
      });

export const refreshEnterpriseUserThunk =
  (id: string) => (dispatch: Dispatch<Action>) =>
    getCustomerUserClientApi(id).then((response) => {
      dispatch(receiveNormalizedData(response.body));
    });

export const resetEnterprisePasswordThunk =
  (payload: ResetPasswordPayload) => (dispatch: Dispatch<Action>) =>
    resetEnterprisePasswordClientApi({
      password: payload.password,
      token: payload.token,
    })
      .then((res) => {
        if (res.statusCode >= 400) {
          const notificationBody = {
            notification: 'passwordResetExpiredToken',
            type: 'warning',
          };

          if (res.statusCode !== 416) {
            notificationBody.notification = 'signupTokenError';
          }
          dispatch(showNotification(notificationBody));

          throw res;
        }

        return res;
      })
      .then(() => {
        dispatch(showNotification({ notification: 'passwordReset' }));
      })
      .then(testEvents.resetEnterprisePasswordEvent())
      .catch((err) => {
        throw err;
      });

export const uploadProfileImageThunk =
  (payload: UploadProfileImagePayload) => (dispatch: Dispatch<Action>) =>
    dispatch(receiveNormalizedData(payload));

export const updateEnterpriseAccountThunk =
  (payload: UpdateEnterpriseAccountPayload) => (dispatch: Dispatch<Action>) =>
    dispatch(receiveNormalizedData(payload));

export const updateSelfServeAccountThunk =
  (payload: UpdateEnterpriseAccountPayload) => (dispatch: Dispatch<Action>) =>
    dispatch(receiveNormalizedData(payload));

export const updateAccountThunk =
  (payload: UpdateAccountPayload) => (dispatch: Dispatch<Action>) => {
    testEvents.updateAccountEvent(payload.user);
    return dispatch(receiveNormalizedData(payload));
  };

export const getEnterpriseUserLicensingThunk =
  (currency: string) => (dispatch: Dispatch<Action>) =>
    getEnterpriseUserLicensingClientApi(currency)
      .then((res) => {
        if (!res.ok || !res.statusCode === 200) {
          throw res;
        }
        if (res?.body) dispatch(receiveNormalizedData(res.body));
      })
      .catch((err) => {
        throw err;
      });

export const submitSelfServePaymentDataThunk =
  (payload: SelfServePaymentPayload) => (dispatch: Dispatch<Action>) => {
    dispatch(updatePaymentStatus({ status: 'pending' }));
    const {
      billingAddress,
      buyerType,
      cart,
      country,
      originalReferrerUrl,
      paymentMethod,
      referrerUrl,
      returnUrl,
      totalPricing,
    } = payload;

    return submitSelfServePaymentDataClientApi({
      billingAddress,
      buyerType,
      cart,
      country,
      originalReferrerUrl,
      paymentMethod,
      referrerUrl,
      returnUrl,
      totalPricing,
    })
      .then((response) => {
        // 1. get to adyen
        const action = response?.body?.adyenResponse?.action;

        if (response?.body?.adyenResponse?.resultCode === 'RedirectShopper') {
          if (action?.paymentMethodType === 'paypal') {
            return window.open(response.body.adyenResponse.action.url, '_self');
          }

          // regular 3ds flow
          const { url, method, data } = action;

          const form = document.createElement('form');
          form.method = method;
          form.action = url;
          form.id = '3dform';

          const paReq = document.createElement('input');
          paReq.type = 'hidden';
          paReq.name = 'PaReq';
          paReq.value = data.PaReq;
          form.appendChild(paReq);

          const MD = document.createElement('input');
          MD.type = 'hidden';
          MD.name = 'MD';
          MD.value = data.MD;
          form.appendChild(MD);

          const TermUrl = document.createElement('input');
          TermUrl.type = 'hidden';
          TermUrl.name = 'TermUrl';
          TermUrl.value = data.TermUrl;
          form.appendChild(TermUrl);

          document.body.appendChild(form);
          return form.submit();
        }

        if (
          !action &&
          response?.body?.transaction?.status === ADYEN_STATUS_SUCCESS
        ) {
          dispatch(updatePaymentStatus({ status: 'success' }));
          dispatch(clearCartThunk());
          dispatch(receiveNormalizedData(response.body));
          return payload.navigate(
            `/market/download/${response.body.transaction.id}?track=true`
          );
        }
        throw response;
      })
      .catch((err) => {
        track({
          eventType: 'click_inbound',
          eventName: 'ecom_purchase_fail',
          eventAction: 'click_button',
          eventLabel: 'Failed payment',
          eventPosition: 'checkout_payment',
        });
        dispatch(
          updatePaymentStatus({
            status: 'error',
            error: {
              code: err.body?.adyenResponse?.resultCode || err.statusCode,
              message:
                err?.body?.adyenResponse?.refusalReason ||
                (err?.messages && err.messages[0]) ||
                'Unknown Error',
            },
          })
        );
        throw err;
      });
  };

// gets:
// available payment methods
// latest used address (we use this information in the checkout to calculate the taxes)
// calls /buyer/checkcart -> adds dealItem to cart (needed if we come from /market/pricing with a pack)
export const refreshCheckoutPaymentMethodsAndLatesAddressThunk =
  (payload: RefreshCheckoutInfoPayload) => (dispatch: Dispatch<Action>) =>
    checkCartClientApi(payload)
      .then((res) => res.body)
      .then((body) => {
        dispatch(receiveNormalizedData(body));
        dispatch(checkCartRemovalsThunk(payload));
      });

// todo: find a better name for that
export const toggleLikeRedux =
  (payload: ToggleLikePayload) => (dispatch: Dispatch<Action>) => {
    if (payload.authenticated) {
      dispatch(toggleLike(payload));
      return true;
    }

    return console.error('triggered like button in logged out state');
  };

export const toggleLightboxStripThunk =
  (payload: ToggleLightboxPayload) => (dispatch: Dispatch<Action>) => {
    dispatch(toggleLightboxStrip(payload));
    dispatch(
      setSessionFieldThunk({
        name: SESSION_FIELD_LIGHTBOX_STRIP_OPEN,
        value: !payload.isOpen,
      })
    );
    dispatch(
      setTempField({
        name: TEMP_FIELD_LIGHTBOX_ANIM_DISABLED,
        value: payload.isOpen && payload.isLightboxAnimDisabled,
      })
    );
  };

export const selectLightboxThunk =
  (payload: SelectLightboxPayload) => (dispatch: Dispatch<Action>) =>
    dispatch(
      setSessionFieldThunk({
        name: SESSION_FIELD_SELECTED_LIGHTBOX_ID,
        value: payload.lightboxId,
      })
    );

export const addToLightboxUIAction =
  (payload: AddToLightboxPayload) => (dispatch: Dispatch<Action>) => {
    dispatch(addToLightbox(payload));
    testEvents.addToLightboxEvent(); // Custom event for selenium tests
  };

export const removeFromLightboxUIAction =
  (payload: AddToLightboxPayload) => (dispatch: Dispatch<Action>) => {
    dispatch(removeFromLightbox(payload));
    testEvents.removeFromLightboxEvent(); // Custom event for selenium tests
  };
export const refreshAuthUserThunk = () => (dispatch: Dispatch<Action>) =>
  refreshUserClientApi()
    .then((response) => response.body)
    .then((body) => dispatch(receiveNormalizedData(body)));
