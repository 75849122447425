/* @flow */
import * as React from 'react';
import t from 'counterpart';
import styled from '@emotion/styled';
import { Box, Text, Flex } from '@eyeem-ui/atoms';
import { ThemeProvider } from '@eyeem-ui/theme';
import { Email, Upload, Danger, PlusCircle } from '@eyeem-ui/icons';

import { track } from '../../../helpers/tools';

import Link from '../link';
import T from '../translate.jsx';

import ResendVerificationEmailModal from '../../modals/resendVerificationEmail';

import { StyledNotificationWrapper } from './styles';

const StyledTaskBadge = styled(Text)`
  padding: 4px 8px;
  min-width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.aqua50};
  text-align: center;
  align-self: center;
`;

const StyledIcon = styled(Flex)`
  margin-right: 16px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.grey100};
  height: 48px;
  width: 48px;
  justify-content: center;
  align-items: center;
`;

type Props = {
  task: TaskItem,
  resendVerificationEmail?: (payload: OpenModalPayload) => void,
  openModal: Function,
};

export default class Task extends React.Component<Props> {
  resendVerificationEmail = async () => {
    track({
      eventPosition: 'news',
      eventAction: t('tracking.eventAction.link'),
      eventName: 'access_modal_verifyEmail',
      eventLabel: 'VERIFY_EMAIL',
    });
    const { data } = await this.props.resendVerificationEmail();
    this.props.openModal({
      content: ResendVerificationEmailModal,
      useEUModal: true,
      contentProps: {
        title: t('modal.resendVerificationEmail.headline'),
        marketUser: { email: data.resendVerificationEmail.email },
        openModal: this.props.openModal,
      },
    });
  };

  actionsThatArentLinks = () => ({
    '/verify/email': this.resendVerificationEmail,
  });

  render() {
    if (!this.props.task) {
      return null;
    }

    const { task } = this.props;

    const iconMap = {
      email: Email,
      upload: Upload,
      release: Danger,
      keywording: PlusCircle,
    };

    const Icon = iconMap[task.icon];

    return (
      <ThemeProvider>
        <StyledNotificationWrapper>
          <T
            noTranslate
            component={
              this.actionsThatArentLinks()[task.action] ? 'span' : Link
            }
            eventType={t('tracking.eventType.inbound')}
            eventName="open_task"
            eventPosition="news"
            eventLabel={task.type}
            eventAction={t('tracking.eventAction.link')}
            href={!this.actionsThatArentLinks()[task.action] && task.action}
            onClick={this.actionsThatArentLinks()[task.action]}>
            <Flex justifyContent="space-between" alignItems="center">
              <Box flex="0 0 auto">
                <StyledIcon>
                  <Icon color="grey0" size="24" />
                </StyledIcon>
              </Box>
              <Box flex="1 1 auto">
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="left">
                  <Text>{t(task.description.headline)}</Text>
                  {task.description.subline && (
                    <Text variant="title6" color="grey50">
                      {t(task.description.subline)}
                    </Text>
                  )}
                </Flex>
              </Box>
              {task.badgeCount && (
                <StyledTaskBadge variant="title6">
                  {task.badgeCount}
                </StyledTaskBadge>
              )}
            </Flex>
          </T>
        </StyledNotificationWrapper>
      </ThemeProvider>
    );
  }
}
