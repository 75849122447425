/* @flow */
import { SET_SELECTED_LICENSE } from '../constants/actionTypes';

export default function licensingv2(
  state: Licensingv2State = {},
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case SET_SELECTED_LICENSE:
      return {
        ...state,
        selectedLicenseId: action.payload,
      };

    default:
      return state;
  }
}

export const getSelectedLicenseId = (state: Licensingv2State) =>
  state.selectedLicenseId;
