/* @flow */
/* eslint-env browser */

import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import qs from 'qs';

import {
  SEARCH_SORT_RELEVANCE,
  SEARCH_FILTER_MARKET_STATUS_COMMERCIAL,
  SEARCH_FILTER_MARKET_SCORE_GREAT,
  SEARCH_FILTER_MARKET_SCORE_GOOD,
  SEARCH_FILTER_MARKET_SCORE_VERY_GOOD,
  SEARCH_FILTER_REPLACE_QUERY_TRUE,
  SEARCH_FILTER_PRODOWNLOADS_ONLY_NEW,
  SEARCH_FILTER_GRAYSCALE_COLOR,
  SEARCH_BASE_URL_CONST,
  SEARCH_ILLUSTRATION_BASE_URL_CONST,
  SEARCH_FILTER_COLLECTION_MIXED,
  SEARCH_FILTER_COLLECTION_PREMIUM,
  SEARCH_FILTER_MARKET_SCORE_AVERAGE,
  SEARCH_FILTER_STOCK_NOSTOCK,
  SEARCH_FILTER_STOCK_FLAGGED,
  SEARCH_FILTER_MIN_RESOLUTION_9MP,
} from '../constants/misc';

import { getFilter, getAllFilters } from './filterTypes';

import { isBCG, isBuyer, isEnterpriseCustomer, isING } from './accounts';
import {
  cleanObject,
  deleteFromArray,
  addToArray,
  sortObjectKeys,
  compareAsStrings,
} from './tools';

import { getUrlLocalized } from '../helpers/localization';
import { isAdmin } from '../helpers/accounts';

export const SEARCH_BASE_URL = SEARCH_BASE_URL_CONST;

export const searchDataToEndpointParams = (searchData: SearchData) =>
  cleanObject({
    q: searchData.q,
    ...searchData.filters,
    sort: searchData.sort,
    tokens: searchData.tokens,
    relatedSearches: searchData.relatedSearches,
  });

export const endpointParamsToResourceId = (endpointParams: Object): string =>
  qs.stringify(sortObjectKeys(endpointParams), { arrayFormat: 'brackets' });

export const searchDataToResourceId = (searchData: SearchData) =>
  endpointParamsToResourceId(searchDataToEndpointParams(searchData));

const getFiltersFromQuery = (query: SearchQuery, authUser: AuthUser) => {
  const returnFilters = {};
  getAllFilters({ authUser }).forEach((filter) => {
    // remove proDownload filter for non enterprise users
    if (!isEnterpriseCustomer(authUser) && filter.name === 'proDownloads') {
      return null;
    }
    returnFilters[filter.name] = query[filter.name];
  });
  return cleanObject(returnFilters);
};

export const searchTermFromUrl = (pathname?: string) => {
  if (typeof pathname !== 'string') {
    return undefined;
  }

  const parts = pathname.split('/');
  if (parts[1] === 'search' && parts[3] && parts[3].length) {
    let query = decodeURIComponent(parts[3]);
    if (query.length > 1) {
      query = query.trim();
    }
    return query;
  }

  return undefined;
};

export const _hasNoSearchTerm = (pathname?: string) => {
  const hasSearchToken = !!searchTermFromUrl(pathname);

  if (hasSearchToken) {
    return false;
  }

  return true;
};

export const endpointParamsToSearchData = (query: Object, authUser: AuthUser) =>
  cleanObject({
    q: query.q,
    sort: query.sort,
    tokens: query.tokens,
    filters: getFiltersFromQuery(query, authUser),
  });

export const fullUrlParamsToSearchData = (
  pathname?: string,
  query: Object,
  authUser: AuthUser
) => {
  // Get the search term from /search/pictures/:term
  const termFromUrlPath = searchTermFromUrl(pathname);
  const defaultFilters = defaultFilterValues({
    authUser,
  });

  const tempQuery = {
    sort: getDefaultSortValue({ authUser }),
    ...defaultFilters,
    ...query, // other params
    q: termFromUrlPath,
  };
  return endpointParamsToSearchData(tempQuery, authUser);
};

// spaces are not properly encoded, so we re-encode using plusses
export const canonicaliseQueryString = (string: string) =>
  decodeURIComponent(string).replace(/ /g, '+');

export const getSearchUrl = (
  searchData: SearchData,
  extraParams?: {},
  skipAllParams?: boolean,
  isIllustrationSearch?: boolean
) => {
  let url = isIllustrationSearch
    ? SEARCH_ILLUSTRATION_BASE_URL_CONST
    : SEARCH_BASE_URL;
  let params = {};
  let query = searchData.q?.trim();
  if (query?.length < 1) {
    query = ' ';
  }
  // update data with cleaned query
  url = `${url}/${encodeURIComponent(query)}`;
  params.q = encodeURIComponent(query);

  if (skipAllParams || isIllustrationSearch) {
    return url;
  }

  // Only add sort/filters if we have an actual request
  if (params.q) {
    if (searchData.sort) {
      params.sort = searchData.sort;
    }
    if (searchData.tokens) {
      params.tokens = searchData.tokens;
    }
    // Process Filters
    params = {
      ...params,
      ...searchData.filters,
    };
  }

  // Add Extra Params to url (e.g. utm parameters)
  if (extraParams) {
    params = {
      ...params,
      ...extraParams,
    };
  }

  // Build Final Url
  const str = endpointParamsToResourceId(params);
  if (str) {
    url += `?${canonicaliseQueryString(str)}`;
  }
  return url;
};

export const getDefaultSortValue = (params: { authUser: AuthUser }): string => {
  if (isAdmin(params.authUser)) {
    return SEARCH_SORT_RELEVANCE;
  } else {
    return get(
      params,
      'authUser.customer.personalizedAestheticModels[0].modelId',
      SEARCH_SORT_RELEVANCE
    );
  }
};

const getDefaultCollection = (authUser: AuthUser) => {
  if (isBuyer(authUser) || isBCG(authUser)) {
    return SEARCH_FILTER_COLLECTION_MIXED;
  }
  return undefined;
};

const getDefaultStock = (authUser: AuthUser) => {
  if (isBCG(authUser)) {
    return [SEARCH_FILTER_STOCK_NOSTOCK, SEARCH_FILTER_STOCK_FLAGGED];
  }
  if (isING(authUser)) {
    return SEARCH_FILTER_STOCK_NOSTOCK;
  }
  return undefined;
};

const getDefaultMarketScore = (authUser: AuthUser) => {
  if (isING(authUser)) {
    return [
      SEARCH_FILTER_MARKET_SCORE_GOOD,
      SEARCH_FILTER_MARKET_SCORE_VERY_GOOD,
      SEARCH_FILTER_MARKET_SCORE_AVERAGE,
    ];
  }

  // TODO: remove me after asking ramz
  // set very_good as default for marketScore for EY customer
  // added march, 21st
  if (
    compareAsStrings(
      get(authUser, 'customer.id'),
      '665e2776-9b7f-4c73-a2c1-c4ecc0c7b060'
    )
  ) {
    return [SEARCH_FILTER_MARKET_SCORE_VERY_GOOD];
  }

  if (isBuyer(authUser) || isBCG(authUser)) {
    return [SEARCH_FILTER_MARKET_SCORE_GREAT];
  }
  return undefined;
};

export const defaultFilterValues = (params: {
  authUser: AuthUser,
}): SearchFilters => {
  const customerId = get(params, 'authUser.customer.id');
  if (
    customerId === 'df69c9c8-9f5a-4e52-b15f-c79ab9d14af8' ||
    customerId === '36323c53-e74e-4f84-a27c-31e3ef47360d'
  ) {
    return cleanObject({
      grayscale: SEARCH_FILTER_GRAYSCALE_COLOR,
      stock: SEARCH_FILTER_STOCK_NOSTOCK,
      collection: isBuyer(params.authUser)
        ? SEARCH_FILTER_COLLECTION_MIXED
        : undefined,
      marketStatus: isBuyer(params.authUser)
        ? SEARCH_FILTER_MARKET_STATUS_COMMERCIAL
        : undefined,
      marketScore: [SEARCH_FILTER_MARKET_SCORE_GREAT],
      proDownloads: isEnterpriseCustomer(params.authUser)
        ? SEARCH_FILTER_PRODOWNLOADS_ONLY_NEW
        : undefined,
      replaceQuery: SEARCH_FILTER_REPLACE_QUERY_TRUE,
    });
  }
  return cleanObject({
    marketStatus: isBuyer(params.authUser)
      ? SEARCH_FILTER_MARKET_STATUS_COMMERCIAL
      : undefined,
    collection: getDefaultCollection(params.authUser),
    marketScore: getDefaultMarketScore(params.authUser),
    proDownloads: isEnterpriseCustomer(params.authUser)
      ? SEARCH_FILTER_PRODOWNLOADS_ONLY_NEW
      : undefined,
    replaceQuery: SEARCH_FILTER_REPLACE_QUERY_TRUE,
    stock: getDefaultStock(params.authUser),
    grayScale: isING(params.authUser)
      ? SEARCH_FILTER_GRAYSCALE_COLOR
      : undefined,
    minResolution: isING(params.authUser)
      ? SEARCH_FILTER_MIN_RESOLUTION_9MP
      : undefined,
  });
};

export const defaultFiltersSet = (
  filters: SearchFilters,
  params: { authUser: AuthUser }
) => isEqual(filters, defaultFilterValues(params));

export const hasNoSearchTerm = (searchData: SearchData) => !searchData.q;
type SearchTracking =
  | {
      searchType: 'term' | 'sort' | 'sort_pa' | 'reset',
    }
  | {
      searchType: 'filter',
      filterAction: string,
      filterType: string,
      filterValue: string,
    }
  | {
      searchType: 'search_spellcheck_suggestion' | 'search_spellcheck_original',
      previousSpellcheck: SpellcheckObject,
    };

export const trackSearch = (
  searchTracking: SearchTracking,
  searchData: SearchData,
  spellcheck: SpellcheckObject,
  total: number
) =>
  window.dataLayer.push({
    event: 'search',
    search: cleanObject({
      filters: searchData?.filters,
      sorters: searchData?.sort,
      terms: searchData?.q,
      spellcheck: spellcheck && qs.stringify(spellcheck),
      previousSpellcheck:
        searchTracking.previousSpellcheck &&
        qs.stringify(searchTracking.previousSpellcheck),
      results: total,
      searchType: searchTracking.searchType,
      change: searchTracking.searchType === 'filter' && {
        action: searchTracking.filterAction,
        name: `filter_${searchTracking.filterType}`,
        value: searchTracking.filterValue,
      },
    }),
  });

export const toggleFilterValue = (
  currentState: string | string[] = [],
  value: string,
  filterType: FilterType,
  authUser: AuthUser
) => {
  const filter = getFilter({ authUser, filterType });

  if (filter && filter.multipleChoice && Array.isArray(currentState)) {
    if (currentState.indexOf(value) >= 0) {
      const updatedArray = deleteFromArray(currentState, value);
      return updatedArray.length > 0 ? updatedArray : undefined;
    }
    return addToArray(currentState, value);
  }

  return value === '0' || currentState === value
    ? defaultFilterValues({ authUser })[filterType]
    : value;
};

/**
 * Called on filter change (except proDownloads).
 * Will track and trigger a search if there are search terms.
 * TODO: merge this with updateSearchFilters redux action
 */
export const onFilterChange = (params: {
  filterType: FilterType,
  filterValue: string,
  updateSearchFilters: Function,
  setSearchTrack: Function,
  searchData: SearchData,
  unsetContextPaginatableMetadata: Function,
  authUser: AuthUser,
  navigate: Function,
  language?: string,
}) => {
  params.updateSearchFilters({
    filterType: params.filterType,
    value: params.filterValue,
    authUser: params.authUser,
  });
  params.unsetContextPaginatableMetadata();
  window.scrollTo(0, 0);

  const currentFilterState = params.searchData.filters[params.filterType];
  const filter = getFilter({
    authUser: params.authUser,
    filterType: params.filterType,
  });

  // some filters are arrays, some are not
  let filterAction = 'select';
  if (
    (get(filter, 'multipleChoice') &&
      currentFilterState &&
      Array.isArray(currentFilterState) &&
      currentFilterState.indexOf(params.filterValue) >= 0) ||
    currentFilterState === params.filterValue
  ) {
    filterAction = 'deselect';
  }

  params.setSearchTrack({
    searchType: 'filter',
    filterType: params.filterType,
    filterValue: params.filterValue,
    filterAction,
  });

  if (!hasNoSearchTerm(params.searchData)) {
    const newSearchData = {
      ...params.searchData,
      filters: {
        ...params.searchData.filters,
        [params.filterType]: toggleFilterValue(
          params.searchData.filters[params.filterType],
          params.filterValue,
          params.filterType,
          params.authUser
        ),
      },
    };

    params.navigate(
      getUrlLocalized(`${getSearchUrl(newSearchData)}`, params.language)
    );
  }
};

const illustrationLightboxIds = [
  '97e71f6b-763d-40b6-88a4-29f5d1417607',
  'aa138e51-dce6-45c6-8cc1-4a4b1eff21ec',
  'f100c13a-627c-4f20-bed6-8482a8cd9245',
  '1d99a593-a484-4c38-acea-2e52aaf1b01b',
  'fa683976-3883-4389-b586-e7bbe85966e8',
  '5c2a49f6-da24-4f04-bdcc-af28b18e76b0',
  '91a96033-4a6e-4887-ab46-7503a0ac91f5',
  '791e472b-cc58-470e-8f96-93fd5c2dc613',
  '228a2470-b35a-4ade-b243-ebbe627966bb',
  '110fed92-baa7-4161-9814-26bc5d9461bc',
  '5b21e5d9-e925-4003-b187-206219f5da1b',
  '62be1b1b-fd23-4ec8-89b4-cf86fddd727f',
  'abadb25e-fc85-43b6-bfc2-9d80aa6ae6c1',
  'f1e5e024-72ec-48bc-a747-8ed25492a3a3',
  'cb8b9090-5bd6-4b96-9525-36c39fa2224b',
  'be826926-7727-4634-9af9-3a7d671de9a5',
  'd4c9e1bc-c5f1-4a60-aff2-9578920e2e91',
  '618aced5-3a69-449a-aec6-0f4033bd64a5',
  '275eba1c-42d6-493e-9911-b2a4878b55a7',
  '460044dd-7a11-41a8-b22e-acdb9305b316',
];
const photoLightboxIds = [
  '4d314fd9-31bf-4a00-a7c4-f13251e6afb4',
  '0d7bbe90-1753-41c1-896d-830796657323',
  '3a267832-00d2-4bb4-85ce-1511e0b7818b',
  '2f38d622-3d38-4f81-b503-70698301a0ad',
  'ad11c2e5-eab7-43f8-94be-3dd9e7a6113e',
  '0b263b4b-0de4-4146-a3f1-cff138527fec',
  'a97f18c0-ff0d-4976-acf5-7daee55f7f29',
  '4c2ab044-3d67-47fa-996b-005be17be108',
  '88137f6d-8277-473a-a40b-39092895a26e',
  '425f7faa-0def-4e41-8f12-8b82eedb98d4',
  '515f25fe-afe8-48c9-a539-a99385ac4d8e',
  '1b666cec-a215-4743-a9cd-132747b84219',
  '9fda2487-1825-4bac-88f5-c4fa6fa1601e',
  'e29b7f35-feab-4082-bc79-788067aac46d',
  'dc651950-2a6a-4e66-b124-b232fc3e2175',
  '69852130-7f47-4597-8919-b0430f7971ff',
  '141bb9f6-edaf-4458-abd7-84d48e8a483f',
  '3f992478-14c8-41d3-b958-bcc338b00f5a',
];

export const getLightboxIdsForPreviewGrid = (
  isIllustrationSearch: boolean
): $ReadOnlyArray<EyeEmLightboxId> =>
  isIllustrationSearch ? illustrationLightboxIds : photoLightboxIds;
