/* @flow */
import { post } from './clientRequest';

export const storeConversionEventOption = (
  payload: StoreConversionEventOptionPayload
) =>
  post({
    endpoint: '/storeConversionEventOption',
    requestParams: payload,
  });

export const setSessionField = (payload: SetSessionFieldPayload) =>
  post({
    endpoint: '/setSessionField',
    requestParams: payload,
  });

export const switchLanguage = (payload: SwitchLanguagePayload) =>
  post({
    endpoint: '/switchLanguage',
    requestParams: payload,
  });

export const trackMissingTranslation = (payload: {
  locale: string,
  key: string,
  fallback: string,
  scope: string,
}) =>
  post({
    endpoint: '/translate',
    requestParams: payload,
  });

export const getHubspotToken = (payload: HubspotAccessTokenPayload) =>
  post({
    endpoint: '/getHubspotToken',
    requestParams: payload,
  });
