// Queries

export const ALBUM_PHOTOS_QUERY_ALIAS = 'getAlbumPhotos';
export const COMMENTS_QUERY_ALIAS = 'comments';
export const GET_PAYPAL_ACCOUNT = 'getPaypalAccount';
export const LIGHTBOX_FOR_GRID_QUERY_ALIAS = 'getLightboxForGrid';
export const LIGHTBOX_QUERY_ALIAS = 'getLightbox';
export const MULTI_RELEASE_QUERY_ALIAS = 'multiRelease';
export const OWN_LIGHTBOXES_QUERY_ALIAS = 'getOwnLightboxes';
export const RELEASE_QUERY_ALIAS = 'release';
export const SEARCH_QUERY_ALIAS = 'search';
export const SINGLE_ILLUSTRATION_QUERY_ALIAS = 'getIllustration';
export const SINGLE_PHOTO_QUERY_ALIAS = 'getPhoto';
