/* @flow */
/**
 * Our top level component and main entry point into the app
 */

import t from 'counterpart';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import theme from '../helpers/theme';
import GlobalStyle from '../helpers/globalStyles';

import NavbarRouter from './navbarRouter';
import SideNavRouter from './general/sideNavRouter/';
import FooterRouter from './footerRouter';
import PageStructure from './pageStructure/';
import { trackMissingTranslation } from '../clientAPI/MiscAPI';
import { RouterQueryParamsContext } from '../helpers/contexts/routerParams';

const reportMissingTranslate = (locale, key, fallback, scope) => {
  if (typeof window !== 'undefined') {
    trackMissingTranslation({ locale, key, fallback, scope });
    t.offTranslationNotFound(reportMissingTranslate);
  }
};

// prevent node warning on too many Translate Component event listeners
t.setMaxListeners(0);
t.onTranslationNotFound(reportMissingTranslate);

function App(props: {
  path: string,
  _query: {},
  shouldRenderInjectableNavbar?: boolean,
  shouldRenderInjectableFooter?: boolean,
  shouldRenderInjectableSideNavbar?: boolean,
}) {
  const {
    shouldRenderInjectableNavbar,
    shouldRenderInjectableFooter,
    shouldRenderInjectableSideNavbar,
  } = props;

  if (shouldRenderInjectableNavbar) {
    return (
      <StoreProvider store={props.reduxStore}>
        <ThemeProvider theme={{ ...theme, mode: 'light' }}>
          <>
            <GlobalStyle />
            <NavbarRouter
              path={props.path}
              isInExternalPage={shouldRenderInjectableNavbar}
            />
          </>
        </ThemeProvider>
      </StoreProvider>
    );
  }

  if (shouldRenderInjectableSideNavbar) {
    return (
      <StoreProvider store={props.reduxStore}>
        <ThemeProvider theme={{ ...theme, mode: 'light' }}>
          <>
            <GlobalStyle />
            <SideNavRouter path={props.path} />
          </>
        </ThemeProvider>
      </StoreProvider>
    );
  }

  if (shouldRenderInjectableFooter) {
    return (
      <StoreProvider store={props.reduxStore}>
        <ThemeProvider theme={{ ...theme, mode: 'light' }}>
          <>
            <GlobalStyle />
            <FooterRouter
              path={props.path}
              isInExternalPage={shouldRenderInjectableFooter}
            />
          </>
        </ThemeProvider>
      </StoreProvider>
    );
  }

  return (
    <StoreProvider store={props.reduxStore}>
      <RouterQueryParamsContext.Provider value={props._query}>
        <ThemeProvider theme={{ ...theme, mode: 'light' }}>
          <>
            <GlobalStyle />
            <PageStructure path={props.path} />
          </>
        </ThemeProvider>
      </RouterQueryParamsContext.Provider>
    </StoreProvider>
  );
}

export default App;
