/** @flow */
import { useEffect } from 'react';
import { getHubspotToken } from '../../../clientAPI/MiscAPI';

type HubspotChatBotProps = {
  authUser: { email: string },
  isBuyer: boolean,
};

const HubspotChatBot = ({ authUser, isBuyer }: HubspotChatBotProps) => {
  const getToken = async () => {
    const response = await getHubspotToken({ email: authUser.email });
    const token = await response?.body?.token;
    return token;
  };

  const onConversationsAPIReady = () => {
    if (
      !(
        window.hsConversationsSettings &&
        window.hsConversationsSettings.identificationToken
      )
    ) {
      getToken().then((tokenResponse) => {
        if (tokenResponse) {
          window.hsConversationsSettings = {
            ...window.hsConversationsSettings,
            identificationEmail: authUser.email,
            identificationToken: tokenResponse,
          };

          window.HubSpotConversations.widget.load({ widgetOpen: true });
        }
      });
    }
  };

  useEffect(() => {
    if (!isBuyer || !authUser || !authUser.email) return;

    window.hsConversationsSettings = {
      loadImmediately: false,
    };

    if (window.HubSpotConversations) {
      onConversationsAPIReady();
    } else {
      window.hsConversationsOnReady = [onConversationsAPIReady];
    }
  }, [authUser, isBuyer, onConversationsAPIReady]);

  return <></>;
};

export default HubspotChatBot;
