/* @flow */
import {
  RECEIVE_NORMALIZED_DATA,
  ADD_OR_UPDATE_CART_ITEM,
  REMOVE_FROM_CART,
  CLEAR_CART,
  UPDATE_PAYMENT_STATUS,
} from '../constants/actionTypes';

export default function checkout(
  state: CheckoutState = { isLoginVisible: false, timestamp: 0 },
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case RECEIVE_NORMALIZED_DATA: {
      let updatedTotals = state.totals;
      if (
        action?.payload?.cart?.photos?.length === 0 &&
        action?.payload?.cart?.deals?.length === 0
      ) {
        updatedTotals = {};
      }
      return action.payload.checkout &&
        action.payload.checkout.timestamp > state.timestamp
        ? {
            ...action.payload.checkout,
          }
        : { ...state, totals: updatedTotals };
    }
    case ADD_OR_UPDATE_CART_ITEM:
    case REMOVE_FROM_CART:
      return {
        ...state,
        checkoutPending: true,
      };
    case CLEAR_CART:
      return { totals: {}, timestamp: 0 };
    case UPDATE_PAYMENT_STATUS:
      return {
        ...state,
        payment: action.payload,
      };
    default:
      return state;
  }
}

export function mapInitialDataToInitialState(initialData: NormalizedData) {
  return initialData.checkout
    ? initialData.checkout
    : { totals: {}, timestamp: 0 };
}

export const getCheckoutTotals = (state: CheckoutState) => state.totals;

export const getIsCheckoutPending = (state: CheckoutState) =>
  state.checkoutPending;
export const getIsPaymentPending = (state: CheckoutState) =>
  state.payment && state.payment.status === 'pending';
export const getPaymentError = (state: CheckoutState) =>
  state.payment && state.payment.status === 'error' && state.payment.error;
export const getEditorialCountInCheckout = (state: CheckoutState) =>
  state.totalEditorial;
export const getCheckoutDiscountStatus = (state: CheckoutState) =>
  state.discount;
