/* @flow */
import type { Node } from 'react';

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'qs';

import { isLinkExternal, routeMatchesOneOf } from '../tools';
import sanitize from '../sanitize';
import { getUrlUnlocalized } from '../../helpers/localization';

import {
  getIsLightboxStripOpen,
  getAuthUser,
  getIsAuthenticated,
  getIsBuyer,
  getSessionField,
  getIsPhotographer,
} from '../../reducers/';

import {
  toggleLightboxStripThunk,
  toggleHasSideNavThunk,
} from '../actionCreators/redux';

import { RouterQueryParamsContext } from '../../helpers/contexts/routerParams';

import {
  LIGHTBOXSTRIP_CLOSING_PATHS,
  SESSION_FIELD_HAS_SIDE_NAV,
  SIDENAV_PATHS,
} from '../../constants/misc';

const getPatchedNavigate =
  (
    navigate: Function,
    isLightboxStripOpen: boolean,
    toggleLightboxStrip: Function,
    authUser: AuthUser,
    getPath: Function,
    toggleHasSideNav: Function,
    isAuthenticated: boolean,
    isBuyer: boolean,
    isPhotographer: boolean,
    hasSideNav: boolean
  ) =>
  (path: string, navigation: Object, cb: Function) => {
    // coming from webflow, or going to a webflow page.
    // getPath() returns the current route
    // path returns the new route
    // '/' can return a webflow OR react page
    const comingFromExternal = isLinkExternal(getPath(), authUser);
    const goingToExternal = isLinkExternal(path, authUser);
    if (comingFromExternal || goingToExternal) {
      // eslint-disable-next-line
      top.window.location.href = isLinkExternal(path)
        ? path
        : `${window.location.origin}${path}`;
      return;
    }
    if (
      isLightboxStripOpen &&
      routeMatchesOneOf(path, LIGHTBOXSTRIP_CLOSING_PATHS)
    ) {
      toggleLightboxStrip({
        isOpen: true,
        isLightboxAnimDisabled: true,
      });
    }

    const newSideNavState =
      !!routeMatchesOneOf(path, SIDENAV_PATHS) &&
      isAuthenticated &&
      (isBuyer || isPhotographer);
    if (hasSideNav !== newSideNavState) {
      toggleHasSideNav({
        hasSideNav: newSideNavState,
      });
    }

    navigate(getUrlUnlocalized(path), navigation, cb);
  };

function withRouter(WrappedComponent: Node) {
  class WithRouterComponent extends PureComponent<{
    isLightboxStripOpen: boolean,
    toggleLightboxStrip: Function,
    authUser: AuthUser,
    toggleHasSideNav: Function,
    isAuthenticated: boolean,
    isBuyer: boolean,
    hasSideNav: boolean,
    _query: {},
  }> {
    getRouter = () => this.context.router || {};

    getPath = this.getRouter().getPath;

    routerState = this.getRouter().state;

    patchedNavigate = (path: string, navigation: {}, cb: Function) =>
      getPatchedNavigate(
        this.getRouter().navigate,
        this.props.isLightboxStripOpen,
        this.props.toggleLightboxStrip,
        this.props.authUser,
        this.getPath,
        this.props.toggleHasSideNav,
        this.props.isAuthenticated,
        this.props.isBuyer,
        this.props.isPhotographer,
        this.props.hasSideNav
      )(path, navigation, cb);

    clientSideQueryParams() {
      if (typeof window !== 'undefined') {
        if (this.props._query) return this.props._query;

        const query = qs.parse(window.location.search, {
          ignoreQueryPrefix: true,
        });

        if (query) {
          return query;
        }
      }

      return false;
    }

    getSanitizedQueryParams(clientParams: any, serverParams: any) {
      if (!clientParams && !serverParams) return {};
      const params = clientParams || serverParams;
      return sanitize(params);
    }

    render() {
      // eslint-disable-next-line
      const { toggleHasSideNav, ...props } = this.props;

      return (
        <RouterQueryParamsContext.Consumer>
          {(ssrQueryParams) => (
            <WrappedComponent
              navigate={this.patchedNavigate}
              getPath={this.getPath}
              query={this.getSanitizedQueryParams(
                this.clientSideQueryParams(),
                ssrQueryParams
              )}
              {...props} // eslint-disable-line
            />
          )}
        </RouterQueryParamsContext.Consumer>
      );
    }
  }

  WithRouterComponent.contextTypes = {
    router: PropTypes.any,
  };

  return connect(
    (state) => ({
      isLightboxStripOpen: getIsLightboxStripOpen(state),
      isAuthenticated: getIsAuthenticated(state),
      isBuyer: getIsBuyer(state),
      isPhotographer: getIsPhotographer(state),
      authUser: getAuthUser(state),
      hasSideNav: !!getSessionField(state, SESSION_FIELD_HAS_SIDE_NAV),
    }),
    {
      toggleLightboxStrip: toggleLightboxStripThunk,
      toggleHasSideNav: toggleHasSideNavThunk,
    }
  )(WithRouterComponent);
}

export default withRouter;
