/* @flow */
/* Footer router. */
/* Yes, we need this. It provides the footer links on WebFlow pages with router stuff. */
/* eslint-env browser */
/* eslint-disable react/jsx-props-no-spreading */

import loadable from '@loadable/component';

import { Location, Locations } from 'react-router-component';

const Footer = loadable(() => import('../general/footer'));

function FooterRouter(props: {
  path: string | typeof undefined,
  isInExternalPage: boolean,
}) {
  return (
    <Locations component={null} path={props.path} contextual>
      <Location
        path={/./}
        serverRoute={props.path}
        handler={
          <Footer path={props.path} isInExternalPage={props.isInExternalPage} />
        }
      />
    </Locations>
  );
}

function ParentFooterRouter(props: {
  path: string,
  isInExternalPage?: boolean,
}) {
  return (
    <Locations path={props.path} component={null}>
      <Location path="/de(/*)" handler={<FooterRouter {...props} />} />
      <Location path="/(*)" handler={<FooterRouter {...props} />} />
    </Locations>
  );
}

export default ParentFooterRouter;
