/* @flow */
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';

import LightboxStrip from './lightboxStrip';

import {
  TEMP_FIELD_LIGHTBOX_ANIM_DISABLED,
  SESSION_FIELD_HAS_SIDE_NAV,
  SESSION_FIELD_SIDE_NAV_OPEN,
} from '../../../constants/misc';

import {
  getIsLightboxStripOpen,
  getSessionField,
  getSelectedLightboxId,
  getIsMobile,
} from '../../../reducers/';

import { GET_LIGHTBOX } from '../../../graphql/queries/lightbox';

const mapStateToProps = (state) => {
  return {
    isLightboxAnimDisabled: !!getSessionField(
      state,
      TEMP_FIELD_LIGHTBOX_ANIM_DISABLED
    ),
    isLightboxStripOpen: getIsLightboxStripOpen(state),
    selectedLightboxId: getSelectedLightboxId(state),
    isSideNavOpen: !!getSessionField(state, SESSION_FIELD_SIDE_NAV_OPEN),
    hasSideNav: !!getSessionField(state, SESSION_FIELD_HAS_SIDE_NAV),
    isMobile: getIsMobile(state),
  };
};

export default compose(
  connect(mapStateToProps),
  graphql(GET_LIGHTBOX, {
    name: 'selectedLightbox',
    skip: (ownProps) =>
      !ownProps.selectedLightboxId || !ownProps.isLightboxStripOpen,
    options: (ownProps) => ({
      ssr: false,
      variables: {
        lightboxId: ownProps.selectedLightboxId,
      },
    }),
  })
)(LightboxStrip);
