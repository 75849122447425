/* @flow */
/* eslint-env browser */
import t from 'counterpart';

import {
  ADD_COMMENT,
  DELETE_COMMENT,
  ADD_OR_UPDATE_CART_ITEM,
  TOGGLE_USER_FOLLOW,
} from '../constants/actionTypes';

import { productTracking, trackingEventData } from './tools';

const addComment = () =>
  trackingEventData({
    eventType: 'click_inbound',
    eventName: 'interact_add_comment',
    eventAction: 'click_button',
    eventLabel: 'Add comment',
    eventPosition: 'pv_comment',
  });

const deleteComment = () =>
  trackingEventData({
    eventType: 'click_inbound',
    eventName: 'interact_delete_comment',
    eventAction: 'click_button',
    eventLabel: 'Delete comment',
    eventPosition: 'modal_deletecomment',
  });

const addOrUpdateCartItem = (payload) => {
  const getEventLabel = () => {
    if (payload.eventPosition === 'cart') {
      return 'Edit cart item';
    }
    return payload.added ? 'Add to Cart' : 'icon';
  };
  return trackingEventData({
    eventType: 'click_inbound',
    eventName:
      payload.added && payload.eventPosition !== 'cart'
        ? 'ecom_addToCart'
        : 'ecom_editCartItem',
    eventAction: payload.added ? 'click_button' : 'click_icon',
    eventLabel: getEventLabel(),
    eventPosition: payload.isModal
      ? t('tracking.eventPosition.modalContent')
      : payload.eventPosition,
    eventPositionIndex: payload.eventPositionIndex,
    ecommerce: {
      [payload.added && payload.eventPosition !== 'cart' ? 'add' : 'edit']: {
        products: [
          productTracking({
            asset: payload.item.photo,
            cartItem: {
              ...payload.item,
            },
          }),
        ],
      },
    },
    eventOption: payload.eventOption,
  });
};

const toggleUserFollow = (payload) =>
  trackingEventData({
    eventType: 'click_inbound',
    eventName: `interact_${payload.shouldFollow ? 'follow' : 'unfollow'}_user`,
    eventAction: 'click_button',
    eventLabel: `${payload.shouldFollow ? 'follow' : 'unfollow'}_user`,
    eventPosition: payload.eventPosition,
  });

const events = {
  [ADD_COMMENT]: addComment,
  [DELETE_COMMENT]: deleteComment,
  [ADD_OR_UPDATE_CART_ITEM]: addOrUpdateCartItem,
  [TOGGLE_USER_FOLLOW]: toggleUserFollow,
};

const track = (type: string, payload: {}) => {
  if (events[type]) {
    // console.log('pushing', events[type](payload));
    window.dataLayer.push(events[type](payload));
  }
};

export default track;
