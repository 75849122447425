/* @flow */
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import isPlainObject from 'lodash/isPlainObject';
import sanitizeCaja from '@mapbox/sanitize-caja';

function sanitize(
  input: Array<boolean | Object | string> | Object | boolean | string
) {
  if (!isPlainObject(input)) {
    if (isArray(input)) {
      return input.map((el) => sanitize(el));
    } else if (isBoolean(input)) {
      return input;
    } else {
      return sanitizeCaja(input);
    }
  }

  let result = {};
  for (let [key, val] of Object.entries(input)) {
    result[key] = sanitize(val);
  }

  return result;
}

export default sanitize;
