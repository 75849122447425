/* @flow */
/* Our top level component and main entry point into the app */
import { Component } from 'react';
import qs from 'qs';
import styled from '@emotion/styled';
import { Flex } from '@eyeem-ui/atoms';

import BaseWrapper from '../general/baseWrapper';
import DefaultMeta from '../../views/general/defaultMeta';
import HubspotChatBot from '../general/hubspotChatBot/';
import LightboxStrip from '../lightboxStripRouter/';
import ModalWrapper from '../modal/';
import NavbarRouter from '../navbarRouter/';
import NotificationBar from '../notificationBar/';
import PortalWrapper from '../portalWrapper';
import SideNavRouter from '../../views/general/sideNavRouter/';
import ViewRouter from '../viewRouter/';

import { isInPortalMode, isSearchPath } from '../../helpers/tools';
import withRouter from '../../helpers/hoc/withRouter';

const StyledWrapper = styled(Flex)`
  height: 100%;
`;

type Props = {
  authUser: AuthUser,
  cartRemovals: CartRemovalsState,
  checkCartRemovals: Function,
  dismissNotifications: Function,
  isBuyer: boolean,
  isLoginViaParam: boolean,
  isMobile: boolean,
  language: string,
  openModal: Function,
  path: string,
  setSessionField: Function,
  showNotification: Function,
};

class PageStructure extends Component<Props> {
  componentDidMount() {
    this.handleUrlParams();

    this.props.checkCartRemovals({
      isCartPage: window.location.pathname === '/market/cart',
      isCheckoutPage: window.location.pathname === '/market/checkout',
      cartRemovals: this.props.cartRemovals,
    });
    setTimeout(this.props.dismissNotifications, 15000);
  }

  handleUrlParams = () => {
    let queryString = qs.parse(window.location.search.substr(1));

    // if we have an email or sellerSignup URL parameter, remove parameter
    const showEmailNotif = queryString.email === '1';
    const openNativeParamFound = queryString.openNative === '1';

    if (showEmailNotif) {
      this.props.showNotification({ notification: 'emailVerification' });
    }

    /**
     * The following block is to delete URL parameters
     * that were initially put there to allow uploads to a mission
     * via emails or through login.
     * They are used to prefill the tag in upload.
     */
    if (
      showEmailNotif ||
      openNativeParamFound ||
      queryString.albumName ||
      (queryString.albumId && !isSearchPath(this.props.path)) ||
      queryString.referrer
    ) {
      delete queryString.email;
      delete queryString.openNative;
      delete queryString.albumName;
      delete queryString.albumId;
      delete queryString.referrer;

      queryString = qs.stringify(queryString, { addQueryPrefix: true });

      window.history.replaceState({}, '', this.props.path + queryString);
    }
  };

  isEmbeddedView = () => this.props.isLoginViaParam;

  getPath = () => (typeof window === 'undefined' ? this.props.path : undefined); // eslint-disable-line

  render() {
    // on server side we set serverRoute, on client side the router takes care of this
    if (isInPortalMode) {
      return <PortalWrapper />;
    }

    const path = this.getPath();

    return (
      <div className="reactRoot">
        <DefaultMeta path={this.props.path} language={this.props.language} />
        <div>
          <StyledWrapper justifyContent="stretch" flexDirection="row">
            {!this.isEmbeddedView() && <SideNavRouter path={path} />}
            <BaseWrapper>
              {!this.isEmbeddedView() && (
                <NavbarRouter path={path} isMobile={this.props.isMobile} />
              )}
              {this.props.isBuyer && <LightboxStrip path={path} />}
              <NotificationBar />
              <ViewRouter
                isNavbarPresent={!this.isEmbeddedView()}
                path={path}
              />
            </BaseWrapper>
          </StyledWrapper>
        </div>
        <ModalWrapper path={path} />
        <HubspotChatBot />
      </div>
    );
  }
}

export default withRouter(PageStructure);
