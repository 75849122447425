/* @flow */
/* eslint-env browser */

import { createStore, applyMiddleware, compose } from 'redux';

import reduxThunk from 'redux-thunk';
import reduxAnalytics from 'redux-analytics';
import trackDataLayerEvent from './dataLayerEvents';
import rootReducer, { mapInitialDataToInitialState } from '../reducers/';

const middlewares = [
  reduxAnalytics(({ type, payload }) => trackDataLayerEvent(type, payload)),
  reduxThunk,
];

const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.eyeconfig &&
    window.eyeconfig.env === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || // eslint-disable-line no-underscore-dangle
  compose;

const configureStore = (initialData: NormalizedData) => {
  const store = createStore(
    rootReducer,
    mapInitialDataToInitialState(initialData),
    composeEnhancers(applyMiddleware(...middlewares))
  );

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('../reducers/', () => store.replaceReducer(rootReducer));
  }

  return store;
};

export default configureStore;
