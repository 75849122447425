/* @flow */
/* eslint-env browser */
/* eslint-disable no-return-assign */
import type { Node } from 'react';

import { Component } from 'react';

let isChrome;
let isMacFirefox;

type Props = {
  children: Node,
};

/**
 * A component for preventing the flow upward of
 * scroll wheel event emitted by the first child
 */
class ScrollCapture extends Component<Props> {
  componentDidMount() {
    if (this.props.children) {
      // attach the listeners to the first child of the capture div
      if (this.element.children) {
        this.element = this.element.children[0]; // eslint-disable-line

        isChrome = navigator.userAgent.indexOf('Chrome') !== -1;
        isMacFirefox =
          navigator.userAgent.indexOf('Macintosh') !== -1 &&
          navigator.userAgent.indexOf('Firefox') !== -1;

        // Register mousewheel event handlers.
        this.element.onwheel = this.wheelHandler; // Future browsers
        this.element.onmousewheel = this.wheelHandler; // Most current browsers

        if (isMacFirefox) {
          // Firefox only
          this.element.scrollTop = 0;
          this.element.addEventListener(
            'DOMMouseScroll',
            this.wheelHandler,
            false
          );
        }
      }
    }
  }

  componentWillUnmount() {
    this.element.removeEventListener(
      'DOMMouseScroll',
      this.wheelHandler,
      false
    );
  }

  // taken from this jsFiddle: http://jsfiddle.net/dima_k/5mPkB/1/
  // prevent from scrolling parent elements
  wheelHandler = (event: ScrollEvent) => {
    const ev = event || window.event; // Standard or IE event object

    // Extract the amount of rotation from the event object, looking
    // for properties of a wheel event object, a mousewheel event object
    // (in both its 2D and 1D forms), and the Firefox DOMMouseScroll event.
    // Scale the deltas so that one "click" toward the screen is 30 pixels.
    // If future browsers fire both "wheel" and "mousewheel" for the same
    // event, we'll end up double-counting it here. Hopefully, however,
    // cancelling the wheel event will prevent generation of mousewheel.
    let deltaY =
      ev.deltaY * -30 || // wheel event
      ev.wheelDeltaY / 4 || // mousewheel event in Webkit
      (ev.wheelDeltaY === undefined && // if there is no 2D property then
        ev.wheelDelta / 4) || // use the 1D wheel property
      ev.detail * -10 || // Firefox DOMMouseScroll event
      0; // property not defined

    // Most browsers generate one event with delta 120 per mousewheel click.
    // On Macs, however, the mousewheels seem to be velocity-sensitive and
    // the delta values are often larger multiples of 120, at
    // least with the Apple Mouse. Use browser-testing to defeat this.
    if (isChrome || isMacFirefox) {
      deltaY /= 30;
    }

    ev.currentTarget.scrollTop -= deltaY;

    // If we ever get a mousewheel or wheel event in (a future version of)
    // Firefox, then we don't need DOMMouseScroll anymore.
    if (isMacFirefox && ev.type !== 'DOMMouseScroll') {
      this.element.removeEventListener(
        'DOMMouseScroll',
        this.wheelHandler,
        false
      );
    }

    // Don't let this event bubble. Prevent any default action.
    // This stops the browser from using the mousewheel event to scroll
    // the document. Hopefully calling preventDefault() on a wheel event
    // will also prevent the generation of a mousewheel event for the
    // same rotation.
    if (ev.preventDefault) {
      ev.preventDefault();
    }
    if (ev.stopPropagation) {
      ev.stopPropagation();
    }
    ev.cancelBubble = true; // IE events
    ev.returnValue = false; // IE events
    return false;
  };

  render() {
    return (
      <div ref={(el) => (this.element = el)} className="scroll-capture">
        {this.props.children}
      </div>
    );
  }
}

export default ScrollCapture;
