/* @flow */
import { breakpoints } from '@eyeem-ui/theme';

import {
  RECEIVE_NORMALIZED_DATA,
  UPDATE_BROWSER_INFO,
  SWITCH_LANGUAGE,
} from '../constants/actionTypes';

import {
  DEVICE_TYPE_PHONE,
  DEVICE_TYPE_TABLET,
  DEVICE_TYPE_DESKTOP,
  DEVICE_OS_IOS,
  DEVICE_OS_ANDROID,
} from '../constants/misc';

// TODO: find a way to full device without the need for request info?
const mapLegacyRequestInfo = (requestInfo: LegacyRequestInfo): RequestState => {
  const returnRequestInfo = {
    device: {
      isMobile: requestInfo.deviceType === DEVICE_TYPE_PHONE,
      isTablet: requestInfo.deviceType === DEVICE_TYPE_TABLET,
      isDesktop: requestInfo.deviceType === DEVICE_TYPE_DESKTOP,
      isIOS: requestInfo.os === DEVICE_OS_IOS,
      isAndroid: requestInfo.os === DEVICE_OS_ANDROID,
      isMac: requestInfo.isMac,
      operatingSystem: requestInfo.os,
      type: requestInfo.deviceType,
      browser: {
        devicePixelRatio: requestInfo.devicePixelRatio || 2,
        maxWidth: requestInfo.maxWidth,
      },
    },
    currency: requestInfo.currency,
    countryIso: requestInfo.countryIso,
    utm: requestInfo.utm,
  };
  if (requestInfo.language) {
    returnRequestInfo.language = requestInfo.language;
  }
  return returnRequestInfo;
};

export default function request(
  state: RequestState = {},
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case RECEIVE_NORMALIZED_DATA:
      if (action.payload.requestInfo) {
        return {
          ...state,
          ...mapLegacyRequestInfo(action.payload.requestInfo),
          device: {
            ...state.device,
            browser: {
              ...state.device.browser,
            },
          },
        };
      }
      return state;
    case UPDATE_BROWSER_INFO:
      return {
        ...state,
        device: {
          ...state.device,
          browser: action.payload,
        },
      };
    case SWITCH_LANGUAGE:
      return {
        ...state,
        language: action.payload.language,
      };
    default:
      return state;
  }
}

export const mapInitialDataToInitialState = (initialData: NormalizedData) =>
  mapLegacyRequestInfo(initialData.requestInfo);

export const getCountryIso = (state: RequestState): string => state.countryIso;
export const getLanguage = (state: RequestState): string => state.language;

export const getDeviceType = (
  state: RequestState
): 'mobile' | 'tablet' | 'desktop' | 'bot' => state.device.type;
export const getIsMobile = (state: RequestState): boolean =>
  state.device.isMobile;
export const getIsTablet = (state: RequestState): boolean =>
  state.device.isTablet;
export const getIsDesktop = (state: RequestState): boolean =>
  state.device.isDesktop;

export const getIsMac = (state: RequestState): boolean =>
  state.device.isMac || false;

export const getUTM = (state: RequestState): UTMState => state.utm;
export const getDevicePixelRatio = (state: RequestState): number =>
  state.device.browser.devicePixelRatio;

// this is NOT the current browser size, it is the maximum possible
// browser size for the current largest screen the device has.
// For orientation-aware devices these values are given in portrait mode,
// so we need to get the max of the two so that we don't get blurry images
// after an orientation change. Downside is that we load landscape images for portrait devices.
// On server side (= before first in-page navigation), this is a guessed value.
export const getMaxDeviceWidth = (state: RequestState): number =>
  state.device.browser.maxWidth;

export const getScreenDimensions = (
  state: RequestState
): {
  width: number,
  height: number,
} => state.device.browser.screenDimensions;

export const getBreakpointName = (state: RequestState): Breakpoint => {
  let breakpointName = 'small';

  Object.keys(breakpoints).forEach((name) => {
    // iterate over breakpoints and step up as long as necessary
    if (getMaxDeviceWidth(state) > breakpoints[name]) {
      breakpointName = name;
    }
  });
  return breakpointName;
};
export const getCheckoutCurrency = (state: CheckoutState) => state.currency;

// deprecated
export const getDevice = (state: RequestState): DeviceState => state.device;
