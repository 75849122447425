/* @flow */
import get from 'lodash/get';
import find from 'lodash/find';

import {
  RECEIVE_NORMALIZED_DATA,
  ADD_OR_UPDATE_CART_ITEM,
  REMOVE_FROM_CART,
  SAVE_FOR_LATER,
} from '../constants/actionTypes';

import { compareAsStrings } from '../helpers/tools';

const cartItem = (
  state: EyeEmCartItemPhoto = {},
  action: ReduxAction<Action>
) => {
  switch (action.type) {
    case ADD_OR_UPDATE_CART_ITEM:
      return action.payload.item.id === state.id ? action.payload.item : state;
    default:
      return state;
  }
};

const isPhotoItem = (action) =>
  Boolean(action.payload.item.distributionLicenseId);

const addOrUpdateItem = (stateItems, action) => {
  return find(stateItems, { id: action.payload.item.id })
    ? stateItems &&
        stateItems.map((cartItemItem) => cartItem(cartItemItem, action))
    : [action.payload.item, ...(stateItems || [])];
};

export default function cart(
  state: EyeEmCartState = { photos: [], deals: [], savedForLater: [] },
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case RECEIVE_NORMALIZED_DATA:
      return action.payload.cart || state;
    case REMOVE_FROM_CART: {
      const statePhotos = state.photos || [];
      const stateDeals = state.deals || [];
      const stateSavedForLater = state.savedForLater || [];
      const payloadPhotos = action.payload.photos || [];
      const payloadDeals = action.payload.deals || [];
      const payloadSavedForLater = action.payload.savedForLater || [];
      return {
        photos: statePhotos.filter(
          (item) =>
            !payloadPhotos.some((photoId) => compareAsStrings(photoId, item.id))
        ),
        deals: stateDeals.filter(
          (item) =>
            !payloadDeals.some((dealId) => compareAsStrings(dealId, item.id))
        ),
        savedForLater: stateSavedForLater.filter(
          (item) =>
            !payloadSavedForLater.some((itemId) =>
              compareAsStrings(itemId, item.id)
            )
        ),
      };
    }
    case ADD_OR_UPDATE_CART_ITEM:
      return {
        photos: isPhotoItem(action)
          ? addOrUpdateItem(state.photos, action)
          : state.photos || [],
        deals: isPhotoItem(action)
          ? state.deals || []
          : addOrUpdateItem(state.deals, action),
        savedForLater: state.savedForLater.filter(
          (item) => item.id !== action.payload.item.id
        ),
      };
    case SAVE_FOR_LATER:
      return {
        photos: state.photos.filter((photo) => photo.id !== action.payload.id),
        deals: state.deals.filter((deal) => deal.id !== action.payload.id),
        savedForLater: [...state.savedForLater, action.payload],
      };
    default:
      return state;
  }
}

export function mapInitialDataToInitialState(initialData: NormalizedData) {
  return get(initialData, 'cart', {
    photos: [],
    deals: [],
    savedForLater: [],
  });
}

export const getCart = (state: EyeEmCartState) => state;
export const getCartPhotos = (state: EyeEmCartState) =>
  (state && state.photos) || [];
export const getCartDeals = (state: EyeEmCartState) =>
  (state && state.deals) || [];
export const getCartPhotoItem = (state: EyeEmCartState, id: EyeEmAssetId) =>
  find(state.photos, { id });
export const getIsPhotoInCart = (state: EyeEmCartState, id: EyeEmAssetId) =>
  Boolean(getCartPhotoItem(state, id));
export const getCartDealItem = (
  state: EyeEmCartState,
  id: EyeEmDealTemplateId
) => find(state.deals, { id });
export const getSavedForLater = (state: EyeEmCartState) =>
  (state && state.savedForLater) || [];
