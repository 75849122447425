/**
 * German translations file
 *
 * Used by react-translate-component as follows <T time="value">s.community</T>
 *
 * To use values that are passed as properties wrap them in `%(` and `)s%`. So
 * to use the `time` property from the above example, in your translation write
 * `My translation stuff at %(time)s o\'clock`
 *
 * You must escape an apostrophe if they delimit a quote. Escape with a backslash.
 *
 * Default properties available in translation strings:
 *
 * %(br)s - line break
 * %(br2)s - another line break, trololol
 *
 */

export default {
  maintenance: {
    default: {
      headline: 'Wartung bei EyeEm',
      subheadline:
        'Bitte entschuldige die Unannehmlichkeiten. Bei EyeEm finden von 9:30 bis 12:30 Uhr MEZ planmäßige Wartungsarbeiten statt. In dringenden Fällen wende dich bitte an sales@eyeem.com oder support@eyeem.com.',
    },
    footer: '© %(year)s EyeEm Mobile GmbH.',
  },
  defaultBanner: {
    linkText: 'Jetzt einkaufen.',
    text: 'Erhalte jedes Bild bis zu 80%% günstiger mit unseren flexiblen Abonnements.',
  },
  specialBanner: {
    preCodeText:
      'EyeEm Cyber Week! Sichere dir jetzt 30%% Rabatt auf deinen nächsten Einkauf mit dem Code ',
    code: 'flashfriday2021',
    copied: 'Aktionscode in Zwischenablage kopiert',
    postCodeText: '',
  },
  premium: {
    label: 'Premium',
    info: 'Unsere Premium-Kollektion vereint alle hochwertigen Visuals, die darauf ausgerichtet sind, die kreativen Grenzen zu erweitern, ohne dabei Kompromisse bei der Qualität einzugehen.',
    subline: {
      label: 'Premium Kollektion: ',
    },
  },
  downloadHistory: {
    enterprise: {
      headline: 'Download-Historie',
      text: 'Alle unten angezeigten Bilder sind lizenziert oder als hochauflösende Vorschaubilder heruntergeladen worden.',
      feedName: 'Download-Historie',
      filter: 'Nur meine Downloads',
    },
    empty: {
      photos: {
        icon: 'photos',
        headline: 'Du hast noch keine Photos lizensiert.',
        subline: 'Sobald du ein Photo lizensiert hast, siehst du es hier.',
      },
      illustrations: {
        icon: 'photos',
        headline: 'Du hast noch keine Illustrationen lizensiert.',
        subline:
          'Sobald du eine Illustration lizensiert hast, siehst du sie hier.',
      },
    },
  },
  lightboxes: {
    strip: {
      select: {
        noLightboxSelected: 'Standard-Lightbox',
        create: {
          label: 'Neue Lightbox erstellen',
          eventName: 'access_createLightbox',
          eventPosition: 'lightboxStrip_dropdown',
        },
        view: {
          label: 'Anzeigen',
          eventName: 'access_lightbox',
          eventPosition: 'lightboxStrip_dropdown',
        },
        eventName: 'lightbox_switchStrip',
        eventPosition: 'lightboxStrip_dropdown',
      },
      manage: {
        label: 'Lightbox Übersicht',
        eventName: 'access_lightbox_overview',
      },
      viewAllPhotos: {
        label: 'Lightbox anzeigen',
        eventName: 'access_lightbox',
      },
      removePhoto: {
        iconAlt: 'Aus Lightbox entfernen',
        eventName: 'photo_removeFromLightbox',
      },
      accessAsset: {
        eventName: 'access_asset',
        eventLabel: '',
      },
      eventPosition: 'lightboxStrip',
      empty: {
        first: 'Klicke auf das ',
        second: ' Symbol eines Fotos, um es zu deiner Lightbox hinzuzufügen',
      },
      loading: 'Fotos laden',
    },
    actions: {
      duplicate: {
        label: 'Duplizieren',
        eventName: 'access_duplicateLightbox',
      },
      delete: {
        label: 'Löschen',
        eventName: 'access_deleteLightbox',
      },
      editInfo: {
        label: 'Bearbeiten',
        eventName: 'access_editLightbox',
      },
      eventLabel: 'lightboxActions',
      eventName: 'access_lightboxActions',
      select: {
        label: 'Lightbox auswählen',
      },
      share: {
        label: 'Teilen',
        eventName: 'access_lightboxShare',
      },
      trigger: 'Lightbox bearbeiten',
    },
    singleView: {
      client: '%(client)s - ',
      date: {
        one: 'letzte Aktualisierung vor %(count)s Tag',
        other: 'letzte Aktualisierung vor %(count)s Tagen',
      },
      empty: {
        icon: 'photos',
        headline: 'Du hast noch keine Fotos zu deiner Lightbox hinzugefügt.',
        subline: 'Sobald du ein Foto hinzugefügt hast, siehst du es hier.',
      },
      eventPosition: 'lightbox',
      totalPhotos: {
        one: '1 Bild - ',
        other: '%(count)s Bilder - ',
      },
    },
    modals: {
      lightboxInvite: {
        title: 'Teile Lightbox',
        inviteButton: 'Teilen',
        collaborationInfo:
          'Benutzer mit Zugriff können die Lightbox bearbeiten und teilen.',
        viewOnlyTitle: 'Kann anzeigen',
        viewOnlyInfo: 'Benutzer mit diesem Link können die Datei sehen.',
        viewOnlyLink: 'Link kopieren',
        owner: 'Besitzer',
        canEdit: 'Kann bearbeiten',
        inputPlaceholder: 'Team Mitglied hinzufügen',
        linkCopied: 'Kopiert!',
        error: 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut',
        maxMemberError: 'Maximum an Benutzern erreicht.',
        successMessage: '%(email)s eingeladen',
      },
      create: {
        cancel: {
          label: 'Abbrechen',
          eventName: 'lightboxCreate_cancel',
        },
        confirm: {
          label: 'Erstellen',
          eventName: 'lightboxCreate_save',
        },
        eventPosition: 'lightbox_modal_create',
        title: 'Neue Lightbox erstellen',
      },
      delete: {
        body: 'Achtung beim Löschen, du löschst die Lightbox auch für Mitarbeiter.',
        cancel: {
          label: 'Abbrechen',
          eventName: 'lightboxDelete_cancel',
        },
        confirm: {
          label: 'Löschen',
          eventName: 'lightboxDelete_save',
        },
        eventPosition: 'lightbox_modal_delete',
        title: 'Löschen Lightbox',
      },
      duplicate: {
        cancel: {
          label: 'Abbrechen',
          eventName: 'lightboxDuplicate_cancel',
        },
        confirm: {
          label: 'Duplikat',
          eventName: 'lightboxDuplicate_save',
        },
        eventPosition: 'lightbox_modal_duplicate',
        name: 'Kopie von %(name)s',
        title: 'Lightbox duplizieren',
      },
      form: {
        label: {
          name: 'Name',
          clientDescription: 'Kunde (optional)',
          description: 'Beschreibung (optional)',
        },
        placeholder: {
          name: 'Ein kurzer Name der leicht zu verstehen ist',
          clientDescription: 'Ist diese Lightbox für einen speziellen Kunden?',
          description: 'Beschreibe die die Anfoderungen für diese Lightbox',
        },
      },
      share: {
        headline: 'Lightbox teilen',
        button: {
          copy: 'Link kopieren',
          copied: 'Kopiert.',
        },
        eventName: 'lightboxShare_copy',
        eventPosition: 'lightbox_modal_share',
      },
      update: {
        cancel: {
          label: 'Abbrechen',
          eventName: 'lightboxUpdate_cancel',
        },
        confirm: {
          label: 'Speichern',
          eventName: 'lightboxUpdate_save',
        },
        eventPosition: 'lightbox_modal_update',
        title: 'Lightbox-Details bearbeiten',
      },
    },
    flyout: {
      photos: {
        added: 'Foto hinzugefügt zu',
        removed: 'Foto entfernt aus',
      },
      illustrations: {
        added: 'Illustration hinzugefügt zu',
        removed: 'Illustration entfernt aus',
      },
    },
    overview: {
      headline: 'Meine Lightbox',
      total: '%(number)s lightboxes',
      view: 'View Lightbox',
      create: {
        label: 'Neue Lightbox',
        eventName: 'access_createLightbox',
      },
      tableHead: {
        name: 'Name',
        client: 'Client',
        photos: 'Fotos',
        date: 'Erstellungsdatum',
      },
      accessTracking: {
        eventName: 'access_lightbox',
      },
      eventPosition: 'lightbox_overview',
      emptyState:
        'Hier werden deine Lightboxes gespeichert. Klicke zu Beginn auf ‚Neue Lightbox erstellen‘ – Du kannst so viele erstellen, wie du möchtest.',
    },
    addToLightbox: {
      defaultName: 'Meine Lightbox',
      shortlist: 'Auswahlliste',
      winners: 'Sieger',
      add: {
        label: 'Speichern unter %(lightboxName)s',
        regular: {
          eventName: 'photo_addToLightbox',
        },
        shortlist: {
          eventName: 'missionb2b_shortlist_add',
        },
        winners: {
          eventName: 'missionb2b_winner_add',
        },
      },
      remove: {
        label: 'Entfernen aus %(lightboxName)s',
        regular: {
          eventName: 'photo_removeFromLightbox',
        },
        shortlist: {
          eventName: 'missionb2b_shortlist_remove',
        },
        winners: {
          eventName: 'missionb2b_winner_remove',
        },
      },
      eventLabel: 'Symbol',
      gridEventPosition: 'assetgrid',
      pviewEventPosition: 'pv_stage',
    },
  },
  authForms: {
    or: 'oder',
    openId: {
      eventPosition: 'login_openId',
      headline: 'Mit EyeEm verbinden',
      subline:
        'Melde dich mit deinen %(provider)s Zugangsdaten an, um auf Bilder aus der globalen Fotografen-Community von EyeEm zuzugreifen.',
      errors: {
        generic:
          'Tut uns leid, wir konnten dich nicht bei EyeEm anmelden. Bitte versuche es später noch einmal oder kontaktiere deinen Kundenbetreuer.',
      },
      providers: {
        bcg: 'BCG',
        google: 'Google',
      },
      button: {
        label: 'Verbinden',
        eventName: 'openIdLogin_connect',
      },
      googleButton: {
        loginLabel: 'Mit Google einloggen',
        signupLabel: 'Mit Google anmelden',
      },
      creatorLoginError:
        'Momentan nur mit einem Business Account möglich. Bitte benutze das Formular um dich einzulogen.',
      emailAlreadyRegistered:
        'Email schon vergeben. Bitte melde dich an oder benutze eine andere Email um dich anzumelden.',
      noAccoutFoundError:
        'Wir konnten keinen Account mit dieser Email finden. Bitte registriere dich oder benutze eine andere Email um dich einzulogen.',
    },
    login: {
      headline: 'Wilkommen zurück!',
      facebook: 'Mit Facebook einloggen',
      email: 'E-Mail',
      password: 'Passwort',
      rememberMe: 'Eingelogged bleiben',
      submit: 'Anmelden',
      checkoutSubmit: 'Weiter zum Checkout',
      forgotPassword: 'Passwort vergessen?',
      reset: 'Hier zurücksetzen',
      noAccount: 'Du hast noch kein Konto? %(signup)s',
      signup: 'Jetzt registrieren',
      errorConflict:
        'Tut uns leid, wir konnten dich nicht einloggen. Überprüfe die Daten und versuche es erneut.',
    },
    signup: {
      passwordScores: {
        1: {
          color: 'red50',
          text: 'Passwort leicht zu erraten.',
          strength: 'Weak',
        },
        2: {
          color: 'orange50',
          text: 'Passwort könnte besser sein.',
          strength: 'Medium',
        },
        3: { color: 'aqua50', strength: 'Strong' },
      },
      email: 'E-Mail-Adresse',
      password: 'Passwort',
      errorConflict:
        'Tut uns leid, du kannst dich mit dieser E-Mail-Adresse nicht anmelden. Bitte versuche es mit einer anderen.',
      submit: {
        label: 'Mein kostenloses Konto erstellen',
        eventName: {
          photographer: 'photographerSignup_complete_%(suffix)s',
          buyer: 'buyerSignup_complete_%(suffix)s',
          enterprise: 'enterpriseSignup_complete_%(suffix)s',
        },
      },
      legalSubline:
        "Mit der Anmeldung akzeptierst du EyeEm's %(tos)s und %(privacy)s.",
      tos: 'Allgemeinen Geschäftsbedingungen',
      privacy: 'Datenschutzrichtlinie',
      haveAnAccount: 'Du hast bereits ein Konto? ',
      haveAnAccountPlain: 'Du hast bereits ein Konto? ',
      login: 'Anmelden',
      facebook: 'Mit Facebook anmelden',
    },
    enterpriseBuyerSignup: {
      eventPosition: 'enterprise_buyer_signup',
      headline: 'Tritt dem %(customerName) Team auf EyeEm bei.',
      defaultHeadline: 'Werde Teil deines Teams bei EyeEm',
      fullName: 'Vor- und Nachname',
      signingUpAs: 'Anmelden als ',
      submit: {
        label: 'Jetzt beitreten',
      },
    },
    buyerSignup: {
      eventPosition: 'buyer_signup',
      headline: 'Erstelle einen Business Account.',
      link: 'Möchtest du deine Arbeit verkaufen? %(linkToSignup)s',
      linkToSignup: 'Als Anbieter registrieren',
      emailOptIn:
        'Ich möchte über visuelle Trends und Produktupdates informiert werden. Dieser Newsletter kann jederzeit abbestellt werden.',
    },
    creatorSignup: {
      eventPosition: 'creator_signup',
      headline: 'Jetzt als Anbieter anmelden.',
      link: 'Möchtest du Fotos kaufen? %(linkToSignup)s',
      linkToSignup: 'Als Käufer registrieren',
      emailOptIn:
        'Ich möchte aktuelle Informationen über visuelle Trends, Produkte und exklusive Rabatte erhalten.',
    },
    signupSwitch: {
      eventPosition: 'signup_split',
      buyer: {
        headline: 'Stockfotos kaufen',
        subline:
          'Jetzt mit einem Business Account lizenzfreie Bilder runterladen und Foto-Shoots weltweit buchen.',
        button: {
          label: 'Als Bildkäufer registrieren',
          eventName: 'cta_signup_buyer',
        },
      },
      seller: {
        headline: 'Deine Arbeit hochladen und verkaufen',
        subline:
          'Werde Teil einer Community von +8m Kreativen. Lizensiere Deine Bilder an Unternehmen und werde für Foto-Shoots gebucht.',
        button: {
          label: 'Als Anbieter registrieren',
          eventName: 'cta_signup_seller',
        },
      },
    },
    requestPasswordReset: {
      headline: 'Passwort-Reset anfordern',
      subline:
        'Bitte gib unten deine E-Mail-Adresse ein und wir senden dir einen Link, um dein Passwort zurückzusetzen.',
      email: 'E-Mail-Adresse',
      unknownEmail:
        'Diese E-Mail-Adresse ist uns nicht bekannt. Bitte überprüfe, ob du sie korrekt eingegeben haben.',
      submit: 'Link zum Zurücksetzen anfordern',
      backToLogin: 'Zurück zur Anmeldung',
      success: {
        headline: 'E-Mail erfolgreich versendet!',
        subline:
          'Wenn dieser E-Mail ein Konto zugeordnet ist, erhältst du eine Nachricht mit Anweisungen, wie du dein Passwort zurücksetzen kannst.',
        link: 'Weiter zu EyeEm.com',
      },
    },
    resetPassword: {
      headline: 'Passwort ändern',
      password: 'Neues Passwort',
      confirmPassword: 'Passwort bestätigen',
      submit: 'Passwort ändern',
    },
    connectApp: {
      redirectHeadline:
        'Bereits autorisiert, du wirst weitergeleitet zu „%(name)s“.',
      headline: 'Die App „%(name)s“ möchte auf dein EyeEm-Konto zugreifen.',
      submit: 'Genehmigen',
      link: 'Abbrechen',
    },
  },
  sideNav: {
    items: {
      licensing: 'Bilder kaufen',
      production: 'Fotoshootings',
      profile: 'Profil',
      following: 'Following',
      upload: 'Upload',
      missions: 'Missions',
      account: 'Konto',
      more: 'Mehr',
      team: 'Team',
    },
    account: {
      title: 'Konto',
      items: {
        orderHistory: 'Einkäufe',
        settings: 'Einstellungen',
        payout: 'Auszahlung',
        logout: 'Logout',
      },
    },
    more: {
      title: 'Mehr',
      magazine: 'Magazin',
      items: {
        company: {
          title: 'EyeEm',
          about: 'Über EyeEm',
          tech: 'Technologie',
          press: 'Presse',
          careers: 'Jobs',
        },
        legal: {
          title: 'Legal',
          terms: 'AGB und Datenschutz',
          imprint: 'Impressum',
        },
        support: {
          title: 'Support',
          help: 'Hilfe',
        },
      },
    },
  },
  navbar: {
    pricing: 'Preise',
    backToApp: 'Zurück zur App',
    community: {
      label: 'Community',
      eventName: 'access_community',
      eventPosition: 'navbar_community',
      items: {
        missions: {
          label: 'Missionen',
        },
        awards: {
          label: 'Auszeichnungen',
          href: '/awards',
        },
        buyerSwitch: {
          label: 'Für Käufer',
          href: '/',
          eventName: 'access_community_buyer',
        },
      },
    },
    logo: {
      eventName: 'access_eyeem',
      eventLabel: 'logo',
    },
    search: {
      cancel: 'Abbrechen',
    },
    products: {
      label: 'Produkte',
      eventName: 'access_products',
      eventPosition: 'navbar_products',
      items: {
        video: {
          label: 'Video',
          href: '/productions',
        },
        videoContribute: {
          label: 'Video',
          href: '/video/contribute',
        },
        licensing: {
          label: 'Bilder kaufen',
          href: '/marketplace',
        },
        custom: {
          label: 'Fotoshootings buchen',
          href: '/productions',
        },
        searchPA: {
          label: 'Personalisierte Suche',
          href: '/personalized-search',
        },
        missions: {
          label: 'Bilderwettbewerbe',
        },
        pricing: {
          label: 'Preise & Lizenzen',
          href: '/market/licensing',
        },
        photographerSwitch: {
          label: 'Für Anbieter',
          href: '/creators',
          eventName: 'access_products_photographers',
        },
      },
    },
    missions: {
      label: 'Missionen',
      eventName: 'access_missions',
    },
    signIn: {
      label: 'Anmelden',
      eventName: 'access_signup_split',
    },
    upload: {
      label: 'Hochladen',
      eventName: 'upload_access',
      eventLabel: 'Symbol',
    },
    lightbox: {
      label: 'Lightbox',
      eventName: {
        open: 'access_lightboxStrip_open',
        close: 'access_lightboxStrip_close',
      },
      eventLabel: 'Symbol',
      flyout: {
        eventName: 'access_lightbox',
        eventPosition: 'navbar_lightboxFlyout',
      },
    },
    notifications: {
      tasks: {
        headline: 'Anstehende Aufgaben',
        photoReleases: {
          headline: 'Modellfreigaben Anfordern',
          subline: 'Mach deine Fotos kommerziell verfügbar',
        },
        firstPhoto: {
          headline: 'Uploade dein erstes Photo',
        },
        verifyEmail: {
          headline: 'Bitte verifiziere deine Email',
          subline: 'Klicke für mehr Informationen',
        },
        keywording: {
          headline: 'Bestätige die Marktfoto-Details',
          subline:
            'Mach deine Fotos auffindbar und erhöhe deine Verkaufschancen',
        },
      },
      news: {
        headline: 'Aktivität',
      },
      empty:
        'Du hast im Moment keine neuen Benachrichtigungen. Schau bald wieder rein!',
      eventName: 'access_news',
    },
    profileMenu: {
      profile: 'Dein Profil',
      payoutHistory: 'Auszahlung',
      previousPurchases: 'Bestell Historie',
      productions: 'Productions',
      new: 'NEU',
      settings: 'Einstellungen',
      logOut: 'Abmelden',
      eventName: 'access_avatar',
      eventLabel: 'Symbol',
      availableDownloads: {
        TITLE: 'Verfügbare Downloads',
        regular: 'ESSENTIAL BILDER',
        premium: 'PREMIUM BILDER',
      },
      downloadsLeft: 'Downloads übrig',
      unlimited: 'Unbegrenzt',
      renew: 'Erneuern',
    },
    licensesMenu: {
      label: 'Lizenzen',
      eventName: 'access_licenses',
      eventLabel: 'icon',
      emptyStateBuyer: {
        button: 'Kaufe Photo Pack',
        title: 'Spare bis zu 20%% mit unseren Packs.',
        subline:
          'Du brauchst mehrere Bilder? Kaufe Packs und spare bei deinen zukünftigen Downloads.',
      },
    },
    readMeFirst: {
      label: 'Lies mich zuerst',
      href: 'https://dtpuu5koy2g08.cloudfront.net/bcg/EyeEm-Read-Me-User-Guide-Nov-2018.pdf',
      eventName: 'access_readMe',
    },
    bcgCurations: {
      label: 'Kurationen',
      href: '/bcgcurations',
      eventName: 'access_bcgCurationsOnWebflow',
    },
    cart: {
      eventName: 'access_cart',
      eventLabel: 'Symbol',
    },
    myCollection: {
      label: 'Gekaufte Bilder',
      eventName: 'access_myCollection',
      eventLabel: 'Symbol',
    },
    info: {
      buyStockPhotos: 'Kaufe Bilder',
      bookPhotoshoots: 'Buche Shootings',
      pricing: 'Pricing',
      newLabel: 'Neu!',
      contactSales: 'Sales kontaktieren',
      buttons: {
        login: 'Login',
        signup: 'Anmelden',
        creatorSignup: 'Verkaufe deine Photos',
      },
      business: {
        label: 'Für Unternehmen',
        licensing: 'Bilder lizensieren',
        production: 'Foto & Video Produktion',
        pricing: 'Preise & Lizenzen',
      },
      photographers: {
        label: 'Für Anbieter',
        join: 'Die Community',
        sell: 'Bilder verkaufen',
        collective: 'EyeEm Collective',
        awards: 'EyeEm Awards',
        missions: 'Missions',
        support: 'Du brauchst Hilfe?',
      },
      more: {
        label: 'More',
        magazine: 'EyeEm Magazine',
        story: 'Unsere Story',
        tech: 'Unsere Technologie',
        terms: 'AGBs & Datenschutz',
        faq: 'FAQ & Hilfe',
        jobs: 'Jobs',
        contact: 'Kontakt',
      },
    },
    eventPosition: {
      photographer: 'navbar_photographer',
      buyer: 'navbar_buyer',
      enterprise: 'navbar_enterprise',
      naked: 'navbar_naked',
      info: 'navbar_info',
    },
  },
  feedLabel: {
    popular: 'Beliebt',
    following: 'following',
  },
  collapsible: {
    expand: 'Erweitern',
    collapse: 'Reduzieren',
  },
  nsfw: {
    warning: {
      imgAlt: 'Abdeckung für möglicherweise anstößige Bilder',
      message: 'Dieses Foto kann anstößig sein',
      subtitle: {
        mobile: 'Zum Einblenden tippen',
        tablet: 'Zum Einblenden tippen',
        desktop: 'Zum Einblenden anklicken',
      },
    },
  },
  notifications: {
    supportEmail: 'support@eyeem.com',
    accountDeletionPayouts:
      'Kontolöschung fehlgeschlagen. Wenn du noch nicht ausgezahlte Einnahmen hast, zahle sie bitte aus, bevor du dein EyeEm-Konto löschst. Kontaktiere %(supportDeleteAccount)s, wenn deine nicht ausgezahlten Einnahmen unter 10$ liegen.',
    unverifiedUserSettingsUpdateHomepage:
      'Homepage konnte nicht gesetzt werden. Bitte verifiziere zuerst deine E-Mail',
    UserSettingsHomepageShortenerError:
      'Homepage konnte nicht gesetzt werden. Du kannst keine URL shorteners benützen.',
    searchFailed:
      'Bei dieser Suche ist ein Fehler aufgetreten. Bitte versuche es erneut.',
    sellerSignupFailed:
      'Wir konnten deine Registrierung als Verkäufer nicht abschließen. Bitte versuche es erneut oder kontaktiere support@eyeem.com',
    emailVerification: 'Deine E-Mail-Adresse wurde erfolgreich verifiziert.',
    submittedToMission:
      'Gute Arbeit, du hast gerade deine Arbeit bei dieser Mission eingereicht. Wir drücken die Daumen!',
    settingsUpdate: 'Deine Einstellungen wurden aktualisiert.',
    passwordReset: 'Passwort geändert. Du bist jetzt angemeldet.',
    passwordResetEnterprise: 'Passwort geändert. Du kannst dich nun anmelden.',
    serverError:
      'Offensichtlich gibt es technische Probleme. Bitte Seite neu laden.',
    connectionSuccessful:
      'Du hast EyeEm erfolgreich mit deinem %(service)s-Konto verbunden.',
    connectionFailed:
      'Verbindung mit %(service)s fehlgeschlagen. Bitte versuche es erneut.',
    connectionFailedMultiple:
      'Das Facebook-Konto, mit dem du dich verbinden möchtest, wird bereits von einem anderen EyeEm-Konto verwendet. Bitte wende dich an den Support.',
    profileImageUploaded: 'Dein Profilbild wurde erfolgreich aktualisiert.',
    profileImageUploadFailed:
      'Dein Profilbild konnte nicht hochgeladen werden. Bitte versuche es erneut.',
    coverPhotoUpdated: 'Dein Hintergrundbild wurde erfolgreich aktualisiert.',
    loginErrorUnknown:
      'Wir konnten dich nicht anmelden. Bitte fülle alle Felder aus und versuche es erneut oder kontaktiere support@eyeem.com',
    signupEmailConflict:
      'Du kannst dich nicht mit dieser E-Mail-Adresse registrieren. Wähle bitte eine andere.',
    signupErrorUnknown:
      'Registrierung fehlgeschlagen. Bitte versuche es erneut oder kontaktiere support@eyeem.com',
    signupTokenError:
      'Es ist ein Fehler aufgetreten. Bitte versuche es erneut oder kontaktiere support@eyeem.com',
    enterpriseUserRegistered: 'Registrierung erfolgreich.',
    passwordResetExpiredToken:
      'Dein Einladungslink ist abgelaufen. Bitte besuche eyeem.com/password um dein Password zurückzusetzen.',
    enterpriseInviteExpiredToken:
      'Dein Token ist abgelaufen. Bitte kontaktiere support@eyeem.com und bitte um eine erneute Einladung.',
    invalidToken:
      'Dein Token ist abgelaufen. Bitte versuche es erneut oder kontaktiere support@eyeem.com',
    wrongCaptcha:
      'Captcha wurde nicht erkannt. Bitte versuche es erneut oder kontaktiere support@eyeem.com',
    unexpectedUser:
      'Es ist nicht mehr möglich Käufe mit diesem Account zu tätigen. Bitte melde dich neu an oder kontaktiere support@eyeem.com.',
    paypalUpdateError:
      'Es ist ein Fehler aufgetreten. Bitte überprüfe, ob du deinen Namen genau so eingegeben hast, wie er in deinem PayPal-Konto erscheint. In den %(marketSupport)s findest du weitere Informationen.',
    payoutRequestedSuccess:
      'Deine Auszahlung von %(amount)s wurde beantragt. Du erhältst eine E-Mail, sobald die Auszahlung erfolgreich durchgeführt wurde.',
    cart: {
      merged:
        'Du hattest aus einer früheren Sitzung bereits ein oder mehrere Bilder in deinem Warenkorb, also haben wir sie hier hinzugefügt. Benötigst du Hilfe? %(getInTouch)s',
      notFoundPhotos: {
        one: 'Ein Bild aus deinem Warenkorb wurde entfernt, weil es nicht mehr gefunden werden konnte. Benötigst du Hilfe?  %(getInTouch)s',
        other:
          '%(count)s Bilder aus deinem Warenkorb wurden entfernt, weil sie nicht mehr gefunden werden konnten. Benötigst du Hilfe? %(getInTouch)s',
      },
      ownPhotos: {
        one: 'Ein Bild aus deinem Warenkorb wurde entfernt, weil du offensichtlich der Besitzer bist. Benötigst du Hilfe? %(getInTouch)s',
        other:
          '%(count)s Bilder aus deinem Warenkorb wurden entfernt, weil du offensichtlich der Besitzer bist. Benötigst du Hilfe? %(getInTouch)s',
      },
      alreadyBoughtPhotos: {
        one: 'Ein Bild aus deinem Warenkorb wurde entfernt, weil du es offensichtlich schon einmal gekauft hast. Benötigst du Hilfe? %(getInTouch)s',
        other:
          '%(count)s Bilder aus deinem Warenkorb wurden entfernt, weil du sie offensichtlich schon einmal gekauft hast. Benötigst du Hilfe? %(getInTouch)s',
      },
      invalidLicense: {
        one: 'Ein Bild aus deinem Warenkorb wurde entfernt, weil sich die Lizenz geändert hat. Benötigst du Hilfe? %(getInTouch)s',
        other:
          '%(count)s Bilder aus deinem Warenkorb wurden entfernt, weil sich die Lizenzen geändert haben. Benötigst du Hilfe? %(getInTouch)s',
      },
      getInTouch: ' EyeEm Market Support kontaktieren',
    },
    addPhotosToAlbum:
      'Gute Arbeit, du hast gerade deine Bilder bei dieser Mission eingereicht. Wir drücken die Daumen!',
    missionContact:
      'Vielen Dank für deine Nachricht! Wir melden uns innerhalb von 24 Stunden bei dir.',
    blockedlikeBecauseEmail:
      'Du kannst das Fotos erst dann liken, wenn du deine E-Mail-Adresse verifiziert hast.',
    blockedfollowBecauseEmail:
      'Du kannst erst dann Benutzern folgen, wenn du deine E-Mail-Adresse verifiziert hast.',
    blockedCommentBecauseEmail:
      'Du kannst Fotos erst dann kommentieren, wenn du deine E-Mail-Adresse verifiziert hast.',
    genericBackendError:
      'Hoppla. Es ist ein Fehler aufgetreten. Wenn das Problem weiterhin besteht, kontaktiere uns bitte unter support@eyeem.com.',
    cantGetReleases: 'Dieses Foto wurde erfolgreich von der Liste entfernt.',
    userKeywording: {
      submissionFailed:
        'Submission failed. All fields must be filled out correctly.',
      submissionSucceeded: 'The photo was successfully sent for review.',
    },
    changeMarketEmail: 'Bestätigungs-E-Mail gesendet.',
    uploadSuccessful: 'Das Bild wurde erfolgreich hochgeladen!',
    uploadFailed: 'Upload fehlgeschlagen, bitte überprüfe deine Dateien.',
    paypalTokenExpiry:
      'Der Link ist abgelaufen. Bitte fordere einen neuen Link an, indem du auf "Verbinden" klickst.',
  },
  meta: {
    description: {
      default:
        'EyeEm ist eine kostenlose Foto-App für iPhone und Android. Tritt unserer globalen, kreativen Community und unserem Marktplatz bei und entdecke die Welt der Fotografie neu!',
      user: 'Entdecke einzigartige Fotos von %(user)s auf EyeEm',
      userPaginated:
        'Entdecke Seite %(page)s der einzigartigen Fotots von %(user)s auf EyeEm',
      userMarketplace: 'Kaufe einzigartige Fotos von %(user)s auf EyeEm.',
      userMarketplacePaginated:
        'Seite %(page)s von %(user)s Fotos. Kaufe einzigartige Fotos auf EyeEm.',
      userFollowers: 'Entdecke die follower von %(user)s auf EyeEm."',
      userFollowing: 'Entdecke wen %(user)s folgt auf EyeEm."',
      photo:
        'Entdecke das Photo %(photoId)s von %(user)s – Entdecke millionen von herausragenden Photographen mit EyeEm',
      album:
        '%(albumName)s Fotos auf EyeEm – sieh dir %(photoCount)s Bilder von %(photographerCount)s Fotografen an',
      contact:
        'Möchtest du Kontakt aufnehmen? Kontaktiere uns noch heute und teile deine Ideen mit uns! Wir freuen uns, von der EyeEm-Community zu hören.',
      collectionIndex:
        'Verabschiede dich von eintöniger Stockfotografie: Finde und kaufe authentische Bilder von fantastischen EyeEm-Fotografen!',
      privacy:
        'Wir bei EyeEm nehmen den Datenschutz ernst. Lies hier mehr über unsere Datenschutzrichtlinien und -verfahren.',
      tos: 'Teile deine Fotos auf EyeEm? Informiere dich über die Nutzungsbedingungen von EyeEm. Wenn du Fragen hast, kontaktiere uns bitte!',
      privacyDe:
        'Uns ist Datenschutz sehr wichtig. Erfahre hier mehr über unsere Richtlinien.',
      tosDe:
        'Du möchtest deine Fotos auf EyeEm teilen? Hier findest du unsere Leistungsbedingungen. Bitte kontaktiere uns, wenn du Fragen hast!',
      imprint:
        'Impressum: EyeEm Mobile GmbH, c/o Mindspace, Max-Beer-Straße 2-4, 10119 Berlin, Deutschland',
      licensing:
        'Wir nehmen die Lizenzierung ernst und sorgen dafür, dass deine Fotos die Aufmerksamkeit bekommen, die sie verdienen – ohne Aufwand für dich. Lies hier mehr.',
      sellerForm:
        'Knipsen, hochladen, verkaufen: Hier kannst du ganz einfach mit deinen Fotos Geld verdienen.',
      missions:
        'EyeEm ist immer bestrebt, der Community etwas zurückzugeben. Zeig dein Talent: Tritt einer Mission bei und rücke deine Fotografien ins Rampenlicht!',
      press:
        'Möchtest du etwas mit dem EyeEm Medienteam teilen? Oder möchtest du dass das Team etwas mit dir teilt? Nimm noch heute Kontakt mit uns auf – wir freuen uns darauf, von dir zu hören!',
      upload:
        'Lade deine Bilder noch heute auf EyeEm hoch und lasse dich für deine Kreativität belohnen. Erfahre mehr darüber, wie du mit deinen Bildern Geld verdienen kannst.',
      jobs: 'Möchtest du Teil des EyeEm-Teams werden? Wir freuen uns immer auf Gleichgesinnte! Kontaktiere uns und entdecke Karrieremöglichkeiten in Berlin und New York.',
      mlp: 'Jenseits der Stockfotografie: EyeEm ist die beste Anlaufstelle für Fotografen und Bildkäufer. Bei uns findest du perfekte Fotos für jeden Anlass. Suche, finde und kaufe noch heute online bei EyeEm!',
      story: 'Unsere Story',
      mission:
        'Entdecke den Fotowettbewerb %(missionTitle)s von %(partnerName)s. Lade deine Fotos hoch, werde publiziert und gewinne tolle Preise.',
      missionPhotos:
        'Entdecke alle Einsendungen des Fotowettberwerbs %(missionTitle)s von %(partnerName)s. Entdecke tolle Fotographie und lass dich inspirieren!',
      missionPhotosPaginated:
        'Seite %(page)s des Fottowettbewerbs %(missionTitle)s von %(partnerName)s. Entdecke einzigartige Fotographie auf EyeEm.',
      missionPhotographers:
        'Entdecke die Aktuellen Fottowettbewerbe auf EyeEm. Lade deine Fotos hoch, werde publiziert und gewinne tolle Preise.',
      payout: 'EyeEm Marktplatz Auszahlung',
      blog: 'EyeEm Magazine – Fotografie-Tutorials, Tipps und Interviews',
      release:
        'Erteile deine Zustimmung, dass dieses Foto auf EyeEm Market, dem Marktplatz für authentische Fotografie, kommerziell verkauft werden darf.',
      searchDefault:
        'Entdecke einzigartige Fotos mit EyeEm – Bekomme Zugang zu kuratierten Kollektionen und Millionen von abgabefreien Bildern herausragender Fotografen.',
      searchQuery:
        'Entdecke einzigartige %(query)s-Fotos mit EyeEm – Bekomme Zugang zu kuratierten Kollektionen und Millionen von abgabefreien Bildern herausragender Fotografen.',
      seachQueryPaginated:
        'Seite %(page)s von einzigartigen %(query)s-Fotos auf EyeEm – Bekomme Zugang zu kuratierten Kollektionen und Millionen von abgabefreien Bildern herausragender Fotografen.',
      login: 'Hier kannst du dich auf deinem EyeEm Account einloggen.',
      signup: 'Registriere dich auf EyeEm',
      signupCreator:
        'Trete einem Netzwek von +8m Fotographen bei. Werde für Fotoshoots gebucht und lizensiere deine Fotos.',
      signupBuyer:
        'Erstelle ein business Account, Lizensiere einzigartige Fotos und buche Fotoshoots weltweit.',
    },
    title: {
      default: 'EyeEm',
      notFound: 'Nicht gefunden (404) | EyeEm',
      error: 'Fehler (%(statusCode)s) | EyeEm',
      contact: 'Kontakt | EyeEm',
      privacyDe: 'Datenschutz | EyeEm',
      tosDe: 'Nutzungsbedingungen | EyeEm',
      collectionIndex: 'Stockfotos und -bilder | EyeEm',
      privacy: 'Datenschutz | EyeEm',
      tos: 'Allgemeine Geschäftsbedingungen | EyeEm',
      imprint: 'Impressum | EyeEm',
      licensing: 'Lizenzierung | EyeEm',
      sellerForm: 'Fotos mit EyeEm verkaufen',
      missions: 'Community-Fotomissionen | EyeEm',
      press: 'Presse, Medien und PR mit EyeEm',
      upload: 'Bilder und Fotos hochladen | EyeEm',
      jobs: 'Jobs und Karriere bei EyeEm',
      mlp: 'EyeEm: Authentische Stockfotos und abgabefreie Bilder',
      payout: 'Auszahlung',
      modelRelease: 'Formular zur Rechteübertragung',
      propertyRelease: 'Formular zur Rechteübertragung für Objekte',
      iosReleaseManagement: 'EyeEm-Market',
      story: 'Unsere Story | EyeEm',
      search: '%(query)s Bilder | Authentische Bilder auf EyeEm',
      searchPaginated:
        'Seite %(page)s von %(query)s Bildern| Authentische Bilder auf EyeEm',
      mission:
        'Fotowettbewerb %(missionTitle)s informationen von %(partnerName)s | EyeEm',
      missionPhotos:
        'Eingereichte Fotos in %(missionTitle)s von %(partnerName)s | EyeEm',
      missionPhotosPaginated:
        'Seite %(page)s | %(missionTitle)s von %(partnerName)s | EyeEm',
      missionPhotographers: 'Aktuelle Fotowettbewerbe auf EyeEm',
      login: 'Login auf EyeEm',
      signup: 'Melde dich an auf EyeEm',
      signupBuyer: 'Erstelle ein Business Account auf EyeEm',
      signupCreator: 'Erstelle ein Fotographen Account auf EyeEm',
      verifyEmail: 'Email Bestätigung | EyeEm',
    },
  },

  payout: {
    header: {
      title: 'Auszahlungen',
    },
    overview: {
      partnerInfo: '%(partner)s über die Partner-Kollektionen',
      eyeemMarket: 'EyeEm Market',
      machineLearning: 'Portfolio ausgewählter Marktfotos',
      tabs: {
        ready: 'Offen',
        history: 'Historie',
      },
      noneReadyForPayout:
        'Hallo! Du hast im Moment keine Einnahmen, die ausgezahlt werden können.',
      creditNote: 'Gutschrift herunterladen',
      payoutPending: {
        one: '🎉 Herzlichen Glückwunsch, du hast %(count)s Bildlizenz verkauft!',
        other:
          '🎉 Herzlichen Glückwunsch, du hast %(count)s Bildlizenzen verkauft!',
      },
      photoDeleted: 'Bild wurde gelöscht',
      history: {
        subtitle: {
          sold: {
            zero: '%(count)s Bildlizenzen verkauft.',
            one: '%(count)s Bildlizenz verkauft.',
            other: '%(count)s Bildlizenzen verkauft.',
          },
          status: {
            paid: {
              status: 'Ausgezahlt',
              payee: 'an %(payee)s',
              error: '',
            },
            processing: {
              status: 'Auszahlungsabwicklung',
              payee: 'für %(payee)s',
              error:
                'Du erhältst eine PayPal-Benachrichtigung, wenn die Auszahlung erfolgt ist.',
            },
            failed: {
              status: 'Fehlgeschlagen',
              payee: 'für %(payee)s',
              error: 'Error',
            },
            pending: {
              status: 'Ausstehend',
              payee: 'für %(payee)s',
            },
            requested: {
              status: 'Angefordert',
              payee: 'für %(payee)s',
              error:
                'Du erhältst eine PayPal-Benachrichtigung, wenn die Auszahlung erfolgt ist.',
            },
            lapsed: {
              status: 'Verfallen',
              payee: 'for %(payee)s',
              error: 'Du hast die Auszahlung nicht rechtzeitig angefordert.',
            },
            unknown: {
              status: 'Auszahlungsfehler',
              payee: 'für %(payee)s',
              error:
                'Deine Auszahlung hat einen unbekannten Status. Bitte kontaktiere support@eyeem.com für Hilfe.',
            },
          },
        },
        payoutReceipt: 'Auszahlungsbeleg herunterladen',
        expand: 'Bilder ansehen',
        noPayoutHistory:
          'Du hast noch keine Auszahlungen getätigt. Wenn du dies tust, wird sie hier angezeigt.',
        explanation:
          'Beantragte Auszahlungen werden bis zum Ende des Monats bearbeitet. Wenn dein Auszahlungsantrag weniger als 7 Tage vor Monatsende gestellt wurde, erhältst du die Auszahlung im Folgemonat.',
      },
    },
    actions: {
      title: 'Zusammenfassung',
      eventPosition: 'seller_payout',
      payoutTeaser:
        'Sobald du einen Verkauf tätigst, werden deine Einnahmen hier angezeigt.',
      earnings: 'Lizenzerlöse',
      period: 'Zeitraum',
      share: 'Anteil an Lizenzerlösen',
      total: 'Gesamteinnahmen',
      pending: 'Laufende Einnahmen',
      newSales: {
        zero: 'Keine neuen Verkäufe',
        one: '%(count)s Neuer Verkauf',
        other: '%(count)s Neue Verkäufe',
      },
      payoutRequested: 'Auszahlung beantragt',
      totalSales: 'Summe der Einnahmen: %(amount)s',
      minPayoutInfo:
        'Du kannst deine Einnahmen auszahlen, wenn du $%(amount)s USD oder mehr erreichst.',
      learnMore: 'Erfahre mehr',
      learnMoreUrl:
        'https://eyeem.zendesk.com/hc/en-us/articles/115001622085-I-ve-sold-a-photo-Now-what-',
      processing: 'In Verarbeitung',
      readyToPayOut: {
        connectedAccount: 'Verbundenes Konto',
        changeAccount: 'Konto ändern',
        payoutToPayPal: 'Auszahlung\u00A0%(amount)s',
        noCredit: 'Keine Auszahlung möglich',
        payoutError:
          'Wenn du %(minThreshold)s oder mehr verdient hast kannst du sie auszahlen. ',
      },
      paypal: {
        title: 'Paypal',
        notConnected: 'Nicht verbunden',
        connect: 'Verbinden',
        remove: 'Entfernen',
      },
      register: {
        firstName: 'Dein Vorname',
        lastName: 'Dein Nachname',
        email: 'Deine PayPal-E-Mail-Adresse',
        text: 'Bitte gib die E-Mail-Adresse und den vollständigen Namen genau so ein, wie sie in deinem PayPal-Konto erscheinen.',
        button: 'Weiter',
        dontHave: 'Du hast kein PayPal? %(create)s',
        createAnAccount: 'Konto erstellen',
        emailSent:
          'Aus Sicherheitsgründen müssen wir deine Identität verifizieren. Eine E-Mail mit einem Verifizierungslink wurde an %(email)s gesendet. Der Link wird in 30 Minuten ablaufen.',
      },
      requestPassword: {
        input: 'EyeEm-Passwort',
        text: 'Bitte gib dein EyeEm-Passwort ein',
        button: 'Weiter',
        forgot: 'Passwort vergessen?',
        wrongCredentials:
          'Das von dir eingegebene Passwort scheint nicht gültig zu sein. Wenn du dein Passwort nicht kennst, kannst du es einfach über den untenstehenden Link zurücksetzen.',
        marketSupport: {
          label: 'FAQ',
          href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001622085',
        },
      },
    },
  },
  questions: {
    title: 'Fragen?',
    payouts: {
      label:
        'Wir helfen dir gerne! Unter %(link)s findest du weitere Informationen über Auszahlungen.',
      faqLink: 'https://eyeem.zendesk.com/hc/en-us/',
      eventName: 'access_seller_help',
    },
    checkout: {
      label:
        'Wir helfen dir gerne! In %(link)s findest du weitere Informationen zur Zahlung und anderen Themen.',
      faqLink: 'https://eyeem.zendesk.com/hc/en-us/',
      eventName: 'access_buyer_help',
    },
    descriptionLink: 'unseren FAQ',
  },
  search: {
    label: 'Suche',
    placeholder: {
      photo: 'Suche nach Photos',
      illustration: 'Suche nach Illustrationen',
    },
    results: 'ergebnisse',
    fullTextSearch: 'Überall suchen nach',
    pressEnter: 'Drücke die Eingabetaste, um zu suchen',
    resultsHeadline: 'Bilder von %(query)s',
    resultsHeadlineNoQuery: '%(number)s Einzigartige Bilder',
    clearFilters: 'Löschen',
    spellcheck: {
      suggest: 'Meintest du %(correction)s?',
      replace:
        'Zeige Ergebnisse für %(correction)s. Stattdessen nach %(query)s suchen?',
    },
    landing: {
      title: 'Entdecke Millionen von Bildern',
    },
    proDownloadsFilter: {
      allPhotos: 'Alle Fotos',
      newPhotos: {
        bcg: 'Neue BCG Bilder',
        enterprise: 'Neue Fotos',
      },
      downloaded: {
        bcg: 'BCG-Kollektion',
        enterprise: 'Meine Kollektion',
      },
    },
    labels: {
      abstractConcepts: 'Bildstil',
      ageGroups: 'Altersgruppen',
      amountOfPeople: 'Anzahl der Personen',
      collection: 'Kollektionen',
      filters: 'Filter',
      formats: 'Format',
      grayscale: 'Farbe',
      locations: 'Location',
      marketScore: 'Bewertung auf EyeEm Market',
      marketStatus: 'EyeEm Market',
      minResolution: 'Minimale Auflösung',
      proDownloads: 'Download history',
      released: 'Veröffentlichungen',
      reviewedAfter: 'Veröffentlichungsdatum',
      sort: 'Sortieren nach',
      stock: 'Stock',
      uploadedAfter: 'Upload-Datum',
      usage: 'Fotonutzung',
      userSignedUpAfter: 'Datum der Registrierung des Erstellers',
    },
    marketStatus: {
      available: 'Fotos auf EyeEm Market',
      includeEditorial: 'Alle Fotos auf EyeEm Market',
      editorial: 'Redaktionell',
      commercial: 'Kommerziell',
      notAvailable: 'Nicht auf EyeEm Market',
    },
    formats: {
      any: 'Alle',
      square: 'Quadrat',
      panorama: 'Panorama',
      landscape: 'Landschaft',
      widescreen: 'Breitbild',
      portrait: 'Porträt',
    },
    minResolution: {
      any: 'Beliebige Auflösung',
      three: '3 MP',
      nine: '9 MP',
      twelve: '12 MP',
      sixteen: '16 MP',
    },
    grayscale: {
      any: 'Beliebige Farbe',
      color: 'Farbe',
      blackAndWhite: 'Schwarzweiß',
    },
    amountOfPeople: {
      noPeople: 'Keine Menschen',
      onePerson: 'Eine Person',
      twoPeople: 'Zwei Personen',
      groupOfPeople: 'Gruppe von Personen',
    },
    released: {
      model: 'Rechteübertragung durch abgebildete Personen erteilt',
    },
    reviewedAfter: {
      any: 'Beliebiges Datum',
      sevenDays: 'Letzte 7 Tage',
      fourteenDays: 'Letzte 14 Tage',
      oneMonth: 'Letzter Monat',
      twoMonths: 'Letzte 2 Monate',
      sixMonths: 'Letzte 6 Monate',
      oneYear: 'Letztes Jahr',
    },
    uploadedAfter: {
      any: 'Beliebiges Datum',
      sevenDays: 'Letzte 7 Tage',
      fourteenDays: 'Letzte 14 Tage',
      oneMonth: 'Letzter Monat',
      twoMonths: 'Letzte 2 Monate',
      sixMonths: 'Letzte 6 Monate',
      oneYear: 'Letztes Jahr',
    },
    ageGroups: {
      baby: 'Baby',
      child: 'Kind',
      teenager: 'Teenager',
      young_adult: 'Junge Erwachsene',
      mid_adult: 'Mittleres Alter',
      mature_adult: 'Reife Erwachsene',
      senior: 'Senioren',
    },
    abstractConcepts: {
      close_up: 'Nahaufnahme',
      directly_above: 'Direkte Draufsicht',
      aerial_view: 'Luftaufnahme',
      looking_at_camera: 'Porträt',
      diminishing_perspective: 'Abnehmende Perspektive',
      blurred: 'Verschwommen',
      backgrounds: 'Hintergründe und Muster',
      copy_space: 'Platz für Text',
      silhouette: 'Silhouette',
    },
    replaceQuery: {
      replace: 'Ersetzen',
      dontReplace: 'Nicht ersetzen',
    },
    marketScore: {
      great: 'Großartig',
      poor: 'Schlecht',
      average: 'Durchschnitt',
      good: 'Gut',
      very_good: 'Sehr gut',
    },
    collections: {
      screenTitle: 'Qualität',
      none: 'Alle Photos',
      mixed: 'Alle',
      premium: 'Premium · %(currency)s349',
      essential: 'Essential · %(currency)s99',
      premiumEnterprise: 'Premium',
      essentialEnterprise: 'Essential',
    },
    assetTypes: {
      mixed: 'Alle',
      photos: 'Photos',
      illustrations: 'Illustrationen',
    },
    userSignedUpAfter: {
      any: 'Beliebiges Datum',
      sevenDays: 'Letzte 7 Tage',
      fourteenDays: 'Letzte 14 Tage',
      oneMonth: 'Letzter Monat',
      twoMonths: 'Letzte 2 Monate',
      sixMonths: 'Letzte 6 Monate',
      oneYear: 'Letztes Jahr',
    },
    stock: {
      noStock: 'Kein Stock',
      flagged: 'Markiert',
      confirmed: 'Bestätigt',
    },
    sort: {
      unsupported: 'Sortieren',
      relevant: 'Relevanteste',
      recent: 'Neueste',
      aesthetics: 'Ästhetik',
      pa: {
        defaultLabel: 'Trainierte Bildsprache',
        bcg: 'Bildsprache von BCG',
        booking: 'Reisen und Natur',
        ebay: 'Einzelhandel',
        hdi: 'Finanzen',
        google: 'Urban',
        democonsulting: 'Technologie',
        amana_lifestyle: 'Amana Lifestyle',
        amana_conceptual: 'Amana konzeptionell',
        amana_landscape: 'Amana Landschaft',
        bcg_v2: 'Bildsprache von BCG',
        ebay_v2: 'Ebay v2',
        sennheiser: 'Sennheiser Professional',
        sennheiser_v2: 'Sennheiser Professional',
        travel: 'Reisen',
      },
    },
    collectionCurtain: {
      title: 'Stöbere in unseren Kollektionen',
      subline:
        'Deine Suche muss hier nicht enden. Entdecke in unseren EyeEm-Kollektionen Tausende von hochwertigen Bildern einer neuen Generation von Fotografen.',
      collectionIndexLink: 'Alle Kollektionen anzeigen',
    },
    hint: {
      alternativeTerms: {
        title: 'Versuche diese Suchbegriffe',
        terms: {
          nature: 'Natur',
          digital: 'Digital',
          teamwork: 'Teams',
          industry: 'Industrie',
          architecture: 'Architektur',
          business: 'Business & Unternehmen',
        },
      },
      missions: {
        title: 'Auf der Suche nach etwas Bestimmtem?',
        text: 'Missionen ermöglichen es dir, Millionen von Fotografen zu erreichen, um die Bilder zu erhalten, die du wirklich benötigst – speziell für dich erstellt.',
        linkText: 'Mehr erfahren',
      },
    },
    lightboxPreviewGrid: {
      illustrationTitle:
        'Sehen Sie sich hier unsere Illustrationssammlungen an!',
      photoTitle: 'Oder sehe dir unsere Photosammlungen an',
      illustrationSubTitle: 'Illustrations Suche ist in Kürze verfügbar.',
      infoLabel: 'Neu!',
    },
    error: {
      compact: 'Keine Ergebnisse. Versuche es mit anderen Filtern.',
      default: 'Wir konnten keine Ergebnisse für deine Suche finden.',
    },
    related: {
      label: 'verwandt:',
    },
    evaluationInGrid: {
      label: 'Bewerte die Ergebnisse für "%(query)s".',
      eventName: 'search_check_send',
      eventLabel: {
        ok: 'In Ordnung',
        notOk: 'Nicht in Ordnung',
      },
      eventPosition: 'search_grid',
    },
  },
  loginSignup: {
    placeholder: {
      nickname: 'Benutzername',
      email: 'E-Mail-Adresse',
      password: 'Passwort',
    },
    alreadyHave: 'Du hast bereits ein Konto? Login',
    signupSubmit: 'Anmelden',
    signupSubmitFacebook: 'Mit Facebook anmelden',
  },
  directDownload: {
    downloadButton: {
      download: {
        label: 'Herunterladen',
        eventName: 'ecom_download',
      },
      reDownload: {
        label: 'Erneut herunterladen',
        eventName: 'ecom_download_redownload',
      },
      checkOutNow: {
        label: 'Zum Einkaufswagen',
      },
      addToCart: {
        label: 'In den Einkaufswagen',
        eventName: 'ecom_addToCart',
      },
      inCart: {
        label: 'Im Einkaufswagen',
        eventName: 'ecom_addToCart',
      },
      updateLicense: {
        label: 'Lizenz ändern',
        eventName: 'ecom_updateLicenseInCart',
      },
      contactSales: {
        label: 'Jetzt Sales kontaktieren',
      },
    },
    selectLicense: {
      modal: {
        headline: 'Lizenz auswählen',
        sublineLightboxBuyer:
          'Wähle Lizenz für %(count)s photos. Du kannst die Lizenzen in deinem Einkaufswagen editieren.',
        sublineLightboxEnterprise: 'Wähle Lizenz für %(count)s photos.',
        sublineExcludeEditorial:
          'Einige der Bilder in deiner Lightbox stehen nicht zum verkauf und werden von dem Download ausgeschlossen.',
        nextButton: 'Weiter',
        submitButton: 'Herunterladen',
        eventPosition: 'direct_download_modal',
        contactSales: 'Jetzt Sales kontaktieren',
      },
    },
    error: {
      title: 'Es ist ein Fehler aufgetreten.',
    },
  },
  upgradeLicense: {
    upgradeButton: {
      upgrade: {
        label: 'Upgrade',
      },
    },
    selectLicense: {
      modal: {
        headline: 'Upgrade deine Lizenz',
        subline:
          'Damit kannst du Bilder in höherer Auflösung und mit erweiterten Nutzungsrechten kaufen.',
        nextButton: 'Weiter',
        submitButton: 'Upgrade',
        contactSales: 'Jetzt Sales kontaktieren',
      },
    },
  },
  pview: {
    marketDetails: {
      inReview: {
        title: 'Wird überprüft',
        inReview:
          'Vielen Dank für deine Geduld. Wir prüfen gerade deine Illustration. Das dauert normalerweise etwa 7 Tage, kann aber in manchen Fällen auch länger dauern.',
      },
      onSale: {
        title: 'Zum Verkauf',
        onSale:
          'Tolle Neuigkeiten! Dein Bild wurde genehmigt und ist auf dem EyeEm Market zum Verkauf verfügbar. Vielen Dank für deinen Beitrag.',
      },
      notOnSale: {
        title: 'Nicht zum Verkauf',
        imageSimilarity:
          'Die Prüfung deines Bildes ist abgeschlossen und dieses Bild wird nicht auf dem EyeEm Market zum Verkauf angeboten. Auch wenn dieses Bild anderen von dir eingereichten Inhalten zu ähnlich ist, ermutigen wir dich, weiterhin andere Arbeiten einzureichen.',
        watermarks:
          'Die Prüfung deines Bildes ist abgeschlossen und dieses Bild wird nicht auf dem EyeEm-Marktplatz zum Verkauf angeboten. Der Marktplatz kann keine Bilder mit Wasserzeichen oder Kreditlinien akzeptieren. Wenn möglich, überprüfe das Bild, entferne das Wasserzeichen und reiche es erneut ein.',
        lackOfMarketDemand:
          'Die Prüfung deines Bildes ist abgeschlossen und dieses Bild wird nicht auf dem EyeEm-Marktplatz zum Verkauf angeboten. Auch wenn dieses Bild aufgrund fehlender Marktnachfrage nicht genehmigt wurde, ermutigen wir dich, weiterhin andere Arbeiten einzureichen.',
        termsOfService:
          'Dieses Bild wurde nicht für den EyeEm Market akzeptiert, da sie einige Logos, Markenzeichen oder Verstöße gegen das Urheberrecht enthält und somit unzulässig ist. Wenn möglich, überprüfe sie bitte, entferne die unzulässigen Merkmale und reiche sie erneut ein.',
      },
      comingSoon: 'Diese Illustration wird bald zum Kauf verfügbar sein!',
      dealDepleted: 'Dein Team hat keine Downloads mehr übrig.',
      disallowedPartnerSource:
        'Die Lizenzierung von Bildern von Partneragenturen wurde für dieses Konto auf Anfrage deaktiviert.',
      marketAvailabilityMessage: 'Verfügbar auf dem EyeEm Market.',
      noAvailableDeal:
        'Dein Deal beinhaltet nicht unsere %(collectionType)s Collection.',
      viewerRole: {
        headline:
          'Du hast nicht die nötigen Rechte dieses Photo zu kaufen. Bitte ein Teammitglied mit Käuferrechten dieses Bild für dich zu lizensieren.',
      },
      noPermission: {
        headline:
          'Your account does not have permission to purchase this image. Ask an admin or image buyer in your team to purchase it for you.',
        redownload: {
          headline:
            'This photo was already purchased by someone in your company. You can redownload it using the same license.',
        },
      },
      notForSale: {
        enterprise: {
          headline: 'Möchtest du dieses Foto kaufen?',
          subline:
            'Dieses Foto steht noch nicht zum Verkauf. Bitte kontaktiere deinen Kundenbetreuer, wenn du dieses Foto kaufen möchten.',
        },
        buyer: {
          headline: 'Bild derzeit nicht zur Lizensierung verfügbar.',
          subline:
            'Dieses Bild ist derzeit nicht zur Lizenzierung verfügbar. Bitte kontaktiere uns. Gerne schlagen wir dir alternative Fotos vor, die derzeit verfügbar sind, oder kontaktieren den Fotografen für dich.',
          button: 'Kontakt',
          editorial:
            'Dieses Bild steht nicht zum direkten Download zur Verfügung. Bitte kontaktiere deinen Kundenbetreuer, wenn du dieses Foto kaufen möchtest.',
        },
        default: {
          headline: 'Bild derzeit nicht zur Lizensierung verfügbar.',
        },
      },
      purchased: {
        title: 'Du hast dieses Bild bereits lizenziert.',
        text: 'Geh zu deinen Downloads, um es erneut herunterzuladen.',
        button: 'Deine Downloads',
      },
      addToMarket: {
        title: 'Dieses Foto verkaufen',
        text: 'Dieses Foto ist noch nicht auf EyeEm Market. Du kannst das Bild zur Lizensierung anbieten, um damit Geld zu verdienen.',
        button: 'Zu EyeEm Market hinzufügen',
      },
      joinAsSeller: {
        title: 'Möchtest du dieses Foto verkaufen?',
        text: 'Verkaufe deine Arbeiten an führende Fotoagenturen wie Getty Images. Du verdienst jedes Mal Geld und behältst immer dein Copyright.',
        button: 'Registriere dich als Anbieter',
      },
      onHold: {
        title: 'Bestätige deine E-Mail-Adresse',
        text: 'Dieses Foto kann erst zum Verkauf angeboten werden, wenn wir eine Möglichkeit haben, dich zu kontaktieren. Wir benötigen dies, um dich nach einem Verkauf zu bezahlen.',
        button: 'Bestätigungs-E-Mail erneut versenden',
      },
      refused: {
        title: 'Foto leider nicht für EyeEm Market ausgewählt',
        text: 'Leider wurde dieses Foto nicht für den Verkauf auf EyeEm Market ausgewählt. Fragen? Bitte lies die %(faq)s.',
        faqLink: {
          label: 'FAQ',
          href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001621865',
        },
      },
      addToCart: {
        alternateCurrency: {
          label: {
            eu: 'Möchtest du in Euro bezahlen? %(link)s',
            gb: 'Möchtest du in Pfund bezahlen? %(link)s',
            link: 'Rechnung anfordern',
          },
          modal: 'Rechnung anfordern',
        },
      },
      enterprise: {
        title: {
          editorial: 'Nur zur redaktionellen Nutzung',
        },
        text: {
          editorial:
            'Dieses Foto steht nicht zum direkten Download zur Verfügung. Bitte kontaktiere deinen Kundenbetreuer, wenn du dieses Foto kaufen möchtest.',
        },
        depleted: {
          title: 'Keine Downloads übrig',
          subline: 'Wähle eine andere Lizenz oder nimm Kontakt mit uns auf.',
        },
        noDeal: {
          title: 'Kein Deal verfügbar',
          subline:
            'Bitte fülle die Felder aus. Unser Sales Team wird sich umgehend melden.',
        },
      },
      releaseManagement: {
        releaseNamePlaceholder: {
          MODEL: 'Abgebildete Person %(number)s',
          PROPERTY: 'Objekteigentümer %(number)s',
        },
        overflowMenu: {
          removeFromMarket: 'Vom EyeEm Market entfernen',
          editReleases: 'Releases hinzufügen/entfernen',
        },
        marketStatus: {
          headlines: {
            illustrationUnderReview:
              'Deine Illustration wird von unserem Kurationsteam für EyeEm Market geprüft.',
            onMarket: 'Auf EyeEm Market%(tooltip)s',
            partner: 'Partner-Kollektion%(tooltip)s',
            partnerSelected: 'Ausgewählt für die Partner-Kollektion%(tooltip)s',
            underReview: 'Wird überprüft%(tooltip)s',
          },
          tooltips: {
            partner: {
              ok: {
                title: 'Partner-Kollektion',
                body1: 'Dieses Foto ist auf EyeEm Market zum Kauf verfügbar.',
                body2:
                  'Über die Partner-Kollektion ist es auch auf Getty Images und bei anderen Partnern verfügbar.',
                href: 'https://www.eyeem.com/blog/partner-collection',
                link: 'Mehr über die Partner-Kollektion erfahren',
              },
            },
            partnerSelected: {
              ok: {
                title: 'Ausgewählt für die Partner-Kollektionen',
                body1: 'Dieses Foto ist auf EyeEm Market zum Kauf verfügbar.',
                body2:
                  'Über die Partner-Kollektion wurde es auch für den Vertrieb über Getty Images und andere Partner ausgewählt.',
                href: 'https://www.eyeem.com/blog/partner-collection',
                link: 'Mehr über die Partner-Kollektion erfahren',
              },
              modelAndProperty: {
                title: 'Ausgewählt für die Partner-Kollektion',
                body1: 'Dieses Foto ist auf EyeEm Market zum Kauf verfügbar.',
                body2:
                  'Es wurde auch für die Partner-Kollektion ausgewählt, aber es müssen Formulare zur Rechteübertragung durch abgebildete Personen bzw. für die abgebildeten Objekte unterzeichnet werden, bevor es auf Partnerseiten verkauft werden kann.',
                href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001619225',
                link: 'Mehr über Übertragungserklärungen erfahren',
              },
              model: {
                title: 'Ausgewählt für die Partner-Kollektion',
                body1:
                  'Dieses Foto ist auf EyeEm Market erhältlich, aber nur mit einer redaktionellen Lizenz.',
                body2:
                  'Es wurde auch für die Partner-Kollektion ausgewählt, aber es müssen Formulare zur Rechteübertragung durch abgebildete Personen unterzeichnet werden, bevor es auf Partnerseiten verkauft werden kann.',
                href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001619225',
                link: 'Lies mehr über Rechteübertragungen',
              },
              property: {
                title: 'Ausgewählt für die Partner-Kollektion',
                body1:
                  'Dieses Foto ist auf EyeEm Market erhältlich, aber nur mit einer redaktionellen Lizenz.',
                body2:
                  'Es wurde auch für die Partner-Kollektion ausgewählt, aber es müssen Formulare zur Rechteübertragung für abgebildete Objekte unterzeichnet werden, bevor es auf Partnerseiten verkauft werden kann.',
                href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001619225',
                link: 'Lies mehr über Rechteübertragungen für Objekte',
              },
            },
            onMarket: {
              ok: {
                title: 'Auf EyeEm Market',
                body1: 'Dieses Foto ist auf EyeEm Market zum Kauf verfügbar.',
                href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001621505',
                link: 'Lies mehr über den Verkauf von Fotos',
              },
              modelAndProperty: {
                title: 'Auf EyeEm Market',
                body1: 'Dieses Foto ist auf EyeEm Market zum Kauf verfügbar.',
                href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001621505',
                link: 'Lies mehr über den Verkauf von Fotos',
              },
              model: {
                title: 'Auf EyeEm Market',
                body1:
                  'Dieses Foto ist auf EyeEm Market erhältlich, aber nur mit einer redaktionellen Lizenz.',
                body2:
                  'Um es mit einer vollständigen kommerziellen Lizenz zu verkaufen – und möglicherweise mehr Geld zu verdienen –, hole bitte die Erlaubnis von jeder identifizierbaren Person ein, indem du sie bittest, eine Rechteübertragung zu unterschreiben.',
                href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001619225',
                link: 'Lies mehr über Rechteübertragungen',
              },
              property: {
                title: 'Auf EyeEm Market',
                body1:
                  'Dieses Foto ist auf EyeEm Market erhältlich, aber nur mit einer redaktionellen Lizenz.',
                body2:
                  'Um es mit einer vollständigen kommerziellen Lizenz zu verkaufen – und möglicherweise mehr Geld zu verdienen –, hole bitte die Erlaubnis von jedem Objekteigentümer ein, indem du ihn bittest, eine Rechteübertragung zu unterschreiben.',
                href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001619225',
                link: 'Lies mehr über Rechteübertragungen für Objekte',
              },
            },
            underReview: {
              ok: {
                title: 'Wird überprüft',
                body1:
                  'Dieses Bild wird derzeit überprüft. Unser Team wird es sich ansehen, bevor es in den EyeEm-Market aufgenommen wird.',
                href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001618805',
                link: 'Erfahre mehr über den Überprüfungsprozess',
              },
              modelAndProperty: {
                title: 'Wird überprüft',
                body1:
                  'Dieses Bild wird derzeit überprüft. Unser Team wird es sich ansehen, bevor es in den EyeEm-Market aufgenommen wird.',
                body2:
                  'Dieses Foto zeigt Personen sowie Designs, Logos oder private Gebäude. Hole die Erlaubnis von jeder identifizierbaren Person und jedem Objekteigentümer ein, indem du sie bittest, Rechteübertragungserklärungen zu unterschreiben.',
                href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001619225',
                link: 'Mehr über Übertragungserklärungen erfahren',
              },
              model: {
                title: 'Wird überprüft',
                body1:
                  'Dieses Bild wird derzeit überprüft. Unser Überprüfungsteam wird es sich ansehen, bevor es in den EyeEm-Market aufgenommen wird.',
                body2:
                  'Dieses Bild zeigt Personen. Um es mit einer vollständigen kommerziellen Lizenz zu verkaufen – und möglicherweise mehr Geld zu verdienen –, hole bitte die Erlaubnis von jeder identifizierbaren Person ein, indem du sie bittest, eine Rechteübertragung zu unterschreiben.',
                href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001619225',
                link: 'Erfahre mehr über Rechteübertragungen',
              },
              property: {
                title: 'Wird überprüft',
                body1:
                  'Dieses Bild wird derzeit überprüft. Unser Team wird es sich ansehen, bevor es in den EyeEm-Market aufgenommen wird.',
                body2:
                  'Dieses Bild zeigt Designs, Logos oder private Gebäude. Um es mit einer vollständigen kommerziellen Lizenz zu verkaufen – und möglicherweise mehr Geld zu verdienen – hole bitte die Erlaubni von jedem Objekteigentümer ein, indem du sie bittest, eine Rechteübertragung für Objekte zu unterschreiben.',
                href: 'https://eyeem.zendesk.com/hc/en-us/articles/115001619225',
                link: 'Erfahre mehr über Rechteübertragungen',
              },
            },
          },
          editorialUseOk: 'Redaktionelle Nutzung: OK',
          commercialUse: {
            ok: 'Kommerzielle Nutzung: OK',
            modelAndProperty:
              'Kommerzielle Nutzung: Formulare zur Rechteübertragung für Objekte sowie zur Rechteübertragung durch abgebildete Personen erforderlich',
            model:
              'Kommerzielle Nutzung: Formulare zur Rechteübertragung durch abgebildete Personen erforderlich',
            property:
              'Kommerzielle Nutzung: Formulare zur Rechteübertragung für Objekte erforderlich',
          },
        },

        releaseStatus: {
          undefined: 'Rechteübertragung nötig',
          generatingFile: 'Datei wird erzeugt',
          NEEDED: 'Rechteübertragung nötig',
          REQUESTED: 'Rechteübertragung angefordert',
          CLEARED: 'Rechteübertragung erteilt',
          DENIED: 'Rechteübertragung abgelehnt',
        },
        removeRelease: 'Entfernen',
        askForRelease: {
          needed: 'Fragen',
          upload: 'Hochladen',
          pending: 'Erinnern',
          askModal: {
            subline: {
              model: {
                intro: {
                  one: 'Eine Modellfreigabe ist erforderlich, um das Foto mit einer kommerziellen Lizenz verkaufen zu können. Dies ist der Fall, wenn ein Foto Folgendes enthält:',
                  other:
                    'Eine Modellfreigabe ist erforderlich, um die Fotos mit einer kommerziellen Lizenz verkaufen zu können. Dies ist der Fall, wenn ein Foto Folgendes enthält:',
                },
                first: 'Erkennbare Gesichter',
                second: 'Silhouetten von Gesichtern',
                third:
                  'Tätowierungen oder andere wichtige Merkmale, die eine Person identifizierbar machen',
                link: {
                  text: 'Erfahre hier mehr',
                  href: 'https://www.eyeem.com/blog/do-you-need-a-legal-photo-release',
                },
                instructions:
                  'Kopiere den untenstehenden Link zum Rechteübertragungsformular und sende ihn per E-Mail oder über deine sozialen Kanäle an die abgebildete Person.',
                upload:
                  'Hast du das Formular zur Rechteübertragung im PDF- oder JPEG-Format? Lade es hier hoch.',
              },
              property: {
                intro:
                  'Eine unterschriebene Eigentumsfreigabe ist erforderlich, um das Foto mit einer kommerziellen Lizenz verkaufen zu können. Eine Eigentumsfreigabe ist erforderlich, wenn ein Foto Folgendes enthält:',
                first: 'Privateigentum (z. B. Büros, Häuser, Geschäfte)',
                second: 'Logos, Marken oder einzigartige Designelemente',
                third: 'Kunstwerke oder andere künstlerische Kreationen',
                link: {
                  text: 'Erfahre hier mehr',
                  href: 'https://www.eyeem.com/blog/do-you-need-a-legal-photo-release',
                },
                download: {
                  instructions:
                    'Lade ein %(link)s herunter und fülle es aus oder lade ein vorhandenes hoch (PDF / JPG).',
                  link: {
                    text: 'Formular zur Eigentumsfreigabe',
                    href: 'https://dtpuu5koy2g08.cloudfront.net/market/EyeEm_property_release_en.pdf',
                  },
                },
              },
            },
            button: {
              copy: 'Kopieren',
              copied: 'Kopiert.',
              eventName: 'release_request_copy',
              eventPosition: 'pv_stage',
            },
            pdfUpload: {
              label:
                'Hast du das Formular zur Rechteübertragung im PDF- oder JPEG-Format? %(link)s',
              link: 'Lade es hier hoch',
            },
          },
          uploadModal: {
            title: {
              modelRelease: 'Model Release',
              propertyRelease: 'Property Release',
            },
            genderRadio: {
              label: {
                single: 'Geschlecht auswählen',
                multi: 'Geschlecht auswählen',
              },
              male: 'männlich',
              female: 'weiblich',
              other: 'divers',
            },
            birthdaySelect: {
              label: {
                single: 'Geburtsdatum eingeben',
                multi: 'Geburtsdatum eingeben',
              },
            },
            pdfInput: {
              label: 'PDF oder JPEG anhängen',
              button: 'Formular hochladen',
              remove: 'Entfernen',
            },
            success: {
              headline:
                'Das Rechtübertragungsformular wurde erfolgreich übermittelt',
            },
          },
        },
      },
    },
    releaseInfo: {
      availableCommercialy: 'Für kommerzielle Nutzung verfügbar.',
      notForSale: 'Nicht zum Verkauf verfügbar.',
      releaseOnFile:
        '%(amount)s %(releaseType)s Formular(e) zur Rechteübertragung in der Datei',
    },
    comment: {
      input: 'Schreib doch mal Nettes',
      count: {
        zero: 'Kommentare',
        one: '%(count)s Kommentar',
        other: '%(count)s Kommentare',
      },
      add: 'Kommentar hinzufügen',
      delete: 'löschen',
      replyTo: 'antworten an',
      instructions:
        'Drücke %(shift)s + %(enter)s, um einen Zeilenumbruch einzufügen',
    },
    like: {
      zero: 'Likes',
      one: '%(count)s Like',
      other: '%(count)s Likes',
    },
    showAllLikers: 'Alle anzeigen',
    similarPhotos: {
      headline: 'Ähnliche Fotos',
      eventName: 'access_asset',
      eventPosition: 'similar_photos',
    },
    keywordSearchLinks: {
      eyeemvision: 'EyeEmVision-Schlüsselwörter (nur für Administratoren)',
      getty: 'Getty-Schlüsselwörter (nur für Administratoren)',
    },
  },
  share: {
    flyoutHeader: 'Teilen',
    photo: {
      tweet: 'Sieh dir dieses Foto von %(user)s an:',
      default: 'Foto von %(user)s auf EyeEm',
    },
    album: {
      tweet: 'Sieh dir Fotos im Album "%(album)s" an:',
      default: 'Fotos im Album "%(album)s" auf EyeEm ansehen',
    },
    profile: {
      tweet: 'Entdecke die Fotos von %(user)s auf',
      default: 'Sieh dir die Fotos von %(user)s auf EyeEm an',
    },
    blog: {
      tweet: 'Sieh dir diesen Artikel an',
      default: 'Diesen Artikel %(articleUrl)s über @EyeEm ansehen',
    },
    // note: messages are also set by og meta tags. facebook,gplus
    // see the 'meta' property in this file
  },
  tooltip: {
    user: {
      likedPhotos: 'Nur für dich sichtbar',
    },
  },
  modal: {
    assetPosted: {
      // the zero property will not be shown as we don't show a modal when no images have been uploaded
      // it is added in the translations however as counterpart requires it to interpolate the plural translations
      title: {
        zero: `Your %(assetType)s has been posted!`,
        one: `Deine %(assetType)s wurde veröffentlicht!`,
        other: `Deine %(assetType)s wurden veröffentlicht!`,
      },

      assetType: {
        illustrations: {
          zero: 'illustration',
          one: 'Illustration',
          other: 'Illustrationen',
        },
        unknown: {
          zero: 'Bild',
          one: 'Bild',
          other: 'Bilder',
        },
      },
      cta: { goToProfile: 'Zum Profil', uploadMore: 'Mehr hochladen' },
    },

    // TODO: Using the question block is deprecated and should be refactored.
    // Rather create a separate block with all the copy for each new modal you create
    // (as done for leadGen and deleteUser, for example).
    question: {
      confirmNavigation:
        'Möchtest du diese Seite verlassen, ohne den Vorgang abzuschließen?',
      deleteComment: 'Möchtest du diesen Kommentar wirklich löschen?',
      flagPhoto: 'Warum möchtest du dieses Foto melden?',
      removeTag: 'Warum entfernst du diesen Tag?',
      deletePhoto: 'Möchtest du dein Foto wirklich löschen?',
      deletePhotoMarket: 'Möchtest du dieses Foto wirklich löschen?',
      photosPosted: {
        success: {
          one: 'Dein Foto wurde veröffentlicht.',
          other: 'Du hast erfolgreich %(count)s Fotos veröffentlicht.',
        },
        fail: {
          zero: 'Leider konnten wir keines deiner Fotos veröffentlichen.',
          one: 'Nur eines deiner Fotos wurde erfolgreich veröffentlicht.',
          other: 'Nur %(count)s deiner Fotos wurden veröffentlicht.',
        },
      },
      uploadErrors: {
        maxFilesExceeded:
          'Du hast deine Grenze von %(maxFiles)s Bildern erreicht',
        fileError: 'Wir haben etwas übersprungen',
        generalRequestError: 'Beim Hochladen ist etwas schief gelaufen',
      },
    },
    info: {
      confirmNavigation:
        'Du hast ungespeicherte Änderungen, die verloren gehen, wenn du zu einem anderen Abschnitt wechselst. Möchtest du wirklich fortfahren?',
      deletePhoto:
        'Vorsicht! Du kannst diesen Vorgang nicht rückgängig machen.',
      deletePhotoMarket:
        'Dieses Bild wird auch aus dem Verkauf auf EyeEm Market und allen Partner-Kollektionen entfernt. Du kannst damit dann kein Geld mehr verdienen.',
      photosPosted: {
        success:
          'Nur die ersten %(numberOfPhotosInFollowingFeed)s Bilder, die du veröffentlichst, werden im „Following“-Feed angezeigt. In deinem Profil sind alle Bilder sichtbar.',
        fail: {
          one: 'Versuche erneut, das verbleibende Foto zu veröffentlichen.',
          other:
            'Versuche erneut, die verbleibenden %(count)s Fotos zu veröffentlichen.',
        },
      },
      uploadErrors: {
        maxFilesExceeded:
          'Im Moment kannst du nur %(maxFiles)s Bildern auf einmal hochladen. Du kannst jedoch so viele Fotostapel hochladen, wie du möchtest.',
        filesUnsupported:
          'Die folgenden Dateien scheinen keine JPGs zu sein und wurden daher nicht hochgeladen:',
        filesTooBig:
          'Die folgenden Dateien waren zu groß (max %(maxSize)s MB):',
        andMore: '+ %(count)s weitere',
        generalRequestError:
          'Unser Server konnte deinen Upload nicht verarbeiten. Bitte versuche es erneut.',
      },
    },
    radioButtons: {
      removeTag: {
        notInPhoto: 'Ich bin nicht auf dem Foto',
        dontLike: 'Ich mag dieses Foto von mir nicht',
        spam: 'Das ist Spam',
        other: 'divers',
      },
      flagPhoto: {
        nudity: 'Nacktheit',
        copyright: 'Urheberrechtsverletzung',
        tos: 'Verstoß gegen die Nutzungsbedingungen',
      },
    },
    cancel: {
      default: 'Abbrechen',
      done: 'OK',
      confirmNavigation: 'Auf dieser Seite bleiben',
      photosPosted: {
        success: 'Mehr hochladen',
        fail: 'Erneut versuchen',
      },
    },
    confirm: {
      confirmNavigation: 'Verlassen',
      deleteComment: 'Kommentar löschen',
      flagPhoto: 'Zur Überprüfung melden',
      removeTag: 'Meinen Tag entfernen',
      deletePhoto: 'Mein Foto löschen',
      deletePhotoMarket: 'Mein Foto löschen',
      info: 'Bestätigen',
      license: 'Mehr erfahren',
      photosPosted: 'Zum Profil',
      save: 'Speichern',
    },
    message: {
      flagPhoto:
        'Deine Anfrage wurde gesendet. Danke für deinen Beitrag dazu, dass EyeEm ein angenehmer Ort bleibt!',
      removeTag:
        'Du bist auf diesem Foto nicht länger getaggt. Es wird jedoch weiterhin im EyeEm-Archiv auffindbar bleiben.',
    },
    success: {
      default: 'OK',
      gotIt: 'Ich habe verstanden',
    },
    umboTOS: {
      title: 'Aktualisierung der Nutzungsbedingungen',
      description:
        'Wir werden unsere Nutzungsbedingungen am 1. März aktualisieren.',
      accept: 'Updates akzeptieren',
      readTerms: 'Bedingungen lesen',
      href: 'https://www.eyeem.com/de/terms/photographers',
    },
    cantGetReleasesConfirmation: {
      headline: 'Dieses Foto wird aus der Liste entfernt',
      subline:
        'Wenn du auf „Ich kann keine Rechteübertragungen einholen“ klickst, wird das Foto aus der Liste entfernt. Du kannst jederzeit später Rechteübertragungen hinzufügen, indem du von Ihrem Profil aus erneut auf das Foto zugreifst.',
      cantGet: 'Ich kann keine Rechteübertragungen einholen',
    },
    resendVerificationEmail: {
      headline: 'Überprüfe deinen Posteingang!',
      subline:
        'Bitte bestätige deine E-Mail-Adresse, um mit dem Verkauf zu beginnen. Wir haben eine Bestätigungs-E-Mail gesendet an:',
      notYourEmail: 'Nicht deine E-Mail-Adresse?',
      changeIt: 'Ändere sie',
    },
    clearDownloadRequirements: {
      headline: 'Bitte vor dem Download die Infos vervollständigen',
      default: {
        subline:
          '%(customerName)s braucht die folgenden Infos vor dem Download',
        genericCustomer: 'Deine Firma',
      },
      bcg: {
        subline:
          'BCG braucht einen Charge Code vor dem Download. Für Fragen und exclusive Lizenzen bitte %(email)s.',
        email: 'bcg@eyeem.com',
        recentChargeCodeHint: 'Zu letzt verwendeter code: %(code)s',
      },
      submit: 'Jetzt Runterladen',
      back: 'Zurück',
      defaultSelectTitle: 'Wähle eine Option',
    },
    removeFromMarket: {
      headline: 'Möchtest du dieses Foto wirklich aus dem Verkauf nehmen?',
      subline:
        'Dieses Foto wird aus EyeEm Market entfernt und steht nicht mehr zum Verkauf. Dieser Vorgang kann nicht rückgängig gemacht werden.',
      cancel: 'Abbrechen',
      confirm: 'Foto entfernen',
      confirmationMessage:
        'Dein Foto wurde erfolgreich aus EyeEm Market entfernt',
    },
    editReleases: {
      eventPosition: 'modal_editReleases',
      headline:
        'Hinzufügen oder Entfernen von Rechteübertragungen für Objekte und Rechteübertragungen durch abgebildete Personen',
      done: {
        eventName: 'close_modal_editReleases',
        label: 'Fertig',
      },
      addRelease: {
        MODEL: {
          pending: 'Wird hinzugefügt...',
          add: 'Abgebildete Person hinzufügen',
        },
        PROPERTY: {
          pending: 'Wird hinzugefügt...',
          add: 'Objekteigentümer hinzufügen',
        },
      },
    },
    passwordModal: {
      title: 'Bestätige Passwort wechsel',
      subline: 'Bitte gebe dein aktuelles Passwort ein.',
      placeholder: 'Aktuelles Passwort',
      confirm: 'Bestätigen',
    },
    deleteUser: {
      title: 'Möchtest du dein Konto wirklich dauerhaft löschen?',
      body: 'Sobald du es gelöscht haben, gibt es keine Möglichkeit mehr, deine Fotos wiederherzustellen. Bitte gib zur Bestätigung dein Passwort ein:',
      bodyForFBUsers:
        'Sobald du es gelöscht haben, gibt es keine Möglichkeit mehr, deine Fotos wiederherzustellen.',
      placeholderPassword: 'Passwort für %(nickname)s',
      cancel: 'Abbrechen',
      confirm: 'Mein Konto löschen',
      wrongPassword:
        'Das von dir eingegebene Passwort scheint nicht gültig zu sein. Bitte lege auf der Seite mit den Kontoeinstellungen ein neues fest.',
    },
    changeMarketEmail: {
      title: 'E-Mail-Adresse ändern',
      subline:
        'Gib unten eine neue E-Mail-Adresse ein und klicke auf "Speichern und senden", um eine neue Bestätigungs-E-Mail zu senden.',
      placeholderEmail: 'E-Mail-Adresse',
      confirm: 'Speichern und senden',
    },
    confirmPackDownload: {
      title: 'Download bestätigen',
      subline: '1 Download benutzen um dieses Bild zu lizensieren?',
      detailsHeader: 'PACK ÜBERSICHT',
      packTitle: 'Titel',
      type: 'Lizenz',
      downlaodsLeft: 'Downloads',
      confirm: 'Bestätigen',
      back: 'Zurück',
    },
    packDownloadConfirmation: {
      title: 'Ihr Bild ist bereit',
      continue: 'Schließen und weiter',
      reDownload: 'Erneut Downloaden',
    },
    contact: {
      eventPosition: 'contact_modal',
      submit: {
        label: 'Senden',
        eventName: 'contact_form_send',
      },
      statusCodeMessage: {
        409: {
          headline: 'Bild konnte nicht gekauft werden.',
          subline:
            'Dieses Bild wurde schon von einem anderen Nutzer in Ihrem Unternehmen mit Exklusivität gekauft. Bitte kontaktieren Sie uns sollten Sie Fragen haben.',
        },
        402: {
          headline: 'Premium photo nicht verfügbar.',
          subline:
            'Dies ist ein premium Photo welches nicht teil deines aktuellen Deals ist. Bitte kontaktiere deinen Kundenbetreuer, wenn du dieses Foto kaufen möchtest.',
        },
      },
      directDownloadError: {
        errorMessage:
          'Das Bild wurde erfolgreich lizenziert, aber beim Generieren Deines Downloads ist etwas schief gelaufen. Versuche es erneut, indem Du auf Erneut herunterladen klickst. Bitte wende Dich an unser Verkaufsteam, wenn das Problem weiterhin besteht.',
        title: 'Beim Generieren Deines Downloads ist ein Fehler aufgetreten.',
      },
      noMessage: '(keine Nachricht)',
      selectValues: {
        position: {
          __selectTitle__: 'Was beschreibt dich am besten? *',
          inHouse: 'Angestellt im Kreativbereich',
          agency: 'In einer Kreativagentur tätig',
          artBuyer: 'Kunstkäufer',
          photographer: 'Fotograf',
          other: 'Nichts davon',
        },
        usage: {
          __selectTitle__: 'Wie nutzt du die Bilder? *',
          socialMedia: 'Soziale Medien',
          webAds: 'Webdisplay-Anzeigen',
          printAds: 'Print- oder Out-of-Home-Anzeigen',
          editorial: 'Redaktionell',
        },
        companySize: {
          __selectTitle__: 'Größe des Unternehmens *',
          small: '1–10',
          medium: '11–50',
          large: '51–250',
          xlarge: '250+',
        },
      },
      cart: {
        subject: 'Anfrage zum Kauf von Fotos',
        title: 'Bitte kontaktiere uns, um mit deiner Bestellung fortzufahren',
        subline:
          'Der eigenständige Checkout ist derzeit nicht in diesem Land verfügbar. Kontaktiere uns, um die Fotos zu kaufen, die du deinem Warenkorb hinzugefügt hast.',
        placeholderName: 'Name',
        placeholderCompanyName: 'Name des Unternehmens',
        placeholderEmail: 'E-Mail-Adresse',
        placeholderMessage: 'Deine Nachricht',
        placeholderStreet: 'Straße',
        placeholderZip: 'PLZ',
        placeholderCity: 'Ort',
        placeholderCountry: 'Land',
        confirmationMessage:
          'Vielen Dank! Ein Mitglied unseres Teams wird sich mit dir in Verbindung setzen.',
      },
      long: {
        subject: 'EyeEm Market Anfrage',
        placeholderName: 'Dein Name *',
        placeholderCompanyName: 'Name des Unternehmens *',
        placeholderEmail: 'E-Mail-Adresse *',
        bottomLine:
          'Erhalte alle zwei Wochen unseren Newsletter mit exklusiven Nachrichten aus der Welt der Fotografie.',
        confirmationMessage:
          'Vielen Dank für die Kontaktaufnahme! Du wirst in Kürze eine Antwort von unserem Team erhalten.',
      },
      short: {
        subject: 'EyeEm Market Anfrage',
        placeholderName: 'Dein Name *',
        placeholderCompanyName: 'Name des Unternehmens *',
        placeholderEmail: 'E-Mail-Adresse *',
        confirmationMessage:
          'Vielen Dank für die Kontaktaufnahme! Du wirst in Kürze eine Antwort von unserem Team erhalten.',
      },
      support: {
        subject: 'Support-Anfrage',
        title: 'Kontakt',
        subline:
          'Deine Nachricht wird direkt an unser Support-Team gesendet, das dir gerne bei Fragen weiterhilft.',
        placeholderName: 'Dein Name *',
        placeholderEmail: 'E-Mail-Adresse *',
        placeholderMessage: 'Wobei können wir helfen?',
        confirmationMessage:
          'Vielen Dank für die Kontaktaufnahme! Du wirst in Kürze eine Antwort von unserem Team erhalten.',
      },
      supportPayout: {
        subject: 'Paypal-Konto ändern',
        title: 'Paypal-Konto ändern',
        subline:
          'Fülle aus Sicherheitsgründen bitte das folgende Formular mit deinem vollständigen Namen und deiner E-Mail-Adresse aus. Wir werden dann umgehend dein PayPal-Konto zurücksetzen.',
        placeholderName: 'Dein Name *',
        placeholderEmail: 'E-Mail-Adresse *',
        placeholderMessage: 'Wobei können wir helfen?',
        confirmationMessage:
          'Vielen Dank für die Kontaktaufnahme! Du wirst in Kürze eine Antwort von unserem Team erhalten.',
      },
      jobs: {
        subject: 'Initiativbewerbung',
        title: 'Wir suchen dich',
        placeholderName: 'Dein Name *',
        placeholderEmail: 'E-Mail-Adresse *',
        placeholderMessage:
          'Wie stellst du dir die Zusammenarbeit zwischen uns vor?',
        confirmationMessage:
          'Vielen Dank für die Kontaktaufnahme! Du wirst in Kürze eine Antwort von unserem Team erhalten.',
      },
      market: {
        title: 'Kontakt',
        subline: 'An: market@eyeem.com',
      },
      curations: {
        title: 'Kostenlose Kuration anfordern',
      },
      partnerships: {
        title: 'Lass uns zusammenarbeiten',
      },
      missions: {
        subject: 'Anfrage zum Kauf von Fotos',
        title: 'Bitte kontaktiere uns, um mit deiner Bestellung fortzufahren',
        subline:
          'Der eigenständige Checkout ist in diesem Land derzeit nicht verfügbar. Kontaktiere uns, um die Fotos zu kaufen, die du deinem Warenkorb hinzugefügt hast.',
        placeholderEmail: 'E-Mail-Adresse *',
        placeholderMessage: 'Deine Nachricht',
        confirmationMessage:
          'Vielen Dank! Ein Mitglied unseres Teams wird sich mit dir in Verbindung setzen.',
      },
    },
    contactUsToRemove: {
      title: 'Kontaktiere uns, um dieses Foto aus dem Verkauf zu nehmen',
      subline:
        'Dieses Foto steht auf EyeEm und Partnerseiten zum Verkauf. Bitte sende uns eine E-Mail an support@eyeem.com, wenn du den Verkauf einstellen möchten.',
    },
    signUp: {
      like: {
        headline: 'Registriere dich bei EyeEm, um das Foto zu liken',
      },
      comment: {
        headline: 'Registriere dich bei EyeEm, um das Foto zu kommentieren',
      },
      follow: {
        headline: 'Registriere dich bei EyeEm, um diesem Fotografen zu folgen',
      },
    },
  },
  photographerBox: {
    aboutPhotographer: 'Über den Fotografen',
    follows: 'Folgt dir',
    photoBy: 'Von',
  },
  news: {
    page: {
      camera: {
        text: 'Willkommen bei EyeEm! Die Fotografie beginnt hier. Es ist an der Zeit, dein erstes Foto hochzuladen.',
        link: 'Fotos auswählen',
      },
    },
    user: {
      follow: '%(user)s folgt dir jetzt.',
      join: '%(user)s ist gerade EyeEm beigetreten. %(follow)s',
      fbFriend: '%(user)s ist gerade EyeEm beigetreten. %(follow)s',
      twFriend: '%(user)s ist gerade EyeEm beigetreten. %(follow)s',
      followThem: 'Sag hallo. Folge ihm/ihr!',
    },
    album: {
      albumInvite:
        '%(user)s hat dich eingeladen, sich das Album %(album)s anzusehen.',
    },
    photo: {
      albumContributor:
        '%(user)s gerade ein neues Foto zu %(album)s hinzugefügt.',
      taggedPerson: '%(user)s hat dich auf einem Foto markiert.',
      like: '%(user)s gefällt dein Foto.',
      comment: '%(user)s hat dein Foto kommentiert: %(comment)s %(replyLink)s',
      commentMention:
        '%(user)s hat dich in einem Kommentar erwähnt: %(comment)s %(replyLink)s',
      reply: 'Antworten',
      aggregatedComment:
        '%(user)s hat %(photoCount)s deiner Fotos kommentiert: %(photoList)s',
      aggregatedSoldMultiplePhotos:
        'Herzlichen Glückwunsch, du hast %(photoCount)s Fotos verkauft! Checke deinen Kontostand. %(photoList)s',
      aggregatedLikeMultiplePhotos:
        '%(user)s gefallen %(photoCount)s deiner Fotos: %(photoList)s',
      photoSold:
        'Herzlichen Glückwunsch, du hast ein Foto verkauft! Checke deinen Kontostand.',
      photoSoldGetty: {
        title: 'EyeEm Market: ',
        body: {
          one: 'Herzlichen Glückwunsch, du hast ein Foto verkauft!',
          other: 'Herzlichen Glückwunsch, du hast %(count)s Fotos verkauft!',
        },
        link: 'Checke deinen Kontostand.',
      },
    },
    photoAggregated: {
      like: {
        multipleUsers: '%(userList)s gefällt dein Foto.',
        oneMore: 'weiteren Person gefällt dein Foto.',
        muchMore: ' %(likersCount)s weiteren Personen gefällt dein Foto.',
      },
      comment: {
        multipleUsers: '%(userList)s hat dein Foto kommentiert.',
        oneMore: 'weitere Person hat dein Foto kommentiert.',
        muchMore:
          ' %(likersCount)s weitere Personen haben dein Foto kommentiert.',
      },
    },
    mission: {
      link: 'Sieh dir das Album an!',
    },
    market: {
      getty: {
        pending:
          'Deine Fotos wurden für die EyeEm-Kollektion bei Getty Images ausgewählt.',
        approveForSale: 'Jetzt zum Verkauf freigeben',
      },
      payoutEarnings: 'Gewinn auszahlen',
    },
  },
  flag: {
    link: 'Melden',
    modal: {
      title: 'Möchtest du dieses Foto melden?',
    },
  },
  delete: {
    // TODO: why is this on top level?
    link: 'Löschen',
    modal: {
      title: 'Möchtest du wirklich löschen?',
      paragraph: {
        onSale:
          'Es wird dauerhaft von deinem Konto entfernt. Wenn es Teil der Partner-Kollektion ist, wird es auch von allen Partnerseiten entfernt. Dieser Vorgang kann nicht rückgängig gemacht werden.',
        notOnSale:
          'Es wird dauerhaft von deinem Konto entfernt. Dieser Vorgang kann nicht rückgängig gemacht werden.',
      },
      buttons: {
        cancel: 'Abbrechen',
        delete: 'Löschen',
      },
      success: 'Löschung war erfolgreich.',
      error: {
        generic:
          'Dieses Bild kann zur Zeit nicht gelöscht werden. Bitte kontaktiere uns unter support@eyeem.com, wenn du es entfernen möchtest.',
        70001:
          'Ihr Foto ist Teil einer Partner-Kollektion. Bitte kontaktiere uns unter support@eyeem.com, wenn du es löschen möchtest.',
        70002:
          'Dieses Foto wurde in der Vergangenheit bereits verkauft. Bitte kontaktiere uns unter support@eyeem.com, wenn du es löschen möchtest.',
      },
    },
  },
  edit: {
    link: 'Bearbeiten',
    modal: {
      done: 'Speichern',
      cancel: 'Abbrechen',
      error:
        'Dieses Foto kann zur Zeit nicht bearbeitet werden. Bitte versuche es später noch einmal!',
    },
  },
  userlist: {
    editProfile: 'Profil bearbeiten',
    photos: {
      zero: 'Fotos',
      one: 'Foto',
      other: 'Fotos',
    },
    followers: {
      zero: 'Follower',
      one: 'Follower',
      other: 'Follower',
    },
    following: {
      zero: 'following',
      one: 'following',
      other: 'following',
    },
  },

  album: {
    album: 'Album',
    empty: {
      blacklisted: {
        icon: 'photos',
        headline: 'Dieses Album kann sensible Inhalte enthalten.',
      },
      photos: {
        icon: 'photos',
        headline: 'In diesem Album sind noch keine Fotos vorhanden.',
        subline: 'Möchtest du eines hinzufügen?',
        button: {
          label: 'Hochladen',
          href: '/upload/photos',
        },
      },
      contributors: {
        icon: 'people',
        headline: 'Es hat noch niemand Fotos zu diesem Album hinzugefügt.',
        subline: 'Möchtest du die/der Erste sein?',
        button: {
          label: 'Hochladen',
          href: '/upload/photos',
        },
      },
    },
    photos: {
      zero: 'Fotos',
      one: '%(abbreviatedCount)s Foto',
      other: '%(abbreviatedCount)s Fotos',
    },
    illustrations: {
      one: '%(abbreviatedCount)s Illustration',
      other: '%(abbreviatedCount)s Illustrationen',
      zero: 'Illustrationen',
    },
    contributors: {
      zero: 'Fotografen',
      one: '%(abbreviatedCount)s Fotograf',
      other: '%(abbreviatedCount)s Fotografen',
    },
    followButton: {
      label: {
        follow: 'Folgen',
        following: 'following',
      },
      title: {
        follow: 'Album folgen',
        following: 'Ich folge dem Album',
      },
    },
  },
  blog: {
    post: {
      header: {
        subline: 'Von %(authorName)s',
        readingTime: ' - %(min)s min. Lesedauer',
      },
      recommendedArticles: 'Als nächstes:',
    },
  },
  footer: {
    eventPosition: 'footer',

    columns: {
      products: {
        headline: 'Für Firmen',
        items: [
          {
            title: 'Bilder Lizensieren',
            href: '/licensing',
          },
          {
            title: 'Preise und Lizenzen',
            href: '/de/market/pricing',
          },
          {
            title: 'Fragen und Support',
            href: 'https://eyeem.zendesk.com/hc/en-us/categories/360001243737-FAQ-Image-Buyers',
          },
        ],
      },
      photographers: {
        headline: 'Für Anbieter',
        items: [
          {
            title: 'Als Anbieter anmelden',
            href: '/signup',
          },
          {
            title: 'Bilder verkaufen',
            href: '/signup/creator',
          },
          {
            title: 'Fotowettbewerbe',
            href: '/missions/photographers',
          },
          {
            title: 'EyeEm Awards',
            href: '/awards',
          },
          {
            title: 'Fragen und Support',
            href: 'https://eyeem.zendesk.com/hc/',
          },
        ],
      },
      projects: {
        headline: 'Photography culture',
        items: [
          {
            title: 'EyeEm Magazine',
            href: '/magazine',
          },
          {
            title: 'Visual Trends',
            href: '/visualtrends',
          },
        ],
      },
      eyeem: {
        headline: 'Über EyeEm',
        items: [
          {
            title: 'Unsere Story',
            href: '/story',
          },
          {
            title: 'Jobs',
            href: '/jobs',
          },
          {
            title: 'Presse',
            href: '/press',
          },
          {
            title: 'FAQ',
            href: 'https://eyeem.zendesk.com/hc/en-us/categories/115000290289-Frequently-Asked-Questions',
            external: true,
          },
        ],
      },
    },
    misc: {
      headline: '© %(year)s EyeEm Mobile GmbH.',
      items: [
        {
          title: 'Datenschutz',
          href: '/privacy',
        },
        {
          title: 'AGB',
          href: '/tos',
        },
        {
          title: 'Impressum',
          href: '/imprint',
        },
      ],
    },
  },
  friendsFeed: {
    empty: {
      icon: 'people',
      headline: 'Folge Personen, um neue Fotos zu entdecken.',
      subline:
        'Auf EyeEm präsentieren wir jede Woche die besten Fotografen. Entdecke sie in unserem Blog!',
      button: {
        label: 'Zum Blog',
        href: '/magazine',
      },
    },
  },
  user: {
    empty: {
      own: {
        photos: {
          icon: 'upload',
          headline: 'Du hast noch nichts hochgeladen.',
          subline:
            'Schließe dich Millionen von Kreativen an und teile deine Arbeit auf EyeEm.',
          button: {
            label: 'Hochladen',
            href: '/upload/photos',
          },
        },
        illustrations: {
          button: {
            label: 'Upload',
            href: '/upload/illustrations',
          },
          headline: 'Du hast noch nichts hochgeladen.',
          icon: 'upload',
          subline:
            'Schließe dich Millionen von Kreativen an und teile deine Arbeit auf EyeEm.',
        },
        marketPhotos: {
          seller: {
            icon: 'market',
            headline: 'Marken wollen deine Arbeit kaufen. Verkaufe sie jetzt!',
            subline:
              'Du erhältst bei jedem Verkauf Geld und behältst immer dein Copyright. %(link)s',
            link: {
              label: 'Finde heraus, was sich verkauft',
              href: '/a/12853871',
            },
            button: {
              label: 'Hochladen',
              href: '/upload/photos',
            },
          },
          nonSeller: {
            icon: 'market',
            headline: 'Marken wollen deine Arbeit kaufen. Verkaufe sie jetzt!',
            subline:
              'Du erhältst bei jedem Verkauf Geld und behältst immer dein Copyright. %(link)s',
            link: {
              label: 'Finde heraus, was sich verkauft',
              href: '/a/12853871',
            },
            button: {
              label: 'Verkaufe deine Arbeit',
              href: '/market/sell/start',
            },
          },
        },
        partnerPhotos: {
          icon: 'partner',
          headline:
            'Unsere Kuratoren wählen persönlich Fotos aus, die an Getty Images und andere Partner verteilt werden. Deines könnte das nächste sein!',
          button: {
            label: 'Erfahre, wie',
            href: '/blog/partner-collection',
          },
        },
        releasesNeededPhotos: {
          icon: 'releases',
          headline: 'Alle Rechteübertragungen erteilt',
          subline:
            'Sobald weitere Rechteübertragungen benötigt werden, siehst du diese hier',
          button: {
            label: 'Zurück zum Profil',
            href: '/u/%(resourceId)s',
          },
        },
        followers: {
          icon: 'people',
          headline: 'Du hast noch keine Follower.',
          subline:
            'Lade mehr von deinen besten Aufnahmen hoch, um deine Arbeit zu präsentieren!',
          button: {
            label: 'Hochladen',
            href: '/upload/photos',
          },
        },
        followings: {
          icon: 'people',
          headline: 'Folge Personen, um wundervolle Fotos zu entdecken.',
          subline:
            'Oder lass dich von der globalen EyeEm-Community in unserem Blog inspirieren.',
          button: {
            label: 'Mehr lesen',
            href: '/magazine',
          },
        },
        likedPhotos: {
          icon: 'photos',
          headline: 'Du hast noch keine Fotos geliket.',
          subline:
            'Wir präsentieren jede Woche die besten Fotos auf EyeEm. Entdecke sie in unserem Blog!',
          button: {
            label: 'Mehr lesen',
            href: '/magazine',
          },
        },
      },
      foreign: {
        photos: {
          icon: 'photos',
          headline: '%(name)s hat noch keine Fotos hochgeladen.',
          subline: 'Sobald %(name)s Fotos hochgeladen hat, siehst du sie hier.',
        },
        illustrations: {
          headline: '%(name)s hat noch keine Illustrationen hochgeladen.',
          icon: 'photos',
          subline:
            'Sobald %(name)s Illustrationen hochgeladen hat, siehst du sie hier.',
        },
        marketPhotos: {
          icon: 'market',
          headline: '%(name)s hat noch keine Fotos zum Verkauf.',
          subline:
            'Sobald %(name)s Fotos zu verkaufen hat, findest du sie hier.',
        },
        followers: {
          icon: 'people',
          headline: '%(name)s hat noch keine Follower.',
          subline: 'Sobald %(name)s Follower hat, siehst du sie hier.',
        },
        followings: {
          icon: 'people',
          headline: '%(name)s folgt noch niemandem.',
          subline: 'Sobald %(name)s anderen folgt, siehst du es hier.',
        },
        likedPhotos: {
          icon: 'photos',
          headline: '%(name)s hat noch keine Fotos geliket.',
          subline: 'Sobald %(name)s Fotos liket, siehst du sie hier.',
        },
      },
    },
    filterItems: {
      photos: 'Alle Photos',
      market: 'Market',
      partner: 'Partner',
    },
    illustrations: {
      one: '%(abbreviatedCount)s Illustration',
      other: '%(abbreviatedCount)s Illustrationen',
      zero: 'Illustrationen',
    },
    photos: {
      zero: 'Fotos',
      one: '%(abbreviatedCount)s Foto',
      other: '%(abbreviatedCount)s Fotos',
    },
    followers: {
      zero: 'Follower',
      one: '%(abbreviatedCount)s Follower',
      other: '%(abbreviatedCount)s Follower',
    },
    followings: {
      zero: 'Following',
      one: '%(abbreviatedCount)s following',
      other: '%(abbreviatedCount)s following',
    },
    likedPhotos: {
      zero: 'Fotos geliked',
      one: '%(abbreviatedCount)s Geliktes Foto',
      other: '%(abbreviatedCount)s Fotos geliked',
    },
    market: {
      zero: 'EyeEm Market',
      one: '%(abbreviatedCount)s Marktplatz',
      other: '%(abbreviatedCount)s Marktplatz',
    },
    partner: {
      zero: 'Partner',
      one: '%(abbreviatedCount)s Partner',
      other: '%(abbreviatedCount)s Partner',
    },
    earnings: 'Auszahlung',
    joinMarket: 'Verkäuferkonto erstellen',
    followsYou: 'Deine Follower',
    followButton: {
      follow: 'Folgen',
      following: 'following',
    },
  },
  error: {
    restricted: 'Dieses Profil ist eingeschränkt.',
    blocked: 'Du hast diese Person blockiert.',
    notFound: {
      legacyReleaseForm:
        'Der Link zu diesem Rechteübertragungsformular ist veraltet. Bitte frage den Fotografen nach dem neuesten.',
      album: 'Hoppla! Wir können das gesuchte Album nicht finden.',
      collection: 'Hoppla! Wir können die gesuchte Kollektion nicht finden.',
      user: 'Hoppla! Wir können den gesuchten Benutzer nicht finden.',
      photo: 'Hoppla! Wir können das gesuchte Bild nicht finden.',
      release: 'Hoppla! Wir können die von dir gesuchte Version nicht finden.',
      other: 'Hoppla! Wir können die gesuchte Seite nicht finden.',
      mission: 'Hoppla! Wir können die Mission, die du suchst, nicht finden.',
      lightbox: 'Hoppla! Wir können die Lightbox, die du suchst, nicht finden.',
      blogPost: 'Hoppla! Wir können den gesuchten Blog-Post nicht finden.',
      backtoeyeem: 'Zurück zu EyeEm',
      credits: 'Foto von %(photographer)s',
      contactSupport:
        'Oder %(supportLink)s, wenn du vermutest, dass etwas Seltsames passiert.',
      supportLink: 'Wende dich an den Support',
      supportHref: 'mailto:support@eyeem.com',
    },
    formLoadError:
      'Beim Laden des Kontaktformulars ist ein Problem aufgetreten. Bitte versuchen Sie es später erneut.',
  },
  licenses: {
    noPremiumAvailable:
      'Dein momentaner Deal beinhaltet keine Lizenz für Premium Photos.',
    noLicensesAvailable:
      'Dein momentaner Deal hat keine downloads übrig für die verfügbaren Licenzen dieses Photos.',
    contactSales: 'Jetzt Sales kontaktieren',
    learn: 'Erfahre mehr',
    learnMoreLink: '/de/market/licensing',
    licensed: 'Lizensiert',
    remaining: '%(remaining)s von %(total)s downloads übrig',
    remainingDownloads: {
      counters: '%(remaining)s of %(total)s',
      label: 'downloads übrig',
    },
    needMore:
      'Zusätzliche Nutzungsrechte benötigt oder etwas, das auf dein Budget zugeschnitten ist?',
    requestACustomLicense: 'Hol dir dein maßgeschneidertes Angebot',
    requestCustomLicense: 'Hol dir dein maßgeschneidertes Angebot',
    unlimitedRemaining: 'Unbegrenzte Downloads',
    dimensions:
      '%(width)s x %(height)spx / %(cmWidth)s x %(cmHeight)scm @%(dpi)sdpi',
    packs: {
      essential: 'Essential Pack',
      premium: 'Premium Pack',
    },
    deprecatedTypes: {
      1: {
        title: 'Standard Lizenz',
      },
      2: {
        title: 'Erweiterte Lzenz',
      },
      3: {
        title: 'Redaktionelle Lizenz',
        body: 'Für die unbegrenzte redaktionelle Nutzung, z. B. bei der Darstellung von Ereignissen, die berichtenswert oder von öffentlichem Interesse sind, in allen Medien außer TV/Video.',
        confirm: 'Mehr erfahren',
        cancel: 'Verstanden.',
      },
      4: {
        title: 'Social Lizenz',
        body: 'Für die unbegrenzte kommerzielle Nutzung in Social Media-Netzwerken und -Plattformen. Rechteübertragungen für Objekte und Rechteübertragungen durch abgebildete Personen werden bereitgestellt.',
        confirm: 'Mehr erfahren',
        cancel: 'Verstanden.',
      },
      5: {
        title: 'Web Lizenz',
        body: 'Für die uneingeschränkte kommerzielle Nutzung im Web sowie in Printmedien mit einer Auflage von weniger als 5.000 Exemplaren. Rechteübertragungen für Objekte und Rechteübertragungen durch abgebildete Personen werden bereitgestellt.',
        confirm: 'Mehr erfahren',
        cancel: 'Verstanden.',
      },
      6: {
        title: 'Volle Lizenz',
        body: 'Für redaktionelle und kommerzielle Zwecke, einschließlich Werbung in allen Medien (z. B. Web, Print, TV). Auch für Produktverpackung und die Integration in Druck- oder Elektronikprodukte zum Weiterverkauf oder Vertrieb. Rechteübertragungen für Objekte und Rechteübertragungen durch abgebildete Personen werden bereitgestellt.',
        confirm: 'Mehr erfahren',
        cancel: 'Verstanden.',
      },
      7: {
        title: 'Standard Lizenz',
        body: 'Unsere Standard Lizenz erlaubt es Ihnen, Ihr Bild für kommerzielle Zwecke (Webseiten, Soziale Medien und Digitale Werbung) und in Print-Publikationen von bis zu 5000 Kopien zu verwenden.',
        confirm: 'Mehr erfahren',
        cancel: 'Verstanden.',
      },
      8: {
        title: 'Extended Lizenz',
        body: 'Unsere Extended Lizenz erlaubt es Ihnen, Ihr Bild für Werbezwecke in allen Medien, in TV oder Video-Clips, als Teil von Produkten oder ihren Verpackungen oder für eine unbegrenzte Anzahl von Print-Publikationen zu nutzen.',
        confirm: 'Mehr erfahren',
        cancel: 'Verstanden.',
      },
      20: {
        title: 'Basic Lizenz',
      },
      21: {
        title: 'Custom Lizenz',
      },
      partner: {
        title: 'Partner Lizenz',
      },
      machineLearning: {
        title: 'Machine Learning Training',
      },
    },
    types: {
      0: {
        title: 'Hi-Res Vorschau',
        body: 'Nur für interne und Layoutzwecke. Dies ist keine gekaufte Lizenz. Wenn du dich für die Verwendung dieses Bildes entscheidest, musst du auf eine kostenpflichtige Lizenz upgraden.',
      },
      1: {
        title: 'Social Lizenz',
        body: 'Für die unbegrenzte kommerzielle Nutzung in Social Media-Netzwerken und -Plattformen. Rechteübertragungen für Objekte und Rechteübertragungen durch abgebildete Personen werden bereitgestellt.',
        confirm: 'Mehr erfahren',
        cancel: 'Verstanden.',
      },
      2: {
        title: 'Web Lizenz',
        body: 'Für die uneingeschränkte kommerzielle Nutzung im Web sowie in Printmedien mit einer Auflage von weniger als 5.000 Exemplaren. Rechteübertragungen für Objekte und Rechteübertragungen durch abgebildete Personen werden bereitgestellt.',
        confirm: 'Mehr erfahren',
        cancel: 'Verstanden.',
      },
      3: {
        title: 'Volle Lizenz',
        body: 'Für redaktionelle und kommerzielle Zwecke, einschließlich Werbung in allen Medien (z. B. Web, Print, TV). Auch für Produktverpackung und die Integration in Druck- oder Elektronikprodukte zum Weiterverkauf oder Vertrieb. Rechteübertragungen für Objekte und Rechteübertragungen durch abgebildete Personen werden bereitgestellt.',
        confirm: 'Mehr erfahren',
        cancel: 'Verstanden.',
      },
      4: {
        title: 'PREMIUM Social Lizenz',
        body: 'PREMIUM Für die unbegrenzte kommerzielle Nutzung in Social Media-Netzwerken und -Plattformen. Rechteübertragungen für Objekte und Rechteübertragungen durch abgebildete Personen werden bereitgestellt.',
        confirm: 'Mehr erfahren',
        cancel: 'Verstanden.',
      },
      5: {
        title: 'PREMIUM Web Lizenz',
        body: 'PREMIUM Für die uneingeschränkte kommerzielle Nutzung im Web sowie in Printmedien mit einer Auflage von weniger als 5.000 Exemplaren. Rechteübertragungen für Objekte und Rechteübertragungen durch abgebildete Personen werden bereitgestellt.',
        confirm: 'Mehr erfahren',
        cancel: 'Verstanden.',
      },
      6: {
        title: 'PREMIUM Full Lizenz',
        body: 'PREMIUM Für redaktionelle und kommerzielle Zwecke, einschließlich Werbung in allen Medien (z. B. Web, Print, TV). Auch für Produktverpackung und die Integration in Druck- oder Elektronikprodukte zum Weiterverkauf oder Vertrieb. Rechteübertragungen für Objekte und Rechteübertragungen durch abgebildete Personen werden bereitgestellt.',
        confirm: 'Mehr erfahren',
        cancel: 'Verstanden.',
      },
      7: {
        title: 'Standard License',
        body: 'Für kommerzielle Verwendung im Web und Druck (bis zu 5000 Kopien).',
      },
      8: {
        title: 'Extended License',
        body: 'Für kommerzielle Nutzung in allen Medien und in unbegrenzter Anzahl für Print-Publikationen.',
      },
      20: {
        title: 'Basic Lizenz',
        body: 'Alle Basic Lizenzen sind Royalty Free und umfassen die kommerzielle Nutzung in allen Medien. Beinhaltet im Print Bereich eine Auflage von bis zu 500.000 Stück.',
      },
      21: {
        title: 'Custom Lizenz',
        body: '',
      },
      partner: {
        title: 'Partner Lizenz',
      },
    },
    creditText: '%(creator)s / EyeEm',
    copyrightNotice:
      'Urheberrechtlich geschützt von %(creator)s / EyeEm Mobile GmbH, alle Rechte vorbehalten',
  },
  cart: {
    cartTitle: 'Warenkorb',
    savedForLater: 'Für später gespeichert',
    chatSupport: 'Chat support',
    contactSupport: 'Support',
    continue: 'Zurück',
    eventName: 'ecom_shopping',
    eventPosition: 'cart',
    undo: {
      notice: 'Ihr Artikel wurde entfernt.',
      action: 'Rückgängig machen',
    },
  },
  checkout: {
    checkoutTitle: 'Zur Kasse',
    downloadPreview: 'Vorschau herunterladen',
    discountCode: {
      headline: 'Rabatt-Code',
      error: {
        invalid: 'Der Rabatt-Code %(code)s ist ungültig.',
        reused: 'Der Rabatt-Code %(code)s wurde schon benützt.',
        packError:
          'Rabatte können nicht mit anderen Werbeaktionen kombiniert werden. Entferne deine Packs aus dem Einkaufswagen um einen Rabatt-Code zu benützen.',
      },
      placeholder: 'Rabatt-Code',
      submit: 'Weiter',
      remove: 'Entfernen',
    },
    empty: {
      headline: 'Du hast noch keine Bilder in Deinem Warenkorb.',
      text: '%(searchLink)s oder %(collectionsLink)s',
      searchLink: 'Jetzt lossuchen',
      collectionsLink: 'unsere Kollektionen entdecken',
    },
    steps: {
      eventPosition: 'checkout',
      edit: 'Bearbeiten',
      billing: {
        title: 'Rechnungsstellung',
        submit: 'Weiter',
        eventName: 'checkout_edit_billing',
      },
      payment: {
        title: 'Zahlung',
        eventName: 'checkout_edit_payment',
      },
      confirmation: {
        title: 'Bestätigung',
        tax: 'Steuer',
        subtotal: 'Zwischensumme',
        total: 'Summe',
        creditCardSubmit: 'Jetzt kaufen',
        paypalSubmit: 'Mit PayPal kaufen',
        agreement:
          'Mit dieser Bestellung akzeptierst du die EyeEm-%(tos)s, -%(license)s und - %(marketprivacy)s',
      },
    },
    download: {
      headline: 'Vielen Dank',
      headline2: 'Deine Bilder werden gerade vorbereitet.',
      signup: {
        headline: 'Bei EyeEm Anmelden',
        subtext:
          'Schnellerer Checkout und unbegrenzter Zugang zu deinen Bildern',
      },
      signupButton: 'Anmelden',
      preparing: 'Bilder werden vorbereitet',
      text: 'Das Herunterladen von Bildern kann eine Minute dauern. Möchtest du nicht warten? Wir werden dir in Kürze einen Download-Link zusenden. Rechnungen findest du in deinem %(purchaseHistoryLink)s.',
      textv2:
        'Du erhältst in Kürze eine Bestätigungs-E-Mail von market@eyeem.com.',
      orderNumber: 'Bestellnummer: %(paymentId)s',
      link: 'Downloads',
      button: 'Bilder',
      buttonInvoice: 'Rechnung',
      w8: 'Bei Bedarf kannst du %(w8Link)s herunterladen.',
      w8Link: 'das W-8BEN Formular hier',
      details: {
        one: '1 Artikel',
        other: '%(count)s Artikel',
      },
      error:
        'Entweder existiert diese Transaktion nicht oder du hast nicht die notwendigen Berechtigungen, um sie zu sehen.',
    },
    paymentError: {
      default:
        'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal',
      headline:
        'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.',
      7001: 'Es gab ein Problem mit Ihren Kreditkartendaten. Bitte überprüfe dies und versuche es erneut.',
      7002: 'Beim Verarbeiten Ihrer Zahlung ist ein Problem aufgetreten. Deine Karte wurde nicht belastet. Wir empfehlen dir, dich für weitere Informationen an deinen Kartenherausgeber zu wenden.',
      CLIENT_GATEWAY_NETWORK:
        'Es gab ein Problem beim Erreichen unserer Server. Deine Karte wurde nicht belastet. Bitte versuche es erneut.',
      CLIENT_REQUEST_TIMEOUT:
        'Es gab ein Problem beim Erreichen unserer Server. Deine Karte wurde nicht belastet. Bitte versuche es erneut.',
      CLIENT_REQUEST_ERROR:
        'Es gab ein Problem mit Ihren Kreditkartendaten. Bitte überprüfe dies und versuche es erneut.',
      CLIENT_RATE_LIMITED:
        'Beim Verarbeiten deiner Zahlung ist ein Problem aufgetreten. Deine Karte wurde nicht belastet. Versuche es in ein paar Minuten erneut.',
      PAYPAL_POPUP_CLOSED:
        'Möglicherweise hat sich das Paypal-Fenster zu früh geschlossen. Bitte versuche es erneut.',
      PAYPAL_POPUP_OPEN_FAILED:
        'Bitte lass Popups von unserer Domain zu. PayPal ist davon abhängig! Überprüfe die Adressleiste deines Browsers auf eine Eingabeaufforderung oder pass die Sicherheitseinstellungen deines Browsers an.',
      THREEDS_HTTPS_REQUIRED: '',
    },
    photoBy: 'Von %(fullname)s',
    imageSize: 'Größe: %(width)s x %(height)s px',
    photoId: 'ID: #%(photoId)s',
    modals: {
      findOutMore: 'Erfahre mehr über Lizenzvereinbarungen',
      vat: {
        title: 'MwSt.',
        body: 'Die Mehrwertsteuer wird an der Kasse auf Grundlage deines Landes und deiner Kontoart berechnet.',
      },
      accountType: {
        title: 'Kontoauswahl',
        body: 'Diese Einstellung gibt an, ob dein Konto für die geschäftliche oder private Nutzung bestimmt ist. Du musst dies nur einmal auswählen. Wenn du deine Kontoart ändern möchten, wende dich bitte an market@eyeem.com.',
        confirm: 'Mehr erfahren',
        cancel: 'Verstanden.',
      },
      countryRestriction: {
        title: 'Warum kann ich mein Land nicht ändern?',
        body: 'Wir gehen davon aus, dass du dich in diesem Land befindest. Wenn das nicht korrekt ist, kontaktiere uns bitte unter market@eyeem.com.',
      },
      upgradeDifference:
        'Für dieses Upgrade wird dir nur die Preisdifferenz zwischen der ursprünglichen und der neuen Lizenz berechnet.',
    },
    progress: {
      stage1: 'Zusammenfassung',
      stage2: 'Zur Kasse',
      stage3: 'Herunterladen',
    },
    auth: {
      title: 'Wie willst du einkaufen?',
      login: {
        title: 'Wiederkehrender Kunde',
        subtitle: 'Behalte Zugang zu deinen Bildern und schnellerer Checkout.',
        submit: 'Weiter zum Checkout',
      },
    },
    sidebar: {
      checkoutBlocked:
        'Es scheint, dass du dich derzeit außerhalb eines von uns unterstützten Landes befinden. Behalte unseren Blog im Auge, um herauszufinden, wann wir in deinem Land starten, oder kontaktiere uns unter market@eyeem.com.',
      error: {
        editorialUseOnlyAll:
          'Hinweis: Alle Bilder in Ihrem Warenkorb sind nur für den redaktionellen Gebrauch bestimmt.',
        editorialUseOnlyFraction:
          'Hinweis: %(editorialCount)s von %(count)s Bildern in Ihrem Warenkorb sind nur für den redaktionellen Gebrauch bestimmt.',
      },
      images: {
        zero: '%(count)s Bilder',
        one: '%(count)s Bild',
        other: '%(count)s Bilder',
      },
      summary: {
        title: 'Details',
        checkoutTitle: 'Details',
        items: {
          zero: 'Keine Artikel',
          one: '1 Artikel',
          other: '%(count)s Artikel',
          coveredByPack:
            '%(count)s Artikel in deinem Einkaufswagen werden von deinem download Pack abgezogen.',
        },
      },
      tax: 'Steuer',
      subtotal: 'Zwischensumme',
      discountedSubtotal: 'Mit Rabatt',
      orderTotal: 'Summe',
      readAboutLicenseDetailsLink: 'Lizenzvereinbarungen überprüfen',
      readAboutLicenseDetails: '%(link)s',
      buttonCheckoutNow: 'Jetzt bestellen',
      licenseAlreadySelected:
        'Im Einkaufswagen kannst du deine Lizenzen ändern oder entfernen',
      buttonBuyNow: 'Jetzt kaufen',
      accept: 'Wir akzeptieren',
      buttonBuyNowBlocked: 'Checkout nicht möglich',
      buttonBuyNowPaypal: 'Bestellung mit PayPal aufgeben',
      buttonProcessingPayment: 'Zahlung wird verarbeitet',
      benefits: {
        benefit1: 'Abgabefrei',
        benefit2: 'Weltweite Nutzung',
        benefit3: 'Kein Ablaufdatum',
      },
      checkboxes: {
        labelTerms:
          'Ich habe Folgendes gelesen und verstanden: EyeEm-%(tos)s, -%(license)s und %(marketprivacy)s',
        tos: 'Allgemeine Geschäftsbedingungen',
        license: 'Lizenzvereinbarung',
        marketPrivacyPolicy: 'Datenschutzerklärung für EyeEm Market',
        required:
          'Bitte akzeptiere die folgenden Bedingungen, um mit deinem Kauf fortzufahren.',
      },
      removeModal: {
        reallyRemove: 'Möchtest du dieses Foto wirklich entfernen?',
        approveButton: 'Foto entfernen',
        cancelButton: 'Abbrechen',
      },
      contact: {
        eventName: 'cta_contact_modal',
        title: 'Zahlung per Rechnung oder in einer anderen Währung',
        link: 'kontaktiere unser Salesteam',
        paragraph:
          'Möchtest du per Rechnung oder in einer anderen Währung bezahlen? Bitte %(salesTeam)s.',
      },
      vat: 'MwSt. %(rate)s %',
      vatNoteMaybe:
        'Die Mehrwertsteuer wird an der Kasse auf Grundlage deines Landes und deiner Kontoart berechnet.',
      vatNote:
        'Die Mehrwertsteuer wird auf Grundlage deines Landes und deiner Kontoart berechnet.',
    },
    cartItem: {
      photo: {
        eventPosition: 'checkout',
        details: {
          eventName: 'checkout_edit_cart',
          size: 'Größe',
          image: 'Bild',
          edit: 'Bearbeiten',
          remove: 'Entfernen',
        },
        licenseChooser: {
          updateButton: 'Lizenzart aktualisieren',
        },
      },
      deal: {
        details: {
          downloadAmount: 'Download: %(amount)s',
          expiry: 'Gültig bis: 1 Jahr',
        },
      },
      any: {
        addToCart: 'In den Einkaufswagen',
        saveForLater: 'Speichern',
      },
    },
    addToCart: {
      quickAdd: 'Herunterladen',
      goToCart: 'Jetzt herunterladen',
      flyout: {
        by: 'von %(user)s',
        checkout: {
          go: 'Zur Kasse gehen',
          eventName: 'ecom_checkout',
          eventPosition: 'navbar_cartFlyout',
        },
        cart: {
          go: 'Warenkorb',
          eventName: 'access_cart',
          eventPosition: 'navbar_cartFlyout',
        },
      },
    },
    payment: {
      title: 'Zahlungsart auswählen',
      cardName: 'Name auf der Karte',
      cardNumber: 'Kartennummer',
      expiration: {
        month: 'MM',
        year: 'YY',
        ccv: 'CVV',
        monthyear: 'MM/YY',
      },
      method: {
        title: 'Zahlungsweise',
        creditCard: 'Debit/Kreditkarte',
        paypal: 'PayPal',
        required: 'Bitte wähle eine Zahlungsweise aus.',
      },
      redirection: {
        paypal:
          'Nachdem du deine Bestellung in Schritt 3 bestätigt hast, wirst du auf die Website von PayPal weitergeleitet.',
      },
      ssl: {
        title: 'SSL - ',
        description: 'Sichere Verbindung',
      },
    },
    account: {
      malformed: {
        email:
          'Dies ist kein gültiges E-Mail-Format. Bitte versuche es erneut.',
        password:
          'Bitte verwende ein sichereres Passwort mit mindestens 5 Zeichen.',
        taxId:
          'Bitte überprüfe, ob du eine gültige Umsatzsteuer-Identifikationsnummer eingegeben haben.',
      },
      passwordRequired: 'Passwort erforderlich',
      wrongCredentials:
        'Das eingegebene Passwort ist falsch. Bitte versuche es noch einmal.',
      login: {
        title: 'Bei EyeEm anmelden',
        errorUnknown:
          'Wir konnten dich nicht anmelden. Bitte versuche es erneut oder kontaktiere support@eyeem.com',
      },
      signup: {
        title: 'Konto erstellen',
        errorUnknown:
          'Registrierung fehlgeschlagen. Bitte versuche es erneut oder kontaktiere support@eyeem.com',
      },
      createAnAccount: 'Neues EyeEm-Konto erstellen',
      signInToYourAccount: 'Bei deinem EyeEm-Konto anmelden',
      accountInformation: 'Kontoinformationen',
      loggedInAs: 'Angemeldet als %(mail)s',

      loggedIn: 'Angemeldet als',
      haveAccount: 'Hast du bereits ein Konto? %(logIn)s',
      logIn: 'Anmelden',
      dontHaveAccount: 'Du hast noch kein Konto? ',
      dontHaveAccountPlain: 'Du hast noch kein Konto? ',
      createNewOne: 'Konto erstellen',
      forgetPassword: 'Passwort vergessen?',
      email: 'Deine E-Mail-Adresse',
      passwordSignup: 'Passwort',
      passwordLogin: 'Gib dein Passwort ein',
      type: {
        1: 'Privates Konto',
        2: 'Geschäftskonto',
        required: 'Bitte wähle deine Kontoart aus.',
      },
      eventName: {
        valid: 'buyerLogin_complete_valid',
        invalid: 'buyerLogin_complete_invalid',
      },
      eventPosition: 'checkout_signup_form',
    },
  },
  transactions: {
    headline: 'Bestell Historie',
    download: 'Herunterladen',
    downloadImages: {
      one: 'Bild %(download)s',
      other: 'Bilder %(download)s',
    },
    downloadInvoice: 'Rechnung %(download)s',
    photoLicenses: {
      one: '%(count)s Bildlizenz',
      other: '%(count)s Bildlizenzen',
    },
    empty: {
      text: 'Du hast noch keine Einkäufe getätigt. Sobald du dies tust, werden sie hier angezeigt.',
      searchLink: 'Suche starten',
    },
    side: {
      headline: 'Fragen?',
      text: 'Wir helfen dir gerne. Unter %(marketSupportLink)s findest du weitere Informationen über Preise, Steuern und Lizenzen.',
      marketSupportLink: {
        label: 'EyeEm Market Support',
        href: 'https://eyeem.zendesk.com/hc/en-us/',
      },
    },
  },
  widget: {
    expandable: {
      expand: 'Erweitern',
      collapse: 'Reduzieren',
    },
    embeddableSearch: {
      placeholder: 'z. B. Camping für Mädchen in den USA',
    },
  },
  photoCredit: {
    photoBy: 'Von',
  },
  settings: {
    title: 'Einstellungen',
    profile: {
      title: 'Profil',
      description: 'Aktualisiere die Details deines öffentlichen Profils.',
      profileImage: 'Dein Profilfoto',
    },
    coverPhoto: {
      change: 'Ändern',
      label: 'Cover-Foto',
      minSize: 'Min. Auflösung 1200x600px',
      modal: {
        title: 'Wähle ein neues Cover-Foto aus',
        cancel: 'Abbrechen',
        confirm: 'Bestätigen',
        lowResolutionHint:
          'Die Auflösung ist zu niedrig, um dieses Foto als Cover-Foto zu benutzen.',
      },
    },
    account: {
      fullname: 'Name',
      nickname: {
        label: 'Benutzername',
        validation: {
          invalid: 'Dies ist kein gültiger Benutzername',
          unavailable: 'Dieser Benutzername ist nicht verfügbar',
        },
      },
      description: 'Profiltext',
      homepage: 'Homepage',
      homepageUnverified:
        'Homepage - Du musst vorher deine Email verifizieren.',
      email: 'E-Mail-Adresse',
      password: 'Passwort',
      confirmPassword: 'Passwort bestätigen',
      save: 'Änderungen speichern',
      delete: {
        label: 'Konto löschen',
        eventName: 'access_modal_deleteUser',
      },
      eventPosition: 'settings_account',
    },
    emailPassword: {
      title: 'Email und Passwort',
      description: 'Aktualisiere die E-Mail und das Passwort deines Kontos.',
    },
    deleteAccount: {
      title: 'Konto löschen',
      description:
        'Wenn du dein Konto einmal gelöscht hast, gibt es keine Möglichkeit, dies rückgängig zu machen. Bitte sei sicher.',
    },
    notifications: {
      title: 'E-Mail-Benachrichtigung',
      description:
        'Aktualisiere die Art der E-Mail-Benachrichtigungen, die du erhalten möchtest.',
      email_photo_like: 'Foto-Likes',
      email_photo_comment: 'Fotokommentare',
      email_user_follower: 'Neuer Follower',
      email_user_joined: 'Ein Freund ist EyeEm beigetreten',
      email_photo_comment_mention: '@ Erwähnungen',
      email_community_updates: {
        creator: 'Newsletter',
        buyer: 'Neuigkeiten und Updates aus der Welt der Fotografie von EyeEm',
      },
      email_market_model_release_completed:
        'Markt: Rechteübertragung durch abgebildete Personen abgeschlossen',
      email_market_property_release_completed:
        'Markt: Rechteübertragung für Objekte abgeschlossen',
    },
    socialServices: {
      title: 'Integrationen',
      connected: 'Verbunden',
      disconnect: 'Verbindung trennen',
      connect: 'Verbinden',
      serviceNames: {
        twitter: '%(icon)sTwitter',
        facebook: '%(icon)sFacebook',
      },
      eventName: {
        connect: 'socialService_connect',
        disconnect: 'socialService_disconnect',
      },
      eventPosition: 'settings_socialServices',
    },
    linkedApps: {
      title: 'Autorisierte Apps',
      disconnect: 'App entfernen',
    },
    integrations: {
      title: 'Integrationen',
      connected: 'Verbunden',
      disconnect: 'Verbindung trennen',
      connect: 'Verbinden',
      serviceNames: {
        twitter: '%(icon)sTwitter',
        facebook: '%(icon)sFacebook',
      },
    },
    team: {
      headline: 'Mein Team',
      subline: {
        zero: '%(count)s Nutzer',
        one: '%(count)s Nutzer',
        other: '%(count)s Nutzer',
      },
      status: {
        INVITED: 'Eingeladen',
        INACTIVE: 'Deaktiviert',
        ACTIVE: 'Aktiv',
      },
      role: {
        customer: {
          admin: {
            label: 'Admin',
            subtext:
              'Verwaltet das Budget, die Mitglieder und die Berechtigungen des Teams.',
          },
          buyer: {
            label: 'Käufer',
            subtext:
              'Kann mit dem Budget des Teams neue Fotos kaufen und neue Käufer und Zuschauer in das Team einladen.',
          },
          member: {
            label: 'Betrachter',
            subtext:
              'Kann keine neuen Fotos kaufen, kann aber bereits gekaufte Bilder wieder ansehen und herunterladen. Kann neue Betrachter in das Team einladen.',
          },
        },
        wasChanged: '%(user)s was successfully changed to %(role)s.',
        modal: {
          title: 'Confirm change',
          roleChange: 'Role change',
          downgradeWarning:
            'Your access to managing roles and status of users will be revoked on confirmation',
          cancel: 'Abbrechen',
          confirm: 'Confirm',
        },
      },
      invite: {
        trigger: 'Nutzer Einladen',
        modal: {
          title: 'Nutzer Einladen',
          label: 'Emails',
          placeholder: 'anna@company.com, joe@company.com',
          invalidEmail: {
            one: '"%(emails)s" ist keine korrekte Email-Adresse',
            other: '"%(emails)s" sind keine korrekten Email-Adressen',
          },
          tooManyEmails:
            'Bitte tragen sie nicht mehr als 50 Email-Adressen ein',
          cancel: 'Abbrechen',
          confirm: 'Einladen',
        },
        notification: {
          success: 'Users were successfully invited',
          error: "The following emails couldn't be added: %(emails)s",
        },
      },
      actions: {
        reinvite: 'Nochmal einladen',
        wasReinvited: 'Wir haben %(email)s eine neue Einladung gesendet.',
        wasDeactivated: '%(user)s wurde deaktiviert.',
        deactivate: 'Deaktivieren',
        modal: {
          title: 'Nutzer Deaktivieren',
          confirm: 'Nutzer Deaktivieren',
          cancel: 'Abbrechen',
        },
      },
    },
  },
  staticPages: {
    subnavigation: {
      info: {
        press: 'Presse',
        missions: 'Missionen',
        blog: 'Blog',
      },

      company: {
        jobs: 'Karriere',
        contact: 'Ansprechpartner',
      },
      settings: {
        account: 'Konto bearbeiten',
        notifications: 'E-Mail-Benachrichtigung',
        services: 'Social Sharing',
        integrations: 'Integrationen',
        apps: 'Autorisierte Apps',
      },
    },
    legal: {
      oldTos: {
        headline: 'Frühere Allgemeine Geschäftsbedingungen',
        subline:
          'Du kannst %(community)s auf unsere früheren Allgemeinen Geschäftsbedingungen der Community, %(buyers)s auf die früheren Allgemeinen Geschäftsbedingungen für Käufer und %(photographers)s auf die früheren Allgemeinen Geschäftsbedingungen für Fotografen zugreifen.',
        here: 'hier',
      },
      nonLawyers: 'Für Nicht-Juristen',
      sidebar: {
        tos: 'Allgemeine Geschäftsbedingungen',
        privacy: 'Datenschutzrichtlinie',
        imprint: 'Impressum',
        webTos: 'Nutzungsbedingungen der Website',
      },
    },

    contact: {
      title: 'Wir möchten von dir hören.',
      text: 'Hast du Fragen? Wir haben Antworten!%(br)sZuerst möchten wir die Angelegenheit eingrenzen.',
      support: {
        title: 'Support',
        subline: 'FAQ und technischer Support',
        eventName: 'access_help',
        href: 'https://eyeem.zendesk.com/hc/en-us/requests/new',
      },
      press: {
        title: 'Presse',
        subline: 'Medienanfragen und Pressemappe',
        eventName: 'access_press',
      },
      partnerships: {
        title: 'Partnerschaften',
        subline: 'Geschäfts- und Handelsanfragen',
        eventName: 'cta_contact_modal',
      },
      socialLinks: 'Folge uns auf',
      eventPosition: 'contactPage',
    },
    tos: {
      en: {
        tos: {
          headline: 'Allgemeine Geschäftsbedingungen',
          download: 'Allgemeine Geschäftsbedingungen als PDF herunterladen',
          pdfLink:
            'https://dtpuu5koy2g08.cloudfront.net/market/TOS_EN_apr16.pdf',
        },
        privacy: {
          headline: 'Datenschutzrichtlinie',
          download: 'Datenschutzerklärung als PDF herunterladen',
          pdfLink:
            'https://dtpuu5koy2g08.cloudfront.net/market/PP_EN_may18.pdf',
        },

        webTos: {
          headline: 'Nutzungsbedingungen für die Website',
          download: 'Nutzungsbedingungen für die Website als PDF herunterladen',
          pdfLink:
            'https://dtpuu5koy2g08.cloudfront.net/market/WEBTOS_EN_oct17.pdf',
        },
      },
      de: {
        tos: {
          headline: 'Nutzungsbedingungen',
          download: 'Nutzungsbedingungen als PDF herunterladen',
          pdfLink:
            'https://dtpuu5koy2g08.cloudfront.net/market/TOS_DE_apr16.pdf',
        },
        privacy: {
          headline: 'Datenschutzrichtlinien',
          download: 'Datenschutzrichtlinien als PDF herunterladen',
          pdfLink:
            'https://dtpuu5koy2g08.cloudfront.net/market/PP_DE_may18.pdf',
        },
        webTos: {
          headline: 'Website-Nutzungsbedingungen',
          download: 'Website-Nutzungsbedingungen als PDF herunterladen',
          pdfLink:
            'https://dtpuu5koy2g08.cloudfront.net/market/WEBTOS_DE_oct17.pdf',
        },
      },
    },
  },
  dropDownTracking: {
    open: '_open',
    close: '_close',
  },
  landing: {
    market: {
      quote:
        'EyeEm ist eine kreative%(br)sCommunity und ein Marktplatz%(br2)sfür authentische Fotografie.',
      button: 'Mehr erfahren',
    },
    sellerInfo: {
      sellingPoints: {
        revenue: {
          title: 'Du erhältst Geld von jedem Verkauf',
          text: 'Ein Foto kann immer wieder an neue Kunden verkauft werden – und du verdienst jedes Mal Geld.',
        },
        copyright: {
          title: 'Ihr Copyright gehört Ihnen',
          text: 'Du wählst, welche Fotos du verkaufen möchtest und bleibst immer Eigentümer deiner Aufnahmen.',
        },
        distribution: {
          title: 'Verkaufen mit Getty und anderen',
          text: 'Wir arbeiten mit den weltweit führenden Fotoagenturen zusammen, um deine Arbeit bei noch mehr Käufern bekannt zu machen.',
        },
      },
    },
    sellerSignup: {
      header: {
        title: 'Gib deine Anbieterdaten ein',
        text: 'Dies ist notwendig, um dich zu bezahlen, wenn deine Arbeit verkauft wird. Diese Informationen werden sicher aufbewahrt und niemals weitergegeben.',
      },
      redirectBanner: {
        illustrations:
          'Bevor du deine Arbeit hochladen kannst, musst du dich als Anbieter registrieren.',
      },
      form: {
        accountType: 'Ich verkaufe über eine Firma',
        birthday: 'Ich bin mindestens 18 Jahre alt.',
        button: 'Registrieren',
        success: {
          headline: 'Gut gemacht, %(user)s!',
          subline:
            'Du bist nun als Anbieter auf EyeEm Market registriert. Lade einfach deine Arbeit hoch, um loszulegen!',
          dashboard: 'Upload beginnen',
        },
        failure: {
          headline: 'Es ist ein Fehler aufgetreten.',
        },
        quit: {
          headline: 'Bist du sicher?',
          subline:
            'Wenn du dich nicht für EyeEm Market registrierst, kannst du deine Arbeit nicht verkaufen.',
          continue: 'Registrierung fortsetzen',
          quit: 'Beenden',
        },
      },
      confirmNavigationNative: 'Du hast nicht gespeicherte Änderungen.',
      successModal: {
        headline: 'Gut gemacht, %(firstName)s. Du bist bereit zu verkaufen!',
        subline:
          'Lade deine besten kommerziellen Arbeiten hoch, um deine Verkaufschancen zu erhöhen.',
        cta: 'Upload beginnen',
      },
    },
    missions: {
      eventPosition: 'missions',
      topSlide: {
        headline: 'Entdecke EyeEm-Missionen',
        subline:
          'Wo die kreative Community auf globale Marken und Publikationen trifft.',
        button: 'Nimm Kontakt auf!',
      },
      latestSlide: {
        headline: 'Neueste Missionen',
        more: 'Mehr lesen',
      },
      brandsIntro: {
        headline: 'EyeEm-Missionen für Marken',
        subline:
          'Nutze unsere globale Community, um die Geschichte deiner Marke zu erzählen.',
        button: 'Nimm Kontakt auf',
      },
      partnerSlider: {
        engagements: 'Engagements mit der Mission',
        engagingMoments: 'Fesselnde Momente',
        engagedPhotographers: 'Engagierte Fotografen',
        partners: {
          motorola: {
            quote:
              '"Wir waren begeistert von der Anzahl der Einsendungen und der Bandbreite an kreativen Bildern aus der EyeEm-Community."',
            citation: 'Christopher Clare, Creative Director, Motorola',
            bottomLine: '„Choose Color!“ von Motorola',
          },
          bcg: {
            quote:
              '"Durch die Partnerschaft mit EyeEm konnten wir originelle und authentische Bilder finden, die unserer Markensprache einen einzigartigen Look verleihen."',
            citation: 'Massimo Portincaso, Global Marketing Director, BCG',
            bottomLine: '"Visualize The Future" von BCG',
          },
          applemusic: {
            engagements: '420.000+',
            engagingMoments: '5.700+',
            engagedPhotographers: '3.100+',
            bottomLine: '„For the love of music“ by Apple Music Festival',
          },
          gettyimages: {
            engagements: '860.000+',
            engagingMoments: '15.500+',
            engagedPhotographers: '6.500+',
            bottomLine: '"Everyday Joy" von Getty Images',
          },
          huffingtonpost: {
            engagements: '720.000+',
            engagingMoments: '13.300+',
            engagedPhotographers: '7.700+',
            bottomLine:
              '„What does Peace look like to you?“ von Huffington Post',
          },
          mercedesbenz: {
            engagements: '870.000+',
            engagingMoments: '8.100+',
            engagedPhotographers: '4.100+',
            bottomLine: '"Elegance Everywhere" von Mercedes-Benz',
          },
        },
      },
      features: {
        licensing: {
          headline: 'Einfache Lizenzierung',
          subline:
            'Angemessene, pragmatische Lizenzoptionen, von abgabefrei bis exklusiv',
        },
        speed: {
          headline: 'Zügige Abwicklung',
          subline:
            'Wir arbeiten schnell und bringen dich in nur 24 Stunden vom Konzept bis zur Veröffentlichung',
        },
        pricing: {
          headline: 'Transparentes Preismodell',
          subline:
            'Lösungen, die auf das individuelle Budget und die Anforderungen Ihrer Marke zugeschnitten sind',
        },
        engagement: {
          headline: 'Stimmige Markenplatzierung',
          subline:
            'Stehe im Mittelpunkt des Gesprächs und sprich direkt mit deinem Zielpublikum',
        },
      },
      contact: {
        headline: 'Nimm Kontakt auf',
        subline:
          'Gib deine Daten ein, um mit uns in Kontakt zu treten. Du wirst innerhalb von 24 Stunden von uns hören.',
        namePlaceholder: 'Dein Name',
        placeholderEmail: 'E-Mail-Adresse',
        nameError: 'Bitte gib einen Namen ein.',
        emailError: 'Bitte gib eine gültige E-Mail-Adresse ein.',
        button: {
          label: 'Mehr erfahren',
          eventName: 'submit_mission_contact',
        },
      },
    },
  },
  profile: {
    releasesHeader: {
      headline: 'Modellfreigaben Anfordern',
      subline:
        'Diese Fotos enthalten erkennbare Personen und benötigen eine unterschriebene Modelfreigabe, um mit einer kommerziellen Lizenz auf EyeEm Market und unseren Partnerplattformen verkauft werden zu können. Bitte hole die Erlaubnis jeder erkennbaren Person ein, indem du sie bittest, eine Modellfreigabe zu unterschreiben, oder entferne einfach die Fotos, für die du keine Freigabe kriegen kannst.',
      photoCount: {
        zero: 'Es sind für kein Foto Formulare zur Rechteübertragung durch abgebildete Personen erforderlich',
        one: '%(number)s Für das Foto sind Formulare zur Rechteübertragung durch abgebildete Personen erforderlich',
        other:
          '%(number)s Für die Fotos sind Formulare zur Rechteübertragung durch abgebildete Personen erforderlich',
      },
      modalTrigger: {
        label: 'Rechteübertragung für mehrere Fotos',
        eventName: 'access_multiReleases',
      },
      eventPosition: 'user_releases',
      tooltip: {
        body: 'Wenn dieselbe Person auf mehreren Fotos erscheint, kannst du sie gruppieren und ein Mehrfachübertragung für alle Fotos erstellen',
        eventName: 'user_releases_tooltip',
      },
    },
  },
  multipleReleases: {
    header: {
      eventPosition: 'multiRelease_photos',
      buttons: {
        next: {
          label: 'Weiter',
          eventName: 'multiReleases_photos_next',
        },
        cancel: {
          label: 'Abbrechen',
          eventName: 'multiReleases_photos_close',
        },
        done: {
          label: 'Fertig',
          eventName: 'multiReleases_photos_done',
        },
      },
    },
    selectModal: {
      headline: 'Erscheint die gleiche Person auf mehreren Fotos?',
      subline:
        'Alle Fotos auswählen, die dieselbe Person enthalten. In den folgenden Schritten kannst du einen einzelnen Link für die Beantragung der Rechteübertragung erstellen.',
      deselectAll: 'Alle abwählen',
      photoCount: {
        zero: '%(number)s Fotos ausgewählt',
        one: '%(number)s Foto ausgewählt',
        other: '%(number)s Fotos ausgewählt',
      },
      eventPosition: 'multiRelease_photos',
    },
    selectionStrip: {
      headline: {
        zero: 'Die Person ist auf %(boldCount)s Fotos abgebildet',
        one: 'Die Person ist auf %(boldCount)s Foto abgebildet',
        other: 'Die Person ist auf %(boldCount)s Foto abgebildet',
      },
      headlineWithName: {
        zero: '%(firstName)s %(lastName)s ist auf %(boldCount)s Fotos abgebildet',
        one: '%(firstName)s %(lastName)s ist auf %(boldCount)s Foto abgebildet',
        other:
          '%(firstName)s %(lastName)s ist auf %(boldCount)s Fotos abgebildet',
      },
      eventPosition: 'multiRelease_selectionStrip',
      edit: {
        label: 'Bearbeiten',
        eventName: 'multiReleases_request_edit',
      },
      view: {
        label: 'Anzeigen',
        eventName: 'multiReleases_request_view',
      },
    },
    releaseInfo: {
      eventPosition: 'multiRelease_request',
      next: {
        label: 'Weiter',
        eventName: 'multiReleases_request_next',
      },
      back: {
        label: 'Zurück',
        eventName: 'multiReleases_request_back',
      },
      form: {
        headline: 'Name der abgebildeten Person eingeben',
        labels: {
          firstName: 'Vorname der abgebildeten Person',
          lastName: 'Nachname der abgebildeten Person',
        },
      },
    },
    confirm: {
      eventPosition: 'multiRelease_requestConfirm',
      infoBox: {
        title: 'Sind alle Angaben korrekt?',
        text: 'Nach diesem Schritt kannst du den Namen der abgebildeten Person nicht mehr ändern und keine Fotos mehr zu deiner Auswahl hinzufügen bzw. daraus entfernen.',
      },
      next: {
        label: 'Weiter',
        eventName: 'multiRelease_requestConfirm_next',
      },
      edit: {
        label: 'Bearbeiten',
        eventName: 'multiRelease_requestConfirm_edit',
      },
    },
    askModel: {
      eventPosition: 'multiRelease_request',
      needed: 'Fragen',
      pending: 'Erinnern',
      subline:
        'Hole dir bitte die Erlaubnis, diese Fotos zu verkaufen, indem du den untenstehenden Link zum Rechteübertragungsformular kopierst und an folgende Adresse sendest %(firstName)s %(lastName)s',
      button: {
        copy: 'Kopieren',
        copied: 'Kopiert.',
        eventName: 'multiReleases_request_copy',
      },
    },
    view: {
      headline: {
        zero: '%(modelName)s ist auf %(boldCount)s Fotos abgebildet',
        one: '%(modelName)s ist auf %(boldCount)s Foto abgebildet',
        other: '%(modelName)s ist auf %(boldCount)s Fotos abgebildet',
      },
    },
    removeReleaseWarning: {
      headline: 'Entfernen aus einem Foto',
      subline:
        'Das Foto, von dem du die Anforderung der Rechteübertragung entfernst, ist Teil einer Mehrfachveröffentlichung. Die Anforderung der Rechteübertragung wird aus dem aktuellen Foto entfernt und das Foto aus der Mehrfachveröffentlichung.',
      button: {
        cancel: 'Abbrechen',
        remove: 'Entfernen',
      },
    },
  },
  upload: {
    filestack: {
      headline: 'Hochladen aus anderen Quellen',
      importTooltip: {
        message: 'Importieren von %(service)s',
        dropbox: 'Dropbox',
        flickr: 'Flickr',
        googledrive: 'Google Drive',
        picasa: 'Google Photos',
        instagram: 'Instagram',
      },
    },
    noAccess: {
      unsupportedDevice: {
        headline: 'Gerät wird nicht unterstützt',
        subline:
          'Zum hinzufügen von Keywords, downloade die EyeEm App für iOS und Android.',
        button: 'Downloade die App',
      },
      regular: {
        headline: 'Liebst du Fotografie? Dann sei dabei!',
        subline:
          'EyeEm ist eine Fotocommunity, in der du lernst, entdeckt werden und Geld verdienen kannst.%(br)sLade Fotos direkt hoch oder importiere sie von Flickr oder anderen Quellen. Lasse sie von unserer Technologie für dich taggen!',
        button: 'Legen wir los!',
      },
      phone: {
        headline: 'Nur noch ein Schritt bis zum Hochladen',
        subline:
          'Lade EyeEm herunter, um Bilder direkt von deinem Handy hochzuladen. Oder kehre von deinem Desktop aus zu dieser Seite zurück, um den Web-Upload zu nutzen.',
        button: 'Hole dir die App',
      },
    },
    confirmNavigationNative: 'Es gibt nicht gepostete Fotos.',
    disabledMessage:
      'Wähle ein oder mehrere Fotos aus, um diese Informationen zu bearbeiten',
    addToMarketTooltip: {
      title: 'Verkaufe dein Foto',
      body: 'Aktiviere dieses Kontrollkästchen, um deine Fotos für den Verkauf auf EyeEm Market sowie über die Partner-Kollektion bei Getty Images und anderen Partnern zum Kauf anzubieten. In den %(bodyLink)s erfährst du mehr darüber, wie viel du verdienen kannst.',
      bodyLink: {
        label: 'FAQs zu EyeEm Market',
        href: 'https://eyeem.zendesk.com/hc/en-us/',
      },
      extraTitle: 'Welche Fotos soll ich zu EyeEm Market hinzufügen?',
      extraBody:
        'Lade nur Fotos hoch, die du selbst aufgenommen hast. Um herauszufinden, was Käufer suchen, besuche unseren %(bodyLink)s.',
      extraBodyLink: {
        label: 'Blog',
        href: 'https://www.eyeem.com/magazine',
      },
    },
    landing: {
      headline: 'Bilder hochladen',
      button: 'Vom Computer',
      buttonIos: 'Von der Kamera ',
      buttonAndroid: 'Aus der Galerie',
      subline:
        'Du kannst bis zu %(maxFiles)s Fotos auf einmal hochladen. Bitte keine Bilder mit Wasserzeichen hochladen.',
      drophint: 'Oder einfach Bilder hierhin ziehen',
      coverLabel: 'Fotos hier droppen',
    },
    selectAll: 'Alle auswählen',
    multipleSelection: {
      hint: 'Verwende %(button)s, um mehrere Fotos auszuwählen',
      button: {
        mac: 'CMD',
        other: 'STRG',
      },
    },
    postPhotos: {
      one: 'Foto veröffentlichen',
      other: 'Alle Fotos veröffentlichen',
    },
    removePhoto: {
      eventName: 'upload_edit_deletePhoto',
    },
    uploadingPhotos: {
      one: 'Upload von %(count)s Foto ...',
      other: 'Upload von %(count)s Fotos ...',
    },
    postingPhotos: 'Fotos werden veröffentlicht',
    addMorePhotos: 'Weitere Fotos hinzufügen',
    lowResolutionHint:
      'Die Auflösung ist zu niedrig, um im EyeEm Market akzeptiert zu werden. Bitte stelle sicher, dass du Fotos von mindestens 4 MP Größe hochlädst.',
    sidebar: {
      headline: {
        zero: 'Fotos zur Bearbeitung auswählen',
        one: 'Bearbeiten eines Fotos',
        other: 'Bearbeiten von %(count)s Fotos',
      },
      discoverBar: {
        headline: 'Auffindbarkeit',
        level: {
          0: 'schlecht',
          1: 'mittelmäßig',
          2: 'mittelmäßig',
          3: 'sehr gut',
        },
        subline:
          'Füge Metadaten hinzu, um dein Foto auffindbar zu machen und deine Verkaufschancen zu erhöhen.',
      },
      addToMarket: {
        label: 'Zu EyeEm Market hinzufügen',
        subline:
          'Verdiene Geld, wenn dein Foto auf EyeEm Market oder auf Partnerseiten verkauft wird.',
        marketMission:
          'Die Mission, bei der du dein Foto einreichen möchtest, ist eine EyeEm Market Mission. Deine Bild muss zum EyeEm Market hinzugefügt werden, um an der Mission teilnehmen zu können.',
      },
      descriptionHeader: 'Bildunterschrift hinzufügen',
      descriptionPlaceholder: {
        regular: 'Bildunterschrift hinzufügen ...',
        mixed: 'Alle Bildunterschriften ersetzen ...',
      },
      albumsHeader: 'Tags hinzufügen',
      albumsPlaceholder: {
        regular: 'Tags zu deinem Foto hinzufügen ...',
        mixed: 'Alle Tags ersetzen ...',
        more: 'Weitere Tags hinzufügen ...',
      },
      albumsInput: {
        limitReached:
          'Grenzwert erreicht. Entferne Tags, um weitere hinzuzufügen ...',
        popularTags: 'Beliebte Tags',
        createTag: 'Tag "%(itemName)s" erstellen',
        photos: {
          one: '1 Foto',
          other: '%(countAbbr)s Fotos',
        },
        loading: 'Wird geladen...',
        noAlbum: 'Keine Tags gefunden',
      },
      locationHeader: 'Standort hinzufügen',
      locationPlaceholder: {
        regular: 'Füge einen Standort zu deinem Foto hinzu ...',
        mixed: 'Alle Standorte ersetzen ...',
      },
      locationInput: {
        popularLocations: 'Beliebte Standorte',
        loading: 'Wird geladen...',
        noLocation: 'Kein Ort gefunden',
      },
      keywordsInput: {
        headline: 'Tag-Vorschläge',
        selectedTags: 'Ausgewählte Tags',
        loading: 'Tag-Vorschläge laden ...',
        noIntersection:
          'Die ausgewählten Fotos haben keine gemeinsamen Tag-Vorschläge.',
        allAdded: 'Alle vorgeschlagenen Tags wurden hinzugefügt.',
        addAll: 'Alle hinzufügen %(count)s',
      },
    },
  },
  appBanner: {
    headline: 'EyeEm',
    subline: {
      Android: 'Kostenlos bei Google Play',
      iOS: 'Kostenlos im App Store',
    },
    button: 'Öffnen',
  },
  grid: {
    showMore: 'Mehr anzeigen',
    noResults: 'Keine Ergebnisse',
    pagination: {
      eventPosition: 'paginationControls',
      nextPage: {
        label: 'Weiter',
        eventName: 'access_grid_next',
      },
      prevPage: {
        label: 'Zurück',
        eventName: 'access_grid_previous',
      },
      dots: '...',
      page: 'Seiten',
    },
    photoCard: {
      eventName: 'access_asset',
      eventLabel: '',
      comment: {
        eventLabel: 'Kommentar',
      },
      feedback: {
        label: '...',
        eventLabel: 'Feedback',
        eventName: 'access_search_feedback',
      },
      similarPhotos: {
        label: 'Nicht ähnlich',
        eventName: 'access_asset',
        eventPosition: 'similar_photos',
      },
      releasesNeeded: {
        zero: 'Formulare zur Rechteübertragung durch abgebildete Personen erforderlich',
        one: 'Formular zur Rechteübertragung durch abgebildete Personen erforderlich',
        other:
          'Formulare zur Rechteübertragung durch abgebildete Personen erforderlich',
      },
      cantGetReleases: {
        eventName: 'releases_cantClear',
        label: {
          zero: 'Ich kann keine Rechteübertragungen einholen',
          one: 'Ich kann keine Rechteübertragungen einholen',
          other: 'Ich kann keine Rechteübertragungen einholen',
        },
      },
      manageReleases: {
        label: 'Rechteübertragungen verwalten',
        eventName: 'releases_manage',
      },
      aestheticsBar: {
        label: 'Engagement-Punktzahl',
        value: '%(percent)s%',
      },
      overlay: {
        lightbox: 'Gespeichert',
        inCart: 'Im Einkaufswagen',
        purchased: 'Heruntergeladen',
        hiRes: 'Hi-Res Vorschau',
      },
    },
  },
  forms: {
    label: {
      generic: 'Element',
      firstName: 'Vorname',
      lastName: 'Nachname',
      email: 'E-Mail-Adresse',
      birthday: 'Geburtsdatum',
      company: 'Name des Unternehmens',
      taxId: 'UStID',
      city: 'Ort',
      address: 'Straße',
      zip: 'Postleitzahl',
      state: 'Bundesland(optional)',
      gender: {
        male: 'männlich',
        female: 'weiblich',
        other: 'divers',
      },
      password: {
        VALIDATOR_MIN_LENGTH: 'Mindestens 8 Zeichen',
        VALIDATOR_PASSWORD_LOWER: 'Ein Zeichen in Kleinschreibung',
        VALIDATOR_PASSWORD_UPPER: 'Ein Zeichen in Großschreibung',
        VALIDATOR_PASSWORD_NUMBER: 'Eine Zahl',
        VALIDATOR_PASSWORD_SPECIAL_CHAR: 'Ein Sonderzeichen',
      },
    },

    placeholder: {
      firstName: 'Vorname',
      lastName: 'Nachname',
      name: 'Name',
      email: 'name@example.com',
      date: {
        day: 'DD',
        month: 'MM',
        year: 'JJJJ',
      },
      country: 'Land',
      company: 'Name des Unternehmens',
      companyOptional: 'Firma (optional)',
      taxId: {
        required: 'UStID',
        optional: 'Steuer-ID (Optional)',
      },
      address: 'Straße',
      city: 'Ort/Stadt',
      zip: 'PLZ',
      state: 'Bundesland (optional)',
      modelRelease: {
        firstName: 'Vorname der abgebildeten Person',
        lastName: 'Nachname der abgebildeten Person',
        parentFirstName: 'Vorname des Elternteils',
        parentLastName: 'Nachname des Elternteils',
        modelEmail: 'E-Mail-Adresse der abgebildeten Person',
        parentEmail: 'E-Mail-Adresse des Elternteils',
      },
      propertyRelease: {
        propertyAddress: 'Adresse des Objekts',
        ownerAddress: 'Adresse des Eigentümers',
        ownerFirstName: 'Vorname des Eigentümers',
        ownerLastName: 'Nachname des Eigentümers',
        selectPropertyCategory: '- Objektkategorie auswählen -',
      },
      accountSettings: {
        fullname: 'Name',
        nickname: 'Benutzername',
        description: 'Schreib etwas über dich.',
        homepage: 'Deine persönliche Homepage',
        email: 'E-Mail-Adresse',
        password: 'Passwort',
        newPassword: 'Neues Passwort',
        confirmPassword: 'Passwort bestätigen',
      },
    },
    fileInput: {
      upload: 'Hochladen',
      change: 'Änderung',
      remove: 'Entfernen',
      formats: 'Unterstützte Formate: %(formats)s',
      resolution: 'Min. Auflösung: %(resolution)s',
      pngOrJpeg: 'JPEG oder PNG',
    },
    show: 'anzeigen',
    hide: 'ausblenden',
    button: {
      next: 'Weiter',
      back: 'Zurück',
      done: 'Fertig',
      showCollapsibleFields: 'Name des Unternehmens und Steuernummer',
    },
    error: {
      generic: '%(elementName)s ist ungültig.',
      mandatory: '%(elementName)s ist eine Pflichtangabe.',
      checked:
        'Bitte bestätige, dass du über 18 Jahre alt bist. Wenn du unter 18 Jahre alt bist, kannst du dich nicht für den Verkauf auf EyeEm Market registrieren.',
      selected: 'Bitte wähle einen der angegebenen Werte aus.',
      adult: 'Um sich anzumelden, musst du mindestens 18 Jahre alt sein.',
      fullname: 'Ihr Name darf zwischen 2 und 128 Zeichen lang sein.',
      countryPaypal:
        'Leider unterstützt PayPal den Empfang von Zahlungen in diesem Land nicht. Wenn du eine Adresse in einem anderen Land hast, versuche bitte, diese einzugeben.',
      typeOfPropertySelected: 'Bitte wähle die Art des Objekts aus.',
      genderSelected: 'Bitte wähle das Geschlecht der abgebildeten Person aus.',
      password: 'Dein Passwort muss mindestens 6 Zeichen lang sein.',
      description: 'Deine Biografie kann bis zu 130 Zeichen lang sein.',
      emailUnavailable: 'Diese E-Mail-Adresse wird bereits verwendet.',
      ccNumber:
        'Deine Kreditkartennummer sollte 13, 16 oder 19 Ziffern lang sein.',
      VALIDATOR_REQUIRED: 'Dieses Feld muss ausgefüllt werden.',
      VALIDATOR_ZIP: 'Postleitzahl ist nicht gültig',
      VALIDATOR_ZIP_MAX_LENGTH: 'Postleitzahl zu lang (max 10 Zahlen)',
      VALIDATOR_UNICODE_STRING: 'Dies ist kein gültiger Wert',
      VALIDATOR_INT: 'Dies ist keine gültige Nummer',
      VALIDATOR_STRING: 'Dies ist keine gültige Zeichenkette',
      VALIDATOR_EMAIL: 'Dies ist keine gültige E-Mail-Adresse',
      VALIDATOR_CHARGECODE: 'Dies ist kein gültiger Gebührenschlüssel',
      VALIDATOR_CHARGECODE_ERROR:
        'Die Überprüfung des Gebührenschlüssels ist fehlgeschlagen. Bitte wende dich an deinen Vorgesetzten.',
      VALIDATOR_PASSWORD: 'Ihr Passwort muss mindestens 8 Zeichen lang sein.',
      VALIDATOR_ENTERPRISE_PASSWORD:
        'Ihr Passwort muss mindestens 8 Zeichen lang sein.',
      VALIDATOR_PASSWORD_OR_EMPTY:
        'Ihr Passwort muss mindestens 8 Zeichen lang sein.',
      VALIDATOR_ENTERPRISE_PASSWORD_OR_EMPTY:
        'Ihr Passwort muss mindestens 8 Zeichen lang sein.',
      PASSWORDS_MUST_MATCH: 'Passwörter stimmen nicht überein',
      VALIDATOR_MIN_LENGTH: 'Mindestens 8 Zeichen',
      VALIDATOR_PASSWORD_LOWER: 'Ein Zeichen in Kleinschreibung',
      VALIDATOR_PASSWORD_UPPER: 'Ein Zeichen in Großschreibung',
      VALIDATOR_PASSWORD_NUMBER: 'Eine Zahl',
      VALIDATOR_PASSWORD_SPECIAL_CHAR: 'Ein Sonderzeichen',
      VALIDATOR_ADULT:
        'Um sich anzumelden, musst du mindestens 18 Jahre alt sein.',
      VALIDATOR_DATE: 'Bitte gib ein vollständiges Datum an',
      VALIDATOR_TAX_ID: 'Steuer-ID ist nicht gültig',
      VALIDATOR_PAYPAL_COUNTRY:
        'Leider unterstützt PayPal den Empfang von Zahlungen in diesem Land nicht. Wenn du eine Adresse in einem anderen Land haben, versuche bitte, diese einzugeben.',
      VALIDATOR_BCG_EMAIL:
        'Bitte melde dich über %(link)s mit deinem Okta-Anmeldeinformationen an.',
      startDateBeforeEndDate:
        'Bitte gib ein Startdatum an, das vor dem Enddatum liegt.',
      endDateBeforeStartDate:
        'Bitte gib ein Enddatum an, das nach dem Startdatum liegt.',
      LINK_MUST_BE_VALID: `Benötigt entweder 'http://' oder 'https://'`,
      VALIDATOR_CAPTION_TOO_SHORT:
        'Die Bildunterschrift sollte mindestens 10 Zeichen lang sein',
      VALIDATOR_CAPTION_TOO_LONG:
        'Die Bildunterschrift sollte weniger als 100 Zeichen sein',
      VALIDATOR_CAPTION_CHARS:
        'Die Bildunterschrift enthält nicht unterstützte Zeichen',
      VALIDATOR_NOT_ENOUGH_KEYWORDS: 'Mindestens 5 keywords hinzufügen',
      VALIDATOR_TOO_MANY_KEYWORDS_50: 'Zu viele Keywords ausgewählt',
      VALIDATOR_TOO_MANY_KEYWORDS_40: 'Zu viele Keywords ausgewählt',
      VALIDATOR_MUST_SELECT_NUMBER_OF_PEOPLE:
        'Bitte gib die Anzahl der Menschen auf diesem Foto an',
      VALIDATOR_MUST_SELECT_DAYTIME:
        'Bitte gib an, wann dieses Foto aufgenommen wurde',
    },
  },
  missionBrief: {
    header: {
      button: 'Signoff-Briefing',
    },
    campaignOverview: {
      headline: 'Kampagnenübersicht',
      partnerName: 'Markenname',
      internalTitle: 'Interner Kampagnentitel',
      goals: 'Ziele dieser Mission',
      startDate: 'Startdatum der Mission',
      endDate: 'Enddatum der Mission',
      primaryPurpose: {
        label: 'Hauptzweck',
        options: {
          REACH: 'Reichweite',
          IMAGE_SOURCING: 'Bildbeschaffung',
        },
      },
      brandLogo: 'Markenlogo',
      formats: 'JPEG',
      resolution: '300x300px',
    },
    prize: {
      headline: 'Missionspreis',
      prizeTitle: 'Preis',
      prizeUrl: 'Preis-URL',
      prizeUrlPlaceholder: '+ Preislink hinzufügen',
      prizePhoto: 'Preisfoto',
      formats: 'JPEG, PNG',
      resolution: '1500 px auf der Längsseite',
    },
    visualIdentity: {
      headline: 'Visuelle Identität',
      lightboxId: 'Lightbox-URL',
      lightboxIdPlaceholder: 'Füge einen Referenz-Lightbox-Link hinzu',
      visualStyleDescription: 'Beschreibung des visuellen Stils',
      do: 'Was du tun solltest',
      doPlaceholder: '+ Handlungsempfehlung hinzufügen',
      dont: 'Was du nicht tun solltest',
      dontPlaceholder: '+ Unterlassensempfehlung hinzufügen',
      brandGuidelinesUrl: 'Dokumentation der Markenrichtlinien',
    },
    location: {
      headline: 'Positionierung',
      isTargeted: {
        label: 'Zielgebiet',
        options: {
          false: 'Global',
          true: 'Ortsbezogen',
        },
        placeholder: '+ Einen Standort hinzufügen',
      },
    },
    reach: {
      headline: 'Reichweite',
      tags: {
        title: 'Social Media-Hashtags',
        connected: 'Aktiviert',
        disconnect: 'Deaktivieren',
        connect: 'Aktivieren',
        serviceNames: {
          twitter: '%(icon)sTwitter',
          facebook: '%(icon)sFacebook',
          instagram: '%(icon)sInstagram',
        },
        placeholder: '+ Hashtag hinzufügen',
      },
      push: {
        headline: 'Pushbenachrichtigungen',
        label: 'Pushbenachrichtigungen aktivieren',
      },
    },
    notes: {
      headline: 'Zusätzliche Hinweise',
    },
    imageDetails: {
      headline: 'Bilddetails',
      infoBox: {
        headline: 'Benötigst du noch etwas anderes?',
        body: 'Text Text Text Text Text Text Text',
        button: 'Änderungsanforderungen',
      },
      securedImages: 'Anzahl der gesicherten Bilder',
      embargoPeriod: 'Embargofrist',
      exclusivityOptions: 'Exklusivitätsoptionen',
      buyoutOptions: 'Buyout-Optionen',
      discountAmount: 'Rabattbetrag',
      discountTime: 'Rabattzeitraum',
    },
    signedModal: {
      headline: 'Missions-Briefing',
      paragraph:
        'Wenn du auf „Unterschreiben“ klickst, wird der Vorgang gespeichert.',
      sign: 'Signoff-Briefing',
      cancel: 'Abbrechen',
    },
  },
  mission: {
    status: {
      IN_PREPARATION: 'In Vorbereitung',
      READY_TO_LAUNCH: 'Startbereit',
      RUNNING: 'Mission läuft',
      SELECTING_WINNERS: 'Auswahl der Gewinner',
      COMPLETED: 'Abgeschlossen',
    },
    submit: 'Dein Foto einreichen',
    hostedBy: 'Veranstaltet von',
    mission: 'Mission',
    photos: {
      zero: '%(abbreviatedCount)s Fotos',
      one: '%(abbreviatedCount)s Foto',
      other: '%(abbreviatedCount)s Fotos',
    },
    illustrations: {
      zero: '%(abbreviatedCount)s Illustrationen',
      one: '%(abbreviatedCount)s Illustration',
      other: '%(abbreviatedCount)s Illustrationen',
    },
    brief: {
      brief: 'Deine Mission',
      recap: 'Zusammenfassung',
      whatIsMission: 'Was ist eine Mission? %(link)s',
      link: 'Erfahre mehr.',
    },
    details: {
      title: 'Preis Details',
      prize: 'Preis',
      deadline: 'Frist',
      terms: 'Teilnahmebedingungen',
      marketTipTitle: 'Tipp zu EyeEm EyeEm Market',
      marketTipBody:
        'Die Gewinnerfotos könnten kommerziell lizenziert werden, daher stellen Sie bitte sicher, dass Ihre Bilder mit den entsprechenden Modellfreigaben dem Markt hinzugefügt wurden.',
    },
    enter: {
      headline: 'An dieser Mission teilnehmen',
      button: 'Ihr Foto einreichen',
    },
    expired: {
      winner: 'Gewinner',
      body: 'Wird bald bekanntgegeben',
    },
    completed: {
      grandPrice: 'Hauptpreisträger',
      winner: 'Sieger',
      photoCredit: 'Von %(name)s',
      runnersUp: 'Finalisten',
    },
    sampleImages: {
      headline: 'Visuelle Inspiration',
    },
    uploadToAlbum: {
      loginHeadline:
        'Melde dich an, um deine Arbeit zu dieser Mission hinzuzufügen',
      signupHeadline:
        'Tritt EyeEm bei, um deine Arbeit zu dieser Mission hinzuzufügen',
      eventName: 'mission_submit_asset',
    },
    retagging: {
      modal: {
        switch: {
          headline: 'Wie du an einer Mission teilnehmen kannst',
          paragraph:
            'Du kannst an der Mission teilnehmen, indem du von deinem Gerät hochlädst, oder du wählst aus deinem Profil aus.',
          selectButton: 'Aus Profil auswählen',
          uploadButton: 'Bild(er) hochladen',
        },
        confirm: {
          message:
            'Die oben ausgewählten Bilder werden eingereicht an %(missionName)s',
          button: 'Bilder einreichen',
          strip: {
            zero: '%(boldCount)s Bilder werden eingereicht',
            one: '%(boldCount)s Bild wird eingereicht',
            other: '%(boldCount)s Bilder werden eingereicht',
          },
        },
        select: {
          selectedCounter: {
            zero: '%(count)s Bilder ausgewählt',
            one: '%(count)s Bild ausgewählt',
            other: '%(count)s Bilder ausgewählt',
          },
          cancel: 'Abbrechen',
          next: 'Weiter',
        },
      },
    },
  },
  releasePage: {
    pending: {
      intro: {
        title: 'Release Form',
        text: 'Fill out the form below to give your permission for the displayed photo to be sold commercially on EyeEm Market.',
      },
      modelRelease: {
        modelInfoTitle: "Model's information",
        parentInfoTitle: "Parent's information",
        minorWarning: {
          title: 'Model is under 18',
          text: 'As the model in this photo is a minor, a parent or guardian needs to fill out their details below.',
        },
      },
      propertyRelease: {
        propertyInfoTitle: 'Property information %(tooltip)s',
        ownerInfoTitle: 'Owner information %(tooltip)s',
        addressTitle: 'Address of Property Owner %(tooltip)s',
        sameAddressText:
          'Address of Property Owner is the same as Address of Property',
        propertyCategory: {
          OWN_ARTWORK: 'My artwork/creation',
          ARTWORK: "Someone else's artwork/creation",
          OWN_PROPERTY: 'My home/residence/property',
          PROPERTY: "Someone else's home/residence/property",
          OWN_BRAND: 'My company/brand/logo',
          BRAND: "Someone else's company/brand/logo",
          OTHER: 'Other',
        },
        tooltips: {
          property: {
            title: 'What types of property can I release?',
            text: 'You can sign a release for property that belongs to you and does not contain a visible brand or logo - e.g. your home or artwork. Brands which do not belong to you - e.g. your iPhone or car - require permission from the owner or manufacturer.',
            extraBody: '%(extraBodyLink)s',
            link: {
              label: 'Read more about Property Releases here',
              href: 'https://eyeem.zendesk.com/hc/en-us/articles/202751291',
            },
          },
          owner: {
            title: 'Information about the property owner',
            text: "As the property owner, enter your name and email address. Enter a company name if you are signing a release for your brand or logo - leave blank if you're signing a release for your home or artwork.",
          },
          address: {
            title: 'Where does the property owner live?',
            text: 'If your property and yourself are located at the same address - e.g. your home - you don’t need to provide this extra information. However, if your property is located at a different address, enter your home address here.',
          },
        },
      },
      submit: 'Submit',
      tos: {
        text: 'Ich akzeptiere das %(releases)s und die %(privacyPolicy)s.',
        consent:
          'Ich willige ein, dass EyeEm das abgebildete Foto für gewerbliche und nicht-gewerbliche Zwecke durch EyeEm, EyeEms Partner und Lizenznehmer nutzen, bearbeiten und verwerten darf. Ich willige ein, keine Vergütung zu erhalten.',
        model: {
          title: 'Model Releases',
          pdfLink: 'http://dl.eyeem.com/market/model_release.pdf',
        },
        minorModel: {
          title: 'Model Releases for minors',
          pdfLink: 'http://dl.eyeem.com/market/model_release_for_minors.pdf',
        },
        property: {
          title: 'Property Releases',
          pdfLink: 'http://dl.eyeem.com/market/property_release.pdf',
        },
        privacyPolicy: {
          title: 'Datenschutzhinweise',
          pdfLink: 'https://www.eyeem.com/privacy',
        },
      },
      questions: {
        text: 'Du kannst Deine Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen, indem Du eine E-Mail an %(supportEmail)s sendest. Deine Anfrage wird innerhalb von 90 Tagen bearbeitet, die vorherige Nutzung bleibt unberührt.',
        marketSupport: {
          label: 'Market Support',
          href: 'https://eyeem.zendesk.com/hc/en-us/',
        },
      },
    },
    settled: {
      title: {
        denied: 'Response successfully sent.',
        accepted: 'Thanks for your help, %(firstName)s!',
      },
      text: 'Interested in selling your own photos? Sign up for EyeEm and start earning money now.',
      button: 'Find out more',
    },
  },
  assetGrid: {
    remove: 'Entfernen',
  },
  userKeywording: {
    infoBox: {
      content:
        'Fülle die Bildunterschrift und die Keywords wie ein Profi aus, damit Käufer deine Arbeit entdecken können! ',
      tip: 'Tipp: ',
      linkText: 'Mehr erfahren',
    },
    empty: {
      headline: 'Sie haben keine Fotos zu freigeben.',
      subline:
        'Du hast keine Fotos, die eine Metadatenfreigabe benötigen, um sie auf EyeEm Market und Partnerseiten zu verkaufen.',
      button: { href: '/upload/photos', label: 'Mehr Fotos Hochladen' },
    },
    emptySidebar: 'Wähle ein Bild zum Bearbeiten aus.',
    form: {
      caption: {
        label: 'Bildunterschrift',
        placeholder: 'Example: friends using mobile phones while lying on sofa',
        multiplePlaceholder: 'Mehrere Bildunterschriften ersetzen',
        existing: 'Deine Bildunterschrift',
        suggested: 'Vorgeschlagene Bildunterschrift',
        info: 'Tipp: Wir empfehlen dir, unseren Vorschlag zu bearbeiten, um ihn zutreffender zu machen',
      },
      keywords: {
        input: {
          limitReached:
            'Maximal %(maximum)s sind erlaubt. Bitte entferne einige Keywords',
          label: 'Keywords',
          placeholder: {
            regular: 'Keywords hinzufügen ...',
            more: 'Weitere Keywords hinzufügen ...',
          },
          createTag: 'Keyword "%(itemName)s" erstellen',
          multi: {
            all: 'Keywords zu alle ausgewählten',
            some: 'Keywords zu einigen ausgewählten',
          },
        },
        empty: 'Mindestens 5 keywords sind erforderlich',
        uncontrolled:
          'Einige Keywords werden nicht erkannt. Wenn nicht aufgrund von Rechtschreibfehlern, kannst du sie trotzdem einreichen',
      },
      location: {
        label: 'Standort',
        placeholder: 'Standort hinzufügen',
        skipLocationCheckbox: 'Standort nicht gefunden oder ist nicht relevant',
        hint: 'Du kannst auch Keywords verwenden, um genaue Standortbegriffe hinzuzufügen.',
      },
      people: {
        label: 'Anzahl von Menschen',
        options: {
          default: 'Bitte auswählen',
          noPeople: 'Keine Menschen',
          onePerson: 'Eine Person',
          twoPeople: 'Zwei Personen',
          threePeople: 'Drei Personen',
          mediumGroup: 'Mittlere Gruppe von Personen',
          bigGroup: 'Große Gruppe von Personen',
        },
      },
      when: { label: 'Wann', day: 'Day', night: 'Night' },
      where: { label: 'Wo', indoors: 'Indoors', outdoors: 'Outdoors' },
      submit: 'Senden',
      uploading: 'Hochladen',
    },
  },
  tracking: {
    eventAction: {
      link: 'click_link',
      icon: 'click_icon',
      button: 'click_button',
      asset: 'click_asset',
      passive: 'passive',
      hover: 'hover',
      show: 'show',
      formSubmit: 'form_submit',
    },
    eventAssetType: {
      photo: 'photo',
      illustration: 'illustration',
    },
    eventType: {
      inbound: 'click_inbound',
      outbound: 'click_outbound',
    },
    eventLabel: {
      icon: 'icon',
      selectFromComputer: 'Select from computer',
      uploadPhotos: 'upload_photos',
    },
    eventPosition: {
      modalContent: 'modal_content',
      editingTopbar: 'editing_topbar',
      uploadAccess: 'upload_access',
    },
    eventName: {
      ecomModalShow: 'ecom_modal_show',
      ecomModalSignup: 'ecom_modal_signup',
      ecomModalLogin: 'ecom_modal_login',
      ecomDownload: 'ecom_download',
      missionSubmitAssetSuccess: 'mission_submit_asset_success',
      uploadPostAssets: 'upload_post_assets',
      uploadStart: 'upload_start',
    },
  },
  languages: {
    en: 'English',
    de: 'Deutsch',
  },
  countries: {
    AF: 'Afghanistan',
    AX: 'Åland Islands',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua and Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia, Plurinational State of',
    BQ: 'Bonaire, Sint Eustatius and Saba',
    BA: 'Bosnia and Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    BN: 'Brunei Darussalam',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    CV: 'Cabo Verde',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo',
    CD: 'Congo, the Democratic Republic of the',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    CI: "Côte d'Ivoire",
    HR: 'Croatia',
    CU: 'Cuba',
    CW: 'Curaçao',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    ET: 'Ethiopia',
    FK: 'Falkland Islands (Malvinas)',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard Island and McDonald Islands',
    VA: 'Holy See (Vatican City State)',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran, Islamic Republic of',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KP: "Korea, Democratic People's Republic of",
    KR: 'Korea, Republic of',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: "Lao People's Democratic Republic",
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao',
    MK: 'Macedonia, the former Yugoslav Republic of',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia, Federated States of',
    MD: 'Moldova, Republic of',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands, the',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestine, State of',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RE: 'Réunion',
    RO: 'Romania',
    RU: 'Russian Federation',
    RW: 'Rwanda',
    BL: 'Saint Barthélemy',
    SH: 'Saint Helena, Ascension and Tristan da Cunha',
    KN: 'Saint Kitts and Nevis',
    LC: 'Saint Lucia',
    MF: 'Saint Martin (French part)',
    PM: 'Saint Pierre and Miquelon',
    VC: 'Saint Vincent and the Grenadines',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'Sao Tome and Principe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SX: 'Sint Maarten (Dutch part)',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    GS: 'South Georgia and the South Sandwich Islands',
    SS: 'South Sudan',
    ES: 'Spain',
    LK: 'Sri Lanka',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard and Jan Mayen',
    SZ: 'Swaziland',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syrian Arab Republic',
    TW: 'Taiwan, Republic of China',
    TJ: 'Tajikistan',
    TZ: 'Tanzania, United Republic of',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks and Caicos Islands',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States',
    UM: 'United States Minor Outlying Islands',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VE: 'Venezuela, Bolivarian Republic of',
    VN: 'Viet Nam',
    VG: 'Virgin Islands, British',
    VI: 'Virgin Islands, U.S.',
    WF: 'Wallis and Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },
  uploadfork: {
    title: 'Was lädst du hoch?',
    subtitle: 'Teile deine besten Arbeiten auf EyeEm',
    beta: 'Beta',
    illustrations: {
      title: 'Illustrationen',
      subtitle:
        'Dein gescanntes Bildmaterial oder deine digital erstellten Illustrationen',
    },
    photos: {
      title: 'Fotos',
      subtitle: 'Deine digitalen, analogen oder mobilen Kamerafotos',
    },
  },
  uploader: {
    missionSubmissionFailedModal: {
      duplicate: {
        title: 'Bilder sind bereits eingereicht!',
        body: 'Diese Bilder sind bereits Teil der Mission.',
      },
      generic: {
        title: 'Huch! Etwas ist schief gelaufen.',
        body: 'Einige Bilder wurden übersprungen. Bitte versuche es erneut! Wir mussten folgende überspringen:',
      },
      submissionTypeNotSupported: {
        title: 'Huch! Etwas ist schief gelaufen.',
        body: 'Sieht aus, als hättest du Fotos hochgeladen. Du kannst nur Illustrationen zu dieser Mission hochladen. Wir mussten folgende überspringen:',
      },
      cta: 'Okay',
    },
    missions: {
      title: 'Missionen',
      optional: 'optional',
      subtitle:
        'Füge nur Illustrationen ein, die zu dem Missions-Briefing passen.',
      linkText: 'Entdecke Mission-Briefings',
      tooltip:
        'Missionen sind Wettbewerbe, die dir helfen, dich zu präsentieren und Preise zu gewinnen.',
      hostedBy: 'Veranstaltet von',
      readBrief: 'Brief lesen',
      dropdownPlaceholder: 'Wähle eine Mission',
      emptyDropdown: 'Keine aktiven Missionen. Bleib dran!',
    },

    illustrations: {
      header: 'Illustrationen hochladen',
      specifications: {
        resolution: 'Mindestauflösung: 4MP',
        size: 'Maximale Dateigröße: 45MB',
        format: 'Müssen JPG-Dateien sein',
        license: 'Muss für den Markt geeignet sein (kommerziell)',
      },
      addMore: 'Weitere Illustrationen hinzufügen',
      unsupported: {
        title: 'Einige Dateien wurden übersprungen',
        body: 'Achte darauf, dass es sich bei deinen Dateien um JPGs handelt und dass sie die Mindestauflösung (4MP) und die maximale Dateigröße (45MB) erfüllen. Wir mussten überspringen:',
        cta: 'Okay',
        reason: {
          fileTooBig: 'Datei zu groß',
          wrongFileFormat: 'Datei ist nicht im jpeg-Format',
          resolutionTooSmall: 'Die Auflösung ist zu gering',
          tooManyFiles: 'Zu viele Dateien',
        },
        more: '+ %(count)s mehr',
      },
      emptySidebar:
        'Du kannst SHIFT oder COMMAND/CTRL verwenden, um mehrere Fotos zu bearbeiten.',
      form: {
        categories: {
          title: 'Kategorien',
          subtitle: '(wähle 1-2)',
          dropdownPlaceholder: 'Kategorien auswählen',
          error: 'Wähle mindestens eine Kategorie',
        },
        styles: {
          title: 'Stile',
          subtitle: '(wähle 1-2)',
          dropdownPlaceholder: 'Stile auswählen',
          error: 'Wähle mindestens einen Stil',
        },
        caption: {
          label: 'Bildunterschrift',
          placeholder:
            'Example: seamless pattern of whales with blue and pink floral background elements',
          info: 'Tipp: Wir empfehlen dir, unseren Vorschlag zu bearbeiten, um ihn zutreffender zu machen',
        },
        missions: {
          title: 'Missionen',
          optional: 'optional',
          subtitle:
            'Füge nur Illustrationen ein, die zu dem Missions-Briefing passen.',
          linkText: 'Briefs entdecken.',
          tooltip:
            'Missionen sind Wettbewerbe, die dir helfen, dich zu präsentieren und Preise zu gewinnen.',
          hostedBy: 'Veranstaltet von',
          readBrief: 'Brief lesen',
          dropdownPlaceholder: 'Wähle eine Mission',
          emptyDropdown: 'Keine aktiven Missionen. Bleib dran!',
        },
      },
    },
  },
  assetType: {
    illustrations: {
      zero: 'Illustration',
      one: 'Illustration',
      other: 'Illustrationen',
    },
    photos: {
      zero: 'Foto',
      one: 'Foto',
      other: 'Fotos',
    },
  },
  verifyEmail: {
    success: {
      title: 'Email wurde erfolgreich bestätigt!',
      link: 'Zur Login',
    },
  },
  copyrightModal: {
    title: 'Fotoeigentum',
    paragraph:
      'Indem du Bilder zu EyeEm Market hinzufügst, bestätigst du, dass du die Bilder gemacht hast.',
    link: {
      label: 'Mehr erfahren',
      href: 'https://www.eyeem.com/blog/what-you-need-to-know-about-eyeem-market-copyright-restrictions',
      eventName: 'copyright_modal_learn_more',
    },
    eventPosition: 'pv_copyright_modal',
    buttons: {
      cancel: {
        label: 'Abbrechen',
        eventName: 'copyright_modal_cancel',
      },
      confirm: {
        label: 'Weiter',
        eventName: 'copyright_modal_confirm',
      },
    },
    checkbox: 'Ja, ich habe das Bild gemacht.',
  },
};
