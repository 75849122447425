/* @flow */
/* Lightbox strip */
import t from 'counterpart';
import { Close } from '@eyeem-ui/icons';
import { ThemeProvider } from '@eyeem-ui/theme';
import { withApollo } from '@apollo/client/react/hoc';
import type { ApolloClient } from 'apollo-client';

import Photo from '../../photo/';
import T from '../../translate.jsx';
import { GET_LIGHTBOX } from '../../../../graphql/queries/lightbox';
import withRouter from '../../../../helpers/hoc/withRouter.jsx';
import { refetchQueryByPath } from '../../../../helpers/tools';

import {
  TEMP_FIELD_CONVERSION_EVENT_OPTION,
  LIGHTBOX_PHOTO_HEIGHT,
} from '../../../../constants/misc';
import { ASSET_TYPE_ILLUSTRATION } from '../../../../constants/assets';

type Props = {
  asset: EyeEmAsset,
  lightboxId: EyeEmLightboxId,
  removeFromLightbox: Function,
  translatePrefix: string,
  eventOption?: string,
  conversionEventOption: ConversionEventOption,
  contextPaginatableMetadata: ContextPaginatableMetadataState,
  setContextPaginatableMetadata: Function,
  setTempField: Function,
  storeConversionEventOption: Function,
  client: ApolloClient<any>,
} & WithRouterProps;

const StripPhoto = ({
  asset,
  lightboxId,
  removeFromLightbox,
  translatePrefix,
  eventOption = '',
  conversionEventOption,
  contextPaginatableMetadata,
  setContextPaginatableMetadata,
  setTempField,
  storeConversionEventOption,
  client,
  getPath,
}: Props) => {
  const remove = (event: SyntheticInputEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();

    removeFromLightbox({
      variables: {
        lightboxId: lightboxId,
        photoId: asset?.id,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        removePhoto: {
          __typename: 'Photo',
          id: asset.id,
          photoUrl: asset.photoUrl || asset.previewUrl,
          lightboxIds: asset.lightboxIds || [],
        },
      },
      update: (proxy, { data: { removePhoto } }) => {
        const data = proxy.readQuery({
          query: GET_LIGHTBOX,
          variables: {
            lightboxId: lightboxId,
          },
        });

        const removedId = removePhoto.id;
        const filteredItems = data.lightbox.items.items.filter(
          (item) => item.id !== removedId
        );

        proxy.writeQuery({
          query: GET_LIGHTBOX,
          variables: {
            lightboxId: lightboxId,
          },
          data: {
            lightbox: {
              ...data.lightbox,
              items: {
                ...data.lightbox.items,
                items: filteredItems,
              },
            },
          },
        });
      },
    });
    return refetchQueryByPath(getPath(), client);
  };

  const handlePhotoClick = () =>
    contextPaginatableMetadata &&
    setContextPaginatableMetadata(contextPaginatableMetadata);

  const handleStoreConversionEventOption = () => {
    setTempField({
      name: TEMP_FIELD_CONVERSION_EVENT_OPTION,
      value: conversionEventOption,
    });
    storeConversionEventOption({
      photoId: asset.id,
      eventOption: {
        ...conversionEventOption,
        photoId: asset.id,
      },
    });
  };

  return (
    <T
      noTranslate
      className="lightboxStrip_left_photo"
      component="div"
      onClick={handleStoreConversionEventOption}
      eventAssetType="photo"
      eventType={t('tracking.eventType.inbound')}
      eventAction={t('tracking.eventAction.asset')}
      eventLabel={t(`${translatePrefix}.accessAsset.eventLabel`)}
      eventName={t(`${translatePrefix}.accessAsset.eventName`)}
      eventPosition={t(`${translatePrefix}.eventPosition`)}
      eventOption={`lightboxId=${lightboxId}&photoId=${asset.id}`}>
      <Photo
        asset={asset}
        url={asset.photoUrl || asset.previewUrl}
        link={asset.relativeUrl}
        click={handlePhotoClick}
        size1="h"
        size2={LIGHTBOX_PHOTO_HEIGHT}
        className="lightboxStrip_left_photo_photo"
      />
      {asset.type !== ASSET_TYPE_ILLUSTRATION && (
        <T
          noTranslate
          onClick={remove}
          component="div"
          title={t(`${translatePrefix}.removePhoto.iconAlt`)}
          eventType={t('tracking.eventType.inbound')}
          eventAction={t('tracking.eventAction.icon')}
          eventLabel={t(`${translatePrefix}.removePhoto.iconAlt`)}
          eventName={t(`${translatePrefix}.removePhoto.eventName`)}
          eventPosition={t(`${translatePrefix}.eventPosition`)}
          eventOption={
            eventOption
              ? `${eventOption}&photoId=${asset.id}`
              : `lightboxId=${lightboxId}&photoId=${asset.id}`
          }
          className="lightboxStrip_left_photo_trash">
          <ThemeProvider>
            <Close color="grey100" />
          </ThemeProvider>
        </T>
      )}
    </T>
  );
};

export default withRouter(withApollo(StripPhoto));
