/* @flow */
import { EXPERIMENT_SEARCH_FILTER } from '../constants/misc';

/*
 * Example experiment config

  [EXPERIMENT_GRID_SIZE]: {
    distribute: () => (Math.random() > 0.5 ? 1 : 0), // should return 0 or 1
    shouldTrack: ({ data }) =>
      !!data.sessionFields?.[SESSION_FIELD_HAS_VISITED_SEARCH] // useful for tracking only after meeting a condition
    skip: ({ authUser }) => isPhotographer(authUser), // optional skip experiment based on user attributes
    trackingSlot: 'exp_variant', // could help running multiple experiments
    trackingValue: (isActive) =>
      isActive
        ? 'EB01_searchGridSize_v1_morePhotos'
        : 'EB01_searchGridSize_v0_lessPhotos',
  },
 */

/**
 * List of Experiments
 */
const experiments: Experiments = {
  [EXPERIMENT_SEARCH_FILTER]: {
    distribute: () => 0,
    shouldTrack: () => false,
    skip: () => true,
    trackingSlot: 'exp_variant',
    trackingValue: (isActive) =>
      isActive
        ? 'EBU01_searchFilterBar_v1_collapsed'
        : 'EBU01_searchFilterBar_v0_expanded',
  },
};

export default experiments;
