/* @flow */
// Libraries
import styled from 'styled-components';
import { styleUtils } from 'eyeem-components';

const StyledSuggestion = styled.div`
  padding: 0 12px;
  cursor: pointer;
  line-height: 40px;
  border-radius: 10px;
  color: #0f0f11;
  background-color: ${(props) =>
    props.isActive ? styleUtils.fromTheme('colors.greys.grey6') : 'initial'};

  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
`;

// prevent line break when suggestion ends with a space
const StyledSuggestionBoxItem = styled.span`
  white-space: nowrap;
`;

const StyledSuggestionBoxItemLeft = styled.span`
  font-size: 0.865rem;
  max-width: calc(100% - 24px);
  display: inline-block;
  overflow: hidden;
  float: left;
  text-overflow: ellipsis;
`;

function Suggestion(props: {
  suggestion: KeywordSuggestion,
  isActive: boolean,
}) {
  return (
    <StyledSuggestion isActive={props.isActive}>
      <StyledSuggestionBoxItem>
        <StyledSuggestionBoxItemLeft>
          {props.suggestion.text}
        </StyledSuggestionBoxItemLeft>
      </StyledSuggestionBoxItem>
    </StyledSuggestion>
  );
}

export default Suggestion;
