/* @flow */

import classnames from 'classnames';

const regLink =
  /((?:http|https):\/\/[\w-]+(?:\.[\w-]+)+(?:[\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?)/;

function ParseLinks(props: {
  text: string,
  className?: string,
  linkClassName?: string,
}) {
  // split the message into components
  const textComponents = props.text.split(regLink);
  const linkClassName = props.linkClassName;

  return (
    <span className={props.className}>
      {textComponents.map((textComponent, index) => {
        // is this component a link?
        if (regLink.test(textComponent)) {
          return (
            <a
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              target="_blank"
              rel="nofollow noopener noreferrer"
              href={textComponent}
              className={classnames('g_link', linkClassName)}>
              {textComponent}
            </a>
          );
        }

        return (
          <span // eslint-disable-next-line react/no-array-index-key
            key={index}>
            {textComponent}
          </span>
        );
      })}
    </span>
  );
}

export default ParseLinks;
