// @flow
import { gql } from '@apollo/client';

export const MARK_NEWS_READ = gql`
  mutation markNewsAsRead($newestNewsId: ID!) {
    markNewsAsRead(newestNewsId: $newestNewsId) {
      id
      total
      unseen
      oldestId
      newestId
      items {
        id
      }
    }
  }
`;
