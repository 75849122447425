/* @flow */

import { createContext, useContext, Component } from 'react';
import { Instance as Translator, translatorType } from 'counterpart';
import damalsGermanLocale from 'damals/locales/de';
import counterpartGermanLocale from 'counterpart/locales/de';

import en from '../locales/en';
import de from '../locales/de';

export function createTranslator(locale: string) {
  const translator = new Translator();

  translator.registerTranslations('en', en);
  translator.registerTranslations('de', de);
  translator.registerTranslations('de', counterpartGermanLocale);
  translator.registerTranslations('de', damalsGermanLocale);
  translator.setLocale(locale);

  return translator;
}

const TranslatorContext = createContext();

// used on client side
export const TranslatorProvider = TranslatorContext.Provider;

export function useTranslator() {
  const translator = useContext(TranslatorContext);

  if (!translator) {
    throw new Error('missing TranslatorContext.Provider component');
  }

  return translator;
}

// TODO: used on server side but we should be good with the client solution above, somehow it didn't work
class FakeTranslator extends Component {
  getChildContext() {
    return {
      translator: this.props.translator,
    };
  }

  render() {
    return this.props.children;
  }
}

FakeTranslator.childContextTypes = {
  translator: translatorType,
};

export const FakeTranslatorProvider = FakeTranslator;
