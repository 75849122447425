/* @flow */
import { connect } from 'react-redux';

import {
  getIsMobile,
  getIsBuyer,
  getIsLoginViaParam,
  getIsAuthenticated,
} from '../../../reducers/';

import Footer from './footer.jsx';

const mapStateToProps = (state) => ({
  isBuyer: getIsBuyer(state),
  isAuthenticated: getIsAuthenticated,
  isMobile: getIsMobile(state),
  isLoginViaParam: getIsLoginViaParam(state),
});

export default connect(mapStateToProps)(Footer);
