/* @flow */
/* Lightbox strip */
import t from 'counterpart';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from '@emotion/styled';

import { Spinner, Text, Flex, Box } from '@eyeem-ui/atoms';
import { ThemeProvider } from '@eyeem-ui/theme';
import { LightboxAdd } from '@eyeem-ui/icons';

import T from '../../general/translate.jsx';
import Link from '../../general/link/';
import PhotoStrip from '../../general/photoStrip/';

import SelectLightboxDropdown from '../selectLightboxDropdown/';

import { isPaginatableEmpty } from '../../../helpers/tools';
import theme from '../../../helpers/theme';

const StyledEmptyStateFlex = styled(Flex)`
  height: 100%;
`;
type Props = {
  isLightboxStripOpen: boolean,
  isLightboxAnimDisabled: boolean,
  selectedLightbox?: {
    lightbox?: EyeEmLightbox,
  },
  isSideNavOpen: boolean, // eslint-disable-line
  hasSideNav: boolean,
  isMobile: boolean, // eslint-disable-line
};

const getSideGap = (props: Props) => {
  if (props.isMobile) {
    return 0;
  }

  if (props.isSideNavOpen) {
    return theme.openSideNavWidth;
  }

  return theme.sideNavWidth;
};

function LightboxStrip(props: Props) {
  if (props.isLightboxAnimDisabled) {
    return null;
  }

  const paginatable = props.selectedLightbox?.lightbox?.items;

  let stripContent;
  if (
    !props.selectedLightbox?.lightbox ||
    (paginatable && isPaginatableEmpty(paginatable))
  ) {
    // no selected lightbox or empty show empty
    stripContent = (
      <ThemeProvider>
        <StyledEmptyStateFlex alignItems="center" pl={3}>
          <Text color="grey100" variant="body">
            {t('lightboxes.strip.empty.first')}
          </Text>
          <Box px={2}>
            <LightboxAdd />
          </Box>
          <Text color="grey100" variant="body">
            {t('lightboxes.strip.empty.second')}
          </Text>
        </StyledEmptyStateFlex>
      </ThemeProvider>
    );
  } else if (!paginatable || props.selectedLightbox?.loading) {
    // if pending, show spinner
    stripContent = (
      <div className="lightboxStrip_left lightboxStrip_left-text">
        <ThemeProvider>
          <Flex alignItems="center">
            <Box mr="2">
              <Spinner inline size="24" />
            </Box>
            <Text>{t('lightboxes.strip.loading')}</Text>
          </Flex>
        </ThemeProvider>
      </div>
    );
  } else if (paginatable && !isPaginatableEmpty(paginatable)) {
    // show photostrip
    stripContent = (
      <PhotoStrip
        translatePrefix="lightboxes.strip"
        className="lightboxStrip_left"
        floatButton={<div className="lightboxStrip_left_float" />}
        paginatable={paginatable}
        lightboxId={props.selectedLightbox?.lightbox?.id}
      />
    );
  }

  const sideNavGap = getSideGap(props);
  const lightboxStyle = {
    left: props.hasSideNav ? sideNavGap : 0,
  };

  return (
    <div>
      <TransitionGroup>
        {props.isLightboxStripOpen && (
          <CSSTransition
            classNames="transition_lightBox"
            timeout={{ enter: 500, exit: 500 }}>
            <div className="lightboxStrip" style={lightboxStyle}>
              {stripContent}
              <div className="lightboxStrip_right">
                <SelectLightboxDropdown />
                <T
                  component={Link}
                  useStyledButton
                  fullWidth
                  color="ghostWhite"
                  size="small"
                  className="typo-topMarginBoost-small2 text-centered"
                  eventType={t('tracking.eventType.inbound')}
                  eventAction={t('tracking.eventAction.button')}
                  eventName={t('lightboxes.strip.manage.eventName')}
                  eventLabel={t('lightboxes.strip.manage.label')}
                  eventPosition={t('lightboxes.strip.eventPosition')}
                  href="/lightboxes">
                  lightboxes.strip.manage.label
                </T>
                {props.selectedLightbox?.lightbox?.name && (
                  <T
                    eventType={t('tracking.eventType.inbound')}
                    eventAction={t('tracking.eventAction.button')}
                    eventName={t('lightboxes.strip.viewAllPhotos.eventName')}
                    eventLabel={props.selectedLightbox.lightbox.name}
                    eventPosition={t('lightboxes.strip.eventPosition')}
                    eventOption={`lightboxId=${props.selectedLightbox.lightbox.id}`}
                    component={Link}
                    useStyledButton
                    fullWidth
                    color="ghostWhite"
                    size="small"
                    href={`/lightboxes/${props.selectedLightbox.lightbox.id}`}
                    className="typo-topMarginBoost-small2 text-centered">
                    lightboxes.strip.viewAllPhotos.label
                  </T>
                )}
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {props.isLightboxStripOpen && (
          <CSSTransition
            classNames="transition_lightboxGhost"
            timeout={{ enter: 500, exit: 500 }}>
            <div className="lightboxGhost" />
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
}

export default LightboxStrip;
