/* @flow */
import type { Node } from 'react';

import { useState, useRef } from 'react';
import { FormRenderProps } from 'react-final-form';
import styled from '@emotion/styled';
import { Text, Box } from '@eyeem-ui/atoms';

type Props = {
  label: string,
  trigger: Node,
  clearInputTrigger: Node,
} & FormRenderProps;

const RemoveTriggerWrapper = styled.div`
  height: 40px;
  padding-top: 10px;
`;

const RemoveTrigger = styled.div`
  float: right;
`;

const FileNameWrapper = styled.div`
  width: calc(100% - 70px);
  overflow: hidden;
`;

const StyledInput = styled.input`
  display: none;
`;

const StyledRow = styled.div`
  position: relative;
`;

const FileUpload = (props: Props) => {
  const [fileName, setFileName] = useState('');
  const inputRef = useRef();

  const handleChange = (event: SyntheticInputEvent<HTMLElement>) => {
    setFileName(event.target.files[0].name);
    return props.input.onChange(event.target.files[0]);
  };

  const clearInput = () => {
    setFileName('');
    inputRef.current.value = '';
    return props.input.onChange('');
  };

  const handleBlur = (event: SyntheticInputEvent<HTMLElement>) =>
    event.preventDefault();

  const errorMessage = props.meta.touched && props.meta.error;
  const { value, ...inputProps } = props.input;

  return (
    <StyledRow>
      {!props.input.value && (
        <Box>
          <label htmlFor="fileupload">{props.trigger}</label>
        </Box>
      )}
      {props.input.value && (
        <RemoveTriggerWrapper>
          <RemoveTrigger onClick={clearInput}>
            {props.clearInputTrigger}
          </RemoveTrigger>
          <FileNameWrapper>
            <Text>{fileName}</Text>
          </FileNameWrapper>
        </RemoveTriggerWrapper>
      )}
      <StyledInput
        ref={inputRef}
        {...inputProps}
        filename={fileName}
        onChange={handleChange}
        onBlur={handleBlur}
        accept=".pdf,.jpeg,.jpg,.png"
        type="file"
        id="fileupload"
      />
      {errorMessage && (
        <Box mt={2}>
          <Text variant="title6" bold color="red50">
            {errorMessage}
          </Text>
        </Box>
      )}
    </StyledRow>
  );
};

export default FileUpload;
