import { connect } from 'react-redux';
import {
  resetUpload,
  shouldAskForNavigationConfirmation,
} from '../../../helpers/actionCreators/redux';
import ConfirmNavigation from './confirmNavigation.jsx';

export default connect(null, {
  resetUpload,
  shouldAskForNavigationConfirmation,
})(ConfirmNavigation);
