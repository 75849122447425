/* @flow */
/*
  NOTE: Temp Flags, Session Fields and Backend Flags:
  Temp Flags are stored only temporarily on client side and will be wiped out on page reload
  or if a hard link was clicked (e.g EyeEm Logo).
  Current use cases are the animation on/off state of the Purchase History button or slider
  positions.
  If you need something with longer TTL you should go for Session Fields and if it needs to be
  accessible by other clients (i.e. Android/ iOS), you need a Backend Flag (aka Auth User News
  Settings).
*/

import { SET_TEMP_FIELD } from '../constants/actionTypes';

export default function tempFields(
  state: TempFieldsState = {},
  action: ReduxAction<Action>
) {
  switch (action.type) {
    case SET_TEMP_FIELD:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    default:
      return state;
  }
}

export const getTempField = (state: TempFieldsState, name: string) =>
  state[name];
