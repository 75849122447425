/* @flow */
import t from 'counterpart';
import find from 'lodash/find';

import {
  SEARCH_FILTER_MARKET_STATUS_AVAILABLE,
  SEARCH_FILTER_MARKET_STATUS_EDITORIAL,
  SEARCH_FILTER_MARKET_STATUS_COMMERCIAL,
  SEARCH_FILTER_MARKET_STATUS_NOT_AVAILABLE,
  SEARCH_FILTER_MARKET_SCORE_GREAT,
  SEARCH_FILTER_MARKET_SCORE_POOR,
  SEARCH_FILTER_MARKET_SCORE_AVERAGE,
  SEARCH_FILTER_MARKET_SCORE_GOOD,
  SEARCH_FILTER_MARKET_SCORE_VERY_GOOD,
  SEARCH_FILTER_PRODOWNLOADS_ONLY_NEW,
  SEARCH_FILTER_PRODOWNLOADS_ONLY_OLD,
  SEARCH_FILTER_FORMATS_PORTRAIT,
  SEARCH_FILTER_FORMATS_LANDSCAPE,
  SEARCH_FILTER_FORMATS_SQUARE,
  SEARCH_FILTER_FORMATS_WIDESCREEN,
  SEARCH_FILTER_FORMATS_PANORAMA,
  SEARCH_FILTER_STOCK_NOSTOCK,
  SEARCH_FILTER_STOCK_FLAGGED,
  SEARCH_FILTER_STOCK_CONFIRMED,
  SEARCH_FILTER_MIN_RESOLUTION_ANY,
  SEARCH_FILTER_MIN_RESOLUTION_3MP,
  SEARCH_FILTER_MIN_RESOLUTION_9MP,
  SEARCH_FILTER_MIN_RESOLUTION_12MP,
  SEARCH_FILTER_MIN_RESOLUTION_16MP,
  SEARCH_FILTER_RELEASED_MODEL,
  SEARCH_FILTER_PEOPLE_NO_PEOPLE,
  SEARCH_FILTER_PEOPLE_ONE_PERSON,
  SEARCH_FILTER_PEOPLE_TWO_PEOPLE,
  SEARCH_FILTER_PEOPLE_GROUP_OF_PEOPLE,
  SEARCH_FILTER_UPLOADED_SINCE_ANY,
  SEARCH_FILTER_UPLOADED_SINCE_7_DAYS,
  SEARCH_FILTER_UPLOADED_SINCE_14_DAYS,
  SEARCH_FILTER_UPLOADED_SINCE_1_MONTH,
  SEARCH_FILTER_UPLOADED_SINCE_2_MONTHS,
  SEARCH_FILTER_UPLOADED_SINCE_6_MONTHS,
  SEARCH_FILTER_UPLOADED_SINCE_1_YEAR,
  SEARCH_FILTER_REVIEWED_AFTER_ANY,
  SEARCH_FILTER_REVIEWED_AFTER_7_DAYS,
  SEARCH_FILTER_REVIEWED_AFTER_14_DAYS,
  SEARCH_FILTER_REVIEWED_AFTER_1_MONTH,
  SEARCH_FILTER_REVIEWED_AFTER_2_MONTHS,
  SEARCH_FILTER_REVIEWED_AFTER_6_MONTHS,
  SEARCH_FILTER_REVIEWED_AFTER_1_YEAR,
  SEARCH_FILTER_SIGNEDUP_AFTER_ANY,
  SEARCH_FILTER_SIGNEDUP_AFTER_7_DAYS,
  SEARCH_FILTER_SIGNEDUP_AFTER_14_DAYS,
  SEARCH_FILTER_SIGNEDUP_AFTER_1_MONTH,
  SEARCH_FILTER_SIGNEDUP_AFTER_2_MONTHS,
  SEARCH_FILTER_SIGNEDUP_AFTER_6_MONTHS,
  SEARCH_FILTER_SIGNEDUP_AFTER_1_YEAR,
  SEARCH_FILTER_AGE_GROUP_BABY,
  SEARCH_FILTER_AGE_GROUP_CHILD,
  SEARCH_FILTER_AGE_GROUP_TEENAGER,
  SEARCH_FILTER_AGE_GROUP_YOUNG_ADULT,
  SEARCH_FILTER_AGE_GROUP_MID_ADULT,
  SEARCH_FILTER_AGE_GROUP_MATURE_ADULT,
  SEARCH_FILTER_AGE_GROUP_SENIOR,
  SEARCH_FILTER_ABSTRACT_CONCEPTS_CLOSE_UP,
  SEARCH_FILTER_ABSTRACT_CONCEPTS_DIRECTLY_ABOVE,
  SEARCH_FILTER_ABSTRACT_CONCEPTS_AERIAL_VIEW,
  SEARCH_FILTER_ABSTRACT_CONCEPTS_LOOKING_AT_CAMERA,
  SEARCH_FILTER_ABSTRACT_CONCEPTS_DIMINISHING_PERSPECTIVE,
  SEARCH_FILTER_ABSTRACT_CONCEPTS_BLURRED,
  SEARCH_FILTER_ABSTRACT_CONCEPTS_BACKGROUNDS,
  SEARCH_FILTER_ABSTRACT_CONCEPTS_COPY_SPACE,
  SEARCH_FILTER_ABSTRACT_CONCEPTS_SILHOUTTE,
  SEARCH_FILTER_GRAYSCALE_ANY,
  SEARCH_FILTER_GRAYSCALE_COLOR,
  SEARCH_FILTER_GRAYSCALE_BLACK_AND_WHITE,
  SEARCH_FILTER_REPLACE_QUERY_TRUE,
  SEARCH_FILTER_REPLACE_QUERY_FALSE,
  SEARCH_FILTER_COLLECTION_ESSENTIAL,
  SEARCH_FILTER_COLLECTION_PREMIUM,
  SEARCH_FILTER_COLLECTION_MIXED,
} from '../constants/misc';

import { isPhotographer, isBuyer, isBCG, isAdmin } from './accounts';

export const getAllFilters = ({
  authUser,
  facets,
}: {
  authUser: AuthUser,
  facets?: SearchFacets,
}): SearchFilter[] => {
  let marketStatusOptions = [
    {
      value: SEARCH_FILTER_MARKET_STATUS_AVAILABLE,
      text: isPhotographer(authUser)
        ? 'search.marketStatus.available'
        : 'search.marketStatus.includeEditorial',
    },
  ];

  if (isAdmin(authUser)) {
    marketStatusOptions = [
      ...marketStatusOptions,
      {
        value: SEARCH_FILTER_MARKET_STATUS_EDITORIAL,
        text: 'search.marketStatus.editorial',
      },
      {
        value: SEARCH_FILTER_MARKET_STATUS_COMMERCIAL,
        text: 'search.marketStatus.commercial',
      },
      {
        value: SEARCH_FILTER_MARKET_STATUS_NOT_AVAILABLE,
        text: 'search.marketStatus.notAvailable',
      },
    ];
  }

  const filters = [
    // empty filter, with just a name, we need that right now to have this not filtered out
    { name: 'albumId' },
    {
      name: 'collection',
      displayName: 'Quality',
      type: 'radio',
      multipleChoice: false,
      options: [
        {
          value: SEARCH_FILTER_COLLECTION_MIXED,
          text: `search.collections.${SEARCH_FILTER_COLLECTION_MIXED}`,
        },
        {
          value: SEARCH_FILTER_COLLECTION_PREMIUM,
          text: `search.collections.${SEARCH_FILTER_COLLECTION_PREMIUM}`,
        },
        {
          value: SEARCH_FILTER_COLLECTION_ESSENTIAL,
          text: `search.collections.${SEARCH_FILTER_COLLECTION_ESSENTIAL}`,
        },
      ].filter((item) => item),
    },
    {
      name: 'locations',
      displayName: t('search.labels.locations'),
      type: 'checkbox',
      multipleChoice: true,
      options: facets?.locations?.elements
        ? facets.locations.elements.map((location) => ({
            noTranslate: true,
            text: location.key,
            value: location.key,
          }))
        : [],
    },
    {
      name: 'marketStatus',
      displayName: (isBuyer(authUser) && t('search.labels.usage')) || '',
      type: marketStatusOptions.length === 1 ? 'checkbox' : 'radio',
      multipleChoice: marketStatusOptions.length === 1,
      options: marketStatusOptions,
    },
    {
      name: 'marketScore',
      type: 'checkbox',
      multipleChoice: true,
      options: [
        {
          value: SEARCH_FILTER_MARKET_SCORE_GREAT,
          text: 'search.marketScore.great',
        },
        {
          value: SEARCH_FILTER_MARKET_SCORE_VERY_GOOD,
          text: 'search.marketScore.very_good',
        },
        {
          value: SEARCH_FILTER_MARKET_SCORE_GOOD,
          text: 'search.marketScore.good',
        },
        {
          value: SEARCH_FILTER_MARKET_SCORE_AVERAGE,
          text: 'search.marketScore.average',
        },
        {
          value: SEARCH_FILTER_MARKET_SCORE_POOR,
          text: 'search.marketScore.poor',
        },
      ],
    },
    {
      name: 'uploadedAfter',
      type: 'radio',
      multipleChoice: false,
      options: [
        {
          value: SEARCH_FILTER_UPLOADED_SINCE_ANY,
          text: 'search.uploadedAfter.any',
        },
        {
          value: SEARCH_FILTER_UPLOADED_SINCE_7_DAYS,
          text: 'search.uploadedAfter.sevenDays',
        },
        {
          value: SEARCH_FILTER_UPLOADED_SINCE_14_DAYS,
          text: 'search.uploadedAfter.fourteenDays',
        },
        {
          value: SEARCH_FILTER_UPLOADED_SINCE_1_MONTH,
          text: 'search.uploadedAfter.oneMonth',
        },
        {
          value: SEARCH_FILTER_UPLOADED_SINCE_2_MONTHS,
          text: 'search.uploadedAfter.twoMonths',
        },
        {
          value: SEARCH_FILTER_UPLOADED_SINCE_6_MONTHS,
          text: 'search.uploadedAfter.sixMonths',
        },
        {
          value: SEARCH_FILTER_UPLOADED_SINCE_1_YEAR,
          text: 'search.uploadedAfter.oneYear',
        },
      ],
    },
    {
      name: 'userSignedUpAfter',
      type: 'radio',
      multipleChoice: false,
      options: [
        {
          value: SEARCH_FILTER_SIGNEDUP_AFTER_ANY,
          text: 'search.userSignedUpAfter.any',
        },
        {
          value: SEARCH_FILTER_SIGNEDUP_AFTER_7_DAYS,
          text: 'search.userSignedUpAfter.sevenDays',
        },
        {
          value: SEARCH_FILTER_SIGNEDUP_AFTER_14_DAYS,
          text: 'search.userSignedUpAfter.fourteenDays',
        },
        {
          value: SEARCH_FILTER_SIGNEDUP_AFTER_1_MONTH,
          text: 'search.userSignedUpAfter.oneMonth',
        },
        {
          value: SEARCH_FILTER_SIGNEDUP_AFTER_2_MONTHS,
          text: 'search.userSignedUpAfter.twoMonths',
        },
        {
          value: SEARCH_FILTER_SIGNEDUP_AFTER_6_MONTHS,
          text: 'search.userSignedUpAfter.sixMonths',
        },
        {
          value: SEARCH_FILTER_SIGNEDUP_AFTER_1_YEAR,
          text: 'search.userSignedUpAfter.oneYear',
        },
      ],
    },
    {
      name: 'stock',
      type: 'checkbox',
      multipleChoice: true,
      options: [
        {
          value: SEARCH_FILTER_STOCK_NOSTOCK,
          text: 'search.stock.noStock',
        },
        {
          value: SEARCH_FILTER_STOCK_FLAGGED,
          text: 'search.stock.flagged',
        },
        {
          value: SEARCH_FILTER_STOCK_CONFIRMED,
          text: 'search.stock.confirmed',
        },
      ],
    },

    {
      name: 'proDownloads',
      type: 'radio',
      multipleChoice: false,
      options: [
        {
          value: SEARCH_FILTER_PRODOWNLOADS_ONLY_NEW,
          text: `search.proDownloadsFilter.newPhotos.${
            isBCG(authUser) ? 'bcg' : 'enterprise'
          }`,
        },
        {
          value: SEARCH_FILTER_PRODOWNLOADS_ONLY_OLD,
          text: `search.proDownloadsFilter.downloaded.${
            isBCG(authUser) ? 'bcg' : 'enterprise'
          }`,
        },
      ],
    },
    {
      name: 'replaceQuery',
      type: 'radio',
      multipleChoice: false,
      options: [
        {
          value: SEARCH_FILTER_REPLACE_QUERY_TRUE,
          text: 'search.replaceQuery.replace',
        },
        {
          value: SEARCH_FILTER_REPLACE_QUERY_FALSE,
          text: 'search.replaceQuery.dontReplace',
        },
      ],
    },
    {
      name: 'formats',
      type: 'checkbox',
      multipleChoice: true,
      options: [
        {
          value: SEARCH_FILTER_FORMATS_PORTRAIT,
          text: 'search.formats.portrait',
        },
        {
          value: SEARCH_FILTER_FORMATS_LANDSCAPE,
          text: 'search.formats.landscape',
        },
        {
          value: SEARCH_FILTER_FORMATS_SQUARE,
          text: 'search.formats.square',
        },
        {
          value: SEARCH_FILTER_FORMATS_WIDESCREEN,
          text: 'search.formats.widescreen',
        },
        {
          value: SEARCH_FILTER_FORMATS_PANORAMA,
          text: 'search.formats.panorama',
        },
      ],
    },
    {
      name: 'minResolution',
      type: 'radio',
      multipleChoice: false,
      options: [
        {
          value: SEARCH_FILTER_MIN_RESOLUTION_ANY,
          text: 'search.minResolution.any',
        },
        {
          value: SEARCH_FILTER_MIN_RESOLUTION_3MP,
          text: 'search.minResolution.three',
        },
        {
          value: SEARCH_FILTER_MIN_RESOLUTION_9MP,
          text: 'search.minResolution.nine',
        },
        {
          value: SEARCH_FILTER_MIN_RESOLUTION_12MP,
          text: 'search.minResolution.twelve',
        },
        {
          value: SEARCH_FILTER_MIN_RESOLUTION_16MP,
          text: 'search.minResolution.sixteen',
        },
      ],
    },
    {
      name: 'grayscale',
      type: 'radio',
      multipleChoice: false,
      options: [
        {
          value: SEARCH_FILTER_GRAYSCALE_ANY,
          text: 'search.grayscale.any',
        },
        {
          value: SEARCH_FILTER_GRAYSCALE_COLOR,
          text: 'search.grayscale.color',
        },
        {
          value: SEARCH_FILTER_GRAYSCALE_BLACK_AND_WHITE,
          text: 'search.grayscale.blackAndWhite',
        },
      ],
    },
    {
      name: 'amountOfPeople',
      type: 'checkbox',
      multipleChoice: true,
      options: [
        {
          value: SEARCH_FILTER_PEOPLE_NO_PEOPLE,
          text: 'search.amountOfPeople.noPeople',
        },
        {
          value: SEARCH_FILTER_PEOPLE_ONE_PERSON,
          text: 'search.amountOfPeople.onePerson',
        },
        {
          value: SEARCH_FILTER_PEOPLE_TWO_PEOPLE,
          text: 'search.amountOfPeople.twoPeople',
        },
        {
          value: SEARCH_FILTER_PEOPLE_GROUP_OF_PEOPLE,
          text: 'search.amountOfPeople.groupOfPeople',
        },
      ],
    },
    {
      name: 'released',
      type: 'checkbox',
      multipleChoice: true,
      options: [
        {
          value: SEARCH_FILTER_RELEASED_MODEL,
          text: 'search.released.model',
        },
      ],
    },
    {
      name: 'reviewedAfter',
      type: 'radio',
      multipleChoice: false,
      options: [
        {
          value: SEARCH_FILTER_REVIEWED_AFTER_ANY,
          text: 'search.reviewedAfter.any',
        },
        {
          value: SEARCH_FILTER_REVIEWED_AFTER_7_DAYS,
          text: 'search.reviewedAfter.sevenDays',
        },
        {
          value: SEARCH_FILTER_REVIEWED_AFTER_14_DAYS,
          text: 'search.reviewedAfter.fourteenDays',
        },
        {
          value: SEARCH_FILTER_REVIEWED_AFTER_1_MONTH,
          text: 'search.reviewedAfter.oneMonth',
        },
        {
          value: SEARCH_FILTER_REVIEWED_AFTER_2_MONTHS,
          text: 'search.reviewedAfter.twoMonths',
        },
        {
          value: SEARCH_FILTER_REVIEWED_AFTER_6_MONTHS,
          text: 'search.reviewedAfter.sixMonths',
        },
        {
          value: SEARCH_FILTER_REVIEWED_AFTER_1_YEAR,
          text: 'search.reviewedAfter.oneYear',
        },
      ],
    },
    {
      name: 'ageGroups',
      type: 'checkbox',
      multipleChoice: true,
      options: [
        {
          value: SEARCH_FILTER_AGE_GROUP_BABY,
          text: 'search.ageGroups.baby',
        },
        {
          value: SEARCH_FILTER_AGE_GROUP_CHILD,
          text: 'search.ageGroups.child',
        },
        {
          value: SEARCH_FILTER_AGE_GROUP_TEENAGER,
          text: 'search.ageGroups.teenager',
        },
        {
          value: SEARCH_FILTER_AGE_GROUP_YOUNG_ADULT,
          text: 'search.ageGroups.young_adult',
        },
        {
          value: SEARCH_FILTER_AGE_GROUP_MID_ADULT,
          text: 'search.ageGroups.mid_adult',
        },
        {
          value: SEARCH_FILTER_AGE_GROUP_MATURE_ADULT,
          text: 'search.ageGroups.mature_adult',
        },
        {
          value: SEARCH_FILTER_AGE_GROUP_SENIOR,
          text: 'search.ageGroups.senior',
        },
      ],
    },
    {
      name: 'abstractConcepts',
      type: 'checkbox',
      multipleChoice: true,
      options: [
        {
          value: SEARCH_FILTER_ABSTRACT_CONCEPTS_CLOSE_UP,
          text: 'search.abstractConcepts.close_up',
        },
        {
          value: SEARCH_FILTER_ABSTRACT_CONCEPTS_LOOKING_AT_CAMERA,
          text: 'search.abstractConcepts.looking_at_camera',
        },
        {
          value: SEARCH_FILTER_ABSTRACT_CONCEPTS_AERIAL_VIEW,
          text: 'search.abstractConcepts.aerial_view',
        },
        {
          value: SEARCH_FILTER_ABSTRACT_CONCEPTS_DIRECTLY_ABOVE,
          text: 'search.abstractConcepts.directly_above',
        },
        {
          value: SEARCH_FILTER_ABSTRACT_CONCEPTS_COPY_SPACE,
          text: 'search.abstractConcepts.copy_space',
        },
        {
          value: SEARCH_FILTER_ABSTRACT_CONCEPTS_BACKGROUNDS,
          text: 'search.abstractConcepts.backgrounds',
        },
        {
          value: SEARCH_FILTER_ABSTRACT_CONCEPTS_SILHOUTTE,
          text: 'search.abstractConcepts.silhouette',
        },
        {
          value: SEARCH_FILTER_ABSTRACT_CONCEPTS_BLURRED,
          text: 'search.abstractConcepts.blurred',
        },
        {
          value: SEARCH_FILTER_ABSTRACT_CONCEPTS_DIMINISHING_PERSPECTIVE,
          text: 'search.abstractConcepts.diminishing_perspective',
        },
      ],
    },
  ];

  return filters;
};

const isFilterVisible = (authUser: AuthUser) => (filter: SearchFilter) => {
  switch (filter.name) {
    case 'albumId':
    case 'proDownloads':
    case 'replaceQuery':
      return false;
    case 'locations': // facet
    case 'marketScore':
    case 'stock':
    case 'uploadedAfter':
    case 'userSignedUpAfter':
      return isAdmin(authUser);
    case 'ageGroups':
    case 'amountOfPeople':
    case 'released':
    case 'reviewedAfter':
      return isBuyer(authUser) || isAdmin(authUser);
    case 'collection':
      return !isBCG(authUser) && (isBuyer(authUser) || isAdmin(authUser));
    case 'marketStatus':
      return isPhotographer(authUser) || isAdmin(authUser);
    default:
      return true;
  }
};

export const getVisibleFilters = ({
  authUser,
  facets,
}: {
  authUser: AuthUser,
  facets?: SearchFacets,
}): SearchFilter[] =>
  getAllFilters({ authUser, facets }).filter(isFilterVisible(authUser));

export const getFilter = ({
  authUser,
  facets,
  filterType,
}: {
  authUser: AuthUser,
  facets?: SearchFacets,
  filterType: FilterType,
}): SearchFilter | void =>
  find(getAllFilters({ authUser, facets }), { name: filterType });
